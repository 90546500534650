import { Grid } from "@material-ui/core";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import DetailCard, { DetailCardItem } from "../../../Common/DetailCard";
import {
  RowDetailButtonDelete,
  RowDetailButtonEdit,
} from "../../../Common/RowDetailButtons";
import SmartGridItem from "../../../Common/SmartGridItem";
import { UserRights, UserRightsFinancial } from "../../models/progmaUserRights";
import { CellRowPanel } from "../../../Common/TableComponents";

export default function ProjectFreeRowDetail({ row }: any) {
  const projectFreeId: number = row.id;

  const {
    projectFreeStore: {
      projectFreeList,
      deleteProjectFree,
      projectAndSubjectId,
    },
    projectFreeFormStore: { openProjectFreeForm },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  const projectFree = projectFreeList.find((x) => x.id === projectFreeId)!;

  let detailCardItems: DetailCardItem[] = [
    { title: "Aantal", value: projectFree.quantity, type: "number" },
    { title: "Omschrijving", value: projectFree.description },
    {
      title: "Verkoopprijs",
      value: projectFree.sellingPrice,
      type: "currency",
    },
    {
      title: "Verkoopprijs Totaal",
      value: projectFree.sellingPriceTotal,
      type: "currency",
    },
  ];

  if (
    progmaUserRights?.projectFreeUserRightsFinancial ===
    UserRightsFinancial.None
  ) {
    detailCardItems = detailCardItems.filter(
      (x) => x.title !== "Verkoopprijs" && x.title !== "Verkoopprijs Totaal"
    );
  }

  return (
    //<div style={{backgroundColor: "beige"}}><h3>nollo</h3></div>
    <CellRowPanel>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <SmartGridItem>
          <DetailCard detailCardItems={detailCardItems} />
        </SmartGridItem>

        {progmaUserRights?.projectFreeUserRights === UserRights.Editing && (
          <SmartGridItem>
            <ToolStrip
              flexDirection="column"
              alignItems="start"
              spacing={0.5}
              fullwidth
            >
              {projectAndSubjectId && (
                <RowDetailButtonEdit
                  onClick={() =>
                    openProjectFreeForm(projectAndSubjectId, projectFreeId)
                  }
                />
              )}
              <RowDetailButtonDelete
                onDeleteConfirmed={() => deleteProjectFree(projectFreeId)}
              />
            </ToolStrip>
          </SmartGridItem>
        )}
      </Grid>
    </CellRowPanel>
  );
}
