import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  useMediaQuery,
} from "@material-ui/core";

import {
  DragDropProvider,
  Grid,
  GroupingPanel,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import {
  Column,
  GridColumnExtension,
  Filter,
  FilteringState,
  Grouping,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SearchState,
  SelectionState,
  Sorting,
  SortingState,
} from "@devexpress/dx-react-grid";
import theme from "../themes/theme";
import { useCallback, useEffect, useRef } from "react";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import { exportAsPDF, ExportFormats, gridExportOnSave } from "./Functions";
import {
  CellBoolean,
  CellRowDetail,
  CurrencyTypeProvider,
  HeaderTitle,
  NumberTypeProvider,
  PercentTypeProvider,
} from "./TableComponents";
import { TableConstants } from "./Constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRoot: {
      width: "100%",
    },
  })
);

export enum ColumnType {
  string,
  number,
  percent,
  boolean,
  currency,
  date,
}

export interface AppColumn extends Column {
  columnType?: ColumnType;
  width?: number | string;
  align?: "left" | "right" | "center";
  wordWrapEnabled?: boolean;
  visible?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
}

interface Props {
  tableName: string;
  columns: AppColumn[];
  rows: any[];
  rowDetail: React.ComponentType<TableRowDetail.ContentProps> | undefined;
  tableStateValues: TableStateValues;
  setTableStateValues: (tableStateValues: TableStateValues) => void;
  exportTable?: { exportId: number; exportFormat: ExportFormats };
}

export default function AppTable({
  tableName,
  columns,
  rows,
  rowDetail,
  tableStateValues,
  setTableStateValues,
  exportTable,
}: Props) {

  const exporterRef = useRef(null);

  const startGridExport = useCallback(() => {
    if (exporterRef.current && typeof exporterRef.current === "object") {
      const gridExporter: any = exporterRef.current;
      gridExporter.exportGrid();
    }
  }, [exporterRef]);


  const startExportTable = useCallback(() => {
    if (exportTable!.exportFormat === ExportFormats.pdf) {
      exportAsPDF(tableName, columns, rows);
    } else {
      startGridExport();
    }
  }, [exportTable, tableName, columns, rows, startGridExport]);
  
  useEffect(() => {
    if (exportTable !== undefined) {
      startExportTable();
    }
  }, [exportTable, startExportTable]);

  const classes = useStyles();

  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.only("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.only("lg"));
  const matchesXl = useMediaQuery(theme.breakpoints.between(theme.breakpoints.values.xl, theme.breakpoints.values.xxl)); //only not working
  //const matchesXxl = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.xxl));


// console.log("matchesXs: " + matchesXs);
// console.log("matchesSm: " + matchesSm);
// console.log("matchesMd: " + matchesMd);
// console.log("matchesLg: " + matchesLg);
// console.log("matchesXl: " + matchesXl);
//console.log("matchesXxl: " + matchesXxl);

  const setHiddenColumnNames = (names: string[]) => {
    if (matchesXs) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesXs: names });
    } else if (matchesSm) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesSm: names });
    } else if (matchesMd) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesMd: names });
    } else if (matchesLg) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesLg: names });
    } else if (matchesXl) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesXl: names });
    } else {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesXxl: names });
    }
  };

  const setSorting = (sorting: Sorting[]) => {
    tableStateValues.sorting = sorting;
    setTableStateValues({ ...tableStateValues });
  };
  const setFilters = (filters: Filter[]) => {
    tableStateValues.filters = filters;
    setTableStateValues({ ...tableStateValues });
  };
  const setCurrentPage = (currentPage: number) => {
    tableStateValues.currentPage = currentPage;
    setTableStateValues({ ...tableStateValues });
  };
  const setPageSize = (pageSize: number) => {
    tableStateValues.pageSize = pageSize;
    setTableStateValues({ ...tableStateValues });
  };
  const setExpandedRowIds = (ids: any) => {
    tableStateValues.expandedRowIds =
      ids.length > 1 ? [ids[ids.length - 1]] : ids;
    //tableStateValues.expandedRowIds = ids;
    setTableStateValues({ ...tableStateValues });
  };
  const setGrouping = (grouping: any) => {
    tableStateValues.grouping = grouping;
    setTableStateValues({ ...tableStateValues });
  };
  const setColumnOrder = (columnOrder: string[]) => {
    tableStateValues.columnOrder = columnOrder;
    setTableStateValues({ ...tableStateValues });
  };

  const onSave = (workbook: any) => {
    gridExportOnSave(tableName, workbook, exportTable!.exportFormat!);
  };



  const hiddenColumnNamesToUse = matchesXs
    ? tableStateValues.hiddenColumnNamesXs
    : matchesSm
    ? tableStateValues.hiddenColumnNamesSm
    : matchesMd
    ? tableStateValues.hiddenColumnNamesMd
    : matchesLg
    ? tableStateValues.hiddenColumnNamesLg
    : matchesXl
    ? tableStateValues.hiddenColumnNamesXl
    : tableStateValues.hiddenColumnNamesXxl;

  const numberColumns = columns
    .filter((x) => x.columnType === ColumnType.number)
    .map((x) => x.name);

  const percentColumns = columns
    .filter((x) => x.columnType === ColumnType.percent)
    .map((x) => x.name);

  const currencyColumns = columns
    .filter((x) => x.columnType === ColumnType.currency)
    .map((x) => x.name);

  const booleanColumns = columns
    .filter((x) => x.columnType === ColumnType.boolean)
    .map((x) => x.name);

  const columnExtensions: GridColumnExtension[] = columns
    .filter(
      (x) =>
        x.width !== undefined ||
        x.align !== undefined ||
        x.wordWrapEnabled !== undefined
    )
    .map((x) => {
      return {
        columnName: x.name,
        width: x.width,
        align: x.align === undefined && 
        (x.columnType === ColumnType.number || x.columnType === ColumnType.percent || x.columnType === ColumnType.currency) ? "right" : x.align,
        wordWrapEnabled: x.wordWrapEnabled,
      };
    });

    const CellComponent = (props: any) => {
      const { column } = props;
      if (booleanColumns.includes(column.name)) {
       return CellBoolean(props) }
       else return <Table.Cell {...props} />; 
    };

    
  return (
    <Paper className={classes.tableRoot}>
      <Grid rows={rows} columns={columns} getRowId={(row) => row.id}>
        <SortingState
          sorting={tableStateValues.sorting}
          onSortingChange={setSorting}
        />
        <IntegratedSorting />

        {tableStateValues.useFilterPanel && (
          <FilteringState
            filters={tableStateValues.filters}
            onFiltersChange={setFilters}
          />
        )}
        {/* {useFilterPanel && <IntegratedFiltering />} */}

        <SearchState
          value={tableStateValues.searchValue}
          //onValueChange={setSearchState} not neccesary
        />
        {/* <SearchState defaultValue='' /> */}
        <IntegratedFiltering />

        {tableStateValues.usePaging && (
          <PagingState
            currentPage={tableStateValues.currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={tableStateValues.pageSize}
            onPageSizeChange={setPageSize}
          />
        )}

        {tableStateValues.usePaging && <IntegratedPaging />}

        {rowDetail && (
          <RowDetailState
            expandedRowIds={tableStateValues.expandedRowIds}
            onExpandedRowIdsChange={(e: any) => setExpandedRowIds(e)}
          />
        )}

        <DragDropProvider />

        <GroupingState
          grouping={tableStateValues.grouping}
          onGroupingChange={setGrouping}
        />
        <IntegratedGrouping />

        {/* {tableStateValues.useGroupPanel && (
              <GroupingState
                grouping={tableStateValues.grouping}
                onGroupingChange={setGrouping}
              />
            )}
            {tableStateValues.useGroupPanel && <IntegratedGrouping />} */}

        <SelectionState
          selection={tableStateValues.expandedRowIds}
          onSelectionChange={setExpandedRowIds}
        />


        {numberColumns !== undefined && numberColumns.length > 0 && (
          <NumberTypeProvider for={numberColumns} />
        )}
        {percentColumns !== undefined && percentColumns.length > 0 && (
          <PercentTypeProvider for={percentColumns} />
        )}
        {currencyColumns !== undefined && currencyColumns.length > 0 && (
          <CurrencyTypeProvider for={currencyColumns} />
        )}


        <Table
          messages={TableConstants.tableMessages}
          columnExtensions={columnExtensions}
          cellComponent={CellComponent}
        />

        {/* <TableColumnResizing defaultColumnWidths={defaultColumnWidths} /> */}
        <TableColumnReordering
          order={tableStateValues.columnOrder}
          onOrderChange={setColumnOrder}
        />
        <TableHeaderRow
          showSortingControls
          titleComponent={HeaderTitle}
          showGroupingControls={tableStateValues.useGroupPanel}
        />

        <TableSelection
          selectByRowClick
          highlightRow={false}
          showSelectionColumn={false}
        />

        <TableGroupRow />

        {/* {tableStateValues.useGroupPanel && <TableGroupRow />} */}

        {rowDetail && (
          <TableRowDetail
            contentComponent={rowDetail}
            cellComponent={CellRowDetail}
          />
        )}


        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNamesToUse}
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />

        {tableStateValues.useGroupPanel && <Toolbar />}

        {tableStateValues.useGroupPanel && (
          <GroupingPanel
            showGroupingControls
            messages={TableConstants.groupingPanelMessages}
          />
        )}

        {tableStateValues.useFilterPanel && (
          <TableFilterRow messages={TableConstants.filterRowMessages} />
        )}

        {/* <Toolbar  />
              <SearchPanel />
              <ColumnChooser /> */}

        {tableStateValues.usePaging && (
          <PagingPanel
            pageSizes={tableStateValues.pageSizes}
            messages={TableConstants.pagingPanelMessages}
          />
        )}
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns}
        onSave={onSave}
      />
    </Paper>
  );
}

export class TableStateValues {
  currentPage = 0;
  pageSize = 25;
  pageSizes = [25, 50, 100, 200, 0];
  searchValue = "";
  sorting: Sorting[] = [];
  expandedRowIds: any = [];
  columnOrder: string[] = [];
  hiddenColumnNamesXs: string[] = [];
  hiddenColumnNamesSm: string[] = [];
  hiddenColumnNamesMd: string[] = [];
  hiddenColumnNamesLg: string[] = [];
  hiddenColumnNamesXl: string[] = [];
  hiddenColumnNamesXxl: string[] = [];
  useGroupPanel = false;
  grouping: Grouping[] = [];
  useFilterPanel = false;
  filters: Filter[] = [];
  usePaging: boolean = true;

  constructor(columns: AppColumn[], sorting: Sorting[], usePaging?: boolean) {
    this.sorting = sorting;
    this.columnOrder = columns.map((x) => x.name);
    this.hiddenColumnNamesXs = columns
      .filter((x) => x.visible === undefined || x.visible !== "xs")
      .map((x) => x.name);
    this.hiddenColumnNamesSm = columns
      .filter(
        (x) =>
          x.visible === undefined || !(x.visible === "xs" || x.visible === "sm")
      )
      .map((x) => x.name);
    this.hiddenColumnNamesMd = columns
      .filter(
        (x) =>
          x.visible === undefined ||
          !(x.visible === "xs" || x.visible === "sm" || x.visible === "md")
      )
      .map((x) => x.name);
    this.hiddenColumnNamesLg = columns
      .filter(
        (x) =>
          x.visible === undefined ||
          !(
            x.visible === "xs" ||
            x.visible === "sm" ||
            x.visible === "md" ||
            x.visible === "lg"
          )
      )
      .map((x) => x.name);
    this.hiddenColumnNamesXl = columns
      .filter(
        (x) =>
          x.visible === undefined ||
          !(
            x.visible === "xs" ||
            x.visible === "sm" ||
            x.visible === "md" ||
            x.visible === "lg" ||
            x.visible === "xl"
          )
      )
      .map((x) => x.name);
      this.hiddenColumnNamesXxl = columns
      .filter(
        (x) =>
          x.visible === undefined ||
          !(
            x.visible === "xs" ||
            x.visible === "sm" ||
            x.visible === "md" ||
            x.visible === "lg" ||
            x.visible === "xl" ||
            x.visible === "xxl"
          )
      )
      .map((x) => x.name);


    if (usePaging !== undefined) this.usePaging = usePaging;
  }
}

// export class ExportHelper {
//   tableName
//   exporterRef = useRef(null);
//   exportFormat: ExportFormats | undefined = undefined;

//   constructor(tableName: string) {this.tableName = tableName};

//   startGridExport = useCallback(() => {
//     if (this.exporterRef.current && typeof this.exporterRef.current === "object") {
//       const gridExporter: any = this.exporterRef.current;
//       gridExporter.exportGrid();
//     }
//   }, [this.exporterRef]);

//   setExportFormat = (format: ExportFormats) => this.exportFormat = format;

//   onSave = (workbook: any) => {
//     if (this.exportFormat !== undefined) gridExportOnSave(this.tableName, workbook, this.exportFormat!);
//   };

// }
