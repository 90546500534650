import { Grid } from "@material-ui/core";
import * as yup from "yup";
import { Formik } from "formik";
import LoadingComponent from "../../../Common/LoadingComponent";
import FDialog from "../../../Common/form/FDialog";
import { observer } from "mobx-react-lite";
import FTextfield from "../../../Common/form/FTextField";
import { useVStore } from "../../stores/vStore";
import { ProjectFreeFormValues } from "../../models/projectFree";
import { useEffect } from "react";
import { ProjectFree } from "../../models/projectFree";
import { UserRightsFinancial } from "../../models/progmaUserRights";
import FNumericTextfield from "../../../Common/form/FNumericTextField";
import FCurrencyTextfield from "../../../Common/form/FCurrencyTextField";
import { toCurrency } from "../../../Common/Functions";

interface Props {
  open: boolean;
  id?: number;
  //projectsAndSubjectsId: number;
  onCreated: (projectFree: ProjectFree) => void;
  onEdited: (projectFree: ProjectFree) => void;
  onClose: () => void;
}

const validationSchema = yup.object({
  quantity: yup
    .number()   
    .required("verplicht veld")
    .notOneOf([0], "Kan niet 0 zijn"),
  description: yup.string().required("verplicht veld"),
  sellingPrice: yup.number().nullable(),
  sellingPriceTotal: yup.number().nullable(),
});

export default observer(function ProjectFreeForm({
  open,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {
    projectFreeFormStore: {
      isLoading,
      loadNeeded,
      load,
      projectFreeFormValues,
      create,
      edit,
    },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  useEffect(() => {
    if (loadNeeded || id) load(id);
  }, [loadNeeded, load, id]);

  if (isLoading)
    return (
      <LoadingComponent
        content="Project vrije invoer formulier laden..."
        useBackdrop={true}
      />
    );

  function handleFormSubmit(projectFreeFormValues: ProjectFreeFormValues) {
    if (!id) {
      create(projectFreeFormValues)
        .then((projectFree) => projectFree && onCreated(projectFree))
        .finally(() => onClose());
    } else {
      edit(projectFreeFormValues)
        .then((projectFree) => projectFree && onEdited(projectFree))
        .finally(() => onClose());
    }
  }


  return (
    <Formik
      enableReinitialize
      initialValues={projectFreeFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({setFieldValue, values}) => {
          const handleChange = (quantity: number | undefined, sellingPrice: number | undefined) => {
            const value = quantity !== undefined && sellingPrice !== undefined ? toCurrency(sellingPrice)?.multiply(quantity).value : undefined
              setFieldValue("sellingPriceTotal", value)
          }

        return (
          <FDialog
            title={
              id && id > 0
                ? "Project vrije invoer aanpassen"
                : "Nieuw Project vrije invoer"
            }
            open={open}
            onClose={onClose}
            breakPointFullScreen="md"
          >
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FNumericTextfield
                    name="quantity"
                    label="Aantal"
                    required
                    onValueChanged={(value) => handleChange(value, values.sellingPrice)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name="description"
                    label="Omschrijving"
                    required
                    autoComplete="off"
                  />
                </Grid>

                {progmaUserRights?.projectFreeUserRightsFinancial !==
                  UserRightsFinancial.None && (
                  <>
                    <Grid item xs={12}>
                      <FCurrencyTextfield
                        name="sellingPrice"
                        label="Verkoopprijs"
                        required={false}
                        onValueChanged={(value) => handleChange(values.quantity, value) }   
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FCurrencyTextfield
                        name="sellingPriceTotal"
                        label="Verkoopprijs totaal"
                        required={false}
                        disabled
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </FDialog>
        );
      }}
    </Formik>
  );
});


