export interface ProgmaUserRights {
    relationsUserRights: UserRights;

    articlesUserRights: UserRights;
    articlesUserRightsFinancial: UserRightsFinancial;

    activitiesUserRights: UserRights;
    activitiesUserRightsFinancial: UserRightsFinancial;

    staffUserRights: UserRights;
    staffUserRightsFinancial: UserRightsFinancial;
    
    attachmentsUserRights: UserRights;
    attachmentsUserRightsFinancial: UserRightsFinancial;

    quotationsUserRights: UserRights;

    packingSlipsUserRights: UserRights;

    invoicingUserRights: UserRights;

    objectsUserRights: UserRights;

    projectsUserRights: UserRights;
    projectsUserRightsFinancial: UserRightsFinancial;

    workOrdersUserRights: UserRights;
    workOrdersUserRightsFinancial: UserRightsFinancial;

    workAllow: boolean;
    workIncludeOthers: boolean;

    projectArticlesAllow: boolean;

    projectPurchaseUserRights: UserRights;
    projectPurchaseUserRightsFinancial: UserRightsFinancial;

    projectFreeUserRights: UserRights;
    projectFreeUserRightsFinancial: UserRightsFinancial;

    approveInputAllow: boolean;

    bookKeepingAllow: boolean;
}


export enum UserRights {
    None,
    AllowOnly,
    Editing
  }

export enum UserRightsFinancial {
    None,
    PricesRates,
    All
  }