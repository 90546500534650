import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../Common/LoadingComponent";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import ProjectFreeRowDetail from "./ProjectFreeRowDetail";
import { UserRightsFinancial } from "../../models/progmaUserRights";
import AppTable, { AppColumn, ColumnType, TableStateValues } from "../../../Common/AppTable";
import { useState } from "react";

export default observer(function ProjectFreeTable() {
  const {
    projectFreeStore: {
      isLoading,
      isLoadingProjectFree,
      projectFreeList,
    },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

let columns: AppColumn[] = [
{name: "quantity", title: "Aantal", visible: "sm", columnType: ColumnType.number, width: 55 },
{name: "description", title: "Omschrijving", visible: "xs", wordWrapEnabled: true },
{ name: "sellingPrice", title: "Verkoopprijs", visible: "lg", columnType:ColumnType.currency , width: 100 },
{ name: "sellingPriceTotal", title: "Totaal", visible: "lg",  columnType:ColumnType.currency, width: 120 },
];

const [tableStateValues, setTableStateValues] = useState(
  () =>
    new TableStateValues(
      columns,
      [],
      false
    )
);

  if (
    progmaUserRights?.projectFreeUserRightsFinancial ===
    UserRightsFinancial.None
  ) {
    columns = columns.filter(
      (x) => x.name !== "sellingPrice" && x.name !== "sellingPriceTotal"
    );
  }

  const pageContent = isLoadingProjectFree ? (
    <LoadingComponent useBackdrop={false} />
  ) : (
      <div style={{marginTop: theme.spacing(1), minWidth: 288 }}>
        <AppTable
        tableName='Vrije invoer'
        columns={columns}
        rows={projectFreeList}
        rowDetail={ProjectFreeRowDetail}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues}
        />
      </div>
    );

  if (isLoading) return <LoadingComponent useBackdrop={false} />;

  return (pageContent);
});
