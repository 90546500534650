import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Texts } from "../Constants";

interface Props {
  onClick: () => void;
  size?: 'small' | 'medium';
}

export default function AddButton({ onClick, size }: Props) {
  const sizeToUse = size !== undefined ? size : 'medium'
  return (
    <Button
    size={sizeToUse}
    variant='contained'
    color='primary'
    onClick={onClick}
    startIcon={<AddIcon />}
  >
    {Texts.newText}
  </Button>
  );
}
