import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../Pages/NotFound";
import DashboardPage from "./features/Dashboard/DashboardPage";
import Projects from "./features/Projects/Projects";
import Work from "./features/Work/Work";
import MainLayoutV12 from "./layout/MainLayout";
import LoadingComponent from "../Common/LoadingComponent";
import { UserRights } from "./models/progmaUserRights";
import NoUserRights from "../Pages/NoUserRights";
import { observer } from "mobx-react-lite";
import Relations from "./features/Relations/Relations";
import { useVStore } from "./stores/vStore";
import ProjectArticles from "./features/ProjectArticles/ProjectArticles";
import ProjectFree from "./features/ProjectFree/ProjectFree";
import Articles from "./features/Articles/Articles";
import ProjectPurchase from "./features/ProjectPurchase/ProjectPurchase";
import AccountNotLinked from "../Pages/AccountNotLinked";

export default observer(function AppV12() {
  const vStore = useVStore();
  const {
    progmaUserStore: { progmaUserRights, loadProgmaUserRights, isLoading },
  } = vStore;

  useEffect(() => {
    if (!progmaUserRights) loadProgmaUserRights();
  }, [progmaUserRights, loadProgmaUserRights]);

  if (isLoading)
    return (
      <LoadingComponent
        content='Gebruikersrechten laden...'
        useBackdrop={true}
      />
    );

  return (
    <Switch>
      <Route
        exact
        path='/'
        render={() => {
          vStore.dashboardStore.resetStore();
          return (
            <MainLayoutV12 useBackButton={false} pageTitle='Dashboard'>
              <DashboardPage />
            </MainLayoutV12>
          );
        }}
      />

      <Route
        path='/projects'
        render={() => {
          if (progmaUserRights?.projectsUserRights !== UserRights.None) {
            vStore.projectsStore.resetStore();
            vStore.projectFormStore.resetStore();
            return (
              <MainLayoutV12 useBackButton={true} pageTitle='Projecten'>
                <Projects />
              </MainLayoutV12>
            );
          } else {
            return <NoUserRights />;
          }
        }}
      />

      <Route
        path='/work'
        render={() => {
          if (
            vStore.rootStore.appUserStore.appUser?.choosedAdministration
              ?.staffMemberID !== undefined
          ) {
            if (progmaUserRights?.workAllow) {
              vStore.workStore.resetStore();
              vStore.workFormStore.resetStore();
              return (
                <MainLayoutV12 useBackButton={true} pageTitle='Uren invullen'>
                  <Work />
                </MainLayoutV12>
              );
            } else {
              return <NoUserRights />;
            }
          } else {
            return <AccountNotLinked />;
          }
        }}
      />

      <Route
        path='/project-articles'
        render={() => {
          if (
            vStore.rootStore.appUserStore.appUser?.choosedAdministration
              ?.staffMemberID !== undefined
          ) {
            if (progmaUserRights?.projectArticlesAllow) {
              vStore.projectArticlesStore.resetStore();
              vStore.projectArticlesFormStore.resetStore();
              return (
                <MainLayoutV12
                  useBackButton={true}
                  pageTitle='Artikelen invoeren'
                >
                  <ProjectArticles />
                </MainLayoutV12>
              );
            } else {
              return <NoUserRights />;
            }
          } else {
            return <AccountNotLinked />;
          }
        }}
      />

      <Route
        path='/project-purchase'
        render={() => {
          if (
            vStore.rootStore.appUserStore.appUser?.choosedAdministration
              ?.staffMemberID !== undefined
          ) {
            if (
              progmaUserRights?.projectPurchaseUserRights !== UserRights.None
            ) {
              vStore.projectPurchaseStore.resetStore();
              vStore.projectPurchaseFormStore.resetStore();
              return (
                <MainLayoutV12 useBackButton={true} pageTitle='Project inkoop'>
                  <ProjectPurchase />
                </MainLayoutV12>
              );
            } else {
              return <NoUserRights />;
            }
          } else {
            return <AccountNotLinked />;
          }
        }}
      />

      <Route
        path='/project-free'
        render={() => {
          if (
            vStore.rootStore.appUserStore.appUser?.choosedAdministration
              ?.staffMemberID !== undefined
          ) {
            if (progmaUserRights?.projectFreeUserRights !== UserRights.None) {
              vStore.projectFreeStore.resetStore();
              vStore.projectFreeFormStore.resetStore();
              return (
                <MainLayoutV12 useBackButton={true} pageTitle='Vrije invoeren'>
                  <ProjectFree />
                </MainLayoutV12>
              );
            } else {
              return <NoUserRights />;
            }
          } else {
            return <AccountNotLinked />;
          }
        }}
      />

      <Route
        path='/relations'
        render={() => {
          if (progmaUserRights?.relationsUserRights !== UserRights.None) {
            vStore.relationsStore.resetStore();
            vStore.relationFormStore.resetStore();
            return (
              <MainLayoutV12 useBackButton={true} pageTitle='Relaties'>
                <Relations />
              </MainLayoutV12>
            );
          } else {
            return <NoUserRights />;
          }
        }}
      />

      <Route
        path='/articles'
        render={() => {
          if (progmaUserRights?.articlesUserRights !== UserRights.None) {
            vStore.articlesStore.resetStore();
            vStore.articleFormStore.resetStore();
            return (
              <MainLayoutV12 useBackButton={true} pageTitle='Artikelen'>
                <Articles />
              </MainLayoutV12>
            );
          } else {
            return <NoUserRights />;
          }
        }}
      />

      <Route component={NotFound} />
    </Switch>
  );
});
