import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Dialog, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  copyright: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  title: string;
  children?: any;
}

export default function Layout(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function Copyright() {
    return (
      <Typography variant='body2' color='textSecondary' align='center'>
        {"Copyright © Matek Engineering B.V. "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  
  return (
    <Dialog
      open={true}
      fullScreen={fullScreen}
      aria-labelledby='form-dialog-title'
    >
      <Container component='main' maxWidth='xs'>
        <div className={classes.paper}>
          <Box
            style={{ marginBottom: theme.spacing(3) }}
            display='flex'
            flexDirection='row'
            alignItems='center'
          >
            <Box>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            </Box>
            <Box>
              <Typography component='h1' variant='h5'>
                {props.title}
              </Typography>
            </Box>
          </Box>
          {props.children}
          <Box className={classes.copyright}>
            <Copyright />
          </Box>
        </div>
      </Container>
    </Dialog>
  );
}
