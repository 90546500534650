
import { Context, createContext, useContext } from "react";
import { rootStore, RootStore } from "../../stores/rootStore";
import ArticleFormStore from "./articleFormStore";
import ArticlesStore from "./articlesStore";
import AttachmentFormStore from "./attachmentFormStore";
import AttachmentsStore from "./attachmentsStore";
import DashboardStore from "./dashboardStore";
import ProgmaUserStore from "./progmaUserStore";
import ProjectArticleFormStore from "./ProjectArticleFormStore";
import ProjectArticlesStore from "./projectArticlesStore";
import ProjectFormStore from "./ProjectFormStore";
import ProjectFreeFormStore from "./ProjectFreeFormStore";
import ProjectFreeStore from "./projectFreeStore";
import ProjectPurchaseFormStore from "./ProjectPurchaseFormStore";
import ProjectPurchaseStore from "./projectPurchaseStore";
import ProjectsStore from "./projectsStore";
import RelationFormStore from "./RelationFormStore";
import RelationsStore from "./relationsStore";
import WorkFormStore from "./WorkFormStore";
import WorkOrderFormStore from "./WorkOrdersFormStore";
import WorkOrdersStore from "./workOrdersStore";
import WorkStore from "./workStore";

export class VStore {
    rootStore: RootStore;
    progmaUserStore: ProgmaUserStore;
    dashboardStore: DashboardStore;
    projectFormStore: ProjectFormStore;
    projectsStore: ProjectsStore;

    workOrderFormStore: WorkOrderFormStore;
    workOrdersStore: WorkOrdersStore;

    relationFormStore: RelationFormStore;
    relationsStore: RelationsStore;
    articleFormStore: ArticleFormStore;
    articlesStore: ArticlesStore;
    workFormStore: WorkFormStore;
    workStore: WorkStore;
    projectArticlesFormStore: ProjectArticleFormStore;
    projectArticlesStore: ProjectArticlesStore;
    projectPurchaseFormStore: ProjectPurchaseFormStore;
    projectPurchaseStore: ProjectPurchaseStore;
    projectFreeFormStore: ProjectFreeFormStore;
    projectFreeStore: ProjectFreeStore;

    attachmentFormStore: AttachmentFormStore;
    attachmentsStore: AttachmentsStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.progmaUserStore = new ProgmaUserStore(this);
    this.dashboardStore = new DashboardStore();
    this.projectFormStore = new ProjectFormStore();
    this.projectsStore = new ProjectsStore();

    this.workOrderFormStore = new WorkOrderFormStore();
    this.workOrdersStore = new WorkOrdersStore();

    this.relationFormStore = new RelationFormStore();
    this.relationsStore = new RelationsStore();
    this.articleFormStore = new ArticleFormStore();
    this.articlesStore = new ArticlesStore();
    this.workFormStore = new WorkFormStore();
    this.workStore = new WorkStore(this);
    this.projectArticlesFormStore = new ProjectArticleFormStore();
    this.projectArticlesStore = new ProjectArticlesStore();
    this.projectPurchaseFormStore = new ProjectPurchaseFormStore();
    this.projectPurchaseStore = new ProjectPurchaseStore();
    this.projectFreeFormStore = new ProjectFreeFormStore();
    this.projectFreeStore = new ProjectFreeStore();

    this.attachmentFormStore = new AttachmentFormStore();
    this.attachmentsStore = new AttachmentsStore();
  }

  resetStores = () => {
      this.progmaUserStore.resetStore();
      this.dashboardStore.resetStore();
      this.projectFormStore.resetStore();
      this.projectsStore.resetStore();

      this.workOrderFormStore.resetStore();
      this.workOrdersStore.resetStore();

      this.relationFormStore.resetStore();
      this.relationsStore.resetStore();
      this.articleFormStore.resetStore();
      this.articlesStore.resetStore();
      this.workFormStore.resetStore();
      this.workStore.resetStore();
      this.projectArticlesFormStore.resetStore();
      this.projectArticlesStore.resetStore();
      this.projectPurchaseFormStore.resetStore();
      this.projectPurchaseStore.resetStore();
      this.projectFreeFormStore.resetStore();
      this.projectFreeStore.resetStore();

      this.attachmentFormStore.resetStore();
      this.attachmentsStore.resetStore();
  }
}

let vStore: VStore | undefined = undefined;
let VStoreContext: Context<VStore> | undefined = undefined;

export function useVStore() {
  if (!vStore && !VStoreContext) {
    vStore  = rootStore.v13Store;
    VStoreContext = createContext(vStore);
  }
  return useContext(VStoreContext!);
}
