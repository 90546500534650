import { makeAutoObservable, runInAction } from "mobx";
import { SigningValues } from "../../models/sign";
import vAgent from "../api/vAgent";
import { WorkOrder } from "../models/workOrders";
import saveAs from "file-saver";

const initialValues: {
  workOrders?: WorkOrder[];
  isLoading: boolean;
  filePreview?: { id: number; url: string };
  isLoadingFilePreview: boolean;
} = {
  isLoading: false,
  isLoadingFilePreview: false,
};

export default class WorkOrdersStore {
  workOrders = initialValues.workOrders;
  isLoading = initialValues.isLoading;
  filePreview = initialValues.filePreview;
  isLoadingFilePreview = initialValues.isLoadingFilePreview;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.workOrders = initialValues.workOrders;
    this.isLoading = initialValues.isLoading;
    this.filePreview = initialValues.filePreview;
    this.isLoadingFilePreview = initialValues.isLoadingFilePreview;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  loadWorkOrders = async (all: boolean) => {
    try {
      this.setIsLoading(true);
      const workOrders = await vAgent.WorkOrders.load(all);
      runInAction(() => {
        this.workOrders = workOrders;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  loadWorkOrdersByProjectId = async (projectId: number) => {
    try {
      this.setIsLoading(true);
      const workOrders = await vAgent.WorkOrders.loadByProjectId(projectId);
      runInAction(() => {
        this.workOrders = workOrders;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadWorkOrder = async (id: number) => {
    try {
      this.setIsLoading(true);
      const workOrder = this.workOrders?.find((x) => x.id === id);
      if (workOrder) {
        const blob = await vAgent.WorkOrders.getBlob(id);
        if (blob) saveAs(blob, workOrder.fileName);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  getFilePreview = async (id: number) => {
    try {
      this.closeFilePreview();
      runInAction(() => (this.isLoadingFilePreview = true));
      const blob = await vAgent.WorkOrders.getBlob(id);
      if (blob !== undefined && blob instanceof Blob) {
        const file = new Blob([blob], { type: "application/pdf" });
        //const fileURL = window.URL.createObjectURL(file).replace('blob:', '');
        const fileURL = window.URL.createObjectURL(file);
        //const fileURL = "data:application/pdf," + window.URL.createObjectURL(file).replace('blob:', '');

        runInAction(() => {
          this.filePreview = { id: id, url: fileURL };
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.isLoadingFilePreview = false));
    }
  };

  closeFilePreview = () => {
    runInAction(() => (this.filePreview = initialValues.filePreview));
  };

  sign = async (id: number, signingValues: SigningValues) => {
    try {
      //this.setIsLoading(true);

      const blob = await vAgent.WorkOrders.sign(id, signingValues);
      if (blob !== undefined && blob instanceof Blob) {
        const file = new Blob([blob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const temp = this.workOrders ? this.workOrders.slice() : [];
        temp[temp.findIndex((a) => a.id === id)] = {
          ...temp[temp.findIndex((a) => a.id === id)],
          contactPerson: signingValues.name,
          comments: signingValues.comments,
          signed: true,
        };
        runInAction(() => {
          this.filePreview = { id: id, url: fileURL };
          this.workOrders = temp;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      //this.setIsLoading(false);
    }
  };

  deleteWorkOrder = async (id: number) => {
    try {
      this.setIsLoading(true);
      const result = await vAgent.WorkOrders.delete(id);
      if (result && result > 0) {
        runInAction(() => {
          this.workOrders = this.workOrders!.filter((x) => x.id !== id);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  update = async (id: number) => {
    try {
      this.setIsLoading(true);
      const blob = await vAgent.WorkOrders.update(id);
      if (blob !== undefined && blob instanceof Blob) {
        const fileURL = URL.createObjectURL(blob);
        const temp = this.workOrders ? this.workOrders.slice() : [];
        temp[temp.findIndex((a) => a.id === id)] = {
          ...temp[temp.findIndex((a) => a.id === id)],
          signed: false,
        };
        runInAction(() => {
          this.filePreview = { id: id, url: fileURL };
          this.workOrders = temp;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  send = async (id: number) => {
    try {
      this.setIsLoading(true);
      const workOrder = await vAgent.WorkOrders.send(id);
      if (workOrder !== undefined) this.editWorkOrder(workOrder);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  addWorkOrder = (workOrder: WorkOrder) => {
    const temp = this.workOrders ? this.workOrders.slice() : [];
    temp.push(workOrder);
    runInAction(() => (this.workOrders = temp));
  };

  editWorkOrder = (workOrder: WorkOrder) => {
     const temp = this.workOrders ? this.workOrders.slice() : [];
     temp[temp.findIndex((a) => a.id === workOrder.id)] = workOrder;

     vAgent.WorkOrders.update(workOrder.id).then((blob) => {
      if (blob !== undefined && blob instanceof Blob) {
        const fileURL = URL.createObjectURL(blob);
        temp[temp.findIndex((a) => a.id === workOrder.id)] = {
          ...temp[temp.findIndex((a) => a.id === workOrder.id)],
          signed: false,
        };
        runInAction(() => {
          this.filePreview = { id: workOrder.id, url: fileURL };
          this.workOrders = temp;
        });
      }
      else {
        runInAction(() => {
          this.workOrders = temp;
        });
      }
     })
  };


}
