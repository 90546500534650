import { Column } from "@devexpress/dx-react-grid";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import {
  AppBar,
  Box,
  Paper,
  Tab,
  Tabs,
  useMediaQuery,
} from "@material-ui/core";
import { useCallback, useRef, useState } from "react";
import {
  addAutoTableInPDF,
  ExportFormats,
  getFileNameExport,
  gridExportOnSave,
} from "../../../Common/Functions";

import WorkSummary, { columnsSummary } from "./WorkSummary";
import WorkToolstripAndPage from "./WorkToolstripAndPage";
import jsPDF from "jspdf";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import { observer } from "mobx-react-lite";
import { HeaderTitle, NumberTypeProvider } from "../../../Common/TableComponents";
import { TableConstants } from "../../../Common/Constants";
import { WorkWeekRow } from "../../stores/workStore";

const columns: Column[] = [
  //{ name: "companyName", title: "Klant" },
  //{ name: "project", title: "Project" },
  //{ name: "activity", title: "Activiteit" },
  { name: "description", title: "Omschrijving" },
  { name: "totalMonday", title: "Ma" },
  { name: "totalTuesday", title: "Di" },
  { name: "totalWednesday", title: "Wo" },
  { name: "totalThursday", title: "Do" },
  { name: "totalFriday", title: "Vr" },
  { name: "totalSaturday", title: "Za" },
  { name: "totalSunday", title: "Zo" },
  { name: "totalWeek", title: "Week" },
];

const numberColumns = ["totalMonday", "totalTuesday", "totalWednesday", "totalThursday", "totalFriday", "totalSaturday", "totalSunday", "totalWeek"];  

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
        // <Box p={3}>
        //   <Typography>{children}</Typography>
        // </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default observer(function WorkWeek() {
  const [exportFormat, setExportFormat] = useState<ExportFormats | undefined>(
    undefined
  );
  const [value, setValue] = useState(0);

  const {
    workStore: { workWeekData, generalData, currentDate, staffMemberId },
  } = useVStore();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const tableColumnExtensions: any[] = [
    //{ columnName: 'companyName', align: 'left', width: 'auto', wordWrapEnabled: true },
    //{ columnName: 'project', align: 'left', width: 'auto', wordWrapEnabled: true },
    //{ columnName: 'activity', align: 'left', width: 100 },

    {
      columnName: "description",
      align: "left",
      width: matches ? 250 : undefined,
      wordWrapEnabled: true,
    },
    { columnName: "totalMonday", align: "right", width: 60 },
    { columnName: "totalTuesday", align: "right", width: 60 },
    { columnName: "totalWednesday", align: "right", width: 60 },
    { columnName: "totalThursday", align: "right", width: 60 },
    { columnName: "totalFriday", align: "right", width: 60 },
    { columnName: "totalSaturday", align: "right", width: 60 },
    { columnName: "totalSunday", align: "right", width: 60 },
    { columnName: "totalWeek", align: "right", width: 80 },
  ];

  const onlyProject = value === 0 ? false : true;
  const summaryItems = workWeekData?.getSummaryItemsWorkWeek(onlyProject);
  const rows: WorkWeekRow[] = workWeekData
    ? workWeekData.getWorkWeekRows(onlyProject)
    : [];

  const Cell = (props: any) => {
    const { column, row } = props;
    if (row.id === "sum") {
      if (column.name === "description") {
        return (
          <Table.Cell
            {...props}
            style={{ fontWeight: "bold", textAlign: "right" }}
          />
        );
      } else {
        return <Table.Cell {...props} style={{ fontWeight: "bold" }} />;
      }
    } else if (typeof props.value === "number" && props.value === 0) {
      const { value, ...restProps } = props;
      return <Table.Cell {...restProps}>{undefined}</Table.Cell>;
    }
    return <Table.Cell {...props} />;
  };

  const exportName =
    "Weekoverzicht van " +
    (generalData
      ? generalData.staffMembers.find((x) => x.id === staffMemberId)?.nameShort
      : "?") +
    " week " +
    currentDate.formatNL("w (yyyy)");

  const onSave = (workbook: any) => {
    gridExportOnSave(exportName, workbook, exportFormat!);
  };

  const exporterRef = useRef(null);

  const startGridExport = useCallback(() => {
    if (exporterRef.current && typeof exporterRef.current === "object") {
      const gridExporter: any = exporterRef.current;
      gridExporter.exportGrid();
    }
  }, [exporterRef]);

  const handleExportClick = (exportFormat: ExportFormats) => {
    if (exportFormat === ExportFormats.pdf) {
      const filename = getFileNameExport(exportName, ExportFormats.pdf);

      const doc = new jsPDF();
      doc.text(exportName, 15, 15);
      const startDate = currentDate.startOfWeek()
      const endDate = currentDate.endOfWeek();

      doc.text(
        "Van " +
          startDate.formatNL("dd-MMM") +
          " t/m " +
          endDate.formatNL("dd-MMM"),
        15,
        25
      );

      addAutoTableInPDF(doc, columns, rows, { startY: 35 });

      if (summaryItems && summaryItems.length > 0) {
        addAutoTableInPDF(doc, columnsSummary, summaryItems, {
          tableWidth: 100,
        });
      }

      doc.save(filename);

      //exportAsPDF(name , columns, rowsWithProject);
    } else {
      setExportFormat(exportFormat);
      startGridExport();
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  function getContents(index: number) {
    return (
      <Box
        display='flex'
        flexDirection='column'
        style={{ marginTop: theme.spacing(2) }}
      >
        <Box>
          <Paper>
            <Grid columns={columns} rows={rows}>
              {/* <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSummary /> */}

            <NumberTypeProvider for={numberColumns} />

              <Table
                columnExtensions={tableColumnExtensions}
                cellComponent={Cell}
                messages={TableConstants.tableMessages}
              />
              <TableHeaderRow titleComponent={HeaderTitle} />
              {/* <TableSummaryRow messages={messages} totalCellComponent={Cell} /> */}
            </Grid>
            <GridExporter
              ref={exporterRef}
              rows={rows}
              columns={columns}
              onSave={onSave}
            />
          </Paper>
        </Box>

        <Box>{summaryItems && <WorkSummary summaryItems={summaryItems} />}</Box>
      </Box>
    );
  }

  return (
    <WorkToolstripAndPage onExportClick={handleExportClick}>
      <div style={{ marginTop: theme.spacing(1) }}>
        <AppBar position='static' color='default'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='simple tabs example'
          >
            <Tab label='Alles' {...a11yProps(0)} />
            <Tab label='Alleen op project' {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {getContents(0)}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {getContents(1)}
        </TabPanel>
      </div>
    </WorkToolstripAndPage>
  );
});
