import { FormManager } from './../../Common/Objects';
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import {
  GeneralData,
  WorkFormValues,
  WorkItem,
  TimeAccounting,
} from "../models/work";
import { Project } from "../models/projectAndSubject";
import { Activity } from '../models/activity';

const initialValues: {
  isLoading: boolean;
  workFormValues: WorkFormValues;
  allProjectsAndSubjects: boolean;
  isLoadingProjectsAndSubjects: boolean;
  generalData?: GeneralData;
  layoutOption?: "dateOnly" | "staffMemberOnly";
  inActiveActivity?: Activity;
} = {
  isLoading: false,
  workFormValues: {
    id: 0,
    staffMemberID: 0,
    activityID: undefined,
    _Date: new Date(),
    _From: undefined,
    _To: undefined,
    break: undefined,
    netHours: undefined,
    quantity: 0,
    projectsAndSubjectsID: undefined,
    comments: undefined,
    byStaffMemberID: 0,
  },
  allProjectsAndSubjects: false,
  isLoadingProjectsAndSubjects: false
};

export default class WorkFormStore {
  private formManager: FormManager;

  isLoading = initialValues.isLoading;
  workFormValues = initialValues.workFormValues;
  allProjectsAndSubjects = initialValues.allProjectsAndSubjects;
  isLoadingProjectsAndSubjects = initialValues.isLoadingProjectsAndSubjects;
  generalData = initialValues.generalData;
  layoutOption = initialValues.layoutOption;
  inActiveActivity = initialValues.inActiveActivity;
  workForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.workForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.workFormValues = initialValues.workFormValues;
    this.allProjectsAndSubjects = initialValues.allProjectsAndSubjects;
    this.isLoadingProjectsAndSubjects = initialValues.isLoadingProjectsAndSubjects;
    this.generalData = initialValues.generalData;
    this.layoutOption = initialValues.layoutOption;
    this.inActiveActivity = initialValues.inActiveActivity;
    this.workForm = this.formManager.closeForm();
  };

  resetValues = () => {
    runInAction(() => {
      this.isLoading = initialValues.isLoading;
      this.workFormValues = initialValues.workFormValues;
      //this.allProjectsAndSubjects = initialValues.allProjectsAndSubjects;
      this.isLoadingProjectsAndSubjects = initialValues.isLoadingProjectsAndSubjects;
      this.layoutOption = initialValues.layoutOption;
      this.inActiveActivity = initialValues.inActiveActivity;
      this.workForm = this.formManager.closeForm();
    });
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (date: Date, id: number | undefined, staffMemberId: number) => {
    try {
      this.setIsLoading(true);
      let generalData = this.generalData;
      let formValues: WorkFormValues | undefined;
      let inActiveActivity: Activity | undefined = undefined;

      if (!this.generalData) {
        generalData = await vAgent.Work.getGeneralData();
      }

      if (!id) {
        // new
        formValues = initialValues.workFormValues;
        formValues._Date = date;
        formValues.staffMemberID = staffMemberId;
        if (generalData) {
          if (
            generalData.timeAccounting === TimeAccounting.RequiredWithTimeBreak
          ) {
            const initialFrom = "08:00";
            const workItems = await vAgent.Work.loadDay(date);
            if (workItems && workItems.length > 0) {
              const cloneWork = Array.from(workItems);
              const lastTime = cloneWork.sort((a, b) =>
                a.timeTo! < b.timeTo! ? 1 : -1
              )[0].timeTo;
              formValues._From = lastTime ?? initialFrom;
            } else {
              formValues._From = initialFrom;
            }
            formValues.break = 0;
            formValues.netHours = 0;
          } else if (
            generalData.timeAccounting ===
            TimeAccounting.RequiredWithoutTimeBreak
          ) {
            formValues.netHours = 0;
          }
          formValues.byStaffMemberID = staffMemberId;
        }
      } else {
        //edit
        formValues = await vAgent.Work.getFormValues(id);

        if (formValues && formValues.projectsAndSubjectsID && generalData && generalData.projectsAndSubjects && generalData.projectsAndSubjects.filter((x) => x.id === id).length === 0) {
          const temp = await vAgent.Work.getProjectAndSubject(formValues.projectsAndSubjectsID);
          if (temp) generalData.projectsAndSubjects.splice(0,0,temp); //push(temp)
        }

        if (formValues?.activityID && generalData?.activities.filter(x => x.id === formValues?.activityID).length === 0)
        {
          inActiveActivity = await vAgent.Work.getInactiveActivity(formValues?.activityID);
        }
      }

      runInAction(() => {
        this.generalData = generalData;
        this.workFormValues = formValues
          ? formValues
          : initialValues.workFormValues;
        this.inActiveActivity = inActiveActivity;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  setAllProjectsAndSubjects = async(value: boolean) => {
    try {
      runInAction(() => {
        this.isLoadingProjectsAndSubjects = true
      })

      const list = await vAgent.Work.getProjectsAndSubjects(value);
      runInAction(() => {
        this.generalData!.projectsAndSubjects = list ? list : []
        this.allProjectsAndSubjects = value
      })
    } catch (error) {
      console.log(error);
    } finally
    {
      runInAction(() => {
        this.isLoadingProjectsAndSubjects = false
      })
    }
  }

  getWorkItemsByDate = (date: Date): WorkItem[] => {
    try {
      vAgent.Work.loadDay(date).then((response) => {
        return response;
      });
      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  create = async (work: WorkFormValues) => {
    try {
      return await vAgent.Work.create(work);
    } catch (error) {
      console.log(error);
    }
  };

  edit = async (work: WorkFormValues) => {
    try {
      return await vAgent.Work.edit(work);
    } catch (error) {
      console.log(error);
    }
  };

  openWorkForm = ( id?: number, layoutOption?: "dateOnly" | "staffMemberOnly") => {
    runInAction(() => {
      this.workForm = this.formManager.openForm(id);
      this.layoutOption = layoutOption;
    });
  };

  closeWorkForm = () => {
    runInAction(() => {
      this.workForm = this.formManager.closeForm();
    });
  };

  addProject = async (project: Project) => {
    try {
      if (project) {
        const temp = await vAgent.Work.getProjectAndSubjects(
          project.id
        );
        if (temp && temp.length > 0) {
          const newProjectsAndSubjects = this.generalData?.projectsAndSubjects;
          if (this.generalData && newProjectsAndSubjects) {
            newProjectsAndSubjects.splice(0, 0, ...temp);

            runInAction(() => {
              this.generalData!.projectsAndSubjects = newProjectsAndSubjects;
            });
          }
          return newProjectsAndSubjects ? newProjectsAndSubjects[0].id : undefined;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
}
