import { TextField } from "@material-ui/core";
import theme from "../../themes/theme";

interface Props {
  searchValue: string;
  onSearchValueChanged: (value: string) => void;
}

export default function SearchField({
  searchValue,
  onSearchValueChanged,
}: Props) {
  return (
      <TextField
        style={{ maxWidth: theme.spacing(22) }}
        type='Search'
        label='Zoeken'
        size='medium'
        autoFocus={false}
        variant='outlined'
        value={searchValue}
        onChange={(e) =>
          onSearchValueChanged(e.target.value ? e.target.value : "")
        }
      />
  );
}

