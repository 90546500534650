import { Container, Typography } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../stores/rootStore";

export default observer(function ServerError() {
    const { commonStore: { error } } = useRootStore();

    return (
        <Container>
            <Typography variant="h5">Server fout</Typography>

            <Typography variant="h6" color='error'>{error?.message}</Typography>
            {error?.details && (
                <div style={{marginTop:"20px"}}>
                    <Typography variant="h6" color='textSecondary'>Stack trace</Typography>
                    <code style={{ marginTop: "10px" }}>{error.details}</code>
                </div>
            )}
        </Container>
    );
})