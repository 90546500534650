import { Grid, GridProps, GridSize } from "@material-ui/core";
import { observer } from "mobx-react-lite";
//import { useStore } from "../../stores/vStore";
import LoadingComponent from "../../../Common/LoadingComponent";
import { useEffect } from "react";
import DashboardWork from "./DashboardWork";
import { useVStore } from "../../stores/vStore";
import ReloadButton from "../../../Common/ReloadButton";

export default observer(function DashboardPage() {
  const {
    dashboardStore: { dashboard, load, isLoading },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  useEffect(() => {
    if (!dashboard) load();
  }, [dashboard, load]);


  type MyMatGridProps = {xxl?: boolean | GridSize | undefined, xxxl?: boolean | GridSize | undefined} & GridProps;

  const MyMatGrid = ({xxl, xxxl, ...other}: MyMatGridProps) => {
    const myClass = `MuiGrid-grid-xxl-${xxl} MuiGrid-grid-xxxl-${xxxl}`;
    return <Grid className={myClass} {...other} />;
  };

  const MyGridItem = ({children}: any) => {
    return (
      <MyMatGrid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={4} xxxl={3}>{children}</MyMatGrid>
    )
  }

  if (isLoading)
    return <LoadingComponent useBackdrop={false} />;

  return (
    <Grid container justifyContent='flex-start' spacing={2}>
      <Grid item xs={12}>
          <ReloadButton onReload={load} />
      </Grid>
      {progmaUserRights &&
        progmaUserRights.workAllow && (
          <MyGridItem>
            <DashboardWork />
          </MyGridItem>
        )}
    </Grid>
  );
});
