import { Column } from "@devexpress/dx-react-grid";
import { format, parse } from "date-fns";
import nlBELocale from "date-fns/locale/nl-BE";
import saveAs from "file-saver";
import jsPDF from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import currency from "currency.js";


// export const base64toBlob = (base64Data: string, contentType: string): Blob => {
  
//   console.log(base64Data);
//   console.log(contentType);
  
//   contentType = contentType || '';
//   const sliceSize = 1024;
//   const byteCharacters = atob(base64Data);
//   const bytesLength = byteCharacters.length;
//   const slicesCount = Math.ceil(bytesLength / sliceSize);
//   const byteArrays = new Array(slicesCount);

//   for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
//     const begin = sliceIndex * sliceSize;
//     const end = Math.min(begin + sliceSize, bytesLength);

//     const bytes = new Array(end - begin);
//     for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
//       bytes[i] = byteCharacters[offset].charCodeAt(0);
//     }
//     byteArrays[sliceIndex] = new Uint8Array(bytes);
//   }
//   return new Blob(byteArrays, { type: contentType });
// }

declare global {  
  interface Number {  
    toNumberString(decimals?: number): String;
    toPercentString(decimals?: number): String;
    toCurrencyString(): String;
  }  
 } 

 
 // eslint-disable-next-line no-extend-native
 Number.prototype.toNumberString = function(decimals: number = 2): string {  
  return Intl.NumberFormat("nl-NL", {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(Number(this))
 }  

 // eslint-disable-next-line no-extend-native
 Number.prototype.toPercentString = function(decimals: number = 2): string { 
  return Intl.NumberFormat("nl-NL", {style: "percent", minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(Number(this))
 }  

 // eslint-disable-next-line no-extend-native
 Number.prototype.toCurrencyString = function(): string {  
  return Intl.NumberFormat("nl-NL", {style: "currency", currency: "EUR"}).format(Number(this))
 }  
 //export {};


export const toCurrency = (value: any): currency | undefined => {
  if (value === undefined || value === null ) return undefined;
  return currency(value, { precision: 2, symbol: "€ " })
}

export const toNumber = (value: any, precision: number = 2): number | undefined => {
  if (value === undefined || value === null ) return undefined;
  return currency(value, { precision: precision}).value
}

// export const toCurrencyString = (value: number | bigint | undefined | null) => {
//   if (value === undefined || value === null ) return "";
//   return Intl.NumberFormat("nl-NL", {style: "currency", currency: "EUR"}).format(value);
// }

// export const toNumberString = (value: number | bigint | undefined | null, decimals: number = 2) => {
//   if (value === undefined || value === null ) return "";
//   return Intl.NumberFormat("nl-NL", {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(value);
// }

// export const toPercentString = (value: number | bigint | undefined | null) => {
//   if (value === undefined || value === null ) return "";
//   return Intl.NumberFormat("nl-NL", {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
// }

export enum ExportFormats {
  xlsx,
  csv,
  pdf
}

export const createTextValueFromDate = (date: Date | null) => {
    if (date) {
      return format(date, "yyyy-MM-dd", { locale: nlBELocale }); //, { locale: nlBELocale }
    } else {
      return null;
    }
  };

export const createDateFromTextValue = (value: string) => {
    return parse(value, "yyyy-MM-dd", new Date(), { locale: nlBELocale });
  };

export const createTextValueFromTime = (date: Date | null) => {
    if (date) {
      return format(date, "HH:mm", {});
    } else {
      return null;
    }
  };

export const createTimeFromTextValue = (value: string) => {
    return parse(value, "HH:mm", new Date(), { locale: nlBELocale });
  };


export const getFileNameExport = (name: string, exportFormat: ExportFormats) => {
    return (
      name +
      // "_" +
      // format(new Date(), "yyyy-MM-dd_HHmmss") +
      "." +
      ExportFormats[exportFormat!]);
  }

  export const exportAsPDF = (name: string, columns: Column[], rows:any[]) => {

    const filename = getFileNameExport(name, ExportFormats.pdf);
    const doc = new jsPDF();
    doc.text(name,15,15);

    const myColumnNames = columns.map((x) => x.name);
    const myColumnTitles = columns.map((x) => x.title ? x.title : x.name );

    const myRows = [];
    for (let r = 0; r < rows.length; r++) {
      const myrow = [];
      for (let c = 0; c < columns.length; c++) {
        myrow.push((rows[r] as any)[myColumnNames[c]]);
      }
      myRows.push(myrow);
    }

    autoTable(doc, { 
      head: [myColumnTitles],
      body: myRows,
      startY: 20,
    });

    doc.save(filename);
  }

  export const addAutoTableInPDF = (doc: jsPDF, columns: Column[], rows:any[], userOptions?: UserOptions ) => {
    const myColumnNames = columns.map((x) => x.name);
    const myColumnTitles = columns.map((x) => x.title ? x.title : x.name );
    const myRows = [];
    for (let r = 0; r < rows.length; r++) {
      const myrow = [];
      for (let c = 0; c < columns.length; c++) {
        myrow.push((rows[r] as any)[myColumnNames[c]]);
      }
      myRows.push(myrow);
    }

    autoTable(doc, { 
      head: [myColumnTitles],
      body: myRows,
      ...userOptions
    });
  }


  export const gridExportOnSave = (name: string, workbook: any, exportFormat: ExportFormats) => {

    const filename = getFileNameExport(name, exportFormat!);

    if (exportFormat === ExportFormats.xlsx) {
      workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          filename
        );
      });
    } else if (exportFormat === ExportFormats.csv) {
      workbook.csv.writeBuffer().then((buffer: any) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          filename
        );
      });
    } 
  }




