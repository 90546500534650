import { Typography } from "@material-ui/core";
import React from "react";

interface Props {
  errors: any;
}

export default function ValidationErrors({ errors }: Props) {
  if (typeof errors === "string") {
    return <Typography color='error'>{errors}</Typography>;
  } else if (Array.isArray(errors) && errors.length > 0) {
    return (
      <>
        {errors.map((err: any, i: any) => (
          <Typography color='error' key={i}>
            {err}
          </Typography>
        ))}
      </>
    );
  } else {
    return (
      <Typography color='error'>Kan validatiefouten niet weergeven</Typography>
    );
  }
}
