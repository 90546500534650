import {
  Card,
  CardContent,
  Checkbox,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { Fragment } from "react";
import theme from "../themes/theme";

export interface DetailCardItem {
  title: string;
  value: any;
  type?: 'currency' | 'percent' | 'number' | 'boolean'
}

interface Props {
  detailCardItems: DetailCardItem[];
  minWidth?: number;
  maxWidth?: number;
  limitCompactCard?: number | Breakpoint;
}

export default function DetailCard({
  detailCardItems,
  minWidth,
  maxWidth,
  limitCompactCard,
}: Props) {
  const matchesCompactCard = useMediaQuery(
    theme.breakpoints.down(limitCompactCard ? limitCompactCard : "xs")
  );

  const myDetailCardItems = detailCardItems.filter((x) => x.value)

  const getValue = (item: DetailCardItem) => {
    if (item.type === undefined) return item.value;
    if (item.type === "boolean") return <Checkbox style={{margin:0, padding: "0px 0px"}} checked={item.value} disabled size="small"/>
    if (item.value === undefined || item.value === null) return undefined;
    //if (item.type === "boolean") return item.value ? "Ja" : "Nee";
    if (item.type === "currency") return typeof item.value === "number" ? item.value.toCurrencyString() : item.value //toCurrencyString(item.value);
    if (item.type === "percent") return typeof item.value === "number" ? item.value.toPercentString() : item.value //toPercentString(item.value);
    if (item.type === "number") return typeof item.value === "number" ? item.value.toNumberString() : item.value  //toNumberString(item.value);
    return item.value;
  }

  return (
    <Card
      style={{
        backgroundColor: theme.palette.background.default,
        minWidth: minWidth ? minWidth : undefined,
        maxWidth: maxWidth ? maxWidth : undefined,
        whiteSpace: "normal",
      }}
      variant='outlined'
    >
      <CardContent>
        <table>
          <tbody>
            {!matchesCompactCard
              ? myDetailCardItems.map(
                  (item) => (
                      <tr key={item.title}>
                        <td>
                          <Typography variant='subtitle2'>
                            <b>
                              {item.title}
                              {":"}
                            </b>
                          </Typography>
                        </td>
                        <td style={{ paddingLeft: theme.spacing(2) }}>
                          <Typography
                            variant='subtitle2'
                            style={{ wordWrap: "break-word" }} //, textAlign: (item.kind === "quantity" || item.kind === "percent" || item.kind === "currency") ? "right" : undefined
                          >
                            {getValue(item)}
                          </Typography>
                        </td>
                      </tr>
                    )
                )
              : myDetailCardItems.map(
                  (item) => (
                      <Fragment key={item.title}>
                        <tr>
                          <td>
                            <Typography variant='subtitle2'>
                              <b>
                                {item.title}
                                {":"}
                              </b>
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant='subtitle2'
                              style={{ wordWrap: "break-word" }}
                            >
                              {getValue(item)}  
                            </Typography>
                          </td>
                        </tr>
                      </Fragment>
                    )
                )}
          </tbody>
        </table>

        {/* <Grid container spacing={1}>
  
            {detailCardItems.map((item) => (
              <Fragment key={item.title}>
                <Grid item xs={matchesCompactCard ? 12 : titleWidth}>
                  <Typography variant='subtitle2'>
                    <b>{item.title}{":"}</b>
                  </Typography>
                </Grid>
  
                <Grid item xs={matchesCompactCard ? 12 : valueWidth}>
                  <Typography variant='subtitle2'style={{ wordWrap: "break-word"}}>
                    {item.value}
                  </Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid> */}
      </CardContent>
    </Card>
  );
}
