import { ProjectFreeFormValues } from "../models/projectFree";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { FormManager } from "../../Common/Objects";

const initialValues: {
  isLoading: boolean;
  loadNeeded: boolean;
  projectFreeFormValues: ProjectFreeFormValues;
} = {
  isLoading: false,
  loadNeeded: true,
  projectFreeFormValues: {
    id: 0,
    quantity: 1,
    description: "",
    sellingPrice: undefined,
    sellingPriceTotal: undefined,
    projectsAndSubjectsID: 0,
  },
};

export default class ProjectFreeFormStore {
  private formManager: FormManager;
  
  isLoading = initialValues.isLoading;
  loadNeeded = initialValues.loadNeeded;
  projectFreeFormValues = initialValues.projectFreeFormValues;
  projectFreeForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.projectFreeForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.loadNeeded = initialValues.loadNeeded;
    this.projectFreeFormValues = initialValues.projectFreeFormValues;
    this.projectFreeForm = this.formManager.closeForm();
  };

  resetValues = () => {
    runInAction(() => {
      this.isLoading = initialValues.isLoading;
      this.projectFreeFormValues = initialValues.projectFreeFormValues;
      this.projectFreeForm = this.formManager.closeForm();
    });
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (id?: number) => {
    try {
      this.setIsLoading(true);

      let formValues: ProjectFreeFormValues | undefined =
        this.projectFreeFormValues;
      if (id) {
        formValues = await vAgent.ProjectFree.loadFormValues(id);
      }

      runInAction(() => {
        this.projectFreeFormValues = formValues
          ? formValues
          : initialValues.projectFreeFormValues;
        this.loadNeeded = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  create = async (projectFreeFormValues: ProjectFreeFormValues) => {
    try {
        return await vAgent.ProjectFree.create(projectFreeFormValues);
    } catch (error) {
      console.log(error);
    }
  };

  edit = async (projectFreeFormValues: ProjectFreeFormValues) => {
    try {
        return await vAgent.ProjectFree.edit(projectFreeFormValues);
    } catch (error) {
      console.log(error);
    }
  };

  openProjectFreeForm = (projectAndSubjectsId: number, id?: number) => {
    runInAction(() => {
      this.projectFreeForm = this.formManager.openForm(id);
      this.projectFreeFormValues.projectsAndSubjectsID = projectAndSubjectsId;
    });
  };

  closeProjectFreeForm = () => {
    runInAction(() => {
      this.projectFreeForm = this.formManager.closeForm();
    });
  };
}
