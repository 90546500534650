import {
  Button,
  FormLabel,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import SignatureCanvas from "react-signature-canvas";
import SignaturePad from "react-signature-canvas";
import { useRef, useState } from "react";
import theme from "../../themes/theme";
import * as yup from "yup";
import { Formik } from "formik";
import FDialog from "./FDialog";
import FTextfield from "./FTextField";
import ValidationErrors from "./ValidationErrors";
import { SigningValues } from "../../models/sign";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useRootStore } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";

interface Props {
  open: boolean;
  title: string;
  onSubmit: (signingValues: SigningValues) => Promise<void>;
  onClose: () => void;
  name: string;
  comments: string | undefined;
}

const validationSchema = yup.object({
  name: yup.string().required("verplicht veld"),
});

export default observer(function SignForm({
  open,
  title,
  onSubmit,
  onClose,
  name,
  comments,
}: Props) {
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const padRef = useRef<SignatureCanvas>(null);
  const [signing, setSigning] = useState(false);
  const {
    commonStore: { orientation },
  } = useRootStore();

  const initialSigningValues: SigningValues = {
    name: "",
    comments: "",
    signatureDataUrl: "",
  };
  const initialValues = { error: null, ...initialSigningValues };

  const validate = (values: SigningValues) => {
    if (values.signatureDataUrl?.length === 0)
      return { error: "Eerst handtekening zetten" };
    return undefined;
  };

  function handleFormSubmit(formValues: SigningValues) {
    if (signing) formValues.signatureDataUrl = padRef.current?.toDataURL();
    onSubmit(formValues).finally(() => onClose());
  }

  if (name && name.length > 0) initialValues.name = name;
  if (comments && comments.length > 0) initialValues.comments = comments;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        const errors = validate(values);
        if (errors) {
          if (signing) setSigning(false);
          setErrors(errors);
          setSubmitting(false);
        } else handleFormSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, setFieldValue }) => {
        return (
          <FDialog
            title={!signing ? title : "Zet hieronder uw handtekening:"}
            open={open}
            onClose={onClose}
            breakPointFullScreen='md'
            useButtons={!signing}
            maxWidth='lg'
          >
            {!signing ? (
              <form noValidate autoComplete='off'>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}>
                  Hallo
                  </Grid> */}
                  <Grid
                    item
                    xs={fullScreen && orientation === "landscape" ? 5 : 12}
                  >
                    <FTextfield
                      name='name'
                      label='Naam'
                      required
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={fullScreen && orientation === "landscape" ? 7 : 12}
                  >
                    <FTextfield
                      name='comments'
                      label='Opmerking'
                      required={false}
                      minRows={5}
                      maxRows={5}
                      autoComplete='off'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={fullScreen && orientation === "landscape" ? 5 : 12}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        {values.signatureDataUrl?.length !== 0 && (
                          <>
                            <FormLabel>Handtekening</FormLabel>
                            <div
                              style={{
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                borderRadius: theme.spacing(0.5),
                                padding: theme.spacing(2),
                              }}
                            >
                              <Grid container justifyContent="center">
                                <img
                                  style={{
                                    width: "50%",
                                    height: "50%",
                                    //aspectRatio: "2/1",Not working safari ios
                                    //maxHeight: 200,
                                  }}
                                  src={values.signatureDataUrl}
                                  alt="handtekening"
                                  //width= "100%"
                                  //height= "50%"
                                  // width={
                                  //   fullScreen && orientation === "landscape"
                                  //     ? "100%"
                                  //     : "50%"
                                  // }
                                  // height={
                                  //   fullScreen && orientation === "landscape"
                                  //     ? 100//"calc(100vh - 550px)"
                                  //     : 100
                                  //}
                                />
                              </Grid>
                            </div>
                          </>
                        )}

                        {errors.error && (
                          <Grid item xs={12}>
                            <ValidationErrors errors={errors.error} />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={
                      fullScreen &&
                      orientation === "landscape" &&
                      values.signatureDataUrl?.length !== 0
                        ? 7
                        : 12
                    }
                  >
                    <Button
                      style={{
                        marginTop:
                          fullScreen && orientation === "landscape"
                            ? theme.spacing(2.5)
                            : undefined,
                        // marginBottom: theme.spacing(2),
                      }}
                      variant='outlined'
                      color='default'
                      size='large'
                      fullWidth
                      onClick={() => setSigning(true)}
                    >
                      <Typography>
                        {values.signatureDataUrl?.length === 0
                          ? "Handtekening zetten..."
                          : "Aanpassen"}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : fullScreen && orientation === "landscape" ? (
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <SignaturePad
                    ref={padRef}
                    canvasProps={{
                      style: {
                        width: "100%",
                        height: "calc(100vh - 70px)",
                        //aspectRatio: "2/1", Not working safari ios
                        border: "1px solid black",
                      },
                      onMouseDown: () => {
                        if ("activeElement" in document)
                          (document.activeElement as HTMLElement)?.blur();
                      },
                      onTouchStart: () => {
                        if ("activeElement" in document)
                          (document.activeElement as HTMLElement)?.blur();
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Grid
                    container
                    //direction="column"
                    //justifyContent="space-between"
                    //alignItems="center"
                    spacing={2}
                    //style={{height:"100%"}}
                  >
                    {/* <Grid item xs={12}>
                      <div style={{ height: "100%" }} />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Button
                        variant='outlined'
                        color='secondary'
                        size='medium'
                        fullWidth
                        onClick={() => {
                          padRef.current?.clear();
                        }}
                      >
                        <Typography>Wissen</Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        fullWidth
                        size='medium'
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                          setFieldValue(
                            "signatureDataUrl",
                            padRef.current?.getTrimmedCanvas().toDataURL()
                          );
                          setSigning(false);
                        }}
                      >
                        <Typography>Terug</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SignaturePad
                    ref={padRef}
                    canvasProps={{
                      style: {
                        width: "100%",
                        maxHeight: 350,
                        height: "calc(100vh - 250px)",
                        //aspectRatio: "2/1", Not working safari ios
                        border: "1px solid black",
                      },
                      onMouseDown: () => {
                        if ("activeElement" in document)
                          (document.activeElement as HTMLElement)?.blur();
                      },
                      onTouchStart: () => {
                        if ("activeElement" in document)
                          (document.activeElement as HTMLElement)?.blur();
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    size='medium'
                    fullWidth
                    onClick={() => {
                      padRef.current?.clear();
                      setFieldValue("signatureDataUrl", "");
                    }}
                  >
                    <Typography>Wissen</Typography>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    size='medium'
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                      //console.log(padRef.current?.isEmpty());
                      setFieldValue(
                        "signatureDataUrl",
                        padRef.current?.getTrimmedCanvas().toDataURL()
                      );
                      setSigning(false);
                    }}
                  >
                    <Typography>Terug</Typography>
                  </Button>
                </Grid>
              </Grid>
            )}
          </FDialog>
        );
      }}
    </Formik>
  );
});
