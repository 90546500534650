import { ArticleDTO, ArticleFormValues } from './../models/article';
import { ProjectArticleFormValues } from '../models/projectArticles';
import { Relation, RelationFormValues } from "../models/relation";
import {
  GeneralData,
  WorkFormValues,
  WorkItem,
} from "../models/work";
import rootAgent from "../../api/rootAgent";
import { StaffMember } from "../models/staffMember";
import { ProgmaUserRights } from "../models/progmaUserRights";
import {
  Project,
  ProjectAndSubject,
  ProjectGeneralData,
  ProjectFormValues,
  Status,
} from "../models/projectAndSubject";
import { Dashboard } from "../models/dashboard";
import { ProjectArticle } from "../models/projectArticles";
import { ArticleSDTO } from "../models/article";
import { ProjectPurchase as ProjectPurchaseObject, ProjectPurchaseFormValues } from "../models/projectPurchase";
import { ProjectFree as ProjectFreeObject, ProjectFreeFormValues } from "../models/projectFree";
import { Ledger } from '../models/ledger';
import { Attachment, AttachmentFormValues } from '../models/attachments';

const toUrlString = (value: null | undefined | string | number) => {
  if (!value) return "";
  return value.toString();
};

const baseUrl = "/v12";

const dashboardUrl = baseUrl + "/dashboard";
const dashboard = {
  load: () => rootAgent.requests.get<Dashboard>(`${dashboardUrl}`),
};

const relationsUrl = baseUrl + "/relations";
const Relations = {
  load: () => rootAgent.requests.get<Relation[]>(`${relationsUrl}`),
  loadFormValues: (id: number) =>
  rootAgent.requests.get<RelationFormValues>(
    `${relationsUrl}/load-form-values/${id}`
  ),
  create: (formValues: RelationFormValues) =>
  rootAgent.requests.post<Relation>(`${relationsUrl}/create`, formValues),
  edit: (formValues: RelationFormValues) =>
  rootAgent.requests.put<Relation>(
      `${relationsUrl}/${formValues.id}`,
      formValues
    ),
  delete: (id: number) =>
  rootAgent.requests.del<number>(`${relationsUrl}/${id}`),
};

const articlesUrl = baseUrl + "/articles";
const Articles = {
  load: () => rootAgent.requests.get<ArticleDTO[]>(`${articlesUrl}`),
  loadFormValues: (id: number) =>
  rootAgent.requests.get<ArticleFormValues>(
    `${articlesUrl}/load-form-values/${id}/`
  ),
  create: (formValues: ArticleFormValues) =>
  rootAgent.requests.post<ArticleDTO>(`${articlesUrl}/create`, formValues),
  edit: (formValues: ArticleFormValues) =>
  rootAgent.requests.put<ArticleDTO>(
      `${articlesUrl}/${formValues.id}`,
      formValues
    ),
  delete: (id: number) =>
  rootAgent.requests.del<number>(`${articlesUrl}/${id}`),
};

const projectsUrl = baseUrl + "/projects";
const Projects = {
  load: (all: boolean) =>
  rootAgent.requests.get<Project[]>(`${projectsUrl}/${all}`),
  loadByStatus: (status: Status[]) =>
  rootAgent.requests.get<Project[]>(
      `${projectsUrl}/getprojectsbystatus/${status.join("&")}`
    ),
  getGeneralData: () =>
  rootAgent.requests.get<ProjectGeneralData>(
      `${projectsUrl}/getgeneraldata`
    ),
  getFormValues: (id: number) =>
  rootAgent.requests.get<ProjectFormValues>(
      `${projectsUrl}/getformvalues/${id}`
    ),
  create: (projectFormValues: ProjectFormValues) =>
  rootAgent.requests.post<Project>(
      `${projectsUrl}/create`,
      projectFormValues
    ),
  edit: (projectFormValues: ProjectFormValues) =>
  rootAgent.requests.put<Project>(
      `${projectsUrl}/${projectFormValues.id}`,
      projectFormValues
    ),
  delete: (id: number) =>
  rootAgent.requests.del<number>(`${projectsUrl}/${id}`),
};

const workUrl = baseUrl + "/work";
const Work = {
  getGeneralData: () =>
  rootAgent.requests.get<GeneralData>(`${workUrl}/get-general-data`),
    getProjectsAndSubjects: (allProjectAndSubjects: boolean) =>
  rootAgent.requests.get<ProjectAndSubject[]>(`${workUrl}/get-projects-and-subjects/${allProjectAndSubjects}`),
  loadDay: (date: Date) =>
  rootAgent.requests.get<WorkItem[]>(
      `${workUrl}/load-day/${date.toQueryString()}`
    ),
  loadWeek: (date: Date, staffMemberId?: number) =>
  rootAgent.requests.get<WorkItem[]>(
      `${workUrl}/load-week/${date.toQueryString()}/${toUrlString(
        staffMemberId
      )}`
    ),
  getFormValues: (id: number) =>
  rootAgent.requests.get<WorkFormValues>(`${workUrl}/${id}`),
  getProjectAndSubjects: (projectId: number) =>
  rootAgent.requests.get<ProjectAndSubject[]>(
      `${workUrl}/getprojectandsubjects/${projectId}`
    ),
    getProjectAndSubject: (projectsAndSubjectsID: number) =>
    rootAgent.requests.get<ProjectAndSubject>(
        `${workUrl}/getprojectandsubject/${projectsAndSubjectsID}`
      ),
  create: (WorkFormValues: WorkFormValues) =>
  rootAgent.requests.post<WorkItem>(`${workUrl}/create`, WorkFormValues),
  edit: (WorkFormValues: WorkFormValues) =>
  rootAgent.requests.put<WorkItem>(
      `${workUrl}/${WorkFormValues.id}`,
      WorkFormValues
    ),
  delete: (id: number) => rootAgent.requests.del<number>(`${workUrl}/${id}`),
  setProjectStatus: (id: number, status: Status) =>
  rootAgent.requests.put<WorkItem>(`${workUrl}/setprojectstatus/${id}/${status}`, {})
};

const projectarticlesUrl = baseUrl + "/projectarticles";
const ProjectArticles = {
  getProjectsAndSubjects: (allProjectAndSubjects: boolean) =>
  rootAgent.requests.get<ProjectAndSubject[]>(`${projectarticlesUrl}/get-projects-and-subjects/${allProjectAndSubjects}/`),
  loadProjectArticles: (projectAndSubjectId: number) =>
  rootAgent.requests.get<ProjectArticle[]>(
      `${projectarticlesUrl}/load-project-articles/${projectAndSubjectId}`
    ),
  loadArticles: () =>
  rootAgent.requests.get<ArticleSDTO[]>(
      `${projectarticlesUrl}/load-articles`
    ),
  loadArticle: (id: number) =>
  rootAgent.requests.get<ArticleSDTO>(
      `${projectarticlesUrl}/load-article/${id}`
    ),
  loadFormValues: (id: number) =>
  rootAgent.requests.get<ProjectArticleFormValues>(
      `${projectarticlesUrl}/load-form-values/${id}`
    ),
  create: (projectArticleFormValues: ProjectArticleFormValues) =>
  rootAgent.requests.post<ProjectArticle>(`${projectarticlesUrl}/create`, projectArticleFormValues),
  edit: (projectArticleFormValues: ProjectArticleFormValues) =>
  rootAgent.requests.put<ProjectArticle>(
      `${projectarticlesUrl}/${projectArticleFormValues.id}`,
      projectArticleFormValues
    ),
  delete: (Id: number) =>
  rootAgent.requests.del<number>(
      `${projectarticlesUrl}/${Id}`
    ),
  getProjectAndSubjects: (projectId: number) =>
    rootAgent.requests.get<ProjectAndSubject[]>(
        `${projectarticlesUrl}/getprojectandsubjects/${projectId}`
      ),
  setProjectStatus: (id: number, status: Status) =>
      rootAgent.requests.put<ProjectAndSubject>(`${projectarticlesUrl}/setprojectstatus/${id}/${status}`, {})
};

const projectPurchaseUrl = baseUrl + "/projectpurchase";
const ProjectPurchase = {
  getProjectsAndSubjects: (allProjectAndSubjects: boolean) =>
  rootAgent.requests.get<ProjectAndSubject[]>(`${projectPurchaseUrl}/get-projects-and-subjects/${allProjectAndSubjects}/`),
  loadProjectPurchase: (projectAndSubjectId: number) =>
  rootAgent.requests.get<ProjectPurchaseObject[]>(
      `${projectPurchaseUrl}/load-project-purchase/${projectAndSubjectId}`
    ),
  loadFormValues: (id: number) =>
  rootAgent.requests.get<ProjectPurchaseFormValues>(
      `${projectPurchaseUrl}/load-form-values/${id}`
    ),
  create: (projectPurchaseFormValues: ProjectPurchaseFormValues) =>
  rootAgent.requests.post<ProjectPurchaseObject>(`${projectPurchaseUrl}/create`, projectPurchaseFormValues),
  edit: (projectPurchaseFormValues: ProjectPurchaseFormValues) =>
  rootAgent.requests.put<ProjectPurchaseObject>(
      `${projectPurchaseUrl}/${projectPurchaseFormValues.id}`,
      projectPurchaseFormValues
    ),
  delete: (Id: number) =>
  rootAgent.requests.del<number>(
      `${projectPurchaseUrl}/${Id}`
    ),
  getProjectAndSubjects: (projectId: number) =>
  rootAgent.requests.get<ProjectAndSubject[]>(
        `${projectPurchaseUrl}/getprojectandsubjects/${projectId}`
      ),
  setProjectStatus: (id: number, status: Status) =>
      rootAgent.requests.put<ProjectAndSubject>(`${projectPurchaseUrl}/setprojectstatus/${id}/${status}`, {}),
  loadLedgers: () =>
    rootAgent.requests.get<Ledger[]>(`${projectPurchaseUrl}/get-ledgers`)
};

const projectFreeUrl = baseUrl + "/projectfree";
const ProjectFree = {
  getProjectsAndSubjects: (allProjectAndSubjects: boolean) =>
  rootAgent.requests.get<ProjectAndSubject[]>(`${projectFreeUrl}/get-projects-and-subjects/${allProjectAndSubjects}/`),
  loadProjectFree: (projectAndSubjectId: number) =>
  rootAgent.requests.get<ProjectFreeObject[]>(
      `${projectFreeUrl}/load-project-free/${projectAndSubjectId}`
    ),
  loadFormValues: (id: number) =>
  rootAgent.requests.get<ProjectFreeFormValues>(
      `${projectFreeUrl}/load-form-values/${id}`
    ),
  create: (projectFreeFormValues: ProjectFreeFormValues) =>
  rootAgent.requests.post<ProjectFreeObject>(`${projectFreeUrl}/create`, projectFreeFormValues),
  edit: (projectFreeFormValues: ProjectFreeFormValues) =>
  rootAgent.requests.put<ProjectFreeObject>(
      `${projectFreeUrl}/${projectFreeFormValues.id}`,
      projectFreeFormValues
    ),
  delete: (Id: number) =>
  rootAgent.requests.del<number>(
      `${projectFreeUrl}/${Id}`
    ),
  getProjectAndSubjects: (projectId: number) =>
  rootAgent.requests.get<ProjectAndSubject[]>(
        `${projectFreeUrl}/getprojectandsubjects/${projectId}`
      ),
  setProjectStatus: (id: number, status: Status) =>
      rootAgent.requests.put<ProjectAndSubject>(`${projectFreeUrl}/setprojectstatus/${id}/${status}`, {})
};


const attachmentsUrl = baseUrl + "/attachments";
const Attachments = {
  loadByProjectAndSubjectId: (projectAndSubjectId: number) =>
  rootAgent.requests.get<Attachment[]>(`${attachmentsUrl}/get-by-project-and-subject/${projectAndSubjectId}`),
  getBlob: (id: number) =>
  rootAgent.requests.get<any>(
      `${attachmentsUrl}/getblob/${id}`, {responseType: 'blob' }
    ),
  uploadFile: (formData: FormData) =>
    rootAgent.requests.post<Attachment>(`${attachmentsUrl}/upload-file`, formData, {headers: {"Content-Type": "multipart/form-data"}}),
  loadFormValues: (id: number) =>
    rootAgent.requests.get<AttachmentFormValues>(
        `${attachmentsUrl}/load-form-values/${id}`
      ),
  edit: (attachmentFormValues: AttachmentFormValues) =>
      rootAgent.requests.put<Attachment>(
          `${attachmentsUrl}/${attachmentFormValues.id}`,
          attachmentFormValues
        ),
  delete: (id: number) =>
    rootAgent.requests.del<number>(`${attachmentsUrl}/${id}`),
};


const progmaUserUrl = baseUrl + "/progmauser";
const ProgmaUser = {
  loadProgmaUserRights: (id: number) =>
  rootAgent.requests.get<ProgmaUserRights>(`${progmaUserUrl}/${id}`),
};

const staffUrl = baseUrl + "/staff";
const Staff = {
  getStaff: () => rootAgent.requests.get<StaffMember[]>(`${staffUrl}`),
};

const vAgent = {
  dashboard,
  Projects,
  Work,
  ProjectArticles,
  ProjectPurchase,
  ProjectFree,
  Staff,
  ProgmaUser,
  Relations,
  Articles,
  Attachments
};

export default vAgent;
