import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { Project, Status } from "../models/projectAndSubject";

const buttonTextActualProjects = "Actuele projecten";
const buttonTextAllProjects = "Alle projecten";

const initialValues: {
  projects?: Project[];
  isLoading: boolean;
  loadButtonText: string;
} = {
  isLoading: false,
  loadButtonText: buttonTextActualProjects,
};

export default class ProjectsStore {
  projects = initialValues.projects;
  isLoading = initialValues.isLoading;
  loadButtonText = initialValues.loadButtonText;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.projects = initialValues.projects;
    this.isLoading = initialValues.isLoading;
    this.loadButtonText = initialValues.loadButtonText;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  loadProjects = async (all: boolean) => {
    try {
      this.setIsLoading(true);
      const projects = await vAgent.Projects.load(all);
      const text = all ? buttonTextAllProjects : buttonTextActualProjects;
      runInAction(() => {
        this.projects = projects;
        this.loadButtonText = text;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  loadProjectsByStatus = async (status: Status[], loadButtonText: string) => {
    try {
      this.setIsLoading(true);
      const projects = await vAgent.Projects.loadByStatus(status);
      runInAction(() => {
        this.projects = projects;
        this.loadButtonText = loadButtonText;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteProject = async (id: number) => {
    try {
      this.setIsLoading(true);
      const result = await vAgent.Projects.delete(id);
      if (result && result > 0) {
        runInAction(() => {
          this.projects = this.projects!.filter((x) => x.id !== id);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  addProject = (project: Project) => {
    const temp = this.projects ? this.projects.slice() : [];
    temp.push(project);
    runInAction(() => (this.projects = temp));
  };

  editProject = (project: Project) => {
    const temp = this.projects ? this.projects.slice() : [];
    temp[temp.findIndex((a) => a.id === project.id)] = project;
    runInAction(() => (this.projects = temp));
  };
}
