import { useState } from "react";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik } from "formik";
import FTextfield from "../../Common/form/FTextField";
import FSubmitButton from "../../Common/form/FSubmitButton";
import ValidationErrors from "../../Common/form/ValidationErrors";
import { useRootStore } from "../../stores/rootStore";

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Email moet een geldig e-mailadres zijn")
      .required("Email is vereist"),
  });

export default function ForgotPassword() {
  const { appUserStore } = useRootStore();
  const theme = useTheme();
  const [result, setResult] = useState(false);

  return (
    <Formik
      initialValues={{
        email: "",
        baseUrl: window.location.toString().replace("/forgot-password", ""),
        error: null,
      }}
      onSubmit={(values, { setErrors, setSubmitting }) =>
        appUserStore
          .forgotPassword(values)
          .then((result) => result && setResult(result))
          .catch((error) => {setErrors({ error: error }); setSubmitting(false);})
      }
      validationSchema={validationSchema}
    >
      {({ errors }) =>
        !result ? (
          <form noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FTextfield
                  name='email'
                  label='Email'
                  required
                  autoFocus
                  autoComplete='email'
                />
              </Grid>

              {errors.error && (
                <Grid item xs={12}>
                  <ValidationErrors errors={errors.error} />
                </Grid>
              )}

              <Grid item xs={12}>
                <FSubmitButton size={"large"}>
                  Versturen
                  {/* <Typography>Inloggen</Typography> */}
                </FSubmitButton>
              </Grid>
            </Grid>

            <div style={{ marginTop: theme.spacing(1) }}>
              <Link href='login' variant='body2'>
                Terug naar inloggen
              </Link>
            </div>
          </form>
        ) : (
          <>
            <Typography>Aanvraag gelukt!</Typography>
            <Typography>Controleer uw email...</Typography>

            <div style={{ marginTop: theme.spacing(2) }}>
              <Grid container>
                <Grid item xs>
                  <Link href='login' variant='body2'>
                    Terug naar inloggen
                  </Link>
                </Grid>
              </Grid>
            </div>
          </>
        )
      }
    </Formik>
  );
}
