import { Relation } from "../models/relation";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";

const initialValues: { relations?: Relation[]; isLoading: boolean } = {
  isLoading: false,
};

export default class RelationsStore {
  relations = initialValues.relations;
  isLoading = initialValues.isLoading;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.relations = initialValues.relations;
    this.isLoading = initialValues.isLoading;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  loadRelations = async () => {
    try {
      this.setIsLoading(true);
      const relations = await vAgent.Relations.load();
      runInAction(() => {
        this.relations = relations;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteRelation = async (id: number) => {
    try {
      this.setIsLoading(true);
      const result = await vAgent.Relations.delete(id);
      if (result && result > 0) {
        runInAction(() => {
          this.relations = this.relations!.filter((x) => x.id !== id);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  addRelation = (relation: Relation) => {
    const temp = this.relations ? this.relations.slice() : [];
    temp.push(relation);
    runInAction(() => (this.relations = temp));
  };

  editRelation = (relation: Relation) => {
    const temp = this.relations ? this.relations.slice() : [];
    temp[temp.findIndex((a) => a.id === relation.id)] = relation;
    runInAction(() => (this.relations = temp));
  };
}
