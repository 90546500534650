import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import MainMenu from "./MainMenu";
import MainAppBar from "../../Layouts/MainAppBar";
//import { Box, Grid } from "@material-ui/core";

const appBarHeight = 56;
const drawerWidth = 256;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up("xl")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      padding: theme.spacing(2, 2),
      marginTop: `${appBarHeight}px`,
      [theme.breakpoints.up("xl")]: {
        marginLeft: drawerWidth,
      },
    },
  })
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  useBackButton: boolean;
  pageTitle: string;
  children: any;
}

export default function MainLayout(props: Props) {
  const { window } = props;
  const classes = useStyles();
  //const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function closeTemporyDrawer() {
    setMobileOpen(false);
  }

  const drawer = (
    <div>
      <Hidden lgDown implementation='css'>
        {/* necessary for items of drawer below app bar if variant="permanent"*/}
        <div style={{ height: `${appBarHeight}px` }} />
      </Hidden>

      <MainMenu closeTemporyDrawer={closeTemporyDrawer} />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <MainAppBar
        appBarHeight={appBarHeight}
        useMainMenuIconButton={true}
        handleDrawerToggle={handleDrawerToggle}
        useBackButton={props.useBackButton}
        pageTitle={props.pageTitle}
      />

      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden xlUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor='right' //{theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden lgDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>{props.children}</main>
    </div>
  );
}
