import { VStore as V12Store } from './../V12/stores/vStore';
import { VStore as V13Store } from './../V13/stores/vStore';
import { createContext, useContext } from "react"
import AppUserStore from "./appUserStore";
import AdministrationsStore from './administrationsStore';
import CommonStore from './commonStore';
import AdministrationFormStore from './administrationFormStore';


export class RootStore {
    commonStore;
    appUserStore;
    administrationsStore;
    administrationFormStore;

    v12Store;
    v13Store;
    
    constructor() {
        this.appUserStore = new AppUserStore(this);
        this.commonStore = new CommonStore();
        this.administrationsStore = new AdministrationsStore(this);
        this.administrationFormStore = new AdministrationFormStore();

        this.v12Store = new V12Store(this);
        this.v13Store = new V13Store(this);
    }  

    resetAllStores = () => {
        this.commonStore.resetStore();
        this.administrationsStore.resetStore();
        this.administrationFormStore.resetStore();
        
        this.resetVStoresOnly();
    }

    resetVStoresOnly = () => {      
        this.v12Store.resetStores();
        this.v13Store.resetStores();
    }
}

export const rootStore = new RootStore();

export const RootStoreContext = createContext(rootStore);

export function useRootStore() {
    return useContext(RootStoreContext);
}





// import {action, toJS} from "mobx";

// export default class ResettableStore {
//   _superInitialState: Object | null | undefined

//   @action.bound
//   setInitialState = () => {
//     this._superInitialState = toJS(this)
//   }

//   @action.bound
//   reset() {
//     if (!this._superInitialState) {
//       console.warn('reset() does not work unless you call this.setInitialState() at the end of the constructor.')
//       return
//     }

//     const initialState = {...this._superInitialState}
//     for (const key in initialState) {
//       if (!initialState.hasOwnProperty(key)) continue
//       if (key === '_superInitialState') continue
//       this[key] = initialState[key]
//     }
//   }
// }




// interface StoreV12{
//     coreStore: Store,
//     progmaUserStore: ProgmaUserStore,

//     resetStores: () => void;
// }

// export const storeV12: StoreV12 = {
//     coreStore: store,
//     progmaUserStore: new ProgmaUserStore(store.appUserStore),

//     resetStores: () => {
//         storeV12.progmaUserStore = new ProgmaUserStore(store.appUserStore);
//       }
// }

// export const StoreContextV12 = createContext(storeV12);

// export function useStoreV12() {
//     return useContext(StoreContextV12);
// }