import { makeAutoObservable, runInAction } from "mobx";
import { ServerError } from "../models/serverError";
import theme from "../themes/theme";

// const breakPoints = {
//   xs: 375,
//   sm: 480,
//   md: 
// }

export default class CommonStore {
  error?: ServerError;
  isMobile = this.getIsMobile();
  useFab = this.getUseFab();
  orientation: "portrait" | "landscape" = this.getOrientation();
  // isXs = this.getIsXs();
  // isSm = this.getIsSm();
  // isMd = this.getIsMd();
  // isLg = this.getIsLg();
  // isXl = this.getIsXl();

  constructor() {
    makeAutoObservable(this);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener("orientationchange", this.handleOrientationChange);
  }

  handleResize = () => {
    runInAction(() => {
      this.isMobile = this.getIsMobile();
      this.useFab = this.getUseFab();
      // this.isXs = this.getIsXs();
      // this.isSm = this.getIsSm();
      // this.isMd = this.getIsMd();
      // this.isLg = this.getIsLg();
      // this.isXl = this.getIsXl();
    })
  }

  handleOrientationChange = () => {
    runInAction(() => {
      this.orientation = this.getOrientation();
    })
  }


  private getIsMobile() {
    return window && window.innerWidth < 1300;
  }

  private getUseFab() {
    return window && window.innerWidth < theme.breakpoints.values.xl;
  }

  private getOrientation() {
    if (window.orientation === 0) return "portrait";
    return "landscape";
  }
  // private getIsXs() {
  //   return window && window.innerWidth < theme.breakpoints.values.sm;
  // }

  // private getIsSm() {
  //   return window && window.innerWidth >= theme.breakpoints.values.sm && window.innerWidth < theme.breakpoints.values.md;
  // }

  // private getIsMd() {
  //   return window && window.innerWidth >= theme.breakpoints.values.md && window.innerWidth < theme.breakpoints.values.lg;
  // }

  // private getIsLg() {
  //   return window && window.innerWidth >= theme.breakpoints.values.lg && window.innerWidth < theme.breakpoints.values.xl;
  // }

  // private getIsXl() {
  //   return window && window.innerWidth >= theme.breakpoints.values.xl;
  // }

  resetStore = () => {
    this.error = undefined;
  }

  setServerError = (error: ServerError) => {
    this.error = error;
  };
}