import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { addDays } from "date-fns";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import WorkSummary, { columnsSummary } from "./WorkSummary";
import WorkToolstripAndPage from "./WorkToolstripAndPage";
import { observer } from "mobx-react-lite";
import { TimeAccounting, WorkItem } from "../../models/work";
import WorkRowDetail from "./WorkRowDetail";
import {
  Column,
  RowDetailState,
  SelectionState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableRowDetail,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
  addAutoTableInPDF,
  ExportFormats,
  getFileNameExport,
  gridExportOnSave,
} from "../../../Common/Functions";
import jsPDF from "jspdf";
import { useCallback, useRef, useState } from "react";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import { useVStore } from "../../stores/vStore";
import { CellRowDetail, NumberTypeProvider } from "../../../Common/TableComponents";
import { TableConstants } from "../../../Common/Constants";

const tabHeight = "48px"; // default: '48px'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      //backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
      minHeight: tabHeight,
      height: tabHeight,
    },
    tabRoot: {
      minHeight: tabHeight,
      height: tabHeight,
    },
  })
);

export default observer(function WorkDay() {
  const classes = useStyles();
  const theme = useTheme();
  const [exportFormat, setExportFormat] = useState<ExportFormats | undefined>(
    undefined
  );
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    workStore: {
      currentDate,
      setCurrentDate,
      dayIndex,
      generalData,
      workWeekData,
      staffMemberId,
      expandedRowIds,
      setExpandedRowIds,
    },
  } = useVStore();

  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div style={{ marginTop: "16px" }}>{children}</div>
          // <Box p={3}>
          //   <Typography>{children}</Typography>
          // </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
    };
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentDate(addDays(currentDate, newValue - dayIndex));
  };

  const handleChangeIndex = (index: number) => {
    setCurrentDate(addDays(currentDate, index - dayIndex));
  };

  let columns: Column[] = [
    { name: "times", title: "Tijden" },
    { name: "description", title: "Omschrijving" },
    { name: "total", title: "Totaal" },
  ];

  const numberColumns = ["total"];   

  let tableColumnExtensions: any = [
    { columnName: "times", align: "left", width: 70, wordWrapEnabled: true },
    {
      columnName: "description",
      align: "left",
      width: "auto",
      wordWrapEnabled: true,
    },
    { columnName: "total", align: "right", width: 55 },
  ];

  if (
    generalData &&
    generalData.timeAccounting !== TimeAccounting.RequiredWithTimeBreak
  ) {
    columns = columns.slice(1); //remove times
    tableColumnExtensions = tableColumnExtensions.slice(1); //remove times
  }

  if (matches) {
    columns = columns.filter((x) => x.name !== "total");
    tableColumnExtensions = tableColumnExtensions.filter(
      (x: any) => x.name !== "total"
    );
  }

  let columnsExport: Column[] = [
    { name: "activity", title: "Activiteit" },
    { name: "timeFrom", title: "Van" },
    { name: "timeTo", title: "Tot" },
    { name: "break", title: "Pauze (min)" },
    { name: "netHours", title: "Netto Uren" },
    { name: "quantity", title: "Rekenaantal" },
    { name: "companyName", title: "Bedrijfsnaam" },
    { name: "projectName", title: "Project" },
    { name: "comments", title: "Opmerking" },
  ];

  if (
    generalData &&
    generalData.timeAccounting === TimeAccounting.RequiredWithoutTimeBreak
  ) {
    columnsExport = [
      { name: "activity", title: "Activiteit" },
      { name: "netHours", title: "Netto Uren" },
      { name: "quantity", title: "Rekenaantal" },
      { name: "companyName", title: "Bedrijfsnaam" },
      { name: "projectName", title: "Project" },
      { name: "comments", title: "Opmerking" },
    ];
  }

  if (generalData && generalData.timeAccounting === TimeAccounting.Off) {
    columnsExport = [
      { name: "activity", title: "Activiteit" },
      { name: "quantity", title: "Rekenaantal" },
      { name: "companyName", title: "Bedrijfsnaam" },
      { name: "projectName", title: "Project" },
      { name: "comments", title: "Opmerking" },
    ];
  }

  const getHoursConsecutive = (work: WorkItem[]) => {
    for (var i = 0; i < work.length - 1; i++) {
      if (
        work[i].timeTo &&
        work[i + 1].timeFrom &&
        work[i].timeTo !== work[i + 1].timeFrom
      ) {
        return false;
      }
    }
    return true;
  };

  const workItems = workWeekData
    ? workWeekData.getWorkItemsOfDay(dayIndex)
    : [];

  const rows = workItems.map((x) => ({
    id: x.id,
    times: x.timeFrom + " " + x.timeTo,
    description: x.companyName
      ? x.companyName + " | " + x.projectName + " | " + x.activity
      : x.activity,
    total: generalData
      ? generalData.timeAccounting === TimeAccounting.RequiredWithTimeBreak ||
        generalData.timeAccounting === TimeAccounting.RequiredWithoutTimeBreak
        ? x.netHours
        : x.quantity
      : 0,
  }));

  const summaryItems = workWeekData?.getSummaryItems(workItems);

  const exportName =
    "Werkoverzicht van " +
    (generalData
      ? generalData.staffMembers.find((x) => x.id === staffMemberId)?.nameShort
      : "") +
    " op " +
    currentDate.formatNL("EEEE dd-MM-yyyy");

  const onSave = (workbook: any) => {
    gridExportOnSave(exportName, workbook, exportFormat!);
  };

  const exporterRef = useRef(null);

  const startGridExport = useCallback(() => {
    if (exporterRef.current && typeof exporterRef.current === "object") {
      const gridExporter: any = exporterRef.current;
      gridExporter.exportGrid();
    }
  }, [exporterRef]);

  const handleExportClick = (exportFormat: ExportFormats) => {
    if (exportFormat === ExportFormats.pdf) {
      const filename = getFileNameExport(exportName, ExportFormats.pdf);

      const doc = new jsPDF();
      doc.text(exportName, 15, 15);

      addAutoTableInPDF(doc, columnsExport, workItems, { startY: 25 });

      if (summaryItems && summaryItems.length > 0) {
        addAutoTableInPDF(doc, columnsSummary, summaryItems, {
          tableWidth: 100,
        });
      }

      doc.save(filename);

      //exportAsPDF(name , columns, rowsWithProject);
    } else {
      setExportFormat(exportFormat);
      startGridExport();
    }
  };

  const DayContents = (
    <>
      {workItems &&
        generalData &&
        generalData.timeAccounting === TimeAccounting.RequiredWithTimeBreak &&
        !getHoursConsecutive(workItems) && (
          <div style={{ marginTop: "16px" }}>
            <Typography color='error'>
              Let op uren zijn niet aaneengesloten
            </Typography>
          </div>
        )}

      <div style={{ marginTop: "8px", minWidth: 288 }}>
        <Paper>
          <Grid columns={columns} rows={rows} getRowId={(row) => row.id}>
            <RowDetailState
              expandedRowIds={expandedRowIds}
              onExpandedRowIdsChange={setExpandedRowIds}
            />

            <SelectionState
              selection={expandedRowIds}
              onSelectionChange={setExpandedRowIds}
            />

            <NumberTypeProvider for={numberColumns} />

            <Table
              columnExtensions={tableColumnExtensions}
              messages={TableConstants.tableMessages}
            />
            <TableRowDetail
              contentComponent={WorkRowDetail}
              cellComponent={CellRowDetail}
            />

            <TableSelection
              selectByRowClick
              highlightRow={false}
              showSelectionColumn={false}
            />
          </Grid>
          <GridExporter
            ref={exporterRef}
            rows={workItems}
            columns={columnsExport}
            onSave={onSave}
          />
        </Paper>
      </div>

      <WorkSummary summaryItems={summaryItems} />
    </>
    // <WorkDayItem workStore={workStore}/>
  );

  return (
    <WorkToolstripAndPage onExportClick={handleExportClick}>
      <div className={classes.root}>
        <AppBar position='static' color='default'>
          <Tabs
            variant='scrollable'
            value={dayIndex}
            onChange={handleChange}
            classes={{ root: classes.tabsRoot }}
          >
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <p>
                  Ma
                  <br />
                  {workWeekData?.totalMonday.toNumberString()}
                </p>
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <p>
                  Di
                  <br />
                  {workWeekData?.totalTuesday.toNumberString()}
                </p>
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <p>
                  Wo
                  <br />
                  {workWeekData?.totalWednesday.toNumberString()}
                </p>
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <p>
                  Do
                  <br />
                  {workWeekData?.totalThursday.toNumberString()}
                </p>
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <p>
                  Vr
                  <br />
                  {workWeekData?.totalFriday.toNumberString()}
                </p>
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <p>
                  Za
                  <br />
                  {workWeekData?.totalSaturday.toNumberString()}
                </p>
              }
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.tabRoot }}
              label={
                <p>
                  Zo
                  <br />
                  {workWeekData?.totalSunday.toNumberString()}
                </p>
              }
              {...a11yProps(0)}
            />
          </Tabs>
        </AppBar>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={dayIndex}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={dayIndex} index={0} dir={theme.direction}>
            {DayContents}
          </TabPanel>
          <TabPanel value={dayIndex} index={1} dir={theme.direction}>
            {DayContents}
          </TabPanel>
          <TabPanel value={dayIndex} index={2} dir={theme.direction}>
            {DayContents}
          </TabPanel>
          <TabPanel value={dayIndex} index={3} dir={theme.direction}>
            {DayContents}
          </TabPanel>
          <TabPanel value={dayIndex} index={4} dir={theme.direction}>
            {DayContents}
          </TabPanel>
          <TabPanel value={dayIndex} index={5} dir={theme.direction}>
            {DayContents}
          </TabPanel>
          <TabPanel value={dayIndex} index={6} dir={theme.direction}>
            {DayContents}
          </TabPanel>
        </SwipeableViews>
      </div>
    </WorkToolstripAndPage>
  );
});
