import { Button } from "@material-ui/core";
import SyncIcon from '@material-ui/icons/Sync';

interface Props {
  onReload: () => void;
}

export default function ReloadButton({ onReload }: Props) {

  return (
      <Button
        size='medium'
        variant='outlined'
        // color='secondary'
        onClick={onReload}
        startIcon={<SyncIcon />}
      >
        Herladen
      </Button>
  );
}
