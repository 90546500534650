import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
} from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { ChangeEvent, ReactNode, useState } from "react";
import theme from "../../themes/theme";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

export interface Item {
  id: number | string;
  text: string;
  sourceObject?: any;
}

interface Props {
  name: string;
  label: string;
  required: boolean;
  items: Item[] | undefined;
  autoFocus?: boolean;
  useSearchField?: boolean | undefined;
  onValueChanged?: ((value: unknown) => void) | undefined;
  onAddButtonClick?: (() => void) | undefined;
  extraControl?: JSX.Element;
}

export default function FSelect(props: Props) {
  const [field, mata] = useField(props.name);
  const [stateValues, setStateValues] = useState<{
    searchValue: string;
    open: boolean;
    autoFocus: boolean;
  }>({ searchValue: "", open: false, autoFocus: false });
  const { setFieldValue } = useFormikContext();

  const {
    useSearchField,
    onValueChanged,
    onAddButtonClick,
    extraControl,
    ...baseTextFieldProps
  } = props; //, useClearButton

  const configSelect: TextFieldProps = {
    ...field,
    ...baseTextFieldProps,
  };

  if (mata && mata.touched && mata.error) {
    configSelect.error = true;
    configSelect.helperText = mata.error;
  }

  const filteredItems =
    stateValues.searchValue !== ""
      ? props.items?.filter((x) =>
          x.text.toLowerCase().includes(stateValues.searchValue.toLowerCase())
        )
      : props.items;

  const MyMenuItems = filteredItems
    ? filteredItems.map((item, index) => (
        <MenuItem key={index} value={item.id} style={{ whiteSpace: "normal"}}>
          <Typography noWrap={false}>{item.text}</Typography>
        </MenuItem>
      ))
    : [];


  // if (!props.required) {
  //   MyMenuItems.splice(0,0,<MenuItem key={-1} value={undefined}>Geen</MenuItem>)
  // }

  if (useSearchField) {
    MyMenuItems.splice(
      0,
      0,
      <ListSubheader
        style={{ backgroundColor: theme.palette.background.paper }}
        key={-2}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          style={{ maxWidth: "500px", marginBottom: theme.spacing(1) }}
        >
          <TextField
            label='Zoeken'
            size='medium'
            placeholder='Zoeken...'
            autoFocus={stateValues.autoFocus}
            variant='outlined'
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              // endAdornment: (
              //   <InputAdornment position='end'>
              //     <IconButton
              //       onClick={() =>
              //         setStateValues({
              //           searchValue: "",
              //           open: stateValues.open,
              //           autoFocus: stateValues.autoFocus,
              //         })
              //       }
              //     >
              //       <ClearIcon />
              //     </IconButton>
              //   </InputAdornment>
              // ),
            }}
            // focused={false}
            onClickCapture={(e) => {
              e.stopPropagation();
              // console.log(e);
              // if (e.target.hasOwnProperty("value")) {
              //   e.stopPropagation();
              // } else {
              //     setStateValues({
              //       searchValue: "",
              //       open: stateValues.open,
              //       autoFocus: stateValues.autoFocus,
              //     });
              // }
            }}
            onKeyDownCapture={(e) => {
              e.stopPropagation();
            }}
            onKeyPressCapture={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) =>
              setStateValues({
                searchValue: e.target.value,
                open: stateValues.open,
                autoFocus: stateValues.autoFocus,
              })
            }
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
          {onAddButtonClick && (
            <IconButton onClick={onAddButtonClick}>
              <AddIcon />
            </IconButton>
          )}
        </Box>
        {extraControl && extraControl}
        <Divider />
      </ListSubheader>
    );
  } else if (extraControl) {
    MyMenuItems.splice(
      0,
      0,
      <ListSubheader
        style={{ backgroundColor: theme.palette.background.paper }}
        key={-3}
      >
        {extraControl}
      </ListSubheader>
    );
  }

  const myItem =
    field.value !== undefined &&
    field.value !== null &&
    field.value.toString() !== "" &&
    props.items
      ? props.items.filter((x) => x.id === field.value)[0]
      : undefined;
  const renderText = myItem ? myItem.text : "";

  const handleOnChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: ReactNode
  ) => {
    setFieldValue(props.name, event.target.value);
    if (onValueChanged) return onValueChanged(event.target.value);
  };

  return (
    //<Box display='flex' flexDirection='row' alignItems='center'>
      <TextField
        //style={{textAlign: "right"}}
        select
        fullWidth
        variant='outlined'
        size='medium'
        {...configSelect}
        value={field.value ?? ""}
        onKeyDownCapture={(e) => {
          if (useSearchField && e.key !== "Escape" && e.key !== "Tab") {
            setStateValues({
              searchValue: stateValues.searchValue + e.key,
              open: true,
              autoFocus: true,
            });
          }
        }}
        SelectProps={{
          onChange: handleOnChange,
          MenuProps: { autoFocus: !useSearchField, open: stateValues.open },
          onOpen: () =>
            setStateValues({
              searchValue: "",
              open: true,
              autoFocus: stateValues.autoFocus,
            }),
          onClose: () =>
            setStateValues({
              searchValue: stateValues.searchValue,
              open: false,
              autoFocus: false,
            }),
          renderValue: () => renderText,
          endAdornment: !props.required && (
            <InputAdornment position='end'>
              <IconButton
                style={{ marginRight: theme.spacing(2) }}
                size='medium'
                onClick={() => {
                  setFieldValue(props.name, undefined);
                }}
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      >
        {MyMenuItems}
      </TextField>
      // {props.useClearButton && (
      //   <IconButton onClick={() => setFieldValue(props.name, undefined)}>
      //     <ClearIcon />
      //   </IconButton>
      // )}
    //</Box>
  );
}
