import {
  Column,
  RowDetailState,
  SelectionState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import { useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { TableConstants } from "../../../Common/Constants";
import LoadingComponent from "../../../Common/LoadingComponent";
import { CellRowDetail, CurrencyTypeProvider, HeaderTitle, NumberTypeProvider, PercentTypeProvider } from "../../../Common/TableComponents";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import ProjectPurchaseRowDetail from "./ProjectPurchaseRowDetail";
import { UserRightsFinancial } from "../../models/progmaUserRights";

export default observer(function ProjectPurchaseTable() {
  const {
    projectPurchaseStore: {
      isLoading,
      isLoadingProjectPurchase,
      projectPurchaseList,
      expandedRowIds,
      setExpandedRowIds,
    },
    progmaUserStore: { progmaUserRights },
  } = useVStore();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  let columns: Column[] = [
    { name: "quantity", title: "Aantal" },
    { name: "description", title: "Omschrijving" },
    { name: "costPrice", title: "Kostprijs" },
    { name: "margin", title: "Marge" },
    { name: "profit", title: "Winst" },
    { name: "sellingPrice", title: "Verkoopprijs" },
    { name: "costPriceTotal", title: "Kostprijs totaal" },
    { name: "profitTotal", title: "Winst totaal" },
    { name: "sellingPriceTotal", title: "Totaal" },
  ];

  const numberColumns = ["quantity"];
  const percentColumns = ["margin"];
  const currencyColumns = ["costPrice", "profit", "sellingPrice", "costPriceTotal", "profitTotal", "sellingPriceTotal"];

  if (matches) {
    columns = columns.filter(
      (x) => x.name !== "costPrice" && 
      x.name !== "margin" && 
      x.name !== "profit" && 
      x.name !== "sellingPrice" &&
      x.name !== "costPriceTotal" &&
      x.name !== "profitTotal" &&
      x.name !== "sellingPriceTotal"
    );
  }

  let tableColumnExtensions: any = [
    { columnName: "quantity", align: "right", width: 55 },
    { columnName: "description", align: "left", wordWrapEnabled: true },
    { columnName: "costPrice", align: "right", width: 100 },
    { columnName: "margin", align: "right", width: 80 },
    { columnName: "profit", align: "right", width: 100 },
    { columnName: "sellingPrice", align: "right", width: 100 },
    { columnName: "costPriceTotal", align: "right", width: 120 },
    { columnName: "profitTotal", align: "right", width: 120 },
    { columnName: "sellingPriceTotal", align: "right", width: 120 },
  ];

  if (
    progmaUserRights?.projectPurchaseUserRightsFinancial !==
    UserRightsFinancial.All
  ) {
    columns = columns.filter(
      (x) => x.name !== "costPrice" && x.name !== "margin" && x.name !== "profit" && x.name !== "costPriceTotal" && x.name !== "profitTotal"
    );
    tableColumnExtensions = tableColumnExtensions.filter(
      (x: any) =>
        x.columnName !== "costPrice" && x.columnName !== "margin" && x.columnName !== "profit" && x.columnName !== "costPriceTotal" && x.columnName !== "profitTotal"
    );
  }

  if (
    progmaUserRights?.projectPurchaseUserRightsFinancial ===
    UserRightsFinancial.None
  ) {
    columns = columns.filter(
      (x) => x.name !== "sellingPrice" && x.name !== "sellingPriceTotal"
    );
    tableColumnExtensions = tableColumnExtensions.filter(
      (x: any) =>
        x.columnName !== "sellingPrice" && x.columnName !== "sellingPriceTotal"
    );
  }

  const pageContent = isLoadingProjectPurchase ? (
    <LoadingComponent useBackdrop={false} />
  ) : (
      <div style={{ marginTop: theme.spacing(1), minWidth: 288 }}>
          <Grid
            columns={columns}
            rows={projectPurchaseList}
            getRowId={(row) => row.id}
          >
            <RowDetailState
              expandedRowIds={expandedRowIds}
              onExpandedRowIdsChange={setExpandedRowIds}
            />

            <SelectionState
              selection={expandedRowIds}
              onSelectionChange={setExpandedRowIds}
            />

            <NumberTypeProvider for={numberColumns} />
            <PercentTypeProvider for={percentColumns} />
            <CurrencyTypeProvider for={currencyColumns} />

            <Table
              columnExtensions={tableColumnExtensions}
              messages={TableConstants.tableMessages}
            />

            <TableRowDetail
              contentComponent={ProjectPurchaseRowDetail}
              cellComponent={CellRowDetail}
            />

            <TableSelection
              selectByRowClick
              highlightRow={false}
              showSelectionColumn={false}
            />
            <TableHeaderRow titleComponent={HeaderTitle} />
          </Grid>
      </div>
  );

  if (isLoading) return <LoadingComponent useBackdrop={false} />;

  return (pageContent);
});
