import { CircularProgress, Backdrop, Typography, Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

interface Props {
  content?: string;
  useBackdrop: boolean;
}

export default function LoadingComponent({ content, useBackdrop }: Props) {
  const classes = useStyles();

  const LoadingComponent = (
    // <>
    //   <CircularProgress color="inherit" />
    //   <Typography>{content}</Typography>
    // </>

    <table>
      <tbody>
        <tr>
          <td style={{ textAlign: "center" }}>
            <CircularProgress color='inherit' />
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "center" }}>
            <Typography>{content ? content : "Laden..."}</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const MyComponent = useBackdrop ? (
    <Backdrop className={classes.backdrop} open={true}>
      {LoadingComponent}
    </Backdrop>
  ) : (
    <Box display='flex' justifyContent='center' alignItems='center'>
      {LoadingComponent}
    </Box>
  );

  return MyComponent;
}
