import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Checkbox } from "@material-ui/core";
import theme from "../themes/theme";

export const HeaderTitle = (props: any) => <b>{props.children}</b>

export const CellRowDetail = (props: any) => {
  const { children, ...restProps} = props;
  return <Table.Cell {...restProps} style={{
    width: "100%",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    //margin:0,
    backgroundColor: theme.palette.background.paper,
    // whiteSpace: "normal"
  }} >
    <div style={{ width: "100%", height: theme.spacing(1), backgroundImage: `linear-gradient(to bottom, ${theme.palette.divider}, transparent)` }} />
    <div style={{ margin: theme.spacing(0) }}>{children}</div>
    <div style={{ width: "100%", height: theme.spacing(1), backgroundImage: `linear-gradient(to top, ${theme.palette.divider}, transparent)` }} />
  </Table.Cell>
}

export const CellBoolean = (props: any) => {
  const { value } = props;
  return <Table.Cell {...props} >
    <Checkbox checked={value} disabled size="small"/>
  </Table.Cell>;
}

export const CellRowPanel = ({children}: {children: any}) => <div style={{margin: theme.spacing(2)}}>{children}</div>


const NumberFormatter = ({ value }: any) => {
  if (value === undefined || value === null) return null;
  if (typeof value === "number") return value.toNumberString();
  return value;
};

export const NumberTypeProvider = (props: any) => (
  <DataTypeProvider formatterComponent={NumberFormatter} {...props} />
);

const PercentFormatter = ({ value }: any) => {
  if (value === undefined || value === null) return null;
  if (typeof value === "number") return value.toPercentString();
  return value;
};

export const PercentTypeProvider = (props: any) => (
  <DataTypeProvider formatterComponent={PercentFormatter} {...props} />
);


const CurrencyFormatter = ({ value }: any) => {
  if (value === undefined || value === null) return null;
  if (typeof value === "number") return value.toCurrencyString();
  return value;
  // if (value !== undefined && value !== null)
  //   return value.toLocaleString("nl-NL", {
  //     style: "currency",
  //     currency: "EUR",
  //   });
  // return null;
};

export const CurrencyTypeProvider = (props: any) => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
);


// import { Table } from "@devexpress/dx-react-grid-material-ui";
// import theme from "../themes/theme";

// export const HeaderTitle = (props: any) => <b>{props.children}</b>

// export const CellRowDetail = (props: any) => {
//   const { children, ...restProps } = props;
//   return <Table.Cell {...restProps} style={{
//     width: "100%",
//     paddingTop: theme.spacing(0),
//     paddingBottom: theme.spacing(0),
//     paddingLeft: theme.spacing(0),
//     paddingRight: theme.spacing(0),
//     backgroundColor: theme.palette.background.paper,
//   }} >
//     <div style={{ width: "100%", height: theme.spacing(1), backgroundImage: `linear-gradient(to bottom, ${theme.palette.divider}, transparent)` }} />
//     <div style={{ margin: theme.spacing(2) }}>{children}</div>
//     <div style={{ width: "100%", height: theme.spacing(1), backgroundImage: `linear-gradient(to top, ${theme.palette.divider}, transparent)` }} />
//   </Table.Cell>
// }

