import { IconButton, Typography } from "@material-ui/core";
import ToolStrip from "../ToolStrip";
import ToolStripSpaceBetween from "../ToolStripSpaceBetween";
import GetAppIcon from "@material-ui/icons/GetApp";

interface Props {
  title: string;
  children?: JSX.Element | JSX.Element[];
  onDownloadClick?: () => void;
  limitMoreMenu?: number;
}

export default function FilePreviewToolbar({
  title,
  children,
  onDownloadClick,
  limitMoreMenu
}: Props) {
  const downloadButton = (
    <IconButton onClick={onDownloadClick}>
      <GetAppIcon />
    </IconButton>
  );

  const secondaryTools = children
    ? Array.isArray(children)
      ? [...children, downloadButton]
      : [children, downloadButton]
    : downloadButton;

  return (
    <ToolStripSpaceBetween
      primaryToolStrip={
        <Typography
          color="primary"
          style={{
            maxWidth: 190,
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Typography>
      }
      secondaryToolStrip={
        <ToolStrip spacing={0} limitMoreMenu={limitMoreMenu}>
          {secondaryTools}
        </ToolStrip>
      }
    />
  );
}
