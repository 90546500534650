import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MainAppBar from './MainAppBar';
import { Container } from '@material-ui/core';

const appBarHeight = 56;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            //display: 'flex',
            //width: theme.breakpoints.values.md
        },
        content: {
            padding: theme.spacing(2, 2),
            marginTop: `${appBarHeight}px`
        },
    }),
);

interface Props {
    useBackButton: boolean;
    pageTitle: string;
    children: any;
}

export default function MainLayout({ useBackButton, pageTitle, children }: Props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" style={{padding:0}}>
                <MainAppBar appBarHeight={appBarHeight} useMainMenuIconButton={false} handleDrawerToggle={() => { }} useBackButton={useBackButton} pageTitle={pageTitle} />
                <main className={classes.content}>
                    {children}
                </main>
            </Container>
        </div>
    );
}