import { Column } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { HeaderTitle, NumberTypeProvider } from "../../../Common/TableComponents";
import theme from "../../../themes/theme";
import { SummaryItem } from "../../stores/workStore";
import { Grid as MatGrid, Paper } from "@material-ui/core"
import SmartGridItem from "../../../Common/SmartGridItem";
import { TableConstants } from "../../../Common/Constants";

interface Props {
  summaryItems?: SummaryItem[];
}

export const columnsSummary: Column[] = [
  { name: "activity", title: "Activiteit" },
  { name: "total", title: "Totaal" },
];

const numberColumns = ["total"];


let tableColumnExtensions: any = [
  { columnName: "activity", align: "left", width: "auto" },
  { columnName: "total", align: "right", width: 60 },
];

const Cell = (props: any) => {
  const { column, row } = props;
  if (row.activityID === -1) {
    if (column.name === "activity") {
      return (
        <Table.Cell
          {...props}
          style={{
            fontWeight: "bold",
            textAlign: "right",
            paddingTop: 5,
            paddingBottom: 5,
          }}
        />
      );
    } else {
      return (
        <Table.Cell
          {...props}
          style={{ fontWeight: "bold", paddingTop: 8, paddingBottom: 8 }}
        />
      );
    }
  }
  return <Table.Cell {...props} style={{ paddingTop: 8, paddingBottom: 8 }} />;
};

const HeaderCell = (props: any) => {
  return <Table.Cell {...props} style={{ paddingTop: 8, paddingBottom: 8 }} />;
};

export default function WorkSummary({ summaryItems }: Props) {
  return (
    <MatGrid>
      <SmartGridItem>
        <Paper style={{ marginTop: theme.spacing(3), maxWidth: theme.breakpoints.values.md }}>
          <Grid
            columns={columnsSummary}
            rows={summaryItems ? summaryItems : []}
            getRowId={(row: SummaryItem) => row.activityID}
          >
               <NumberTypeProvider for={numberColumns} />
               
            <Table columnExtensions={tableColumnExtensions} cellComponent={Cell} messages={TableConstants.tableMessages} />
            <TableHeaderRow
              cellComponent={HeaderCell}
              titleComponent={HeaderTitle}
            />
          </Grid>
        </Paper>
      </SmartGridItem>
    </MatGrid>
  );
}
