import { ProjectAndSubject, Status } from './projectAndSubject'
import { Activity } from './activity'
import { StaffMember } from './staffMember'

export interface WorkItem {
  id: number
  date: Date
  activityID: number
  activity: string
  timeFrom: string | undefined
  timeTo: string | undefined
  break: number | undefined
  netHours: number | undefined
  quantity: number
  companyName: string | undefined
  projectAndSubjectID: number | undefined
  projectName: string | undefined
  projectStatus: Status | null
  comments: string | undefined
  staffMemberID: number
  staffMemberNameShort: string
}

export interface WorkFormValues {
  id: number
  staffMemberID: number
  activityID: number | undefined
  _Date: Date
  _From: string | undefined
  _To: string | undefined
  break: number | undefined
  netHours: number | undefined
  quantity: number
  projectsAndSubjectsID: number | undefined
  comments: string | undefined
  byStaffMemberID: number
}

export interface GeneralData {
  timeAccounting: TimeAccounting
  activitiesWork: number[]
  activitiesPersonal: number[]
  activitiesEachTime: number[]
  staffMembers: StaffMember[]
  activities: Activity[]
  projectsAndSubjects: ProjectAndSubject[]
}

export enum TimeAccounting {
  RequiredWithTimeBreak,
  RequiredWithoutTimeBreak,
  Off,
}
