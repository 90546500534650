import { Grid } from "@material-ui/core";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import DetailCard, { DetailCardItem } from "../../../Common/DetailCard";
import {
  RowDetailButtonDelete,
  RowDetailButtonEdit,
} from "../../../Common/RowDetailButtons";
import SmartGridItem from "../../../Common/SmartGridItem";
import { CellRowPanel } from "../../../Common/TableComponents";

export default function ProjectArticleRowDetail({ row }: any) {
  const projectArticleId: number = row.id;

  const {
    projectArticlesStore: {
      projectArticles,
      deleteProjectArticle,
      projectAndSubjectId,
    },
    projectArticlesFormStore: { openProjectArticlesForm },
  } = useVStore();

  const projectArticle = projectArticles.find(
    (x) => x.id === projectArticleId
  )!;

  const detailCardItems: DetailCardItem[] = [
    { title: "Artikelnummer", value: projectArticle.articleNumber },
    { title: "Artikelnaam", value: projectArticle.article },
    { title: "Aantal", value: projectArticle.quantity, type: "number" },
    { title: "Opmerking", value: projectArticle.comments },
  ];

  return (
    <CellRowPanel>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <SmartGridItem>
          <DetailCard detailCardItems={detailCardItems} />
        </SmartGridItem>

        <SmartGridItem>
          <ToolStrip
            flexDirection="column"
            alignItems="start"
            spacing={0.5}
            fullwidth
          >
            {projectAndSubjectId && (
              <RowDetailButtonEdit
                onClick={() =>
                  openProjectArticlesForm(projectAndSubjectId, projectArticleId)
                }
              />
            )}

            <RowDetailButtonDelete
              onDeleteConfirmed={() => deleteProjectArticle(projectArticleId)}
            />
          </ToolStrip>
        </SmartGridItem>
      </Grid>
    </CellRowPanel>
  );
}
