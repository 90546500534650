import {
  Button,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { TimeAccounting, WorkFormValues, WorkItem } from "../../models/work";
import FDialog from "../../../Common/form/FDialog";
import ScheduleIcon from "@material-ui/icons/Schedule";
import FSelect from "../../../Common/form/FSelect";
import ProjectForm from "../Projects/ProjectForm";
import { observer } from "mobx-react-lite";
import FTextField from "../../../Common/form/FTextField";
import FDatePicker from "../../../Common/form/FDatePicker";
import FTimePicker from "../../../Common/form/FTimePicker";
import LoadingComponent from "../../../Common/LoadingComponent";
import { createTimeFromTextValue, toNumber } from "../../../Common/Functions";
//import { useStore } from "../../stores/vStore";
import { UserRights } from "../../models/progmaUserRights";
import { useVStore } from "../../stores/vStore";
import { getProjectAndSubjectDisplayLine } from "../../models/projectAndSubject";
import FProjectsAndSubjectsSDTOSelect from "../../../Common/form/FProjectsAndSubjectsSDTOSelect";
import FNumericTextField from "../../../Common/form/FNumericTextField";
import { Activity } from "../../models/activity";
import theme from "../../../themes/theme";

interface Props {
  open: boolean;
  date: Date;
  staffMemberId: number;
  id: number | undefined;
  onCreated: (value: WorkItem) => void;
  onEdited: (value: WorkItem) => void;
  onClose: () => void;
}

export default observer(function WorkForm({
  open,
  date,
  staffMemberId,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {
    workFormStore: {
      load,
      generalData,
      getWorkItemsByDate,
      isLoading,
      workFormValues,
      allProjectsAndSubjects,
      setAllProjectsAndSubjects,
      isLoadingProjectsAndSubjects,
      create,
      edit,
      addProject,
      layoutOption,
      inActiveActivity
    },
    projectFormStore: { projectForm, openProjectForm, closeProjectForm },
  } = useVStore();
  const [timePickerFromOpen, setTimePickerFromOpen] = useState(false);
  const [timePickerToOpen, setTimePickerToOpen] = useState(false);
  const {
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  useEffect(() => {
    load(date, id, staffMemberId);
  }, [load, id, date, staffMemberId]);

  const validationSchema =
    generalData?.timeAccounting === TimeAccounting.RequiredWithTimeBreak
      ? yup.object({
          staffMemberID: yup.number().required("verplicht veld"),
          activityID: yup.number().required("verplicht veld"),
          _Date: yup.date().required("verplicht veld"),
          _From: yup.string().required("verplicht veld"),
          _To: yup.string().required("verplicht veld"),
          break: yup.number().required("verplicht veld"),
          netHours: yup.number().required("verplicht veld"),
          quantity: yup.number().required("verplicht veld"),
          projectsAndSubjectsID: yup.number().nullable(),
          comments: yup.string().max(4096).nullable(),
          byStaffMemberID: yup.number().required("verplicht veld"),
        })
      : generalData?.timeAccounting === TimeAccounting.RequiredWithoutTimeBreak
      ? yup.object({
          staffMemberID: yup.number().required("verplicht veld"),
          activityID: yup.number().required("verplicht veld"),
          _Date: yup.date().required("verplicht veld"),
          netHours: yup.number().required("verplicht veld"),
          quantity: yup.number().required("verplicht veld"),
          projectsAndSubjectsID: yup.number().nullable(),
          comments: yup.string().max(4096).nullable(),
          byStaffMemberID: yup.number().required("verplicht veld"),
        })
      : yup.object({
          staffMemberID: yup.number().required("verplicht veld"),
          activityID: yup.number().required("verplicht veld"),
          _Date: yup.date().required("verplicht veld"),
          quantity: yup.number().required("verplicht veld"),
          projectsAndSubjectsID: yup.number().nullable(),
          comments: yup.string().max(4096).nullable(),
          byStaffMemberID: yup.number().required("verplicht veld"),
        });

  const getTimes = (
    startTime: string | undefined,
    includeTime: string | undefined
  ) => {
    let result: string[] = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j = j + 15) {
        result.push(
          `${i.toString().padStart(2, "0")}:${j.toString().padStart(2, "0")}`
        );
      }
    }
    if (startTime) {
      result = result.filter((x) => x >= startTime);
    }
    if (includeTime && !result.includes(includeTime)) {
      result.push(includeTime);
    }
    return result.sort((a, b) => (a > b ? 1 : -1));
  };

  const breaks = [0, 15, 30, 45, 60, 75, 90, 105, 120];

  const validate = (values: WorkFormValues) => {
    var netHoursError = {};
    var hasErrors = false;
    if (values.netHours && values.netHours < 0) {
      hasErrors = true;
      netHoursError = { netHoursError, netHours: "Mag niet negatief zijn" };
    }

    if (
      values.activityID &&
      generalData?.activitiesWork.includes(values.activityID) &&
      !values.projectsAndSubjectsID
    ) {
      hasErrors = true;
      netHoursError = {
        netHoursError,
        projectsAndSubjectsID: "Verplicht bij gekozen activiteit",
      };
    } else if (
      values.activityID &&
      !generalData?.activitiesWork.includes(values.activityID) &&
      values.projectsAndSubjectsID
    ) {
      hasErrors = true;
      netHoursError = {
        netHoursError,
        projectsAndSubjectsID: "Project niet toegestaan bij deze activiteit",
      };
    }

    const workItems = getWorkItemsByDate(new Date(values._Date));

    // const handleProjectAdded =

    if (
      values.activityID &&
      generalData?.activitiesPersonal.includes(values.activityID) &&
      generalData?.timeAccounting === TimeAccounting.RequiredWithTimeBreak &&
      workItems &&
      workItems.length > 0
    ) {
      for (var i = 0; i < workItems.length; i++) {
        if (
          workItems[i].timeFrom &&
          workItems[i].timeTo &&
          (!id || id !== workItems[i].id)
        ) {
          if (
            values._From! >= workItems[i].timeFrom! &&
            values._From! < workItems[i].timeTo!
          ) {
            hasErrors = true;
            netHoursError = {
              netHoursError,
              _From: "Tijdoverlap met andere regels",
            };
            break;
          } else if (
            values._To! > workItems[i].timeFrom! &&
            values._To! <= workItems[i].timeTo!
          ) {
            hasErrors = true;
            netHoursError = {
              netHoursError,
              _To: "Tijdoverlap met andere regels",
            };
            break;
          } else if (
            workItems[i].timeFrom! >= values._From! &&
            workItems[i].timeFrom! < values._To!
          ) {
            hasErrors = true;
            netHoursError = {
              netHoursError,
              _To: "Tijdoverlap met andere regels",
            };
            break;
          } else if (
            workItems[i].timeTo! > values._From! &&
            workItems[i].timeTo! <= values._To!
          ) {
            hasErrors = true;
            netHoursError = {
              netHoursError,
              _To: "Tijdoverlap met andere regels",
            };
            break;
          }
        }
      }
    }

    if (hasErrors) {
      return netHoursError;
    } else {
      return undefined;
    }
  };

  function handleFormSubmit(workFormValues: WorkFormValues) {
    if (!id) {
      create(workFormValues)
        .then((workItem) => workItem && onCreated(workItem))
        .finally(() => onClose());
    } else {
      edit(workFormValues)
        .then((workItem) => workItem && onEdited(workItem))
        .finally(() => onClose());
    }
  }

  const myActivities : Activity[] = generalData?.activities ? generalData?.activities.slice() : [];

  if (inActiveActivity) myActivities.unshift(inActiveActivity)


  if (isLoading)
    return (
      <LoadingComponent
        content='Arbeid formulier laden...'
        useBackdrop={true}
      />
    );

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={workFormValues}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          const errors = validate(values);
          if (errors) {
            setErrors(errors);
            setSubmitting(false);
          } else handleFormSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => {
          const handleActivity = (value: number) => {
            calculate(
              +value,
              values._From,
              values._To,
              values.break,
              values.netHours
            );
          };

          const handleFrom = (value: string | undefined) => {
            calculate(
              values.activityID,
              value,
              values._To,
              values.break,
              values.netHours
            );
          };

          const handleTo = (value: string | undefined) => {
            calculate(
              values.activityID,
              values._From,
              value,
              values.break,
              values.netHours
            );
          };

          const handleBreak = (value: number) => {
            calculate(
              values.activityID,
              values._From,
              values._To,
              value,
              values.netHours
            );
          };

          const handleNettoHours = (value: number) => {
            calculate(
              values.activityID,
              values._From,
              values._To,
              values.break,
              value
            );
          };

          const calculate = (
            activity: number | undefined,
            _from: string | undefined,
            _to: string | undefined,
            _break: number | undefined,
            netHours: number | undefined
          ) => {
            let quantity = 1;
            if (
              generalData?.timeAccounting ===
              TimeAccounting.RequiredWithTimeBreak
            ) {
              if (_from && _to) {
                const timeFrom = createTimeFromTextValue(_from);
                const timeTo = createTimeFromTextValue(_to);
                const myBreak = _break ?? 0;
                netHours = toNumber(
                  ((timeTo.valueOf() - timeFrom.valueOf()) / 1000 / 60 -
                    myBreak) /
                    60
                );
                setFieldValue("netHours", netHours);
              }
            } else if (
              generalData?.timeAccounting ===
              TimeAccounting.RequiredWithoutTimeBreak
            ) {
              netHours = netHours ?? 0;
              setFieldValue("netHours", netHours);
            }

            if (generalData?.timeAccounting !== TimeAccounting.Off) {
              if (
                activity &&
                generalData?.activitiesEachTime.includes(activity)
              ) {
                quantity = 1;
              } else {
                quantity = netHours ?? 0;
              }
              setFieldValue("quantity", quantity);
            }
          };
          
          const getBreakButton = (value: number) => {
            return (
              <Button
                //size="small"
                style={{ width: 50 }}
                onClick={() => {
                  setFieldValue("break", value);
                  handleBreak(value);
                }}
              >
                {value}
              </Button>
            );
          };

          if (
            progmaUserRights?.projectsUserRights === UserRights.Editing &&
            projectForm.open
          ) {
            return (
              <ProjectForm
                open={projectForm.open}
                id={projectForm.id}
                onCreated={(project) =>
                  addProject(project).then(
                    (newId) =>
                      newId && setFieldValue("projectsAndSubjectsID", newId)
                  )
                }
                onEdited={() => {}}
                onClose={closeProjectForm}
              />
            );
          }

          return (
            <FDialog
              title={id && id > 0 ? "Regel aanpassen" : "Nieuwe regel"}
              open={open}
              onClose={onClose}
              breakPointFullScreen='sm'
            >
              <form noValidate autoComplete='off'>
                <Grid container spacing={3}>
                  {layoutOption && layoutOption === "dateOnly" && (
                    <Grid item xs={12}>
                      <FDatePicker
                        name='_Date'
                        label='Datum'
                        variant='static'
                        required
                      />
                    </Grid>
                  )}

                  {layoutOption &&
                    layoutOption === "staffMemberOnly" &&
                    progmaUserRights?.workIncludeOthers && (
                      <Grid item xs={12}>
                        <FSelect
                          name='staffMemberID'
                          label='Medewerker'
                          required
                          items={generalData?.staffMembers.map(
                            (staffMember) => ({
                              id: staffMember.id,
                              text: staffMember.nameShort,
                            })
                          )}
                        />
                      </Grid>
                    )}

                  {!layoutOption && (
                    <>
                      <Grid item xs={12}>
                        <FSelect
                          name='activityID'
                          label='Activiteit'
                          required
                          items={
                            myActivities.map((activity) => ({
                              id: activity.id,
                              text:
                                activity.name +
                                " (" +
                                activity.activityCode +
                                ")",
                            }))
                          }
                          useSearchField
                          onValueChanged={(value) =>
                            typeof value === "number" && handleActivity(value)
                          }
                        />
                      </Grid>

                      {generalData?.timeAccounting ===
                        TimeAccounting.RequiredWithTimeBreak && (
                        <>
                          <Grid item xs={12}>
                            <Grid container alignItems='center' spacing={3}>
                              <Grid item xs={5}>
                                {!timePickerFromOpen ? (
                                  <FSelect
                                    name='_From'
                                    label='Van'
                                    required
                                    items={getTimes(
                                      undefined,
                                      values._From
                                    ).map((x) => ({
                                      id: x,
                                      text: x,
                                    }))}
                                    onValueChanged={(value) => {
                                      handleFrom(
                                        typeof value === "string"
                                          ? value
                                          : undefined
                                      );
                                    }}
                                  />
                                ) : (
                                  <FTimePicker
                                    name='_From'
                                    label='Van'
                                    required
                                    open={timePickerFromOpen}
                                    onClose={() => setTimePickerFromOpen(false)}
                                    valueAsString={true}
                                    onValueChanged={(value) => {
                                      handleFrom(
                                        typeof value === "string"
                                          ? value
                                          : undefined
                                      );
                                    }}
                                  />
                                )}
                              </Grid>

                              <Grid item xs={7}>
                                <Button
                                  variant='outlined'
                                  size='large'
                                  onClick={() => setTimePickerFromOpen(true)}
                                >
                                  <ScheduleIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container alignItems='center' spacing={3}>
                              <Grid item xs={5}>
                                {!timePickerToOpen ? (
                                  <FSelect
                                    name='_To'
                                    label='Tot'
                                    required
                                    items={getTimes(
                                      values._From,
                                      values._To
                                    ).map((x) => ({
                                      id: x,
                                      text: x,
                                    }))}
                                    onValueChanged={(value) => {
                                      handleTo(
                                        typeof value === "string"
                                          ? value
                                          : undefined
                                      );
                                    }}
                                  />
                                ) : (
                                  <FTimePicker
                                    name='_To'
                                    label='Tot'
                                    required
                                    open={timePickerToOpen}
                                    onClose={() => setTimePickerToOpen(false)}
                                    valueAsString={true}
                                    onValueChanged={(value) => {
                                      handleTo(
                                        typeof value === "string"
                                          ? value
                                          : undefined
                                      );
                                    }}
                                  />
                                )}
                              </Grid>

                              <Grid item xs={7}>
                                <Button
                                  variant='outlined'
                                  size='large'
                                  onClick={() => setTimePickerToOpen(true)}
                                >
                                  <ScheduleIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container alignItems='center' spacing={3}>
                              <Grid item xs={5}>
                                <FSelect
                                  name='break'
                                  label='Pauze'
                                  required
                                  items={breaks.map((x) => ({
                                    id: x,
                                    text: x.toString(),
                                  }))}
                                  onValueChanged={(value) => {
                                    typeof value === "number" &&
                                      handleBreak(value);
                                  }}
                                />
                              </Grid>

                              <Grid item xs={7}>
                                <Grid item xs={12}>
                                  <ButtonGroup>
                                    {getBreakButton(0)}
                                    {getBreakButton(15)}
                                    {getBreakButton(30)}
                                  </ButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                  <ButtonGroup style={{marginTop: theme.spacing(1)}}>
                                    {getBreakButton(45)}
                                    {getBreakButton(60)}
                                    {getBreakButton(90)}
                                  </ButtonGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {(generalData?.timeAccounting ===
                        TimeAccounting.RequiredWithTimeBreak ||
                        generalData?.timeAccounting ===
                          TimeAccounting.RequiredWithoutTimeBreak) && (
                        <Grid item xs={5}>
                          <FNumericTextField
                            name='netHours'
                            label='Netto Uren'
                            required
                            disabled={
                              generalData?.timeAccounting !==
                              TimeAccounting.RequiredWithoutTimeBreak
                            }
                            // InputLabelProps={{
                            //   shrink: true,
                            // }}
                            onValueChanged={(value) =>
                              value && handleNettoHours(value)
                            }
                          />
                        </Grid>
                      )}

                      <Grid item xs={7}>
                        <FNumericTextField
                          name='quantity'
                          label='Rekenaantal'
                          required
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FProjectsAndSubjectsSDTOSelect
                          name='projectsAndSubjectsID'
                          label='Project / onderwerp'
                          required={false}
                          items={
                            generalData &&
                            generalData.projectsAndSubjects.map((x) => ({
                              id: x.id,
                              text: getProjectAndSubjectDisplayLine(x),
                              sourceObject: x,
                            }))
                          }
                          allProjectsAndSubjects= {allProjectsAndSubjects}
                          onAllProjectsAndSubjectsChanged={setAllProjectsAndSubjects}
                          isLoadingProjectsAndSubjects={isLoadingProjectsAndSubjects}
                          useSearchField
                          // useClearButton
                          onAddButtonClick={
                            progmaUserRights?.projectsUserRights ===
                            UserRights.Editing
                              ? () => openProjectForm()
                              : undefined
                          }
                        ></FProjectsAndSubjectsSDTOSelect>
                      </Grid>

                      <Grid item xs={12}>
                        <FTextField
                          name='comments'
                          label='Opmerking'
                          required={false}
                          autoComplete='off'
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </FDialog>
          );
        }}
      </Formik>
    </>
  );
});
