import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { Project, ProjectAndSubject, Status } from "../models/projectAndSubject";
import { ProjectFree } from "../models/projectFree";

const initialValues: {
  isLoading: boolean;
  isLoadingProjectFree: boolean;
  projectsAndSubjects?: ProjectAndSubject[];
  projectAndSubjectId?: number;
  projectFreeList: ProjectFree[];
  expandedRowIds: (number | string)[];
  isLoadingProjectStatus: boolean;
  allProjectsAndSubjects: boolean;
  isLoadingProjectsAndSubjects: boolean;

} = {
  isLoading: false,
  isLoadingProjectFree: false,
  projectFreeList: [],
  expandedRowIds: [],
  isLoadingProjectStatus: false,
  allProjectsAndSubjects: false,
  isLoadingProjectsAndSubjects: false
};

export default class ProjectFreeStore {
  isLoading = initialValues.isLoading;
  isLoadingProjectFree = initialValues.isLoadingProjectFree;
  projectsAndSubjects = initialValues.projectsAndSubjects;
  projectAndSubjectId = initialValues.projectAndSubjectId;
  projectFreeList = initialValues.projectFreeList;
  expandedRowIds = initialValues.expandedRowIds;
  isLoadingProjectStatus = initialValues.isLoadingProjectStatus;
  allProjectsAndSubjects = initialValues.allProjectsAndSubjects;
  isLoadingProjectsAndSubjects = initialValues.isLoadingProjectsAndSubjects;
  
  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.isLoadingProjectFree = initialValues.isLoadingProjectFree;
    this.projectsAndSubjects = initialValues.projectsAndSubjects;
    this.projectAndSubjectId = initialValues.projectAndSubjectId;
    this.projectFreeList = initialValues.projectFreeList;
    this.expandedRowIds = initialValues.expandedRowIds;
    this.isLoadingProjectStatus = initialValues.isLoadingProjectStatus
    this.allProjectsAndSubjects = initialValues.allProjectsAndSubjects;
    this.isLoadingProjectsAndSubjects = initialValues.isLoadingProjectsAndSubjects;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  private setIsLoadingProjectFree = (state: boolean) => {
    runInAction(() => (this.isLoadingProjectFree = state));
  };

  private setIsLoadingProjectStatus = (state: boolean) => {
    runInAction(() => (this.isLoadingProjectStatus = state));
  };

  loadProjectsAndSubjects = async () => {
    let temp: ProjectAndSubject[] | undefined = undefined; //default
    try {
      this.setIsLoading(true);
      temp = await vAgent.ProjectFree.getProjectsAndSubjects(this.allProjectsAndSubjects);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
    runInAction(() => (this.projectsAndSubjects = temp ? temp : []));
  };
  
  setAllProjectsAndSubjects = async(value: boolean) => {
    try {
      runInAction(() => {
        this.isLoadingProjectsAndSubjects = true
      })
      const list = await vAgent.Work.getProjectsAndSubjects(value);
      runInAction(() => {
        this.projectsAndSubjects = list ? list : []
        this.allProjectsAndSubjects = value
      })
    } catch (error) {
      console.log(error);
    } finally
    {
      runInAction(() => {
        this.isLoadingProjectsAndSubjects = false
      })
    }
  }

  loadProjectsFree = async (projectAndSubjectId: number) => {
    try {
      this.setIsLoadingProjectFree(true);
      const temp = await vAgent.ProjectFree.loadProjectFree(
        projectAndSubjectId
      );
      runInAction(() => {
        this.projectFreeList = temp ? temp : [];
        this.projectAndSubjectId = projectAndSubjectId;
        this.expandedRowIds = [];
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoadingProjectFree(false);
    }
  };

  deleteProjectFree = async (id: number) => {
    try {
      this.setIsLoadingProjectFree(true);
      const result = await vAgent.ProjectFree.delete(id);
      if (result && result > 0) {
        runInAction(() => {
          this.projectFreeList = this.projectFreeList.filter(
            (x) => x.id !== id
          );
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.setIsLoadingProjectFree(false);
      });
    }
  };

  addProjectFree = (projectFree: ProjectFree) => {
    const temp = Array.from(this.projectFreeList);
    temp.push(projectFree);
    runInAction(() => (this.projectFreeList = temp));
  };

  editProjectFree = (projectFree: ProjectFree) => {
    const temp = Array.from(this.projectFreeList);
    temp[temp.findIndex((a) => a.id === projectFree.id)] = projectFree;
    runInAction(() => (this.projectFreeList = temp));
  };

  setExpandedRowIds = (ids: (string | number)[]) => {
    runInAction(() => (this.expandedRowIds = ids));
  };

    
  addProject = async (project: Project) => {
    try {
      if (project) {
        const temp = await vAgent.ProjectFree.getProjectAndSubjects(
          project.id
        );
        if (temp && temp.length > 0) {
          const newProjectsAndSubjects = this.projectsAndSubjects ? Array.from(this.projectsAndSubjects) : [];
            newProjectsAndSubjects.splice(0, 0, ...temp);

            runInAction(() => {
              this.projectsAndSubjects = newProjectsAndSubjects;
            });
          return newProjectsAndSubjects ? newProjectsAndSubjects[0].id : undefined;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  setProjectStatus = async (id: number, status: Status) => {
    try {
      this.setIsLoadingProjectStatus(true);
      const projectAndSubject = await vAgent.ProjectFree.setProjectStatus(id, status);
      if (projectAndSubject !== undefined) {
        const temp = Array.from(this.projectsAndSubjects!);
        temp[temp.findIndex((x) => x.id === projectAndSubject.id)] = projectAndSubject;
        runInAction(() => (this.projectsAndSubjects = temp));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoadingProjectStatus(false);
    }
  };
}
