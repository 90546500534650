import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Formik } from "formik";
import ValidationErrors from "../../Common/form/ValidationErrors";
import { useRootStore } from "../../stores/rootStore";
import * as yup from "yup";
import { useState } from "react";
import FTextfield from "../../Common/form/FTextField";
import FSubmitButton from "../../Common/form/FSubmitButton";
import { Texts } from "../../Common/Constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    flexDirection: "column",
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const {
    appUserStore: { appUser, changePassword },
  } = useRootStore();
  const [result, setResult] = useState(false);

  const validationSchema = yup.object({
    currentPassword: yup
      .string()
      .required("Wachtwoord is vereist")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Moet 8 karakters bevatten, Één hoofdletter, Één kleine letter, Één nummer en één speciaal karakter"
      ),
    newPassword: yup
      .string()
      .required("Wachtwoord is vereist")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Moet 8 karakters bevatten, Één hoofdletter, Één kleine letter, Één nummer en één speciaal karakter"
      ),
    newPasswordConfirmation: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Wachtwoorden moeten overeenkomen")
      .required("Wachtwoordbevestiging is vereist"),
  });

  if (result) return <Typography>Wachtwoord is gewijzigd!</Typography>;

  return (
    <Formik
      initialValues={{
        email: appUser?.username!,
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
        error: null,
      }}
      onSubmit={(values, { setErrors, setSubmitting }) =>
        changePassword(values)
          .then((result) => result && setResult(result))
          .catch((error) => {
            setErrors({ error: error }); setSubmitting(false);
          })
      }
      validationSchema={validationSchema}
    >
      {({ errors }) => (
        <div className={classes.paper}>
          <form noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FTextfield
                  name='currentPassword'
                  label='Huidig wachtwoord'
                  type='password'
                  required
                  autoComplete='current-password'
                />
              </Grid>

              <Grid item xs={12}>
                <FTextfield
                  name='newPassword'
                  label='Nieuw wachtwoord'
                  type='password'
                  required
                  autoComplete='new-password'
                />
              </Grid>

              <Grid item xs={12}>
                <FTextfield
                  name='newPasswordConfirmation'
                  label='Nieuw ww bevestigen'
                  type='password'
                  required
                  autoComplete='new-passwordConfirmation'
                />
              </Grid>

              {errors.error && (
                <Grid item xs={12}>
                  <ValidationErrors errors={errors.error} />
                </Grid>
              )}

              <Grid item xs={12}>
                <FSubmitButton size={"large"}>{Texts.editText}</FSubmitButton>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Formik>
  );
}
