import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../Common/LoadingComponent";
import { useEffect, useState } from "react";
import ArticleForm from "./ArticleForm";
import { UserRights } from "../../models/progmaUserRights";

import ToolStripAndTable from "../../../Common/ToolStripAndTable";
import { useVStore } from "../../stores/vStore";
import ArticleRowDetail from "./ArticleRowDetail";
import { AppColumn, ColumnType, TableStateValues } from "../../../Common/AppTable";

const columnNameArticleNumber = "articleNumber";
const columnNameType = "type";
const columnNameName = "name";
const columnNameInactive = "inactive";

const columns: AppColumn[] = [
  { name: columnNameArticleNumber, title: "Nummer", visible: "sm" },
  { name: columnNameType, title: "Type" , visible: "lg"},
  { name: columnNameName, title: "Naam", visible: "xs" },
  { name: columnNameInactive, title: "Inactief", visible: "xl", columnType: ColumnType.boolean },  
];

export default observer(function Articles() {
  const {
    progmaUserStore: { progmaUserRights },
    articlesStore: { articles, loadArticles, isLoading, addArticle, editArticle },
    articleFormStore: { articleForm, openArticleForm, closeArticleForm }
  } = useVStore();

  useEffect(() => {
    if (!articles) loadArticles();
  }, [articles, loadArticles]);

  const [tableStateValues, setTableStateValues] = useState(
    new TableStateValues(
      columns,
      [{ columnName: columnNameArticleNumber, direction: "desc" }],
    )
  );

  if (isLoading)
    return <LoadingComponent useBackdrop={false} />;

  return (
    <>
      <ToolStripAndTable
        tableName='Articles'
        columns={columns}
        rows={articles ? articles : []}
        rowDetail={ArticleRowDetail}
        onNew={progmaUserRights?.articlesUserRights === UserRights.Editing ? () => openArticleForm(undefined) : undefined}
        onReload={loadArticles}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues}
        tools={undefined}
        limitMoreMenu={479}
      />

      {progmaUserRights?.articlesUserRights === UserRights.Editing && (
        <>
          {articleForm.open && (
            <ArticleForm
              open={articleForm.open}
              id={articleForm.id}
              onCreated={addArticle}
              onEdited={editArticle}
              onClose={closeArticleForm}
            />
          )}
        </>
      )}
    </>
  );
});
