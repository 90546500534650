import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik } from "formik";
import FTextfield from "../../Common/form/FTextField";
import FSubmitButton from "../../Common/form/FSubmitButton";
import ToolStripSpaceBetween from "../../Common/ToolStripSpaceBetween";
import ValidationErrors from "../../Common/form/ValidationErrors";
import { useRootStore } from "../../stores/rootStore";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Email moet een geldig e-mailadres zijn")
    .required("Email is vereist"),
  password: yup
    .string()
    .required("Wachtwoord is vereist")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,//    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Moet 8 karakters bevatten, Één hoofdletter, Één kleine letter, Één nummer en één speciaal karakter"
    ),
});

export default function LogIn() {
  const { appUserStore } = useRootStore();
  const theme = useTheme();

  return (
      <Formik
        initialValues={{ email: "", password: "", error: null }}
        onSubmit={(values, { setErrors, setSubmitting }) =>
          appUserStore.login(values).catch((error) => {
            setSubmitting(false);
            setErrors({ error: error });
          })
        }
        validationSchema={validationSchema}
      >
        {({ errors }) => (
          <form noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FTextfield
                  name='email'
                  label='Email'
                  required
                  autoFocus
                  autoComplete='email'
                />
              </Grid>

              <Grid item xs={12}>
                <FTextfield
                  name='password'
                  label='Wachtwoord'
                  type='password'
                  required
                  autoComplete='current-password'
                />
              </Grid>

              {errors.error && (
                <Grid item xs={12}>
                  <ValidationErrors errors={errors.error} />
                </Grid>
              )}

              <Grid item xs={12}>
                <FSubmitButton size={"large"}>
                  Inloggen
                  {/* <Typography>Inloggen</Typography> */}
                </FSubmitButton>
              </Grid>
            </Grid>

            <div style={{ marginTop: theme.spacing(1) }}>
              <ToolStripSpaceBetween
                primaryToolStrip={
                  <Link href='forgot-password' variant='body2'>
                    Wachtwoord vergeten?
                  </Link>
                }
                secondaryToolStrip={
                  <Link href='register' variant='body2'>
                    Registreren
                  </Link>
                }
              />
            </div>
          </form>
        )}
      </Formik>
  );
}


