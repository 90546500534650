export interface Relation {
    id: number;
    relationNumber: string;
    companyName: string;
    adresVisit: string;
    zipCodeVisit: string;
    cityVisit: string;
}

export interface RelationFormValues {
    id: number;
    typeRelation: string;
    relationNumber: string;
    companyName: string;
    cityVisit: string;
}


export enum TypeRelation {
    Debtor,
    Creditor,
    Supplier,
    Educator,
    Insurer,
    Other,
    Manufacturer,
    Dealer
  }