import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik } from "formik";
import FTextfield from "../../Common/form/FTextField";
import FSubmitButton from "../../Common/form/FSubmitButton";
import ValidationErrors from "../../Common/form/ValidationErrors";
import { useRootStore } from "../../stores/rootStore";

export default function Register() {
  const {
    appUserStore: { register },
  } = useRootStore();
  const theme = useTheme();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Email moet een geldig e-mailadres zijn")
      .required("Email is vereist"),
    password: yup
      .string()
      .required("Wachtwoord is vereist")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Moet 8 karakters bevatten, Één hoofdletter, Één kleine letter, Één nummer en één speciaal karakter"
      ),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Wachtwoorden moeten overeenkomen")
      .required("Wachtwoordbevestiging is vereist"),
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        passwordConfirmation: "",
        error: null,
      }}
      onSubmit={(values, { setErrors, setSubmitting }) =>
        register(values).catch((error) => {setErrors({ error: error }); setSubmitting(false);})
      }
      validationSchema={validationSchema}
    >
      {({
        errors,
      }) => (
        <form noValidate>
          <Grid container spacing={3}>

            <Grid item xs={12}>           
              <FTextfield
                name='email'
                label='Email'
                required
                autoFocus
                autoComplete='email'
              />
            </Grid>

            <Grid item xs={12}>
              <FTextfield
                name='password'
                label='Wachtwoord'
                required
                autoComplete='current-password'
                type='password'
              />
            </Grid>

            <Grid item xs={12}>
              <FTextfield
                name='passwordConfirmation'
                label='Wachtwoord bevestigen'
                required
                autoComplete='current-passwordConfirmation'
                type='password'
              />
            </Grid>

            {errors.error && (
              <Grid item xs={12}>
                <ValidationErrors errors={errors.error} />
              </Grid>
            )}

            <Grid item xs={12}>
              <FSubmitButton size={"large"}>Registreren</FSubmitButton>
            </Grid>
          </Grid>

          <div style={{ marginTop: theme.spacing(1) }}>
            <Link href='/login' variant='body2'>
              Heeft u al een account? Inloggen
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
}
