import { Grid } from "@material-ui/core";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";
import { TimeAccounting } from "../../models/work";
import EventIcon from "@material-ui/icons/Event";
import PeopleIcon from "@material-ui/icons/People";
import { useVStore } from "../../stores/vStore";
import DetailCard, { DetailCardItem } from "../../../Common/DetailCard";
import {
  RowDetailButton,
  RowDetailButtonDelete,
  RowDetailButtonEdit,
} from "../../../Common/RowDetailButtons";
import SmartGridItem from "../../../Common/SmartGridItem";
import CCheckbox from "../../../Common/controls/CCheckBox";
import { Status } from "../../models/projectAndSubject";
import LoadingComponent from "../../../Common/LoadingComponent";
import { observer } from "mobx-react-lite";
import TabComponent, { getTabContent } from "../../../Common/TabComponent";
import ProjectArticlesTable from "../ProjectArticles/ProjectArticlesTable";
import { useEffect } from "react";
import ProjectFreeTable from "../ProjectFree/ProjectFreeTable";
import ProjectPurchaseTable from "../ProjectPurchase/ProjectPurchaseTable";
import { UserRights } from "../../models/progmaUserRights";
import { CellRowPanel } from "../../../Common/TableComponents";

export default observer(function WorkRowDetail({ row }: any) {
  const workId: number = row.id;
  const {
    progmaUserStore: { progmaUserRights },
    workStore: {
      workWeekData,
      generalData,
      deleteWork,
      setProjectStatus,
      isLoadingProjectStatus,
    },
    workFormStore: { openWorkForm },
    projectArticlesStore,
    //projectArticlesStore: { loadProjectsArticles, resetStore },
    projectArticlesFormStore: { openProjectArticlesForm },
    projectPurchaseStore,
    projectPurchaseFormStore: { openProjectPurchaseForm },
    projectFreeStore,
    //projectFreeStore: { loadProjectsFree, resetStore },
    projectFreeFormStore: { openProjectFreeForm },
  } = useVStore();

  const work = workWeekData!.workItemsWeek!.find((x) => x.id === workId)!;

  useEffect(() => {
    if (work.projectAndSubjectID) {
      projectArticlesStore.loadProjectsArticles(work.projectAndSubjectID);
      projectPurchaseStore.loadProjectsPurchase(work.projectAndSubjectID);
      projectFreeStore.loadProjectsFree(work.projectAndSubjectID);
    } else {
      projectArticlesStore.resetStore();
      projectPurchaseStore.resetStore();
      projectFreeStore.resetStore();
    }
  }, [work.projectAndSubjectID, projectArticlesStore, projectPurchaseStore, projectFreeStore]);

  const timeAccounting = generalData && generalData.timeAccounting;

  let detailCardItems: DetailCardItem[] = [
    { title: "Klant", value: work.companyName },
    { title: "Project", value: work.projectName },
    { title: "Activiteit", value: work.activity },
    { title: "Opmerking", value: work.comments },
  ];

  if (timeAccounting === TimeAccounting.RequiredWithTimeBreak) {
    detailCardItems.push({ title: "Van", value: work.timeFrom });
    detailCardItems.push({ title: "Tot", value: work.timeTo });
    detailCardItems.push({ title: "Pauze", value: work.break });
  }

  if (
    timeAccounting === TimeAccounting.RequiredWithTimeBreak ||
    timeAccounting === TimeAccounting.RequiredWithoutTimeBreak
  ) {
    detailCardItems.push({
      title: "Netto uren",
      value: work.netHours,
      type: "number",
    });
  }

  detailCardItems.push({
    title: "Rekenaantal",
    value: work.quantity,
    type: "number",
  });

  const tabContentGeneral = (
    <CellRowPanel>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <SmartGridItem>
          <DetailCard detailCardItems={detailCardItems} />
        </SmartGridItem>

        <SmartGridItem>
          <ToolStrip
            flexDirection="column"
            alignItems="start"
            spacing={0.5}
            fullwidth
          >
            <RowDetailButtonEdit onClick={() => openWorkForm(work.id)} />
            <RowDetailButton
              text="Verplaatsen"
              icon={<EventIcon />}
              onClick={() => openWorkForm(work.id, "dateOnly")}
            />
            {progmaUserRights?.workIncludeOthers && (
              <RowDetailButton
                text="Medewerker aanpassen"
                icon={<PeopleIcon />}
                onClick={() => openWorkForm(work.id, "staffMemberOnly")}
              />
            )}
            <RowDetailButtonDelete
              onDeleteConfirmed={() => deleteWork(workId)}
            />

            {isLoadingProjectStatus.id === work.id &&
            isLoadingProjectStatus.state === true ? (
              <LoadingComponent
                useBackdrop={false}
                content="Project status laden"
              />
            ) : (
              work.projectAndSubjectID !== undefined &&
              work.projectAndSubjectID !== null &&
              (work.projectStatus === Status.Opdracht ||
                work.projectStatus === Status.Opdrachtbevestiging_gestuurd ||
                work.projectStatus === Status.Gereed) && (
                <Grid container>
                  <Grid item>
                    <CCheckbox
                      label="Status: Opdracht"
                      name="statusOrder"
                      required={false}
                      checked={
                        work.projectStatus === Status.Opdracht ||
                        work.projectStatus ===
                          Status.Opdrachtbevestiging_gestuurd
                      }
                      onChange={(checked) =>
                        checked && setProjectStatus(work.id, Status.Opdracht)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <CCheckbox
                      label="Status: Gereed"
                      name="statusOrder"
                      required={false}
                      checked={work.projectStatus === Status.Gereed}
                      onChange={(checked) =>
                        checked && setProjectStatus(work.id, Status.Gereed)
                      }
                    />
                  </Grid>
                </Grid>
              )
            )}
          </ToolStrip>
        </SmartGridItem>
      </Grid>
    </CellRowPanel>
  );

  const myTabs: { label: string; tabContent: JSX.Element }[] = [];
  myTabs.push({ label: "Algemeen", tabContent: tabContentGeneral });
  
  if (work.projectAndSubjectID) {
    if (progmaUserRights?.projectArticlesAllow) {
      myTabs.push({
        label: "Artikelen",
        tabContent: getTabContent(() => {
          work.projectAndSubjectID &&
            openProjectArticlesForm(work.projectAndSubjectID);
        }, <ProjectArticlesTable />),
      });
    }

    if (progmaUserRights?.projectPurchaseUserRights !== UserRights.None) {
      myTabs.push({
        label: "Inkoop",
        tabContent: getTabContent(
          progmaUserRights?.projectPurchaseUserRights === UserRights.Editing &&
            work.projectAndSubjectID !== undefined
            ? () => {
                openProjectPurchaseForm(work.projectAndSubjectID!);
              }
            : undefined,
          <ProjectPurchaseTable />
        ),
      });
    }

    if (progmaUserRights?.projectFreeUserRights !== UserRights.None) {
      myTabs.push({
        label: "Vrije invoer",
        tabContent: getTabContent(
          progmaUserRights?.projectFreeUserRights === UserRights.Editing &&
            work.projectAndSubjectID !== undefined
            ? () => {
                openProjectFreeForm(work.projectAndSubjectID!);
              }
            : undefined,
          <ProjectFreeTable />
        ),
      });
    }
  }

  const content =
    myTabs.length > 1 ? <TabComponent tabPanels={myTabs} /> : tabContentGeneral;

  return content;
});
