import {
  Grid,
} from "@material-ui/core";
import * as yup from "yup";
import { Formik } from "formik";
import LoadingComponent from "../../../Common/LoadingComponent";
import FDialog from "../../../Common/form/FDialog";
import { observer } from "mobx-react-lite";
import FTextfield from "../../../Common/form/FTextField";
import { useVStore } from "../../stores/vStore";
import { useEffect } from "react";
import { Attachment, AttachmentFormValues } from "../../models/attachments";

interface Props {
  open: boolean;
  id: number | undefined;
  onEdited: (attachment: Attachment) => void;
  onClose: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required("verplicht veld"),
});

export default observer(function AttachmentForm({
  open,
  id,
  onEdited,
  onClose,
}: Props) {
  const {
    attachmentFormStore: { load, isLoading, attachmentFormValues, edit },
  } = useVStore();

  useEffect(() => {
    if (id) load(id);
  }, [id, load]);

  function handleFormSubmit(attachmentFormValues: AttachmentFormValues) {
    if (!id) {

    } else {
      edit(attachmentFormValues)
        .then((attachment) => attachment && onEdited(attachment))
        .finally(() => onClose());
    }
  }

  if (isLoading)
    return (
      <LoadingComponent
        content='Bijlage formulier laden...'
        useBackdrop={true}
      />
    );

  return (
    <Formik
      enableReinitialize
      initialValues={attachmentFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <FDialog
            title={id && id > 0 ? "Bijlage aanpassen" : "Nieuwe Bijlage"}
            open={open}
            onClose={onClose}
            breakPointFullScreen='md'
          >
            <form noValidate autoComplete='off'>
              <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                  <FTextfield
                    name='filePath'
                    label='Pad'
                    required={false}
                    autoComplete='off'
                  />
                </Grid> */}

              <Grid item xs={12}>
                  <FTextfield
                    name='name'
                    label='Naam'
                    required
                    autoComplete='off'
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <FCheckbox
                    name='general'
                    label='Algemeen'
                    required
                  />
                </Grid> */}
              </Grid>
            </form>
          </FDialog>
        );
      }}
    </Formik>
  );
});
