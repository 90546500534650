import { Grid } from "@material-ui/core";
import * as yup from "yup";
import { Formik } from "formik";
import LoadingComponent from "../../../Common/LoadingComponent";
import FDialog from "../../../Common/form/FDialog";
import SelectWithSearch from "../../../Common/form/FSelect";
import { observer } from "mobx-react-lite";
import FTextfield from "../../../Common/form/FTextField";
import { useVStore } from "../../stores/vStore";
import {
  ProjectArticle, ProjectArticleFormValues,
} from "../../models/projectArticles";
import { useEffect } from "react";
import { UserRights } from "../../models/progmaUserRights";
import ArticleForm from "../Articles/ArticleForm";
import FNumericTextfield from "../../../Common/form/FNumericTextField";

interface Props {
  open: boolean;
  id?: number;
  // projectsAndSubjectsId: number;
  onCreated: (projectArticle: ProjectArticle) => void;
  onEdited: (projectArticle: ProjectArticle) => void;
  onClose: () => void;
}

const validationSchema = (
  yup.object({
  articleID: yup.number().required("verplicht veld"),
  quantity: yup.number().required("verplicht veld").notOneOf([0], "Kan niet 0 zijn"),
  comments: yup.string().nullable(),
})
)

export default observer(function ProjectArticleForm({
  open,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {
    projectArticlesFormStore: { isLoading, loadNeeded, load, projectArticleFormValues, articles, inactiveArticle, create, edit, addArticle },
    progmaUserStore: {progmaUserRights},
    articleFormStore: { articleForm, openArticleForm, closeArticleForm },
  } = useVStore();

  useEffect(() => {
    if (loadNeeded || id) load(id);
  }, [loadNeeded, load, id]);


  if (isLoading)
    return (
      // <h4>FF wachten</h4>
      <LoadingComponent
        content='Project artikel formulier laden...'
        useBackdrop={true}
      />
    );

  const myArticles = articles ? Array.from(articles) : [];
  if (inactiveArticle) myArticles.unshift(inactiveArticle);

  function handleFormSubmit(projectArticleFormValues: ProjectArticleFormValues) {
    if (!id) {
        create(projectArticleFormValues)
        .then((projectArticle) => projectArticle && onCreated(projectArticle))
        .finally(() => onClose());
    } else {
        edit(projectArticleFormValues)
        .then((projectArticle) => projectArticle && onEdited(projectArticle))
        .finally(() => onClose());
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={projectArticleFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue }) => {


          if(progmaUserRights?.articlesUserRights === UserRights.Editing && articleForm.open) {
            return (
              <ArticleForm
                open={articleForm.open}
                id = {articleForm.id}
                onCreated={(article) => 
                  addArticle(article).then(
                    (newId) => newId && setFieldValue("articleID", newId)
                  )}
                onEdited={() => {}}
                onClose={closeArticleForm}
              />
            )
          }

        return (
          <FDialog
            title={id && id > 0 ? "Project artikel aanpassen" : "Nieuw Project artikel"}
            open={open}
            onClose={onClose}
            breakPointFullScreen='md'
          >
            <form noValidate autoComplete='off'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SelectWithSearch
                    name='articleID'
                    label='Artikel'
                    required={true}
                    items={myArticles.map((x) => ({
                      id: x.id,
                      text: x.name + " (" + x.articleNumber + ")",
                    }))}
                    useSearchField
                    onAddButtonClick={progmaUserRights?.articlesUserRights === UserRights.Editing
                    ? () => openArticleForm()
                    : undefined}
                    autoFocus={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FNumericTextfield
                    name='quantity'
                    label='Aantal'
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='comments'
                    label='Opmerking'
                    required={false}
                    autoComplete='off'
                    multiline
                    minRows={3}
                  />
                </Grid>
              </Grid>
            </form>
          </FDialog>
        );
      }}
    </Formik>
  );
});
