import { observer } from "mobx-react-lite";
import { useState } from "react";
import AppTable, {
  AppColumn,
  ColumnType,
  TableStateValues,
} from "../../../Common/AppTable";
import LoadingComponent from "../../../Common/LoadingComponent";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import ProjectArticleRowDetail from "./ProjectArticleRowDetail";

export default observer(function ProjectArticlesTable() {
  const {
    projectArticlesStore: {
      isLoading,
      isLoadingProjectArticles,
      projectArticles,
    },
  } = useVStore();

  const columns: AppColumn[] = [
    { name: "article", title: "Artikel", visible: "xs", wordWrapEnabled: true },
    {
      name: "quantity",
      title: "Aantal",
      visible: "sm",
      columnType: ColumnType.number,
      width: 65,
    },
    {
      name: "comments",
      title: "Opmerking",
      visible: "lg",
      wordWrapEnabled: true,
    },
  ];

  const [tableStateValues, setTableStateValues] = useState(
    () =>
      new TableStateValues(
        columns,
        [{ columnName: "article", direction: "desc" }],
        false
      )
  );

  const rows = projectArticles.map((x) => ({
    id: x.id,
    article: x.article + " " + x.articleNumber,
    quantity: x.quantity,
    comments: x.comments,
  }));

  const pageContent = isLoadingProjectArticles ? (
    <LoadingComponent useBackdrop={false} />
  ) : (
    <div style={{ marginTop: theme.spacing(1), minWidth: 288 }}>
      <AppTable
        tableName='Project artikelen'
        columns={columns}
        rows={rows}
        rowDetail={ProjectArticleRowDetail}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues}
      />
    </div>
  );

  if (isLoading) return <LoadingComponent useBackdrop={false} />;

  return pageContent;
});
