import { Grid } from "@material-ui/core";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import DetailCard, { DetailCardItem } from "../../../Common/DetailCard";
import {
  RowDetailButtonDelete,
  RowDetailButtonEdit,
} from "../../../Common/RowDetailButtons";
import SmartGridItem from "../../../Common/SmartGridItem";
import { UserRights, UserRightsFinancial } from "../../models/progmaUserRights";
import { CellRowPanel } from "../../../Common/TableComponents";

export default function ProjectPurchaseRowDetail({ row }: any) {
  const projectPurchaseId: number = row.id;

  const {
    projectPurchaseStore: {
      projectPurchaseList,
      deleteProjectPurchase,
      projectAndSubjectId,
    },
    projectPurchaseFormStore: { openProjectPurchaseForm },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  const projectPurchase = projectPurchaseList.find(
    (x) => x.id === projectPurchaseId
  )!;

  let detailCardItems: DetailCardItem[] = [
    { title: "Aantal", value: projectPurchase.quantity, type: "number" },
    { title: "Omschrijving", value: projectPurchase.description },
    { title: "Kostprijs", value: projectPurchase.costPrice, type: "currency" },
    { title: "Marge", value: projectPurchase.margin, type: "percent" },
    { title: "Winst", value: projectPurchase.profit, type: "currency" },
    {
      title: "Verkoopprijs",
      value: projectPurchase.sellingPrice,
      type: "currency",
    },
    {
      title: "Kostprijs Totaal",
      value: projectPurchase.costPriceTotal,
      type: "currency",
    },
    {
      title: "Winst Totaal",
      value: projectPurchase.profitTotal,
      type: "currency",
    },
    {
      title: "Verkoopprijs Totaal",
      value: projectPurchase.sellingPriceTotal,
      type: "currency",
    },
  ];

  if (
    progmaUserRights?.projectPurchaseUserRightsFinancial !==
    UserRightsFinancial.All
  ) {
    detailCardItems = detailCardItems.filter(
      (x) =>
        x.title !== "Kostprijs" &&
        x.title !== "Marge" &&
        x.title !== "Winst" &&
        x.title !== "Kostprijs Totaal" &&
        x.title !== "Winst Totaal"
    );
  }

  if (
    progmaUserRights?.projectPurchaseUserRightsFinancial ===
    UserRightsFinancial.None
  ) {
    detailCardItems = detailCardItems.filter(
      (x) => x.title !== "Verkoopprijs" && x.title !== "Verkoopprijs Totaal"
    );
  }

  return (
    <CellRowPanel>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <SmartGridItem>
          <DetailCard detailCardItems={detailCardItems} />
        </SmartGridItem>

        {progmaUserRights?.projectPurchaseUserRights === UserRights.Editing && (
          <SmartGridItem>
            <ToolStrip
              flexDirection="column"
              alignItems="start"
              spacing={0.5}
              fullwidth
            >
              {projectAndSubjectId && (
                <RowDetailButtonEdit
                  onClick={() =>
                    openProjectPurchaseForm(
                      projectAndSubjectId,
                      projectPurchaseId
                    )
                  }
                />
              )}
              <RowDetailButtonDelete
                onDeleteConfirmed={() =>
                  deleteProjectPurchase(projectPurchaseId)
                }
              />
            </ToolStrip>
          </SmartGridItem>
        )}
      </Grid>
    </CellRowPanel>
  );
}
