import {
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { ChangeEvent } from "react";

interface Props {
  name: string;
  label: string;
  required: boolean;
  size?: "small" | "medium";
  disabled?: boolean | undefined;
  onValueChanged?: ((value: boolean) => void) | undefined;
}

export default function FCheckbox(props: Props) {
  const [field, mata] = useField(props.name);
  const { setFieldValue } = useFormikContext();

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    checked: boolean
  ) => {
    setFieldValue(props.name, checked);
    if (props.onValueChanged) props.onValueChanged(checked);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={props.name}
            required={props.required}
            size={props.size}
            disabled={props.disabled}
            checked={field.value}
            onChange={handleOnChange}
          />
        }
        label={props.label}
      />
      {mata && mata.touched && mata.error && <FormHelperText>{mata.error}</FormHelperText>}
    </>
  );
}
