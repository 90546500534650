import { Button, Grid, Typography } from "@material-ui/core";
import { history } from "../../../";
import { useVStore } from "../../stores/vStore";
import theme from "../../../themes/theme";
import DashboardCardItem from "./DashboardCardItem";
import { TimeAccounting } from "../../models/work";
import ScheduleIcon from "@material-ui/icons/Schedule";
import "./../../../Common/Extensions";

const today = new Date();
const startDate = today.startOfWeek();
const endDate = today.endOfWeek();

export default function DashboardWork() {
  const {
    dashboardStore: { dashboard },
    workStore: { setIgnoreWeekViewReset },
  } = useVStore();

  let todayText;
  if (dashboard?.dashboardWork.today !== undefined) {
    if (dashboard.dashboardWork.timeAccounting !== TimeAccounting.Off) {
      todayText = dashboard.dashboardWork.today + " uur";
    } else {
      todayText = "Aantal " + dashboard.dashboardWork.today;
    }
  }

  let thisWeekText;
  if (dashboard?.dashboardWork.thisWeek !== undefined) {
    if (dashboard.dashboardWork.timeAccounting !== TimeAccounting.Off) {
      thisWeekText = dashboard.dashboardWork.thisWeek + " uur";
    } else {
      thisWeekText = "Aantal " + dashboard.dashboardWork.thisWeek;
    }
  }

  return (
    <DashboardCardItem
      title='Uren'
      actions={[
        <Button
          key={"workToday"}
          size='medium'
          color='primary'
          onClick={() => history.push("/work")}
        >
          Uren invullen
        </Button>,
        <Button
          key={"workWeek"}
          size='medium'
          color='primary'
          onClick={() => {
            setIgnoreWeekViewReset();
            history.push("/work");
          }}
        >
          Weekoverzicht
        </Button>,
      ]}
      icon={<ScheduleIcon style={{ width: 32, height: 32 }} />}
    >
      <Grid container direction='row' spacing={1}>
        <Grid item xs={12}>
          <Typography color='textSecondary'>
            {"Vandaag (" +
              new Date().formatNL("EEEE d MMM yyyy") +
              "):"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary'>
            {todayText}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              height: theme.spacing(2),
              width: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color='textSecondary'>
            {"Week " +
              new Date().formatNL("w") +
              " ("}
            {startDate.formatNL("d MMM") +
              " t/m " +
              endDate.formatNL("d MMM")}
            {"):"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h5' color='textPrimary'>
            {thisWeekText}
          </Typography>
        </Grid>
      </Grid>
    </DashboardCardItem>
  );
}
