import { Container, Typography, Button, Grid } from "@material-ui/core";
import React from "react";
import { history } from "../"

export default function NotFound() {
  return (
    <Container maxWidth="md">
          <Grid container direction="column" alignItems="center" >
      <Grid item>
        <Typography variant="h5" style={{ marginTop: '24px' }}>Niets gevonden</Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" style={{ marginTop: "16px" }} onClick={() => history.goBack()}>Ga terug...</Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" style={{ marginTop: "16px" }} onClick={() => history.push('/')}>Dashboard</Button>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" style={{ marginTop: "16px" }} onClick={() => history.push('/administrations')}>Administraties</Button>
      </Grid>
  </Grid>
    </Container>
  );
}