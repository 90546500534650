import { Checkbox, FormControlLabel } from "@material-ui/core";
import LoadingComponent from "../LoadingComponent";
import CSelect, {Item} from "./CSelect";

interface Props {
  name: string;
  label: string;
  required: boolean;
  items: Item[] | undefined;
  allProjectsAndSubjects: boolean;
  onAllProjectsAndSubjectsChanged: ((value: boolean) => void) | undefined;
  isLoadingProjectsAndSubjects: boolean;
  autoFocus?: boolean;
  useSearchField?: boolean | undefined;
  value: any;
  onChange: (value: unknown) => void;
  onAddButtonClick?: (() => void) | undefined;
}

export default function CProjectsAndSubjectsSDTOSelect(props: Props) {


  return (
    <CSelect
      name={props.name}
      label={props.label}
      required={props.required}
      items={props.items}
      autoFocus={props.autoFocus}
      useSearchField={props.useSearchField}
      value={props.value}
      onChange={props.onChange}
      onAddButtonClick={props.onAddButtonClick}
      extraControl={
        props.onAllProjectsAndSubjectsChanged !== undefined ?
        props.isLoadingProjectsAndSubjects
        ?
          <LoadingComponent useBackdrop={false}/>
        :
        <FormControlLabel
          control={
          <Checkbox
            name={"checkboxAllProjectsAndSubjects"}
            checked={props.allProjectsAndSubjects}
            onChange={(_event, checked) => props.onAllProjectsAndSubjectsChanged!(checked)}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          />
        }
        label='Alle projecten / onderwerpen'
      />
       :
       undefined
      }
    />
  );
}
