import { format, startOfWeek, endOfWeek } from "date-fns";
import nlBELocale from "date-fns/locale/nl-BE";

declare global {
  interface Number {
    toNumberString(decimals?: number): String;
    toPercentString(decimals?: number): String;
    toCurrencyString(): String;
  }
  interface Date {
    formatNL(formatstring: string): String;
    startOfWeek(): Date;
    endOfWeek(): Date;
    toQueryString(): String;
  }
}

// eslint-disable-next-line no-extend-native
Number.prototype.toNumberString = function (decimals: number = 2): string {
  return Intl.NumberFormat("nl-NL", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(Number(this));
};

// eslint-disable-next-line no-extend-native
Number.prototype.toPercentString = function (decimals: number = 2): string {
  return Intl.NumberFormat("nl-NL", {
    style: "percent",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(Number(this));
};

// eslint-disable-next-line no-extend-native
Number.prototype.toCurrencyString = function (): string {
  return Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  }).format(Number(this));
};

// eslint-disable-next-line no-extend-native
Date.prototype.formatNL = function (formatstring: string) {
  return format(this, formatstring, { locale: nlBELocale });
};

// eslint-disable-next-line no-extend-native
Date.prototype.startOfWeek = function () {
  return startOfWeek(this, { locale: nlBELocale });
};

// eslint-disable-next-line no-extend-native
Date.prototype.endOfWeek = function () {
  return endOfWeek(this, { locale: nlBELocale });
};

// eslint-disable-next-line no-extend-native
Date.prototype.toQueryString = function () {
  return format(this, "yyyy-MM-dd");
};
//export {};
