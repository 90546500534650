import { Box } from "@material-ui/core";


interface Props {
  primaryToolStrip: JSX.Element;
  secondaryToolStrip: JSX.Element;
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
}

export default function ToolStripSpaceBetween({
  primaryToolStrip,
  secondaryToolStrip,
  flexDirection
}: Props) {
  const flexDirectionToUse =
    flexDirection !== undefined ? flexDirection : "row";

  return (
    <Box
      display='flex'
      flexDirection={flexDirectionToUse}
      flexWrap='wrap'
      alignItems='center'
      justifyContent='space-between'
      style={{ width: "100%" }}
    >
      <Box>{primaryToolStrip}</Box>

      <Box>{secondaryToolStrip}</Box>
    </Box>
  );
}
