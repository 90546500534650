import {
  Column,
  RowDetailState,
  SelectionState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import { useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { TableConstants } from "../../../Common/Constants";
import LoadingComponent from "../../../Common/LoadingComponent";
import { CellRowDetail, CurrencyTypeProvider, HeaderTitle, NumberTypeProvider } from "../../../Common/TableComponents";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import ProjectFreeRowDetail from "./ProjectFreeRowDetail";

import { UserRightsFinancial } from "../../models/progmaUserRights";

export default observer(function ProjectFreeTable() {
  const {
    projectFreeStore: {
      isLoading,
      isLoadingProjectFree,
      projectFreeList,
      expandedRowIds,
      setExpandedRowIds,
    },
    progmaUserStore: { progmaUserRights },
  } = useVStore();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  let columns: Column[] = [
    { name: "quantity", title: "Aantal" },
    { name: "description", title: "Omschrijving" },
    { name: "sellingPrice", title: "Verkoopprijs" },
    { name: "sellingPriceTotal", title: "Totaal" },
  ];

  const numberColumns = ["quantity"];
  const currencyColumns = ["sellingPrice", "sellingPriceTotal"];

  if (matches) {
    columns = columns.filter(
      (x) => x.name !== "sellingPrice" && x.name !== "sellingPriceTotal"
    );
  }

  let tableColumnExtensions: any = [
    { columnName: "quantity", align: "right", width: 55 },
    { columnName: "description", align: "left", wordWrapEnabled: true },
    { columnName: "sellingPrice", align: "right", width: 100 },
    { columnName: "sellingPriceTotal", align: "right", width: 120 },
  ];

  if (
    progmaUserRights?.projectFreeUserRightsFinancial ===
    UserRightsFinancial.None
  ) {
    columns = columns.filter(
      (x) => x.name !== "sellingPrice" && x.name !== "sellingPriceTotal"
    );
    tableColumnExtensions = tableColumnExtensions.filter(
      (x: any) =>
        x.columnName !== "sellingPrice" && x.columnName !== "sellingPriceTotal"
    );
  }

  const pageContent = isLoadingProjectFree ? (
    <LoadingComponent useBackdrop={false} />
  ) : (
      <div style={{marginTop: theme.spacing(1), minWidth: 288 }}>
          <Grid
            columns={columns}
            rows={projectFreeList}
            getRowId={(row) => row.id}
          >
            <RowDetailState
              expandedRowIds={expandedRowIds}
              onExpandedRowIdsChange={setExpandedRowIds}
            />

            <SelectionState
              selection={expandedRowIds}
              onSelectionChange={setExpandedRowIds}
            />

            <NumberTypeProvider for={numberColumns} />
            <CurrencyTypeProvider for={currencyColumns} />

            <Table
              columnExtensions={tableColumnExtensions}
              messages={TableConstants.tableMessages}
            />

            <TableRowDetail
              contentComponent={ProjectFreeRowDetail}
              cellComponent={CellRowDetail}
            />

            <TableSelection
              selectByRowClick
              highlightRow={false}
              showSelectionColumn={false}
            />
            <TableHeaderRow titleComponent={HeaderTitle} />
          </Grid>
      </div>
    );

  if (isLoading) return <LoadingComponent useBackdrop={false} />;

  return (pageContent);
});
