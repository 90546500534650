import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Messages, Texts } from "../../Common/Constants";
import { Administration } from "../../models/administration";
import { useRootStore } from "../../stores/rootStore";
import theme from "../../themes/theme";

const useStyles = makeStyles({
  pos: {
    marginBottom: 5,
  },
});

interface Props {
  administration: Administration;
}

export default observer(function AdministrationCard({ administration }: Props) {
  const classes = useStyles();
  const {
    administrationsStore: { deleteAdministration },
    administrationFormStore: { openAdministrationForm },
  } = useRootStore();

  const handleDelete = () => {
    if (window.confirm(Messages.deleteConfirmationMessage)) {
      deleteAdministration(administration.id);
    }
  };

  return (
    <Card
      key={administration.id}
      style={{ minWidth: 288, padding: theme.spacing(1) }}
    >
      <CardContent>
        <Typography variant='h6' color='textPrimary' gutterBottom>
          {administration.name}
        </Typography>
        <Typography className={classes.pos} color='textSecondary'>
          Administratienummer: {administration.administrationNumber}
        </Typography>
        <Typography className={classes.pos} color='textSecondary'>
          Medewerker: {administration.userName}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          color='primary'
          variant='outlined'
          fullWidth
          onClick={() => openAdministrationForm(administration.id)}
        >
          {Texts.editText}
        </Button>
      </CardActions>
      <CardActions>
        <Button
          color='primary'
          variant='outlined'
          fullWidth
          onClick={handleDelete}
        >
          {Texts.deleteText}
        </Button>
      </CardActions>
    </Card>
  );
});
