import { TextFieldProps, TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';

interface Props {
  name: string;
  label: string;
  required: boolean;
  type?: string | undefined;
  autoComplete?: string | undefined;
  variant?: 'filled' | 'outlined' | 'standard';
  size?: 'small' | 'medium';
  autoFocus?: boolean;
  multiline?: boolean;
  minRows?: number | string;
  maxRows?: number | string;
  disabled?: boolean | undefined;
  onValueChanged?: ((value: string | undefined) => void) | undefined;
}

export default function FTextfield (props: Props) {
  const [field, mata] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  const { onValueChanged, ...textFieldProps} = props;

  const configTextfield: TextFieldProps = {
    ...field,
    ...textFieldProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const myValue = e.target.value !== "" ? e.target.value : undefined;
    setFieldValue(props.name, myValue);
    if (onValueChanged) onValueChanged(myValue);
  }

  return (
    <TextField fullWidth variant="outlined" size="medium"  {...configTextfield} value={field.value ?? ""} onChange={handleOnChange}  />
  );
};
