import { IconButton, Grid } from "@material-ui/core";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import saveAs from "file-saver";
import { PDFObject } from "react-pdfobject";

import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import FilePreviewToolbar from "./FilePreviewToolbar";

interface Props {
  url: string;
  fileName: string;
}

export default function PdfViewer({ url, fileName }: Props) {
  const [numPages, setNumPages] = useState<number | null>(null);
  //const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [widthDiv, setWidthDiv] = useState(100);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const onPageSucces = (page: any) => {
    if (page._pageIndex === 0) {
      const parentDiv = document.querySelector("#pdfdiv");
      if (parentDiv) {
        const pageScale = parentDiv.clientWidth / page.originalWidth;
        if (scale !== pageScale) {
          setScale(pageScale);
        }
      }
    }
  };

  const content = PDFObject.supportsPDFs() ? (
    <div style={{ maxWidth: 900 }}>
      <FilePreviewToolbar
        title={fileName}
        onDownloadClick={() => {
          saveAs(url, fileName);
        }}
      />
      {/* PDFJS_URL={url} forcePDFJS */}
      {/* fallbackLink={`<p>Deze browser ondersteunt geen inline pdf's. Download de pdf om deze te bekijken: <a href='${url}'>Download PDF</a></p>`}/> */}
      <PDFObject url={url} height="80vh" width="100%" />
    </div>
  ) : (
    <div style={{ maxWidth: 900 }}>
      <FilePreviewToolbar
        title={fileName}
        onDownloadClick={() => {
          saveAs(url, fileName);
        }}
        limitMoreMenu={460}
      >
        {/* <Box display="flex" flexDirection="row" alignItems="center">
          <ToolStrip limitMoreMenu={359} spacing={0}> */}
            <IconButton
              onClick={() =>
                !(widthDiv - 25 < 25) && setWidthDiv(widthDiv - 25)
              }
            >
              <ZoomOutIcon />
            </IconButton>

            <IconButton
              onClick={() =>
                !(widthDiv + 25 > 400) && setWidthDiv(widthDiv + 25)
              }
            >
              <ZoomInIcon />
            </IconButton>

            <IconButton
              onClick={() =>
                !(rotation - 90 < 0)
                  ? setRotation(rotation - 90)
                  : setRotation(rotation - 90 + 360)
              }
            >
              <RotateLeftIcon />
            </IconButton>
          {/* </ToolStrip>
        </Box> */}
      </FilePreviewToolbar>

      <Grid
        container={widthDiv < 100 ? true : undefined}
        justifyContent={widthDiv < 100 ? "center" : undefined}
      >
        <div
          id="pdfdiv"
          style={{
            backgroundColor: "darkgray",
            width: `${widthDiv}%`,
            //height: "auto",
            //display: "flex",
            //flexDirection: "row",
            //alignItems: "center",
          }}
        >
          <Document
            options={{ workerSrc: "pdf.worker.js" }}
            file={{ url: url }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scale}
                rotate={rotation}
                onLoadSuccess={onPageSucces}
              />
            ))}
          </Document>
        </div>
      </Grid>
    </div>
  );

  return content;
}
