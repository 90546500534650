import { makeAutoObservable, runInAction } from "mobx";
import rootAgent from "../api/rootAgent";
import { FormManager } from "../Common/Objects";
import { AdministrationFormValues } from "../models/administration";

const initialValues: {
  administrationFormValues: AdministrationFormValues;
  isLoading: boolean;
} = {
  administrationFormValues: {
    id: 0,
    name: "",
    administrationNumber: 0,
    userName: "",
    password: "",
  },
  isLoading: false,
};

export default class AdministrationFormStore {
  private formManager: FormManager;

  administrationFormValues = initialValues.administrationFormValues;
  isLoading = initialValues.isLoading;
  administrationForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.administrationForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.administrationFormValues = initialValues.administrationFormValues;
    this.isLoading = initialValues.isLoading;
    this.administrationForm = this.formManager.closeForm();
  }

  resetValues = () => {
    runInAction(() => {
      this.administrationFormValues = initialValues.administrationFormValues;
      this.isLoading = initialValues.isLoading;
      this.administrationForm = this.formManager.closeForm();
    });
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  loadAdministrationFormValues = async (id: number) => {
    try {
      this.setIsLoading(true);
      const temp = await rootAgent.Administrations.getFormValues(id);
      if (temp) {
        runInAction(() => (this.administrationFormValues = temp));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  create = async (administration: AdministrationFormValues) => {
    try {
      return await rootAgent.Administrations.create(administration);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  edit = async (administration: AdministrationFormValues) => {
    try {
      return await rootAgent.Administrations.edit(administration);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  openAdministrationForm = ( id?: number) => {
    runInAction(() => {
      this.administrationForm = this.formManager.openForm(id);
    });
  };

  closeAdministrationForm = () => {
    runInAction(() => {
      this.administrationForm = this.formManager.closeForm();
    });
  };
}
