import saveAs from "file-saver";
import FilePreviewToolbar from "./FilePreviewToolbar";

interface Props {
  url: string;
  fileName: string;
}

export default function ImgViewer({ url, fileName }: Props) {
  return (
    <div style={{ maxWidth: 900 }}>
      <FilePreviewToolbar
        title={fileName}
        onDownloadClick={() => {
          saveAs(url, fileName);
        }}
      />
      <img src={url} alt={fileName} width="100%" height="auto" />
    </div>
  );
}
