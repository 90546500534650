export interface SettingsProjectType {
    id: number;
    kind: Kind;
    name: string;
}

export enum Kind {
    Project,
    Garantie,
    Intern,
    Meerwerk
  }