import { TextFieldProps, TextField, InputAdornment } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { toCurrency } from "../Functions";

interface Props {
  name: string;
  label: string;
  required: boolean;
  variant?: "filled" | "outlined" | "standard";
  size?: "small" | "medium";
  autoFocus?: boolean;
  disabled?: boolean | undefined;
  onValueChanged?: ((value: number | undefined) => void) | undefined;
}

export default function FCurrencyTextfield(props: Props) {
  const [field, mata] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  const { onValueChanged, ...textFieldProps } = props;

  const configTextfield: TextFieldProps = {
    ...field,
    ...textFieldProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    //console.log(e.target.value);
    //console.log(typeof e.target.value);

    //if (typeof e.target.value === "string") console.log(e.target.value.replace(",", ".").replace("0.000", "0.00"))

    const valueToWorkWithString = e.target.value !== "" ? e.target.value.replace(",", ".") : undefined; //.replace(",", ".")
    var regexpPeroid = new RegExp(/^\d+(\.\d{0,2})?$/);
    var regexpComma = new RegExp(/^\d+(,\d{0,2})?$/);
    //var regexpPeroid = new RegExp(/^(\d+(?:.\d{1,2})?).*/gm);
    //var regexpComma = new RegExp(/^(\d+(?:,\d{1,2})?).*/gm);
    if (valueToWorkWithString === undefined || regexpPeroid.test(valueToWorkWithString) || regexpComma.test(valueToWorkWithString)) {
      const myValue = valueToWorkWithString ? toCurrency(valueToWorkWithString)?.value : undefined;

      // console.log(myValue);
      // console.log(typeof myValue);
      setFieldValue(props.name, myValue);
      if (onValueChanged) onValueChanged(myValue);
    }
  };

  let myValue = field.value !== undefined ? field.value : "";
  
  return (
    <TextField
      fullWidth
      type="number"
      inputMode="decimal"
      variant='outlined'
      size='medium'
      autoComplete='off'
      {...configTextfield}
      value={myValue}
      onChange={handleOnChange}
      InputProps={{
        // inputMode: "decimal",
        // type: "number",
        inputProps: {style: {textAlign: "right"}},
        startAdornment: <InputAdornment position='start'>€</InputAdornment>,
      }}
      // onKeyDown={(e) => {
      //   if (e.key === "." || e.key === ",") {
      //     e.key
      //   }
      // }}
      onInput = {(e: any) =>{
        //console.log(e.target.value);
        //console.log(typeof e.target.value);
        //if (e.target.value !== undefined && e.target.value !== null) e.target.value = e.target.value.toString().replace(".000", ".00").replace(",000", ",00");
        //if (e.target.value !== undefined && e.target.value !== null) e.target.value = e.target.value.toString().replace(".", ",");
         //e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
    }}
    />
  );
}
