import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../Common/LoadingComponent";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import ProjectPurchaseRowDetail from "./ProjectPurchaseRowDetail";
import { UserRightsFinancial } from "../../models/progmaUserRights";
import AppTable, { AppColumn, ColumnType, TableStateValues } from "../../../Common/AppTable";
import { useState } from "react";

export default observer(function ProjectPurchaseTable() {
  const {
    projectPurchaseStore: {
      isLoading,
      isLoadingProjectPurchase,
      projectPurchaseList,
    },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  let columns: AppColumn[] = [
    { name: "quantity", title: "Aantal", visible: "sm", width: 75, columnType: ColumnType.number},
    { name: "description", title: "Omschrijving", visible: "xs" , wordWrapEnabled: true},
    { name: "costPrice", title: "Kostprijs", visible: "xxl", width: 100, columnType: ColumnType.currency },
    { name: "margin", title: "Marge", visible: "md", width: 80, columnType: ColumnType.percent },
    { name: "profit", title: "Winst", visible: "xxl", width: 100, columnType: ColumnType.currency },
    { name: "sellingPrice", title: "Verkoopprijs", visible: "xxl", width: 100, columnType: ColumnType.currency },
    { name: "costPriceTotal", title: "Kostprijs totaal", visible: "xxl", width: 120, columnType: ColumnType.currency },
    { name: "profitTotal", title: "Winst totaal", visible: "lg", width: 120, columnType: ColumnType.currency },
    { name: "sellingPriceTotal", title: "Totaal", visible: "md", width: 120, columnType: ColumnType.currency },
  ];

  const [tableStateValues, setTableStateValues] = useState(
    () =>
      new TableStateValues(
        columns,
        [],
        false
      )
  );
  
  if (
    progmaUserRights?.projectPurchaseUserRightsFinancial !==
    UserRightsFinancial.All
  ) {
    columns = columns.filter(
      (x) => x.name !== "costPrice" && x.name !== "margin" && x.name !== "profit" && x.name !== "costPriceTotal" && x.name !== "profitTotal"
    );
  }

  if (
    progmaUserRights?.projectPurchaseUserRightsFinancial ===
    UserRightsFinancial.None
  ) {
    columns = columns.filter(
      (x) => x.name !== "sellingPrice" && x.name !== "sellingPriceTotal"
    );
  }

  const pageContent = isLoadingProjectPurchase ? (
    <LoadingComponent useBackdrop={false} />
  ) : (
      <div style={{ marginTop: theme.spacing(1), minWidth: 288 }}>
        <AppTable
        tableName='Vrije invoer'
        columns={columns}
        rows={projectPurchaseList}
        rowDetail={ProjectPurchaseRowDetail}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues}
        />
      </div>
  );

  if (isLoading) return <LoadingComponent useBackdrop={false} />;

  return (pageContent);
});
