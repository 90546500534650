import { TextFieldProps, TextField, InputAdornment } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { ChangeEvent } from "react";
import { toNumber } from "../Functions";

interface Props {
  name: string;
  label: string;
  required: boolean;
  variant?: "filled" | "outlined" | "standard";
  size?: "small" | "medium";
  autoFocus?: boolean;
  disabled?: boolean | undefined;
  onValueChanged?: ((value: number | undefined) => void) | undefined;
}

export default function FPercentTextfield(props: Props) {
  const [field, mata] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  const { onValueChanged, ...textFieldProps } = props;

  const configTextfield: TextFieldProps = {
    ...field,
    ...textFieldProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const valueToWorkWithString = e.target.value !== "" ? e.target.value.replace(",", ".") : undefined;
    const myValue = valueToWorkWithString ? toNumber(Number(valueToWorkWithString) / 100,4) : undefined;
    setFieldValue(props.name, myValue);
    if (onValueChanged) onValueChanged(myValue);
  };

  const myValue = field.value !== undefined ? toNumber(field.value * 100, 2) : "";
  
  return (
    <TextField
      fullWidth
      type="number"
      inputMode="decimal"
      variant='outlined'
      size='medium'
      autoComplete='off'
      {...configTextfield}
      value={myValue}
      onChange={handleOnChange}
      InputProps={{
        // inputMode: "decimal",
        // type: "number",
        inputProps: {style: {textAlign: "right"}},
        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
      }}
    />
  );
}
