import { Project, Status } from './../models/projectAndSubject';
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { ProjectAndSubject } from "../models/projectAndSubject";
import { ProjectArticle } from "../models/projectArticles";

const initialValues: {
  isLoading: boolean;
  isLoadingProjectArticles: boolean;
  projectsAndSubjects?: ProjectAndSubject[];
  projectAndSubjectId?: number;
  projectArticles: ProjectArticle[];
  expandedRowIds: (number | string)[];
  isLoadingProjectStatus: boolean;
  allProjectsAndSubjects: boolean;
  isLoadingProjectsAndSubjects: boolean;
} = {
  isLoading: false,
  isLoadingProjectArticles: false,
  projectArticles: [],
  expandedRowIds: [],
  isLoadingProjectStatus: false,
  allProjectsAndSubjects: false,
  isLoadingProjectsAndSubjects: false
};

export default class ProjectArticlesStore {
  isLoading = initialValues.isLoading;
  isLoadingProjectArticles = initialValues.isLoadingProjectArticles;
  projectsAndSubjects = initialValues.projectsAndSubjects;
  projectAndSubjectId = initialValues.projectAndSubjectId;
  projectArticles = initialValues.projectArticles;
  expandedRowIds = initialValues.expandedRowIds;
  isLoadingProjectStatus = initialValues.isLoadingProjectStatus;
  allProjectsAndSubjects = initialValues.allProjectsAndSubjects;
  isLoadingProjectsAndSubjects = initialValues.isLoadingProjectsAndSubjects;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.isLoadingProjectArticles = initialValues.isLoadingProjectArticles;
    this.projectsAndSubjects = initialValues.projectsAndSubjects;
    this.projectAndSubjectId = initialValues.projectAndSubjectId;
    this.projectArticles = initialValues.projectArticles;
    this.expandedRowIds = initialValues.expandedRowIds;
    this.isLoadingProjectStatus = initialValues.isLoadingProjectStatus
    this.allProjectsAndSubjects = initialValues.allProjectsAndSubjects
    this.isLoadingProjectsAndSubjects = initialValues.isLoadingProjectsAndSubjects
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  private setIsLoadingProjectArticles = (state: boolean) => {
    runInAction(() => (this.isLoadingProjectArticles = state));
  };

  private setIsLoadingProjectStatus = (state: boolean) => {
    runInAction(() => (this.isLoadingProjectStatus = state));
  };

  loadProjectsAndSubjects = async () => {
    let temp: ProjectAndSubject[] | undefined = undefined; //default
    try {
      this.setIsLoading(true);
      temp = await vAgent.ProjectArticles.getProjectsAndSubjects(this.allProjectsAndSubjects);
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
    runInAction(() => (this.projectsAndSubjects = temp ? temp : []));
  };

  setAllProjectsAndSubjects = async(value: boolean) => {
    try {
      runInAction(() => {
        this.isLoadingProjectsAndSubjects = true
      })
      const list = await vAgent.Work.getProjectsAndSubjects(value);
      runInAction(() => {
        this.projectsAndSubjects = list ? list : []
        this.allProjectsAndSubjects = value
      })
    } catch (error) {
      console.log(error);
    } finally
    {
      runInAction(() => {
        this.isLoadingProjectsAndSubjects = false
      })
    }
  }

  loadProjectsArticles = async (projectAndSubjectId: number) => {
    try {
      this.setIsLoadingProjectArticles(true);
      const temp = await vAgent.ProjectArticles.loadProjectArticles(
        projectAndSubjectId
      );
      runInAction(() => {
        this.projectArticles = temp ? temp : [];
        this.projectAndSubjectId = projectAndSubjectId;
        this.expandedRowIds = [];
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoadingProjectArticles(false);
    }
  };

  deleteProjectArticle = async (id: number) => {
    try {
      this.setIsLoadingProjectArticles(true);
      const result = await vAgent.ProjectArticles.delete(id);
      if (result && result > 0) {
        runInAction(() => {
          this.projectArticles = this.projectArticles.filter(
            (x) => x.id !== id
          );
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.setIsLoadingProjectArticles(false);
      });
    }
  };

  addProjectArticle = (projectArticle: ProjectArticle) => {
    const temp = Array.from(this.projectArticles);
    temp.push(projectArticle);
    runInAction(() => (this.projectArticles = temp));
  };

  editProjectArticle = (projectArticle: ProjectArticle) => {
    const temp = Array.from(this.projectArticles);
    temp[temp.findIndex((a) => a.id === projectArticle.id)] = projectArticle;
    runInAction(() => (this.projectArticles = temp));
  };

  setExpandedRowIds = (ids: (string | number)[]) => {
    runInAction(() => (this.expandedRowIds = ids));
  };
  
  addProject = async (project: Project) => {
    try {
      if (project) {
        const temp = await vAgent.ProjectArticles.getProjectAndSubjects(
          project.id
        );
        if (temp && temp.length > 0) {
          const newProjectsAndSubjects = this.projectsAndSubjects ? Array.from(this.projectsAndSubjects) : [];
            newProjectsAndSubjects.splice(0, 0, ...temp);

            runInAction(() => {
              this.projectsAndSubjects = newProjectsAndSubjects;
            });
          return newProjectsAndSubjects ? newProjectsAndSubjects[0].id : undefined;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  setProjectStatus = async (id: number, status: Status) => {
    try {
      this.setIsLoadingProjectStatus(true);
      const projectAndSubject = await vAgent.ProjectArticles.setProjectStatus(id, status);
      if (projectAndSubject !== undefined) {
        const temp = Array.from(this.projectsAndSubjects!);
        temp[temp.findIndex((x) => x.id === projectAndSubject.id)] = projectAndSubject;
        runInAction(() => (this.projectsAndSubjects = temp));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoadingProjectStatus(false);
    }
  };

}
