import { AttachmentFormValues } from "../models/attachments";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { FormManager } from "../../Common/Objects";
import { UserRightsFinancial } from "../models/progmaUserRights";

const initialValues: {
  isLoading: boolean;
  attachmentFormValues: AttachmentFormValues;
} = {
  isLoading: false,
  attachmentFormValues: {
    id: 0,
    filePath: "",
    name: "",
    nameEnglish: "",
    description: "",
    descriptionEnglish: "",
    onlyAdmin: false,
    userRightsFinancial: UserRightsFinancial.None,
    general: false,
    activityID: undefined,
    articleID: undefined,
    invoiceID: undefined,
    projectAndSubjectID: undefined,
    relationID: undefined,
    staffMemberID: undefined,
    objectID: undefined,
  },
};

export default class AttachmentFormStore {
  private formManager: FormManager;
  
  isLoading = initialValues.isLoading;
  attachmentFormValues = initialValues.attachmentFormValues;
  attachmentForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.attachmentForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.attachmentFormValues = initialValues.attachmentFormValues;
    this.attachmentForm = this.formManager.closeForm();
  };

  resetValues = () => {
    this.isLoading = initialValues.isLoading;
    this.attachmentFormValues = initialValues.attachmentFormValues;
    this.attachmentForm = this.formManager.closeForm();
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (id: number) => {
    try {
      this.setIsLoading(true);
      const temp = await vAgent.Attachments.loadFormValues(id!);
      if (temp) {
        runInAction(() => {
          this.attachmentFormValues = temp;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };


  edit = async (formValues: AttachmentFormValues) => {
    try {
      const editedItem = await vAgent.Attachments.edit(formValues);
      return editedItem;
    } catch (error) {
      console.log(error);
    }
  };

  openAttachmentForm = ( id?: number) => {
    runInAction(() => {
      this.attachmentForm = this.formManager.openForm(id);
    });
  };

  closeAttachmentForm = () => {
    runInAction(() => {
      this.attachmentForm = this.formManager.closeForm();
    });
  };

}
