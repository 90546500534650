import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../Common/LoadingComponent";
import { useEffect, useState } from "react";
import RelationForm from "./RelationForm";
import { UserRights } from "../../models/progmaUserRights";

import ToolStripAndTable from "../../../Common/ToolStripAndTable";
import { useVStore } from "../../stores/vStore";
import RelationRowDetail from "./RelationRowDetail";
import { AppColumn, TableStateValues } from "../../../Common/AppTable";

const columnNameRelationNumber = "relationNumber";
const columnNameCompanyName = "companyName";
const columnNameAdresVisit = "adresVisit";
const columnNameZipCodeVisit = "zipCodeVisit";
const columnNameCityVisit = "cityVisit";

const columns: AppColumn[] = [
  { name: columnNameRelationNumber, title: "Relatienummer", visible: "lg" },
  { name: columnNameCompanyName, title: "Bedrijfsnaam", visible: "xs" },
  { name: columnNameAdresVisit, title: "Adres (vestiging)", visible: "xl" },
  { name: columnNameZipCodeVisit, title: "Postcode (vestiging)", visible: "xl" },
  { name: columnNameCityVisit, title: "Plaats (vestiging)", visible: "sm" },
];

export default observer(function Relations() {
  const {
    progmaUserStore: { progmaUserRights },
    relationsStore: { relations, loadRelations, isLoading, addRelation, editRelation },
    relationFormStore: { relationForm, openRelationForm, closeRelationForm }
  } = useVStore();

  useEffect(() => {
    if (!relations) loadRelations();
  }, [relations, loadRelations]);

  const [tableStateValues, setTableStateValues] = useState(
    new TableStateValues(
      columns,
      [{ columnName: columnNameCompanyName, direction: "asc" }],
    )
  );

  if (isLoading)
    return <LoadingComponent useBackdrop={false} />;

  return (
    <>
      <ToolStripAndTable
        tableName='Relaties'
        columns={columns}
        rows={relations ? relations : []}
        rowDetail={RelationRowDetail}
        onNew={progmaUserRights?.relationsUserRights === UserRights.Editing ? () => openRelationForm(undefined) : undefined}
        onReload={loadRelations}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues}
        tools={undefined}
        limitMoreMenu={479}
      />

      {progmaUserRights?.relationsUserRights === UserRights.Editing && (
        <>
          {relationForm.open && (
            <RelationForm
              open={relationForm.open}
              id={relationForm.id}
              onCreated={addRelation}
              onEdited={editRelation}
              onClose={closeRelationForm}
            />
          )}
        </>
      )}
    </>
  );
});
