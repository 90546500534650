import { TextFieldProps, TextField } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { ChangeEvent } from "react";

interface Props {
  name: string;
  label: string;
  required: boolean;
  variant?: "filled" | "outlined" | "standard";
  size?: "small" | "medium";
  autoFocus?: boolean;
  disabled?: boolean | undefined;
  onValueChanged?: ((value: number | undefined) => void) | undefined;
}

export default function FNumericTextfield(props: Props) {
  const [field, mata] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  const { onValueChanged, ...textFieldProps } = props;

  const configTextfield: TextFieldProps = {
    ...field,
    ...textFieldProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const valueToWorkWithString = e.target.value !== "" ? e.target.value.replace(",", ".") : undefined;
    const myValue = valueToWorkWithString ? Number(valueToWorkWithString) : undefined;
    setFieldValue(props.name, myValue);
    if (onValueChanged) onValueChanged(myValue);
  };
  
  return (
    <TextField
      fullWidth
      type="number"
      inputMode="decimal"
      variant='outlined'
      size='medium'
      autoComplete='off'
      {...configTextfield}
      value={field.value ?? ""}
      onChange={handleOnChange}
      InputProps={{
        // inputMode: "decimal",
        // type: "number",
        inputProps: {style: {textAlign: "right"}},
        //endAdornment: <InputAdornment position='end'></InputAdornment>,
      }}
    />
  );
}
