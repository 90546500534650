import { RelationFormValues } from "../models/relation";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { FormManager } from "../../Common/Objects";

const initialValues: {
  isLoading: boolean;
  relationFormValues: RelationFormValues;
} = {
  isLoading: false,
  relationFormValues: {
    id: 0,
    typeRelation: "10000000",
    relationNumber: "",
    companyName: "",
    cityVisit: "",
  },
};

export default class RelationFormStore {
  private formManager: FormManager;
  
  isLoading = initialValues.isLoading;
  relationFormValues = initialValues.relationFormValues;
  relationForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.relationForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.relationFormValues = initialValues.relationFormValues;
    this.relationForm = this.formManager.closeForm();
  };

  resetValues = () => {
    this.isLoading = initialValues.isLoading;
    this.relationFormValues = initialValues.relationFormValues;
    this.relationForm = this.formManager.closeForm();
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (id: number) => {
    try {
      this.setIsLoading(true);
      const temp = await vAgent.Relations.loadFormValues(id!);
      if (temp) {
        runInAction(() => {
          this.relationFormValues = temp;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  create = async (relation: RelationFormValues) => {
    try {
      const newItem = await vAgent.Relations.create(relation);
      return newItem;
    } catch (error) {
      console.log(error);
    }
  };

  edit = async (relation: RelationFormValues) => {
    try {
      const editedItem = await vAgent.Relations.edit(relation);
      return editedItem;
    } catch (error) {
      console.log(error);
    }
  };

  openRelationForm = ( id?: number) => {
    runInAction(() => {
      this.relationForm = this.formManager.openForm(id);
    });
  };

  closeRelationForm = () => {
    runInAction(() => {
      this.relationForm = this.formManager.closeForm();
    });
  };

}
