import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import WorkForm from "./WorkForm";
import LoadingComponent from "../../../Common/LoadingComponent";
import WorkDay from "./WorkDay";
import WorkWeek from "./WorkWeek";
import { useVStore } from "../../stores/vStore";
import ProjectArticleForm from "../ProjectArticles/ProjectArticleForm";
import ProjectFreeForm from "../ProjectFree/ProjectFreeForm";
import ProjectPurchaseForm from "../ProjectPurchase/ProjectPurchaseForm";

export default observer(function Work() {
  const {
    workStore: {
      //loadNeeded,
      workWeekData,
      loadWeek,
      isLoading,
      weekView,
      addWorkItem,
      editWorkItem,
      currentDate,
      staffMemberId,
    },
    workFormStore: { workForm, closeWorkForm },
    projectArticlesStore: { addProjectArticle, editProjectArticle },
    projectArticlesFormStore: {
      projectArticleForm,
      closeProjectArticlesForm,
    },
    projectPurchaseStore: { addProjectPurchase, editProjectPurchase },
    projectPurchaseFormStore: {
      projectPurchaseForm,
      closeProjectPurchaseForm,
    },
    projectFreeStore: { addProjectFree, editProjectFree },
    projectFreeFormStore: {
      projectFreeForm,
      closeProjectFreeForm,
    },
  } = useVStore();

  useEffect(() => {
    if (!workWeekData) loadWeek();
  }, [workWeekData, loadWeek]);

  if (isLoading) return <LoadingComponent useBackdrop={false} />;

  return (
    <>
      {!weekView ? <WorkDay /> : <WorkWeek />}
      {workForm.open && (
        <WorkForm
          open={workForm.open}
          date={currentDate}
          staffMemberId={staffMemberId!}
          id={workForm.id}
          onCreated={addWorkItem}
          onEdited={editWorkItem}
          onClose={closeWorkForm}
        />
      )}

      {projectArticleForm.open && (
        <ProjectArticleForm
          open={projectArticleForm.open}
          id={projectArticleForm.id}
          // projectsAndSubjectsId={projectAndSubjectId}
          onCreated={addProjectArticle}
          onEdited={editProjectArticle}
          onClose={closeProjectArticlesForm}
        />
      )}

      {projectPurchaseForm.open && (
        <ProjectPurchaseForm
          open={projectPurchaseForm.open}
          id={projectPurchaseForm.id}
          // projectsAndSubjectsId={projectAndSubjectId}
          onCreated={addProjectPurchase}
          onEdited={editProjectPurchase}
          onClose={closeProjectPurchaseForm}
        />
      )}

      {projectFreeForm.open && (
        <ProjectFreeForm
          open={projectFreeForm.open}
          id={projectFreeForm.id}
          // projectsAndSubjectsId={projectAndSubjectId}
          onCreated={addProjectFree}
          onEdited={editProjectFree}
          onClose={closeProjectFreeForm}
        />
      )}
    </>
  );
});
