import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { GeneralData, WorkItem, TimeAccounting } from "../models/work";
import { getDay, addDays } from "date-fns";
import { VStore } from "./vStore";
import { Status } from "../models/projectAndSubject";

function getCurrentDate() {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
}

function getDayIndex(date: Date) {
  const day = getDay(date);
  if (day === 0) {
    return 6;
  } else {
    return day - 1;
  }
}

const initialValues: {
  workWeekData?: WorkWeekData;
  isLoading: boolean;
  generalData?: GeneralData;
  staffMemberId?: number;
  expandedRowIds: (number | string)[];
  weekView: boolean;
  isLoadingProjectStatus: { id: number; state: boolean };
} = {
  isLoading: false,
  expandedRowIds: [],
  weekView: false,
  isLoadingProjectStatus: { id: 0, state: false },
};

export default class WorkStore {
  private vStore;
  private ignoreWeekViewReset = false;

  workWeekData = initialValues.workWeekData;
  isLoading = initialValues.isLoading;
  generalData = initialValues.generalData;
  staffMemberId = initialValues.staffMemberId;
  expandedRowIds = initialValues.expandedRowIds;
  weekView = initialValues.weekView;
  currentDate = getCurrentDate();
  dayIndex = getDayIndex(this.currentDate);
  isLoadingProjectStatus = initialValues.isLoadingProjectStatus;

  constructor(vStore: VStore) {
    this.vStore = vStore;
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.workWeekData = initialValues.workWeekData;
    this.isLoading = initialValues.isLoading;
    this.generalData = initialValues.generalData;
    this.staffMemberId = initialValues.staffMemberId;
    this.expandedRowIds = initialValues.expandedRowIds;
    this.weekView = !this.ignoreWeekViewReset ? initialValues.weekView : true;
    this.currentDate = getCurrentDate();
    this.dayIndex = getDayIndex(this.currentDate);
    this.ignoreWeekViewReset = false;
    this.isLoadingProjectStatus = initialValues.isLoadingProjectStatus;
  };

  setIgnoreWeekViewReset = () => {
    this.ignoreWeekViewReset = true;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  private setIsLoadingProjectStatus = (id: number, state: boolean) => {
    runInAction(() => (this.isLoadingProjectStatus = { id: id, state: state }));
  };

  setCurrentDate = (date: Date) => {
    runInAction(() => {
      this.currentDate = date;
      this.dayIndex = getDayIndex(date);
    });
    if (this.loadNeeded()) this.loadWeek();
  };

  previousDay = () => {
    this.setCurrentDate(addDays(this.currentDate, -1));
  };

  nextDay = () => {
    this.setCurrentDate(addDays(this.currentDate, 1));
  };

  previousWeek = () => {
    this.setCurrentDate(addDays(this.currentDate, -7));
  };

  nextWeek = () => {
    this.setCurrentDate(addDays(this.currentDate, 7));
  };

  private loadNeeded() {
    return (
      !this.workWeekData ||
      +this.currentDate.startOfWeek() !==
        +this.workWeekData.dateLoaded.startOfWeek()
      // +startOfWeek(this.currentDate, { locale: nlBELocale }) !==
      //   +startOfWeek(this.workWeekData.dateLoaded, { locale: nlBELocale })
    );
  }

  loadWeek = async () => {
    try {
      this.setIsLoading(true);

      let generalData = this.generalData;
      if (!generalData) {
        generalData = await vAgent.Work.getGeneralData();
      }

      if (
        this.staffMemberId === undefined &&
        this.vStore.rootStore.appUserStore.appUser?.choosedAdministration
          ?.staffMemberID !== undefined
      )
        this.setStaffMember(
          this.vStore.rootStore.appUserStore.appUser.choosedAdministration
            .staffMemberID
        );
      const workItems = await vAgent.Work.loadWeek(
        this.currentDate,
        this.staffMemberId
      );
      const workWeekData: WorkWeekData = new WorkWeekData(
        workItems ? workItems : [],
        this.currentDate,
        this.staffMemberId!,
        this.generalData
      );
      runInAction(() => {
        this.generalData = generalData;
        this.workWeekData = workWeekData;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  getWorkItemsByDate = (date: Date): WorkItem[] => {
    try {
      if (
        this.workWeekData &&
        +date.startOfWeek() ===
          +this.workWeekData!.dateLoaded.startOfWeek()
      ) {
        const dayIndex = getDayIndex(date);
        return this.workWeekData?.getWorkItemsOfDay(dayIndex);
      }
      vAgent.Work.loadDay(date).then((response) => {
        return response;
      });
      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  deleteWork = async (id: number) => {
    try {
      this.setIsLoading(true);
      const result = await vAgent.Work.delete(id);
      if (result && result > 0) {
        runInAction(() => {
          this.workWeekData?.deleteWorkItem(id);
        });
        this.vStore.dashboardStore.resetStore();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  addWorkItem = (workItem: WorkItem) => {
    const temp = new WorkWeekData(
      this.workWeekData!.workItems,
      this.currentDate,
      this.staffMemberId!,
      this.generalData
    );
    temp.addWorkItem(workItem);
    runInAction(() => (this.workWeekData = temp));
    this.vStore.dashboardStore.resetStore();
  };

  editWorkItem = (workItem: WorkItem) => {
    const temp = new WorkWeekData(
      this.workWeekData!.workItems,
      this.currentDate,
      this.staffMemberId!,
      this.generalData
    );
    temp.editWorkItem(workItem);
    runInAction(() => (this.workWeekData = temp));
    this.vStore.dashboardStore.resetStore();
  };

  setProjectStatus = async (id: number, status: Status) => {
    try {
      this.setIsLoadingProjectStatus(id, true);
      const editedWorkItem = await vAgent.Work.setProjectStatus(id, status);
      if (editedWorkItem !== undefined) {
        const temp = new WorkWeekData(
          this.workWeekData!.workItems,
          this.currentDate,
          this.staffMemberId!,
          this.generalData
        );
        temp.editWorkItem(editedWorkItem);
        runInAction(() => (this.workWeekData = temp));
        this.vStore.dashboardStore.resetStore();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoadingProjectStatus(0, false);
    }
  };

  setStaffMember = (staffMemberId: number) => {
    runInAction(() => (this.staffMemberId = staffMemberId));
    this.loadWeek();
  };

  setExpandedRowIds = (ids: (string | number)[]) => {
    runInAction(
      () => (this.expandedRowIds = ids.length > 1 ? [ids[ids.length - 1]] : ids)
    );
  };

  setWeekView = (value: boolean) => {
    runInAction(() => (this.weekView = value));
  };
}

export interface SummaryItem {
  activityID: number;
  activity: string;
  total: number;
}

export class WorkWeekData {
  workItems: WorkItem[];
  dateLoaded: Date;
  generalData?: GeneralData;
  staffMemberId: number;

  workItemsMonday: WorkItem[] = [];
  workItemsTuesday: WorkItem[] = [];
  workItemsWednesday: WorkItem[] = [];
  workItemsThursday: WorkItem[] = [];
  workItemsFriday: WorkItem[] = [];
  workItemsSaturday: WorkItem[] = [];
  workItemsSunday: WorkItem[] = [];

  workItemsWeek: WorkItem[] = [];

  totalMonday: number = 0;
  totalTuesday: number = 0;
  totalWednesday: number = 0;
  totalThursday: number = 0;
  totalFriday: number = 0;
  totalSaturday: number = 0;
  totalSunday: number = 0;

  totalWeek: number = 0;

  dates: Date[] = [];

  constructor(
    workItems: WorkItem[],
    dateLoaded: Date,
    staffMemberId: number,
    generalData?: GeneralData
  ) {
    this.workItems = workItems;
    this.dateLoaded = dateLoaded;
    this.generalData = generalData;
    this.staffMemberId = staffMemberId;
    this.updateProperties();

    const dateStartOfWeek = dateLoaded.startOfWeek();

    this.dates.push(dateStartOfWeek);
    this.dates.push(addDays(dateStartOfWeek, 1));
    this.dates.push(addDays(dateStartOfWeek, 2));
    this.dates.push(addDays(dateStartOfWeek, 3));
    this.dates.push(addDays(dateStartOfWeek, 4));
    this.dates.push(addDays(dateStartOfWeek, 5));
    this.dates.push(addDays(dateStartOfWeek, 6));
  }

  addWorkItem(workItem: WorkItem) {
    if (workItem.staffMemberID === this.staffMemberId) {
      this.workItems.push(workItem);
      this.updateProperties();
    }
  }

  editWorkItem(workItem: WorkItem) {
    if (workItem.staffMemberID === this.staffMemberId) {
      this.workItems[this.workItems.findIndex((x) => x.id === workItem.id)] =
        workItem;
      this.updateProperties();
    } else {
      this.deleteWorkItem(workItem.id);
    }
  }

  deleteWorkItem(id: number) {
    this.workItems = this.workItems.filter((x) => x.id !== id);
    this.updateProperties();
  }

  getWorkItemsOfDay(dayIndex: number) {
    switch (dayIndex) {
      case 0:
        return this.workItemsMonday;
      case 1:
        return this.workItemsTuesday;
      case 2:
        return this.workItemsWednesday;
      case 3:
        return this.workItemsThursday;
      case 4:
        return this.workItemsFriday;
      case 5:
        return this.workItemsSaturday;
      case 6:
        return this.workItemsSunday;
      default:
        return [];
    }
  }

  getTotalOfDay(dayIndex: number) {
    switch (dayIndex) {
      case 0:
        return this.totalMonday;
      case 1:
        return this.totalTuesday;
      case 2:
        return this.totalWednesday;
      case 3:
        return this.totalThursday;
      case 4:
        return this.totalFriday;
      case 5:
        return this.totalSaturday;
      case 6:
        return this.totalSunday;
      default:
        return 0;
    }
  }

  private updateProperties() {
    this.workItemsMonday = this.updateWorkItemsDay(0);
    this.workItemsTuesday = this.updateWorkItemsDay(1);
    this.workItemsWednesday = this.updateWorkItemsDay(2);
    this.workItemsThursday = this.updateWorkItemsDay(3);
    this.workItemsFriday = this.updateWorkItemsDay(4);
    this.workItemsSaturday = this.updateWorkItemsDay(5);
    this.workItemsSunday = this.updateWorkItemsDay(6);

    this.workItemsWeek = this.workItemsMonday
      .concat(this.workItemsTuesday)
      .concat(this.workItemsWednesday)
      .concat(this.workItemsThursday)
      .concat(this.workItemsFriday)
      .concat(this.workItemsSaturday)
      .concat(this.workItemsSunday);

    this.totalMonday = this.calculateTotal(this.workItemsMonday);
    this.totalTuesday = this.calculateTotal(this.workItemsTuesday);
    this.totalWednesday = this.calculateTotal(this.workItemsWednesday);
    this.totalThursday = this.calculateTotal(this.workItemsThursday);
    this.totalFriday = this.calculateTotal(this.workItemsFriday);
    this.totalSaturday = this.calculateTotal(this.workItemsSaturday);
    this.totalSunday = this.calculateTotal(this.workItemsSunday);

    this.totalWeek =
      this.totalMonday +
      this.totalTuesday +
      this.totalWednesday +
      this.totalThursday +
      this.totalFriday +
      this.totalSaturday +
      this.totalSunday;
  }

  private updateWorkItemsDay(dayIndex: number): WorkItem[] {
    if (this.workItems && this.workItems.length > 0) {
      const datestartOfWeek = this.dateLoaded.startOfWeek();
      const dateFrom = addDays(datestartOfWeek, dayIndex);
      const dateTo = addDays(dateFrom, 1);
      const temp = this.workItems.filter(
        (x) => +new Date(x.date) >= +dateFrom && +new Date(x.date) < +dateTo
      );
      temp.sort((a, b) => (a.timeFrom! > b.timeFrom! ? 1 : -1));
      return temp;
    }
    return [];
  }

  private calculateTotal(workItems: WorkItem[]): number {
    if (workItems && this.generalData) {
      if (this.generalData.timeAccounting !== TimeAccounting.Off) {
        return workItems.reduce(
          (sum, current) => sum + (current.netHours ? current.netHours : 0),
          0
        );
        // return workItems.filter((x) => this.generalData.activitiesWork.includes(x.activityID)).reduce(
        //   (sum, current) => sum + (current.netHours ? current.netHours : 0),
        //   0,
        // )
      } else {
        return workItems.reduce(
          (sum, current) => sum + (current.quantity ? current.quantity : 0),
          0
        );
      }
    } else return 0;
  }

  getWorkWeekRows(onlyProjectRows: boolean) {
    let rows: WorkWeekRow[] = []; //default

    if (this.workItemsWeek.length > 0) {
      const processed: string[] = [];
      for (let index = 0; index < this.workItemsWeek.length; index++) {
        const element = this.workItemsWeek[index];
        const idRow = element.projectAndSubjectID
          ? element.projectAndSubjectID + "-" + element.activityID
          : "onbekend-" + element.activityID;

        if (!processed.includes(idRow)) {
          const companyName = element.companyName
            ? element.companyName
            : "onbekend";

          const project = element.projectName
            ? element.projectName
            : "onbekend";

          const description =
            companyName !== "onbekend" && project !== "onbekend"
              ? companyName + " - " + project + " - " + element.activity
              : element.activity;

          const newItem: WorkWeekRow = {
            id: idRow,
            companyName: companyName,
            project: project,
            activity: element.activity,
            description: description,
            totalMonday: this.calculateTotal(
              this.workItemsMonday.filter(
                (x) =>
                  x.projectAndSubjectID === element.projectAndSubjectID &&
                  x.activityID === element.activityID
              )
            ),
            totalTuesday: this.calculateTotal(
              this.workItemsTuesday.filter(
                (x) =>
                  x.projectAndSubjectID === element.projectAndSubjectID &&
                  x.activityID === element.activityID
              )
            ),
            totalWednesday: this.calculateTotal(
              this.workItemsWednesday.filter(
                (x) =>
                  x.projectAndSubjectID === element.projectAndSubjectID &&
                  x.activityID === element.activityID
              )
            ),
            totalThursday: this.calculateTotal(
              this.workItemsThursday.filter(
                (x) =>
                  x.projectAndSubjectID === element.projectAndSubjectID &&
                  x.activityID === element.activityID
              )
            ),
            totalFriday: this.calculateTotal(
              this.workItemsFriday.filter(
                (x) =>
                  x.projectAndSubjectID === element.projectAndSubjectID &&
                  x.activityID === element.activityID
              )
            ),
            totalSaturday: this.calculateTotal(
              this.workItemsSaturday.filter(
                (x) =>
                  x.projectAndSubjectID === element.projectAndSubjectID &&
                  x.activityID === element.activityID
              )
            ),
            totalSunday: this.calculateTotal(
              this.workItemsSunday.filter(
                (x) =>
                  x.projectAndSubjectID === element.projectAndSubjectID &&
                  x.activityID === element.activityID
              )
            ),
            totalWeek: 0,
          };

          newItem.totalMonday =
            newItem.totalMonday === 0 ? undefined : newItem.totalMonday;
          newItem.totalTuesday =
            newItem.totalTuesday === 0 ? undefined : newItem.totalTuesday;
          newItem.totalWednesday =
            newItem.totalWednesday === 0 ? undefined : newItem.totalWednesday;
          newItem.totalThursday =
            newItem.totalThursday === 0 ? undefined : newItem.totalThursday;
          newItem.totalFriday =
            newItem.totalFriday === 0 ? undefined : newItem.totalFriday;
          newItem.totalSaturday =
            newItem.totalSaturday === 0 ? undefined : newItem.totalSaturday;
          newItem.totalSunday =
            newItem.totalSunday === 0 ? undefined : newItem.totalSunday;

          newItem.totalWeek =
            (newItem.totalMonday ? newItem.totalMonday : 0) +
            (newItem.totalTuesday ? newItem.totalTuesday : 0) +
            (newItem.totalWednesday ? newItem.totalWednesday : 0) +
            (newItem.totalThursday ? newItem.totalThursday : 0) +
            (newItem.totalFriday ? newItem.totalFriday : 0) +
            (newItem.totalSaturday ? newItem.totalSaturday : 0) +
            (newItem.totalSunday ? newItem.totalSunday : 0);

          rows.push(newItem);
          processed.push(idRow);
        }
      }
    }

    if (onlyProjectRows)
      rows = rows.filter(
        (x) => x.companyName !== "onbekend" && x.project !== "onbekend"
      ); //Only project rows...

    rows = rows.sort((a, b) => (a.description > b.description ? 1 : -1));
    // rows = rows
    // .sort((a, b) =>
    //   a.companyName + a.project + a.activity >
    //     b.companyName + a.project + a.activity
    //     ? 1
    //     : -1
    // );

    //Add summary row

    const sumRow: WorkWeekRow = {
      id: "sum",
      companyName: "",
      project: "",
      activity: "",
      description: "Totalen:",
      totalMonday: rows.reduce(
        (a, b) => +a + (b.totalMonday ? +b.totalMonday : 0),
        0
      ),
      totalTuesday: rows.reduce(
        (a, b) => +a + (b.totalTuesday ? +b.totalTuesday : 0),
        0
      ),
      totalWednesday: rows.reduce(
        (a, b) => +a + (b.totalWednesday ? +b.totalWednesday : 0),
        0
      ),
      totalThursday: rows.reduce(
        (a, b) => +a + (b.totalThursday ? +b.totalThursday : 0),
        0
      ),
      totalFriday: rows.reduce(
        (a, b) => +a + (b.totalFriday ? +b.totalFriday : 0),
        0
      ),
      totalSaturday: rows.reduce(
        (a, b) => +a + (b.totalSaturday ? +b.totalSaturday : 0),
        0
      ),
      totalSunday: rows.reduce(
        (a, b) => +a + (b.totalSunday ? +b.totalSunday : 0),
        0
      ),
      totalWeek: rows.reduce(
        (a, b) => +a + (b.totalWeek ? +b.totalWeek : 0),
        0
      ),
    };

    sumRow.totalMonday =
      sumRow.totalMonday === 0 ? undefined : sumRow.totalMonday;
    sumRow.totalTuesday =
      sumRow.totalTuesday === 0 ? undefined : sumRow.totalTuesday;
    sumRow.totalWednesday =
      sumRow.totalWednesday === 0 ? undefined : sumRow.totalWednesday;
    sumRow.totalThursday =
      sumRow.totalThursday === 0 ? undefined : sumRow.totalThursday;
    sumRow.totalFriday =
      sumRow.totalFriday === 0 ? undefined : sumRow.totalFriday;
    sumRow.totalSaturday =
      sumRow.totalSaturday === 0 ? undefined : sumRow.totalSaturday;
    sumRow.totalSunday =
      sumRow.totalSunday === 0 ? undefined : sumRow.totalSunday;

    rows.push(sumRow);

    return rows;
  }

  getSummaryItems(workItems: WorkItem[]) {
    let summary: SummaryItem[] = [];
    const processedActivities: number[] = [];

    if (workItems && workItems.length > 0) {
      for (let index = 0; index < workItems.length; index++) {
        const element = workItems[index];
        if (!processedActivities.includes(element.activityID)) {
          summary.push({
            activityID: element.activityID,
            activity: element.activity,
            total: this.calculateTotal(
              workItems.filter((x) => x.activityID === element.activityID)
            ),
          });
          processedActivities.push(element.activityID);
        }
      }

      summary = summary.sort((a, b) => (a.activity > b.activity ? 1 : -1));

      summary.push({
        activityID: -1,
        activity: "Totaal:",
        total: summary.reduce((a, b) => +a + +b.total, 0),
      });

      return summary;
    }
  }

  getSummaryItemsWorkWeek(onlyProjectRows: boolean) {
    const myWorkItems = onlyProjectRows
      ? this.workItemsWeek.filter((x) => x.companyName && x.projectName)
      : this.workItemsWeek;
    return this.getSummaryItems(myWorkItems);
  }
}

export interface WorkWeekRow {
  id: string;
  companyName: string;
  project: string;
  activity: string;
  description: string;
  totalMonday: number | undefined;
  totalTuesday: number | undefined;
  totalWednesday: number | undefined;
  totalThursday: number | undefined;
  totalFriday: number | undefined;
  totalSaturday: number | undefined;
  totalSunday: number | undefined;
  totalWeek: number | undefined;
}
