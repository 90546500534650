import { observer } from "mobx-react-lite";
import { CSSProperties, Fragment } from "react";
import { useRootStore } from "../stores/rootStore";
import theme from "../themes/theme";
import CFab from "./controls/CFab";
import ToolStrip from "./ToolStrip";
import ToolStripSpaceBetween from "./ToolStripSpaceBetween";

interface Props {
  onAddButtonClick?: () => void;
  //breakPointFab?: number;
  primaryToolStripTools?: JSX.Element | JSX.Element[];
  secondaryToolStripTools?: JSX.Element | JSX.Element[];
  spacing?: number;
  cssProperties?: CSSProperties;
  children?: any;
}

export default observer(function PageAndToolStrip({
  onAddButtonClick,
  //breakPointFab,
  primaryToolStripTools,
  secondaryToolStripTools,
  spacing,
  cssProperties,
  children,
}: Props) {
  const { commonStore: { useFab } } = useRootStore();

  const primaryToolStrip =
    primaryToolStripTools !== undefined || onAddButtonClick !== undefined ? (
      <ToolStrip
        onAddButtonClick={
          !useFab && onAddButtonClick !== undefined ? onAddButtonClick : undefined
        }
        spacing={spacing}
      >
        {Array.isArray(primaryToolStripTools)
          ? primaryToolStripTools.map((tool, index) => (
            <Fragment key={index}>{tool}</Fragment>
          ))
          : primaryToolStripTools}
      </ToolStrip>
    ) : undefined;

  const secondaryToolStrip =
    secondaryToolStripTools !== undefined ? (
      <ToolStrip spacing={spacing}>
        {Array.isArray(secondaryToolStripTools)
          ? secondaryToolStripTools.map((tool, index) => (
            <Fragment key={index}>{tool}</Fragment>
          ))
          : secondaryToolStripTools}
      </ToolStrip>
    ) : undefined;

  return (
    <div style={{ marginBottom: useFab && onAddButtonClick !== undefined ? theme.spacing(11) : undefined, width: "100%", ...cssProperties }}>
      <ToolStripSpaceBetween
        primaryToolStrip={primaryToolStrip ? primaryToolStrip : <Fragment />}
        secondaryToolStrip={
          secondaryToolStrip ? secondaryToolStrip : <Fragment />
        }
      />

      <div style={{ marginTop: theme.spacing(3) }}>
        {children}
      </div>

      {useFab && onAddButtonClick !== undefined && <CFab onClick={onAddButtonClick!} />}
    </div>
  );
})
