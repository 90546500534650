import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Texts } from "../Constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

interface Props {
  onClick: () => void;
  children?: any;
}

export default function FCancelButton({ onClick, children }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        variant='contained'
        color='secondary'
        size='medium'
        fullWidth
        onClick={onClick}
      >
        {children ? { children } : <Typography>{Texts.cancelText}</Typography>}
      </Button>
    </div>
  );
}
