import { FormManager } from "./../../Common/Objects";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import {
  ProjectGeneralData,
  ProjectFormValues,
  Status,
} from "../models/projectAndSubject";
import { Relation } from "../models/relation";

const initialValues: {
  isLoading: boolean;
  loadNeeded: boolean;
  projectGeneralData?: ProjectGeneralData;
  projectFormValues: ProjectFormValues;
} = {
  isLoading: false,
  loadNeeded: true,
  // projectGeneralData: undefined,
  projectFormValues: {
    id: 0,
    typeProjectID: undefined,
    clientID: undefined,
    projectName: "",
    projectDescription: "",
    status: Status.Opdracht,
  },
};

export default class ProjectFormStore {
  private formManager: FormManager;

  isLoading = initialValues.isLoading;
  loadNeeded = initialValues.loadNeeded;
  projectGeneralData = initialValues.projectGeneralData;
  projectFormValues = initialValues.projectFormValues;
  projectForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.projectForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.loadNeeded = initialValues.loadNeeded;
    this.projectGeneralData = initialValues.projectGeneralData;
    this.projectFormValues = initialValues.projectFormValues;
    this.projectForm = this.formManager.closeForm();
  };

  resetValues = () => {
    this.isLoading = initialValues.isLoading;
    this.projectFormValues = initialValues.projectFormValues;
    this.projectForm = this.formManager.closeForm();
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (id?: number) => {
    try {
      this.setIsLoading(true);
      let projectGeneralData = this.projectGeneralData;
      let formValues: ProjectFormValues | undefined;

      if (this.loadNeeded) {
        projectGeneralData = await vAgent.Projects.getGeneralData();
      }

      if (id) {
        formValues = await vAgent.Projects.getFormValues(id);
      }

      runInAction(() => {
        this.projectGeneralData = projectGeneralData;
        this.projectFormValues = formValues
          ? formValues
          : initialValues.projectFormValues;
        this.loadNeeded = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  create = async (project: ProjectFormValues) => {
    try {
      return await vAgent.Projects.create(project);
    } catch (error) {
      console.log(error);
    }
  };

  edit = async (project: ProjectFormValues) => {
    try {
        return await vAgent.Projects.edit(project);
    } catch (error) {
      console.log(error);
    }
  };

  addRelation = async (relation: Relation) => {
    try {
      if (relation) {
        const projectGeneralData = this.projectGeneralData!;
        projectGeneralData.clients.unshift(relation);

        runInAction(() => {
          this.projectGeneralData = projectGeneralData;
        });

        return relation.id;
      }
    } catch (error) {
      console.log(error);
    }
  };

  openProjectForm = (id?: number | undefined) => {
    runInAction(() => {
      this.projectForm = this.formManager.openForm(id);
    });
  };

  closeProjectForm = () => {
    runInAction(() => {
      this.projectForm = this.formManager.closeForm();
    });
  };
}
