import {
  useMediaQuery,
} from "@material-ui/core";

import {
  TableRowDetail,
} from "@devexpress/dx-react-grid-material-ui";
import SearchField from "./controls/CSearchField";
import theme from "../themes/theme";
import ReloadButton from "./ReloadButton";
import PageAndToolStrip from "./PageAndToolStrip";
import { Fragment, useState } from "react";
import TableButton from "./TableButton";
import ToolStrip from "./ToolStrip";
import { ExportFormats } from "./Functions";
import AppTable, {AppColumn, TableStateValues} from "./AppTable";

interface Props {
  tableName: string;
  columns: AppColumn[];
  rows: any[];
  rowDetail: React.ComponentType<TableRowDetail.ContentProps> | undefined;
  onNew: (() => void) | undefined;
  onReload: (() => void) | undefined;
  tableStateValues: TableStateValues;
  setTableStateValues: (tableStateValues: TableStateValues) => void;
  tools: JSX.Element | JSX.Element[] | undefined;
  limitMoreMenu: number;
}

export default function ToolStripAndTable({
  tableName,
  columns,
  rows,
  rowDetail,
  onNew,
  onReload,
  tableStateValues,
  setTableStateValues,
  tools,
  limitMoreMenu,
}: Props) {
  const [exportTable, setExportTable] = useState<{exportId: number, exportFormat: ExportFormats} | undefined>(
    undefined
  );
  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));
  const matchesSm = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.only("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.only("lg"));

  const setHiddenColumnNames = (names: string[]) => {
    if (matchesXs) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesXs: names });
    } else if (matchesSm) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesSm: names });
    } else if (matchesMd) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesMd: names });
    } else if (matchesLg) {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesLg: names });
    } else {
      setTableStateValues({ ...tableStateValues, hiddenColumnNamesXl: names });
    }
  };

  const setUseGroupPanel = (value: boolean) => {
    tableStateValues.useGroupPanel = value;
    setTableStateValues({ ...tableStateValues });
  };
  const setUseFilterPanel = (value: boolean) => {
    tableStateValues.useFilterPanel = value;
    setTableStateValues({ ...tableStateValues });
  };
  const setSearchState = (value: string) => {
    tableStateValues.searchValue = value;
    setTableStateValues({ ...tableStateValues });
  };

  const reloadButton = onReload && <ReloadButton onReload={onReload} />;

  const hiddenColumnNamesToUse = matchesXs
    ? tableStateValues.hiddenColumnNamesXs
    : matchesSm
    ? tableStateValues.hiddenColumnNamesSm
    : matchesMd
    ? tableStateValues.hiddenColumnNamesMd
    : matchesLg
    ? tableStateValues.hiddenColumnNamesLg
    : tableStateValues.hiddenColumnNamesXl;

const handleExportClick = (exportFormat: ExportFormats) => {
  setExportTable({exportId: exportTable !== undefined ? exportTable.exportId + 1 : 1, exportFormat: exportFormat})
}
  const tableButton = (
    <TableButton
      columns={columns}
      hiddenColumnNames={hiddenColumnNamesToUse}
      onHiddenColumnNamesChange={(names) => setHiddenColumnNames(names)}
      useGroupPanel={tableStateValues.useGroupPanel}
      onUseGroupPanelChanged={(value) => setUseGroupPanel(value)}
      useFilterPanel={tableStateValues.useFilterPanel}
      onUseFilterPanelChanged={(value) => setUseFilterPanel(value)}
      onExportClick={handleExportClick}
    />
  );

  const moreMenuTools = tools
    ? Array.isArray(tools)
      ? Array.from(tools)
      : [tools]
    : [];
  if (reloadButton) moreMenuTools.push(reloadButton);
  moreMenuTools.push(tableButton);

  const toolStripMoreMenu = (
    <ToolStrip limitMoreMenu={limitMoreMenu}>
      {moreMenuTools.map((tool, index) => (
        <Fragment key={index}>{tool}</Fragment>
      ))}
    </ToolStrip>
  );

  const searchField = (
    <SearchField
      searchValue={tableStateValues.searchValue}
      onSearchValueChanged={(value) => {
        setSearchState(value);
      }}
    />
  );

  return (
    <PageAndToolStrip
      onAddButtonClick={onNew}
      secondaryToolStripTools={[toolStripMoreMenu, searchField]}
    >
      <AppTable
        tableName={tableName}
        columns={columns}
        rows={rows}
        rowDetail={rowDetail}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues}
        exportTable={exportTable}
      />
    </PageAndToolStrip>
  );
}
