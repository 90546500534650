import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import { Administration } from "../../models/administration";
import { observer } from "mobx-react-lite";
import AdministrationForm from "./AdministrationForm";
import { history } from "../..";
import LoadingComponent from "../../Common/LoadingComponent";
import { useRootStore } from "../../stores/rootStore";
import PageAndToolStrip from "../../Common/PageAndToolStrip";
import AdministrationButton from "./AdministrationButton";
import AdministrationCard from "./AdministrationCard";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import theme from "../../themes/theme";
import SettingsIcon from "@material-ui/icons/Settings";
import ValidationErrors from "../../Common/form/ValidationErrors";

export default observer(function Administrations() {
  //const [allowAutoChoose, setAllowAutoChoose] = useState(true);
  const [manageAdministrations, setManageAdministrations] = useState(false);
  const {
    administrationsStore: {
      isLoading,
      administrations,
      loadAdministrations,
      allowAutoChoose,
      chooseAdministration,
      isLoadingChoose,
      isLoadingDelete,
      addAdministration,
      editAdministration,
    },
    administrationFormStore: {
      administrationForm,
      openAdministrationForm,
      closeAdministrationForm,
    },
  } = useRootStore();

  useEffect(() => {
    if (!administrations) loadAdministrations();
  }, [administrations, loadAdministrations]);

  if (administrations && administrations.length > 0) {
    try {
      var locationState: any = history.location.state;
      if (
        allowAutoChoose === true &&
        locationState &&
        locationState.autoChoose === true &&
        administrations.length === 1
      ) {
        chooseAdministration(administrations[0].id);
      } else {
        //if (allowAutoChoose === true) setAllowAutoChoose(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (isLoading)
    return (
      <LoadingComponent content='Administraties laden...' useBackdrop={false} />
    );

  if (isLoadingChoose)
    return (
      <LoadingComponent
        content='Gekozen administratie laden...'
        useBackdrop={true}
      />
    );

  if (isLoadingDelete)
    return <LoadingComponent content='Verwijderen...' useBackdrop={true} />;

  const myComponent = !manageAdministrations ? (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Grid item xs={12} sm={12} md={"auto"}>
        <Typography variant='h6'>Administratiekeuze</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={"auto"}>
        <Link href='#' onClick={() => setManageAdministrations(true)}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box style={{ paddingTop: 4 }}>
              <SettingsIcon fontSize='small' />
            </Box>
            <Box style={{ marginLeft: theme.spacing(1) }}>
              Administratiebeheer
            </Box>
          </Box>
        </Link>
      </Grid>
      <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
        {isLoading ? (
          <LoadingComponent useBackdrop={false} />
        ) : (
          <Paper
            style={{
              paddingTop: theme.spacing(4),
              paddingBottom: theme.spacing(4),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }}
          >
            <Grid container spacing={2}>
              {administrations && administrations.length > 0 ? (
                <>
                  <Grid item xs={12}>
                    <Typography>Kies hier uw administratie...</Typography>
                  </Grid>

                  {administrations?.map((administration: Administration) => (
                    <Grid item xs={12} key={administration.id}>
                      <AdministrationButton administration={administration} />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item>
                  <ValidationErrors errors='Maak eerst een administratiekoppeling aan door op Administratiebeheer te klikken' />
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
      </Grid>
    </Grid>
  ) : (
    <Grid>
      <Grid item xs={12}>
        <Link href='#' onClick={() => setManageAdministrations(false)}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box style={{ paddingTop: 5 }}>
              <ArrowBackIcon fontSize='small' />
            </Box>
            <Box style={{ marginLeft: theme.spacing(1) }}>
              Terug naar administratie keuze
            </Box>
          </Box>
        </Link>
      </Grid>

      <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
        <PageAndToolStrip
          onAddButtonClick={() => openAdministrationForm(undefined)}
          primaryToolStripTools={
            <Typography variant='h6'>Administratiebeheer</Typography>
          }
        >
          {isLoading ? (
            <LoadingComponent useBackdrop={false} />
          ) : (
            <Grid container spacing={2}>
              {administrations &&
                administrations.map((administration: Administration) => (
                  <Grid item xs={12} key={administration.id}>
                    <AdministrationCard administration={administration} />
                  </Grid>
                ))}
            </Grid>
          )}
        </PageAndToolStrip>
      </Grid>
    </Grid>
  );

  return (
    <Container maxWidth={"md"} style={{ padding: 0 }}>
      {myComponent}

      {administrationForm.open && (
        <AdministrationForm
          open={administrationForm.open}
          id={administrationForm.id}
          onCreated={addAdministration}
          onEdited={editAdministration}
          onClose={closeAdministrationForm}
        />
      )}
    </Container>
  );
});
