import { DatePicker, DatePickerProps } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { WrapperVariant } from "@material-ui/pickers/wrappers/Wrapper";
import { useField, useFormikContext } from "formik";
import { Texts } from "../Constants";
import { createDateFromTextValue, createTextValueFromDate } from "../Functions";

interface Props {
  name: string;
  label: string;
  required: boolean;
  format?: string;
  showTodayButton?: boolean;
  size?: "small" | "medium" | undefined;
  variant?: WrapperVariant;
  open?: boolean;
  onClose?: () => void;
  valueAsString?: boolean;
  onValueChanged?: (value: MaterialUiPickersDate | string | undefined) => void;
}

export default function FDatePicker(props: Props) {
  const [field, mata] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  const { valueAsString, onValueChanged, ...datePickerProps } = props;
  // const theme = useTheme();

  const configDatePicker: DatePickerProps = {
    ...field,
    ...datePickerProps,
  };

  if (mata && mata.touched && mata.error) {
    configDatePicker.error = true;
    configDatePicker.helperText = mata.error;
  }

  const handleChange = (value: MaterialUiPickersDate) => {
    const valueToUse = value
      ? valueAsString
        ? createTextValueFromDate(value)
        : value
      : undefined;
    setFieldValue(props.name, valueToUse);
    if (onValueChanged) onValueChanged(valueToUse);
  };

  const getValue = () => {
    if (field.value && valueAsString)
      return createDateFromTextValue(field.value);
    return field.value;
  };

  return (
    <DatePicker
      clearable={!props.required}
      fullWidth
      inputVariant='outlined'
      size='medium'
      format='dd-MM-yy'
      showTodayButton
      todayLabel={Texts.todayText}
      okLabel={Texts.chooseText}
      cancelLabel={Texts.cancelText}
      clearLabel={Texts.clearText}
      {...configDatePicker}
      value={getValue()}
      onChange={handleChange}
      // InputProps={{
      //   endAdornment: !props.required && (
      //     <InputAdornment position='end'>
      //       <IconButton
      //         // style={{ marginRight: theme.spacing(3) }}
      //         size='medium'
      //         onClick={() => {
      //           setFieldValue(props.name, undefined);
      //         }}
      //       >
      //         <ClearIcon />
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      // }}
    />
  );
}
