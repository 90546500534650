import { createStyles, Fab, makeStyles, Theme } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "absolute",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
  })
);

interface Props {
  onClick: () => void;
}

export default function CFab({ onClick }: Props) {
  const classes = useStyles();

  return (
    <Fab
      style={{ position: "fixed" }}
      color='primary'
      aria-label='add'
      className={classes.fab}
      onClick={onClick}
    >
      <AddIcon />
    </Fab>
  );
}
