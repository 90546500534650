import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import LoadingComponent from "../../../Common/LoadingComponent";
import FDialog from "../../../Common/form/FDialog";
import {
  Project,
  ProjectFormValues,
  Status,
} from "../../models/projectAndSubject";
import SelectWithSearch from "../../../Common/form/FSelect";
import { observer } from "mobx-react-lite";
import FTextfield from "../../../Common/form/FTextField";
import { Kind } from "../../models/settingsProjectType";
import { UserRights } from "../../models/progmaUserRights";
import RelationForm from "../Relations/RelationForm";
import { useVStore } from "../../stores/vStore";

interface Props {
  open: boolean;
  id: number | undefined;
  onCreated: (project: Project) => void;
  onEdited: (project: Project) => void;
  onClose: () => void;
}

const validationSchema = yup.object({
  status: yup.number().required("verplicht veld"),
  typeProjectID: yup.number().required("verplicht veld"),
  clientID: yup.number().required("verplicht veld"),
  projectName: yup.string().required("verplicht veld"),
  projectDescription: yup.string().nullable(),
});

export default observer(function ProjectForm({
  open,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {
    projectFormStore: {
      isLoading,
      loadNeeded,
      load,
      projectGeneralData,
      projectFormValues,
      create,
      edit,
      addRelation,
    },
    relationFormStore: { relationForm, openRelationForm, closeRelationForm },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  useEffect(() => {
    if (loadNeeded || id) load(id);
  }, [loadNeeded, load, id]);

  function handleFormSubmit(projectFormValues: ProjectFormValues) {
    if (!id) {
      create(projectFormValues)
        .then((project) => project && onCreated(project))
        .finally(() => onClose());
    } else {
      edit(projectFormValues)
        .then((project) => project && onEdited(project))
        .finally(() => onClose());
    }
  }

  if (isLoading)
    return (
      <LoadingComponent
        content='Project formulier laden...'
        useBackdrop={true}
      />
    );

  return (
    <Formik
      enableReinitialize
      initialValues={projectFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue }) => {
        if (
          progmaUserRights?.relationsUserRights === UserRights.Editing &&
          relationForm.open
        ) {
          return (
            <RelationForm
              open={relationForm.open}
              id={undefined}
              onCreated={(relation) =>
                addRelation(relation).then(
                  (newId) => newId && setFieldValue("clientID", newId)
                )
              }
              onEdited={() => {}}
              onClose={closeRelationForm}
            />
          );
        }

        return (
          <FDialog
            title={id && id > 0 ? "Project aanpassen" : "Nieuw Project"}
            open={open}
            onClose={onClose}
            breakPointFullScreen='md'
          >
            <form noValidate autoComplete='off'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SelectWithSearch
                    name='clientID'
                    label='Klant'
                    required={true}
                    items={
                      projectGeneralData &&
                      projectGeneralData.clients.map((client) => ({
                        id: client.id,
                        text:
                          client.companyName + " (" + client.cityVisit + ")",
                      }))
                    }
                    useSearchField
                    //useClearButton={false}
                    onAddButtonClick={
                      progmaUserRights?.relationsUserRights ===
                      UserRights.Editing
                        ? openRelationForm
                        : undefined
                    }
                    autoFocus={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectWithSearch
                    name='typeProjectID'
                    label='Type project'
                    required={true}
                    items={
                      projectGeneralData &&
                      projectGeneralData.settingsProjectTypes.map((x) => ({
                        id: x.id,
                        text: x.name + " (" + Kind[x.kind] + ")",
                      }))
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='projectName'
                    label='Projectnaam'
                    required
                    autoComplete='off'
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectWithSearch
                    name='status'
                    label='Status'
                    required={true}
                    items={(
                      Object.keys(Status).filter((v) =>
                        isNaN(Number(v))
                      ) as (keyof typeof Status)[]
                    ).map((key, index) => ({ id: index, text: key }))}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='projectDescription'
                    label='Omschrijving'
                    required={false}
                    autoComplete='off'
                    multiline
                    minRows={3}
                  />
                </Grid>
              </Grid>
            </form>
          </FDialog>
        );
      }}
    </Formik>
  );
});
