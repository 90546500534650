import {
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import ValidationErrors from "../../Common/form/ValidationErrors";
import { useRootStore } from "../../stores/rootStore";
import * as yup from "yup";
import FTextfield from "../../Common/form/FTextField";
import FSubmitButton from "../../Common/form/FSubmitButton";
import { Texts } from "../../Common/Constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    //display: 'flex',
    flexDirection: "column",
    //alignItems: 'right',
  },
  wrapper: {
    marginTop: theme.spacing(3),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Wachtwoord is vereist")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Moet 8 karakters bevatten, Één hoofdletter, Één kleine letter, Één nummer en één speciaal karakter"
    ),
});

export default function DeleteAccount() {
  const classes = useStyles();
  const {
    appUserStore: { appUser, deleteAccount },
  } = useRootStore();

  return (
    <Formik
      initialValues={{ email: appUser?.username!, password: "", error: null }}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        if (
          window.confirm(
            "Uw account en al uw administratiekoppelingen zullen worden verwijderd. Weet u dit zeker?"
          )
        ) {
          deleteAccount(values).catch((error) => {
            setErrors({ error: error });
            setSubmitting(false);
          });
        }
      }}
      validationSchema={validationSchema}
    >
      {({ errors }) => (
        <div className={classes.paper}>
          <form noValidate>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <FTextfield
                  name='password'
                  label='Wachtwoord'
                  type='password'
                  required
                  autoComplete='current-password'
                />
              </Grid>

              {errors.error && (
                <Grid item xs={12}>
                  <ValidationErrors errors={errors.error} />
                </Grid>
              )}

              <Grid item xs={12}>
                <FSubmitButton size={"large"}>
                  {Texts.deleteText}
                </FSubmitButton>
              </Grid>

            </Grid>
          </form>
        </div>
      )}
    </Formik>
  );
}
