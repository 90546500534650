import { Grid } from "@material-ui/core";
import * as yup from "yup";
import { Formik } from "formik";
import LoadingComponent from "../../../Common/LoadingComponent";
import FDialog from "../../../Common/form/FDialog";
import { observer } from "mobx-react-lite";
import FTextfield from "../../../Common/form/FTextField";
import { useVStore } from "../../stores/vStore";
import { ProjectPurchaseFormValues } from "../../models/projectPurchase";
import { useEffect } from "react";
import { ProjectPurchase } from "../../models/projectPurchase";
import { UserRightsFinancial } from "../../models/progmaUserRights";
import FSelect from "../../../Common/form/FSelect";
import FPercentTextfield from "../../../Common/form/FPercentTextField";
import FCurrencyTextfield from "../../../Common/form/FCurrencyTextField";
import FNumericTextfield from "../../../Common/form/FNumericTextField";
import { toCurrency, toNumber } from "../../../Common/Functions";
import ValidationErrors from "../../../Common/form/ValidationErrors";

interface Props {
  open: boolean;
  id?: number;
  //projectsAndSubjectsId: number;
  onCreated: (projectPurchase: ProjectPurchase) => void;
  onEdited: (projectPurchase: ProjectPurchase) => void;
  onClose: () => void;
}

const validationSchema = yup.object({
  ledgerID: yup.number().required("verplicht veld"),
  quantity: yup
    .number()
    .required("verplicht veld")
    .notOneOf([0], "Kan niet 0 zijn"),
  description: yup.string().required("verplicht veld"),
  costPrice: yup.number().nullable(),
  margin: yup.number().nullable(),
  profit: yup.number().nullable(),
  sellingPrice: yup.number().nullable(),
  costPriceTotal: yup.number().nullable(),
  profitTotal: yup.number().nullable(),
  sellingPriceTotal: yup.number().nullable(),
});

export default observer(function ProjectPurchaseForm({
  open,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {
    projectPurchaseFormStore: {
      isLoading,
      loadNeeded,
      load,
      projectPurchaseFormValues,
      create,
      edit,
      ledgers,
    },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  useEffect(() => {
    if (loadNeeded || id) load(id);
  }, [loadNeeded, load, id]);

  if (isLoading)
    return (
      <LoadingComponent
        content='Project inkoop invoer formulier laden...'
        useBackdrop={true}
      />
    );

  function handleFormSubmit(
    projectPurchaseFormValues: ProjectPurchaseFormValues
  ) {
    if (!id) {
      create(projectPurchaseFormValues)
        .then(
          (projectPurchase) => projectPurchase && onCreated(projectPurchase)
        )
        .finally(() => onClose());
    } else {
      edit(projectPurchaseFormValues)
        .then((projectPurchase) => projectPurchase && onEdited(projectPurchase))
        .finally(() => onClose());
    }
  }

  const validate = (projectPurchaseFormValues: ProjectPurchaseFormValues) => {
    if (projectPurchaseFormValues.costPrice !== undefined && projectPurchaseFormValues.sellingPrice !== undefined
       && (projectPurchaseFormValues.margin === undefined || projectPurchaseFormValues.profit === undefined))
      return { error: "Als de kostprijs en de verkoopprijs ingevuld zijn dan moet ook de marge en de winst ingevuld zijn." };
    return undefined;
  };

  const initialValues = { error: null, ...projectPurchaseFormValues };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(projectPurchaseFormValues, { setErrors, setSubmitting }) => {
        const errors = validate(projectPurchaseFormValues);
        if (errors) {
          setErrors(errors);
          setSubmitting(false);
        } else handleFormSubmit(projectPurchaseFormValues);
      }}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values, errors }) => {
        const setTotals = (
          quantity: number | undefined,
          costPrice: number | undefined,
          profit: number | undefined,
          sellingPrice: number | undefined
        ) => {
          const costPriceTotal =
            quantity !== undefined && costPrice !== undefined
              ? toCurrency(costPrice)?.multiply(quantity)
              : undefined;
          setFieldValue("costPriceTotal", costPriceTotal?.value);

          const sellingPriceTotal =
            quantity !== undefined && sellingPrice !== undefined
              ? toCurrency(sellingPrice)?.multiply(quantity)
              : undefined;
          setFieldValue("sellingPriceTotal", sellingPriceTotal?.value);

          setFieldValue(
            "profitTotal",
            costPriceTotal && sellingPriceTotal
              ? sellingPriceTotal.subtract(costPriceTotal.value).value
              : undefined
          );
        };

        return (
          <FDialog
            title={
              id && id > 0 ? "Project inkoop aanpassen" : "Nieuw Project inkoop"
            }
            open={open}
            onClose={onClose}
            breakPointFullScreen='md'
          >
            <form noValidate autoComplete='off'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FSelect
                    name='ledgerID'
                    label='Groep'
                    required
                    items={
                      ledgers &&
                      ledgers.map((ledger) => ({
                        id: ledger.id,
                        text: ledger.name,
                      }))
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FNumericTextfield
                    name='quantity'
                    label='Aantal'
                    required
                    onValueChanged={(value) => {
                      setTotals(
                        value,
                        values.costPrice,
                        values.profit,
                        values.sellingPrice
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='description'
                    label='Omschrijving'
                    required
                    autoComplete='off'
                  />
                </Grid>

                {progmaUserRights?.projectPurchaseUserRightsFinancial ===
                  UserRightsFinancial.All && (
                  <>
                    <Grid item xs={12}>
                      <FCurrencyTextfield
                        name='costPrice'
                        label='Kostprijs'
                        required={false}
                        onValueChanged={(value) => {
                          const currencyValue = toCurrency(value);
                          let profit = toCurrency(values.profit);
                          let sellingPrice = toCurrency(values.sellingPrice);
                          if (currencyValue === undefined) {
                            setFieldValue("margin", undefined);
                            profit = undefined;
                            setFieldValue("profit", profit);
                          } else if (values.margin !== undefined) {
                            profit = currencyValue.multiply(values.margin);
                            sellingPrice = currencyValue.add(profit.value);
                            setFieldValue("profit", profit.value);
                            setFieldValue("sellingPrice", sellingPrice.value);
                          } else if (sellingPrice !== undefined) {
                            profit = sellingPrice.subtract(currencyValue);
                            setFieldValue(
                              "margin",
                              toNumber(profit.value / currencyValue.value, 4)
                            );
                            setFieldValue("profit", profit.value);
                          }
                          setTotals(
                            values.quantity,
                            currencyValue?.value,
                            profit?.value,
                            sellingPrice?.value
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FPercentTextfield
                        name='margin'
                        label='Marge'
                        required={false}
                        disabled={values.costPrice === undefined}
                        onValueChanged={(value) => {
                          let profit = toCurrency(values.profit);
                          let sellingPrice = toCurrency(values.sellingPrice);
                          const costPrice = toCurrency(values.costPrice);
                          if (value === undefined) {
                            profit = undefined;
                            setFieldValue("profit", profit);
                          } else if (costPrice !== undefined) {
                            profit = costPrice.multiply(value);
                            sellingPrice = costPrice.add(profit.value);
                            setFieldValue("profit", profit.value);
                            setFieldValue("sellingPrice", sellingPrice.value);
                          }
                          setTotals(
                            values.quantity,
                            costPrice?.value,
                            profit?.value,
                            sellingPrice?.value
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FCurrencyTextfield
                        name='profit'
                        label='Winst'
                        required={false}
                        disabled={values.costPrice === undefined}
                        onValueChanged={(value) => {
                          const currencyValue = toCurrency(value);
                          let sellingPrice = toCurrency(values.sellingPrice);
                          const costPrice = toCurrency(values.costPrice);
                          if (currencyValue === undefined) {
                            setFieldValue("margin", undefined);
                          } else if (costPrice !== undefined) {
                            setFieldValue(
                              "margin",
                              toNumber(currencyValue.value / costPrice.value, 4)
                            );
                            sellingPrice = costPrice?.add(currencyValue.value);
                            setFieldValue("sellingPrice", sellingPrice.value);
                          }
                          setTotals(
                            values.quantity,
                            costPrice?.value,
                            currencyValue?.value,
                            sellingPrice?.value
                          );
                        }}
                      />
                    </Grid>
                  </>
                )}

              {errors.error && (
                  <Grid item xs={12}>
                    <ValidationErrors errors={errors.error} />
                  </Grid>
                )}

                {progmaUserRights?.projectPurchaseUserRightsFinancial ===
                  UserRightsFinancial.All && (
                  <Grid item xs={12}>
                    <FCurrencyTextfield
                      name='sellingPrice'
                      label='Verkoopprijs'
                      required={false}
                      onValueChanged={(value) => {
                        const currencyValue = toCurrency(value);
                        const costPrice = toCurrency(values.costPrice);
                        let profit = toCurrency(values.profit);
                        if (currencyValue === undefined) {
                          profit = undefined;
                          setFieldValue("margin", undefined);
                          setFieldValue("profit", profit);
                        } else if (costPrice !== undefined) {
                          profit = currencyValue.subtract(costPrice.value);
                          setFieldValue(
                            "margin",
                            toNumber(profit.value / costPrice.value, 4)
                          );
                          setFieldValue("profit", profit.value);
                        }
                        setTotals(
                          values.quantity,
                          costPrice?.value,
                          profit?.value,
                          currencyValue?.value
                        );
                      }}
                    />
                  </Grid>
                )}

                {progmaUserRights?.projectPurchaseUserRightsFinancial ===
                  UserRightsFinancial.All && (
                  <>
                    <Grid item xs={12}>
                      <FCurrencyTextfield
                        name='costPriceTotal'
                        label='Kostprijs totaal'
                        required={false}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FCurrencyTextfield
                        name='profitTotal'
                        label='Winst totaal'
                        required={false}
                        disabled
                      />
                    </Grid>
                  </>
                )}

                {progmaUserRights?.projectPurchaseUserRightsFinancial ===
                  UserRightsFinancial.All && (
                  <Grid item xs={12}>
                    <FCurrencyTextfield
                      name='sellingPriceTotal'
                      label='Verkoopprijs totaal'
                      required={false}
                      disabled
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </FDialog>
        );
      }}
    </Formik>
  );
});
