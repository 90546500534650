import { Button, Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import theme from "../themes/theme";
import { Texts } from "./Constants";


export const getTabContent = (
  onNewButtonClick: (() => void) | undefined,
  component: JSX.Element
) => {
  return (
    <div
      style={{
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      }}
    >
      <Grid container spacing={2}>
        {onNewButtonClick !== undefined && (
          <Grid item xs={12}>
            <Button
              style={{ marginLeft: theme.spacing(2) }}
              variant="outlined"
              color="primary"
              onClick={onNewButtonClick}
            >
              {Texts.newText}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper>{component}</Paper>
        </Grid>
      </Grid>
    </div>
  );
};





interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <>{children}</>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  tabPanels: { label: string; tabContent: JSX.Element }[];
}

export default function TabComponent({ tabPanels }: Props) {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };


  return (
    <>
      {/* <AppBar position="static" color="default"> */}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          style={{marginBottom: theme.spacing(2), backgroundColor: theme.palette.background.paper, paddingLeft: theme.spacing(0), paddingRight: theme.spacing(0)}}
        >
          {tabPanels.map((myTab, index) => (
            <Tab key={`tab_${myTab.label}`} label={myTab.label} {...a11yProps({ index })} />
          ))}
          ;
        </Tabs>
      {/* </AppBar> */}
      {tabPanels.map((myTab, index) => (
        <TabPanel  key={`tabPanel_${myTab.label}`} value={value} index={index}>
          {myTab.tabContent}
        </TabPanel>
      ))}
    </>
  );
}
