import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import {
  Administration,
  AdministrationFormValues,
} from "../../models/administration";
import LoadingComponent from "../../Common/LoadingComponent";
import FDialog from "../../Common/form/FDialog";
import FTextField from "../../Common/form/FTextField";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/rootStore";

const validationSchema = yup.object({
  name: yup.string().min(1).required("vereist"),
  administrationNumber: yup.number().required("vereist"),
  userName: yup.string().required("vereist"),
  password: yup.string().required("vereist"),
});

interface Props {
  open: boolean;
  id: number | undefined;
  onCreated: (administration: Administration) => void;
  onEdited: (administration: Administration) => void;
  onClose: () => void;
}

export default observer(function AdministrationForm({
  open,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {administrationFormStore: {
      administrationFormValues,
      loadAdministrationFormValues,
      create,
      edit,
      isLoading,
  }} = useRootStore();

  useEffect(() => {
    if (id) loadAdministrationFormValues(id);
  }, [id, loadAdministrationFormValues]);

  function handleFormSubmit(administrationFormValues: AdministrationFormValues) {
    if (!id) {
      create(administrationFormValues)
        .then((administration) => administration && onCreated(administration))
        .finally(() => onClose());
    } else {
      edit(administrationFormValues)
        .then((administration) => administration && onEdited(administration))
        .finally(() => onClose());
    }
  }

  if (isLoading)
    return (
      <LoadingComponent content='Administratie formulier laden...' useBackdrop={true} />
    );

  return (
    <Formik
      // enableReinitialize
      initialValues={administrationFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      <FDialog
        title={
          id && id > 0
            ? "Administratie aanpassen"
            : "Nieuwe administratie koppeling"
        }
        open={open}
        onClose={onClose}
        breakPointFullScreen='md'
      >
        <form noValidate autoComplete='off'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FTextField
                name='name'
                label='Naam'
                required
                autoFocus
                autoComplete='off'
              />
            </Grid>

            <Grid item xs={12}>
              <FTextField
                name='administrationNumber'
                label='Administratienummer'
                required
                autoComplete='cc-csc'
              />
            </Grid>

            <Grid item xs={12}>
              <FTextField
                name='userName'
                label='Gebruikersnaam'
                required
                autoComplete='off'
              />
            </Grid>

            <Grid item xs={12}>
              <FTextField
                name='password'
                label='Wachtwoord'
                required
                autoComplete='new-password'
                type='password'
              />
            </Grid>
          </Grid>
        </form>
      </FDialog>
    </Formik>
  );
});
