import React from "react";
import {
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Collapse,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { NavLink, useLocation } from "react-router-dom";
import LoadingComponent from "../../Common/LoadingComponent";
import { observer } from "mobx-react-lite";
import { UserRights } from "../models/progmaUserRights";

import DashboardIcon from "@material-ui/icons/Dashboard";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import { useVStore } from "../stores/vStore";
import BuildIcon from "@material-ui/icons/Build";
import InputIcon from "@material-ui/icons/Input";
import EditIcon from '@material-ui/icons/Edit';
import CategoryIcon from "@material-ui/icons/Category";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

interface Props {
  closeTemporyDrawer: () => void;
}

export default observer(function MainMenu({ closeTemporyDrawer }: Props) {
  const classes = useStyles();
  const location = useLocation();
  const [openInput, setOpenInput] = React.useState(true);
  const [openManage, setOpenManage] = React.useState(
    location !== undefined &&
      location !== null &&
      (location.pathname === "/relations" || location.pathname === "/articles")
      ? true
      : false
  );

  const {
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  const handleClickInput = () => {
    setOpenInput(!openInput);
  };

  if (!progmaUserRights)
    return <LoadingComponent content='Menu laden...' useBackdrop={false} />;

  return (
    <>
      <List>
        <ListItem
          button
          key='dashboard'
          component={NavLink}
          to='/'
          activeClassName='Mui-selected'
          exact
          onClick={closeTemporyDrawer}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>

        {progmaUserRights?.projectsUserRights !== UserRights.None && (
          <ListItem
            button
            key='projects'
            component={NavLink}
            to='/projects'
            activeClassName='Mui-selected'
            exact
            onClick={closeTemporyDrawer}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary='Projecten' />
          </ListItem>
        )}

        {progmaUserRights?.workOrdersUserRights !== UserRights.None && (
          <ListItem
            button
            key='work-orders'
            component={NavLink}
            to='/work-orders'
            activeClassName='Mui-selected'
            exact
            onClick={closeTemporyDrawer}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary='Werkbonnen' />
          </ListItem>
        )}

        {(progmaUserRights?.workAllow ||
          progmaUserRights?.projectArticlesAllow ||
          progmaUserRights.projectFreeUserRights !== UserRights.None ||
          progmaUserRights.projectPurchaseUserRights !== UserRights.None) && (
          <>
            <ListItem button onClick={handleClickInput}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary='Invoeren' />
              {openInput ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openInput} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {progmaUserRights?.workAllow && (
                  <ListItem
                    button
                    key='work'
                    component={NavLink}
                    to='/work'
                    activeClassName='Mui-selected'
                    exact
                    onClick={closeTemporyDrawer}
                    className={classes.nested}
                    //dense
                  >
                    <ListItemIcon>
                      <ScheduleIcon />
                    </ListItemIcon>
                    <ListItemText primary='Uren invullen' />
                  </ListItem>
                )}
                {progmaUserRights?.projectArticlesAllow && (
                  <ListItem
                    button
                    key='project-articles'
                    component={NavLink}
                    to='/project-articles'
                    activeClassName='Mui-selected'
                    exact
                    onClick={closeTemporyDrawer}
                    className={classes.nested}
                    //dense
                  >
                    <ListItemIcon>
                      <BuildIcon />
                    </ListItemIcon>
                    <ListItemText primary='Project artikelen' />
                  </ListItem>
                )}
                {progmaUserRights?.projectPurchaseUserRights !==
                  UserRights.None && (
                  <ListItem
                    button
                    key='project-purchase'
                    component={NavLink}
                    to='/project-purchase'
                    activeClassName='Mui-selected'
                    exact
                    onClick={closeTemporyDrawer}
                    className={classes.nested}
                    //dense
                  >
                    <ListItemIcon>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary='Project inkoop' />
                  </ListItem>
                )}
                {progmaUserRights?.projectFreeUserRights !==
                  UserRights.None && (
                  <ListItem
                    button
                    key='project-free'
                    component={NavLink}
                    to='/project-free'
                    activeClassName='Mui-selected'
                    exact
                    onClick={closeTemporyDrawer}
                    className={classes.nested}
                    //dense
                  >
                    <ListItemIcon>
                      <InputIcon />
                    </ListItemIcon>
                    <ListItemText primary='Project vrije invoer' />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}

        {(progmaUserRights.relationsUserRights !== UserRights.None ||
          progmaUserRights.articlesUserRights !== UserRights.None) && (
          <>
            <ListItem button onClick={() => setOpenManage(!openManage)}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary='Beheren' />
              {openManage ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openManage} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {progmaUserRights.relationsUserRights !== UserRights.None && (
                  <ListItem
                    button
                    key='relations'
                    component={NavLink}
                    to='/relations'
                    activeClassName='Mui-selected'
                    exact
                    onClick={closeTemporyDrawer}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary='Relaties' />
                  </ListItem>
                )}
                {progmaUserRights.articlesUserRights !== UserRights.None && (
                  <ListItem
                    button
                    key='articles'
                    component={NavLink}
                    to='/articles'
                    activeClassName='Mui-selected'
                    exact
                    onClick={closeTemporyDrawer}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText primary='Artikelen' />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}

        {/* {appUser?.choosedAdministration?.progmaUserType === 1 && (
          <ListItem
            button
            key='invoicing'
            component={NavLink}
            to='/invoicing'
            activeClassName='Mui-selected'
            exact
            onClick={closeTemporyDrawer}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary='Facturatie' />
          </ListItem>
        )} */}
      </List>

      {/* <Divider />

      <List>
        <ListItem
          button
          key='administrations'
          component={NavLink}
          to='/administrations'
          exact
          onClick={() => { closeTemporyDrawer(); cleareAllStores() }}
        >
          <ListItemIcon>
            <CloseIcon />
          </ListItemIcon>
          <ListItemText primary='Administratie afsluiten' />
        </ListItem>
      </List> */}
    </>
  );
});
