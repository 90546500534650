import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true
    // mobileS: true,
    // mobileM: true,
    // mobileL: true,
    // tablet: true; // adds the `tablet` breakpoint
    // laptop: true;
    // desktop: true;
  }
}

const theme = createTheme({
  breakpoints: {
    keys: [
      'xs',
      'sm',
      'md',
      'lg',
      'xl',
      'xxl',
      'xxxl'
    ],
    values: {
      xs: 0,
      sm: 375,
      md: 480, 
      lg: 768,
      xl: 1024,
      xxl: 1366,
      xxxl: 1680,
      // mobileS: 0,
      // mobileM: 320,
      // mobileL: 360,
      // tablet: 480,
      // laptop: 768,
      // desktop: 1366,
    },
  },
  palette: {
    primary: {
      main: '#0065B0',
    },
    secondary: {
      main: red[500],
    },
    background: {
      default: "#eeeeee"
    }
  },
});

export default theme;