import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  Theme,
} from "@material-ui/core";
import { useState } from "react";
import theme from "../themes/theme";
import MoreIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moreMenu: {
      padding: theme.spacing(2),
    },
  })
);

interface Props {
  children: any;
}

export default function MoreMenu({ children }: Props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const childrenToUse: JSX.Element[] | undefined = Array.isArray(children) ? children.filter((x) => typeof x === 'object') : undefined;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton size="medium" edge="end" color="inherit" onClick={handleMenu}>
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-more"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        <Box
          className={classes.moreMenu}
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          alignItems="flex-start"
        >
          {Array.isArray(childrenToUse) ? (
            childrenToUse.map((item, index) => (
              <div key={index}>
                {index === 0 ? (
                  <Box>{item}</Box>
                ) : (
                  <Box style={{ marginTop: theme.spacing(2) }}>{item}</Box>
                )}
              </div>
            ))
          ) : (
            <Box>{childrenToUse}</Box>
          )}
        </Box>
      </Menu>
    </Box>
  );
}
