import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tabs,
  Tab,
  Box,
  Typography,
  AppBar,
  useTheme,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { useRootStore } from "../../stores/rootStore";
import { useState } from "react";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      //maxWidth: 800,
      //width: 600,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
          {/* <Typography>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
  };
}

function PageTitle(props: any) {
  return (
    <Typography color='primary' variant='h6' style={{ marginBottom: "16px" }}>
      {props.children}
    </Typography>
  );
}

export default function ProfilePage() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const {appUserStore: { appUser }} = useRootStore();
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue >= 0 && newValue <= 2) {setValue(newValue)};
  };

  const handleChangeIndex = (index: number) => {
    if (index >= 0 && index <= 2) {setValue(index)};
  };

  return (
      <div className={classes.root}>
        <AppBar position='static' color='default'>
          <Tabs
            variant='scrollable'
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab label='Informatie' {...a11yProps(0)} />
            <Tab label='Wachtwoord' {...a11yProps(1)} />
            <Tab label='Account' {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PageTitle>Profiel informatie</PageTitle>
            <Typography>Email: {appUser?.username}</Typography>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <PageTitle>Wachtwoord aanpassen</PageTitle>
            <ChangePassword/>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <PageTitle>Account</PageTitle>
            <Grid item>
              <Typography>
                Als u uw account verwijdert dan worden alle administratiekoppelingen ook permanent verwijderd en dit kan niet worden hersteld.
              </Typography>
              <DeleteAccount/>
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </div>
  );
}
