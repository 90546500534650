import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";

interface Props {
  title: string;
  children: any;
  actions: any;
  icon?: any;
}

export default function DashboardCardItem({
  title,
  children,
  actions,
  icon,
}: Props) {
  const titleComponent = (
    <ToolStrip alignItems="center" justifyContent="flex-start">
      {icon && icon}
      <Typography variant="h6" style={{ paddingBottom: 8 }}>
        {title}
      </Typography>
    </ToolStrip>
  );

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            {titleComponent}
          </Grid>
          <Grid item xs={12}>
          <div
            style={{
              height: theme.spacing(2),
              width: "100%",
            }}
          />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
      {actions && (
        <>
          <Divider /> <CardActions>{actions}</CardActions>
        </>
      )}
    </Card>
  );
}
