import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../Common/LoadingComponent";
import {
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import ProjectForm from "./ProjectForm";
//import { useStore } from "../../stores/vStore";
import { UserRights } from "../../models/progmaUserRights";

import ToolStripAndTable from "../../../Common/ToolStripAndTable";
import { Grouping } from "@devexpress/dx-react-grid";
import ProjectsLoadButton from "./ProjectsLoadButton";
import { useVStore } from "../../stores/vStore";
import ProjectRowDetail from "./ProjectRowDetail";
import ProjectArticleForm from "../ProjectArticles/ProjectArticleForm";
import ProjectPurchaseForm from "../ProjectPurchase/ProjectPurchaseForm";
import ProjectFreeForm from "../ProjectFree/ProjectFreeForm";
import { AppColumn, TableStateValues } from "../../../Common/AppTable";
import AttachmentForm from "../Attachments/AttachmentForm";

const columnNameProjectNumber = "projectNumber";
const columnNameCompanyName = "companyName";
const columnNameProjectName = "projectName";
const columnNameStatus = "status";
const columnNameNameTypeProject = "nameTypeProject";
const columnNameProjectDescription = "projectDescription";


const columns: AppColumn[] = [
  { name: columnNameProjectNumber, title: "Projectnummer", visible: "md"  },
  { name: columnNameCompanyName, title: "Klantnaam", visible: "sm" },
  { name: columnNameProjectName, title: "Projectnaam", visible: "xs" },
  { name: columnNameStatus, title: columnNameStatus, visible: "lg" },
  { name: columnNameNameTypeProject, title: "Naam type", visible: "xl" },
  { name: columnNameProjectDescription, title: "Project omschrijving" },
];

export default observer(function Projects() {
  const {
    progmaUserStore: { progmaUserRights },
    projectsStore: { projects, loadProjects, isLoading, addProject, editProject },
    projectFormStore: { projectForm, openProjectForm, closeProjectForm },
    projectArticlesStore: { addProjectArticle, editProjectArticle },
    projectArticlesFormStore: {
      projectArticleForm,
      closeProjectArticlesForm,
    },
    projectPurchaseStore: { addProjectPurchase, editProjectPurchase },
    projectPurchaseFormStore: {
      projectPurchaseForm,
      closeProjectPurchaseForm,
    },
    projectFreeStore: { addProjectFree, editProjectFree },
    projectFreeFormStore: {
      projectFreeForm,
      closeProjectFreeForm,
    },
    attachmentsStore: {editAttachment},
    attachmentFormStore: {attachmentForm, closeAttachmentForm}
  } = useVStore();

  useEffect(() => {
    if (!projects) loadProjects(false);
  }, [projects, loadProjects]);

  const [groupingCompanyName, setgroupingCompanyName] = useState(false);

  const [tableStateValues, setTableStateValues] = useState(() =>
    new TableStateValues(
      columns,
      [{ columnName: columnNameProjectNumber, direction: "desc" }],
    //   [
    //     columnNameProjectNumber,
    //     columnNameCompanyName,
    //     columnNameProjectName,
    //     columnNameStatus,
    //     columnNameNameTypeProject,
    //     columnNameProjectDescription,
    //   ],
    //   [
    //     columnNameProjectName,
    //   ], [
    //   columnNameCompanyName,
    //   columnNameProjectName,
    // ], [
    //   columnNameProjectNumber,
    //   columnNameCompanyName,
    //   columnNameProjectName,
    // ], [
    //   columnNameProjectNumber,
    //   columnNameCompanyName,
    //   columnNameProjectName,
    // ], [
    //   columnNameProjectNumber,
    //   columnNameCompanyName,
    //   columnNameProjectName,
    //   columnNameStatus,
    //   columnNameNameTypeProject,
    // ]
    )
  );

  const handleGroupingCompanyName = (value: boolean) => {
    let temp = tableStateValues.grouping.slice();
    const groupCompanyName: Grouping = { columnName: columnNameCompanyName };

    if (value) {
      if (
        tableStateValues.grouping.filter((x) => x.columnName === columnNameCompanyName)
          .length === 0
      ) {
        temp.push(groupCompanyName);
      }
    } else {
      temp = tableStateValues.grouping.filter(
        (x) => x.columnName !== columnNameCompanyName
      );
    }

    setgroupingCompanyName(value);

    tableStateValues.grouping = temp;
    setTableStateValues({ ...tableStateValues });
  };

  if (tableStateValues.useGroupPanel) {
    if (
      groupingCompanyName &&
      tableStateValues.grouping.filter((x) => x.columnName === columnNameCompanyName)
        .length === 0
    ) {
      setgroupingCompanyName(false);
    } else if (
      !groupingCompanyName &&
      tableStateValues.grouping.filter((x) => x.columnName === columnNameCompanyName)
        .length > 0
    ) {
      setgroupingCompanyName(true);
    }
  }

  if (isLoading)
    return <LoadingComponent useBackdrop={false} />;

  return (
    <>
      <ToolStripAndTable
        tableName='Projecten'
        columns={columns}
        rows={projects ? projects : []}
        rowDetail={ProjectRowDetail}
        onNew={progmaUserRights?.projectsUserRights === UserRights.Editing ? () => openProjectForm(undefined) : undefined}
        onReload={undefined}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues}
        tools={[
          <ProjectsLoadButton />,
          <FormControlLabel
            control={
              <Switch
                size='medium'
                checked={groupingCompanyName}
                onChange={(e) => handleGroupingCompanyName(e.target.checked)}
                name='checkedA'
              />
            }
            label={
              <Typography style={{ fontSize: "0.95rem" }}>
                Groepeer op klant
              </Typography>
            }
          />,
        ]}
        limitMoreMenu={1150}
      />

      {progmaUserRights?.projectsUserRights === UserRights.Editing && (
        <>
          {projectForm.open && (
            <ProjectForm
              open={projectForm.open}
              id={projectForm.id}
              onCreated = {addProject}
              onEdited = {editProject}
              onClose={closeProjectForm}
            />
          )}
        </>
      )}

      
{projectArticleForm.open && (
        <ProjectArticleForm
          open={projectArticleForm.open}
          id={projectArticleForm.id}
          // projectsAndSubjectsId={projectAndSubjectId}
          onCreated={addProjectArticle}
          onEdited={editProjectArticle}
          onClose={closeProjectArticlesForm}
        />
      )}

      {projectPurchaseForm.open && (
        <ProjectPurchaseForm
          open={projectPurchaseForm.open}
          id={projectPurchaseForm.id}
          // projectsAndSubjectsId={projectAndSubjectId}
          onCreated={addProjectPurchase}
          onEdited={editProjectPurchase}
          onClose={closeProjectPurchaseForm}
        />
      )}

      {projectFreeForm.open && (
        <ProjectFreeForm
          open={projectFreeForm.open}
          id={projectFreeForm.id}
          // projectsAndSubjectsId={projectAndSubjectId}
          onCreated={addProjectFree}
          onEdited={editProjectFree}
          onClose={closeProjectFreeForm}
        />
      )}

      {attachmentForm.open && (
        <AttachmentForm
          open={attachmentForm.open}
          id={attachmentForm.id}
          onEdited={editAttachment}
          onClose={closeAttachmentForm}
        />
      )}
    </>
  );
});
