import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Messages, Texts } from "./Constants";
import SendIcon from '@material-ui/icons/Send';

export const RowDetailButton = ({
  text,
  icon,
  onClick,
}: {
  text: string;
  icon: JSX.Element;
  onClick: () => void;
}) => {
  return (
    <Button
      fullWidth
      variant="outlined"
      size="medium"
      onClick={onClick}
      startIcon={icon}
      color="primary"
    >
      {text}
    </Button>
  );
};

export const RowDetailButtonEdit = ({ onClick }: { onClick: () => void }) => {
  return (
    <RowDetailButton
      text={Texts.editText}
      icon={<EditIcon />}
      onClick={onClick}
    />
  );
};

export const RowDetailButtonDelete = ({
  onDeleteConfirmed,
}: {
  onDeleteConfirmed: () => void;
}) => {
  return (
    <RowDetailButton
      text={Texts.deleteText}
      icon={<DeleteIcon />}
      onClick={() =>
        window.confirm(Messages.deleteConfirmationMessage) &&
        onDeleteConfirmed()
      }
    />
  );
};

export const RowDetailButtonSend = ({
  onClick,
  isAlreadySended,
}: {
  onClick: () => void;
  isAlreadySended: boolean;
}) => {
  return (
    <RowDetailButton
      text={Texts.sendText}
      icon={<SendIcon />}
      onClick={() =>
        isAlreadySended
          ? window.confirm("Is al verstuurd. Doorgaan?") && onClick()
          : onClick()
      }
    />
  );
};
