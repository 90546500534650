import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../Common/LoadingComponent";
import { useEffect, useState } from "react";
import WorkOrderForm from "./WorkOrderForm";
import { UserRights } from "../../models/progmaUserRights";

import ToolStripAndTable from "../../../Common/ToolStripAndTable";
import { Grouping } from "@devexpress/dx-react-grid";
import { useVStore } from "../../stores/vStore";
import WorkOrderRowDetail from "./WorkOrderRowDetail";
import ReloadActualAndAllButton from "../../../Common/ReloadActualAndAllButton"
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import { AppColumn, ColumnType, TableStateValues } from "../../../Common/AppTable";

const columnNameWorkOrderNumber = "workOrderNumber";
const columnNameCompanyName = "companyName";
const columnNameProjectName = "projectName";
const columnNameContactPerson = "contactPerson";
const columnNameSigned = "signed";
const columnNameSended = "sended";

const columns: AppColumn[] = [
  { name: columnNameWorkOrderNumber, title: "WB. nummer", visible: "xs" },
  { name: columnNameCompanyName, title: "Klantnaam", visible: "xs" },
  { name: columnNameProjectName, title: "Projectnaam", visible: "md"  },
  { name: columnNameContactPerson, title: "Contactpersoon", visible: "xl" },
  { name: columnNameSigned, title: "Afgetekend", visible: "lg", columnType: ColumnType.boolean},
  { name: columnNameSended, title: "Verzonden", visible: "lg", columnType: ColumnType.boolean},
]

export default observer(function WorkOrders() {
  const {
    progmaUserStore: { progmaUserRights },
    workOrdersStore: { workOrders, loadWorkOrders, isLoading, addWorkOrder, editWorkOrder },
    workOrderFormStore: { workOrderForm, openWorkOrderForm, closeWorkOrderForm },
  } = useVStore();

  useEffect(() => {
    if (!workOrders) loadWorkOrders(false);
  }, [workOrders, loadWorkOrders]);

  const [groupingCompanyName, setgroupingCompanyName] = useState(false);

  const [tableStateValues, setTableStateValues] = useState(() =>
    new TableStateValues(
      columns,
      [{ columnName: columnNameWorkOrderNumber, direction: "desc" }],
    )
  );

  const handleGroupingCompanyName = (value: boolean) => {
    let temp = tableStateValues.grouping.slice();
    const groupCompanyName: Grouping = { columnName: columnNameCompanyName };

    if (value) {
      if (
        tableStateValues.grouping.filter((x) => x.columnName === columnNameCompanyName)
          .length === 0
      ) {
        temp.push(groupCompanyName);
      }
    } else {
      temp = tableStateValues.grouping.filter(
        (x) => x.columnName !== columnNameCompanyName
      );
    }

    setgroupingCompanyName(value);

    tableStateValues.grouping = temp;
    setTableStateValues({ ...tableStateValues });
  };

  if (tableStateValues.useGroupPanel) {
    if (
      groupingCompanyName &&
      tableStateValues.grouping.filter((x) => x.columnName === columnNameCompanyName)
        .length === 0
    ) {
      setgroupingCompanyName(false);
    } else if (
      !groupingCompanyName &&
      tableStateValues.grouping.filter((x) => x.columnName === columnNameCompanyName)
        .length > 0
    ) {
      setgroupingCompanyName(true);
    }
  }

  if (isLoading)
    return <LoadingComponent useBackdrop={false} />;

  return (
    <>
      <ToolStripAndTable
        tableName='Werkbonnen'
        columns={columns}
        rows={workOrders ? workOrders : []}
        rowDetail={WorkOrderRowDetail}
        onNew={progmaUserRights?.workOrdersUserRights === UserRights.Editing ? () => openWorkOrderForm(undefined) : undefined}
        onReload={undefined}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues}
        tools={[
        <ReloadActualAndAllButton onReloadActual={() => {loadWorkOrders(false)}} onReloadAll={() => {loadWorkOrders(true)}}/>,
        <FormControlLabel
        control={
          <Switch
            size='medium'
            checked={groupingCompanyName}
            onChange={(e) => handleGroupingCompanyName(e.target.checked)}
            name='checkedA'
          />
        }
        label={
          <Typography style={{ fontSize: "0.95rem" }}>
            Groepeer op klant
          </Typography>
        }
      />
      ]}
        limitMoreMenu={1150} 
      />

      {progmaUserRights?.workOrdersUserRights === UserRights.Editing && (
        <>
          {workOrderForm.open && (
            <WorkOrderForm
              open={workOrderForm.open}
              id={workOrderForm.id}
              onCreated = {addWorkOrder}
              onEdited = {editWorkOrder}
              onClose={closeWorkOrderForm}
            />
          )}
        </>
      )}
    </>
  );
});
