import { FileExtensions, ImgExtensions } from "../Constants";
import PdfViewer from "./PdfViewer";
//import FileComponent from "../FileComponent";
import ImgViewer from "./ImgViewer";
import { Grid, Typography } from "@material-ui/core";
import FilePreviewToolbar from "./FilePreviewToolbar";

export const PreviewableExtensions = [FileExtensions.pdf, ...ImgExtensions];

interface Props {
  filename: string;
  extension: string;
  url?: string;
  onDownloadClick?: () => void;
}


export default function FilePreview({
  filename,
  extension,
  url,
  onDownloadClick,
}: Props) {
  if (url !== undefined) {
    if (extension === FileExtensions.pdf)
      return <PdfViewer url={url} fileName={filename} />;

    if (ImgExtensions.includes(extension.toLowerCase()))
      return <ImgViewer fileName={filename} url={url} />;

    return <Typography>Kan geen voorbeeld tonen van {filename}</Typography>;
  }

  return (
    <div style={{maxWidth: 260}}>
    <Grid container>
      <Grid item xs={12}>
        <FilePreviewToolbar title={filename} onDownloadClick={onDownloadClick}/>
      </Grid>
      <Grid item xs={12}>
        <div style={{border: "1px solid black", padding: 40}}>
        <Typography color="error">Voorbeeld niet mogelijk</Typography>
        </div>
      </Grid>
    </Grid>
    </div>
  )


  //return <FileComponent id={id} fileName={fileName} onDownloadClick={onDownloadClick} />

}
