import {
  Grid,
} from "@material-ui/core";
import * as yup from "yup";
import { Formik } from "formik";
import LoadingComponent from "../../../Common/LoadingComponent";
import FDialog from "../../../Common/form/FDialog";
import { observer } from "mobx-react-lite";
import FTextfield from "../../../Common/form/FTextField";
import { useVStore } from "../../stores/vStore";
import { useEffect } from "react";
import { ArticleDTO, ArticleFormValues } from "../../models/article";
import FCheckbox from "../../../Common/form/FCheckbox";

interface Props {
  open: boolean;
  id: number | undefined;
  onCreated: (article: ArticleDTO) => void;
  onEdited: (article: ArticleDTO) => void;
  onClose: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required("verplicht veld"),
});

export default observer(function ArticleForm({
  open,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {
    articleFormStore: { load, isLoading, articleFormValues, create, edit },
  } = useVStore();

  useEffect(() => {
    if (id) load(id);
  }, [id, load]);

  function handleFormSubmit(articleFormValues: ArticleFormValues) {
    if (!id) {
      create(articleFormValues)
        .then((article) => article && onCreated(article))
        .finally(() => onClose());
    } else {
      edit(articleFormValues)
        .then((article) => article && onEdited(article))
        .finally(() => onClose());
    }
  }

  if (isLoading)
    return (
      <LoadingComponent
        content='Artikel formulier laden...'
        useBackdrop={true}
      />
    );

  return (
    <Formik
      enableReinitialize
      initialValues={articleFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <FDialog
            title={id && id > 0 ? "Artikel aanpassen" : "Nieuw Artikel"}
            open={open}
            onClose={onClose}
            breakPointFullScreen='md'
          >
            <form noValidate autoComplete='off'>
              <Grid container spacing={3}>
              <Grid item xs={12}>
                  <FTextfield
                    name='name'
                    label='Naam'
                    required
                    autoComplete='off'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='type'
                    label='Type'
                    required
                    autoComplete='off'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FCheckbox
                    name='inactive'
                    label='Inactief'
                    required
                  />
                </Grid>
              </Grid>
            </form>
          </FDialog>
        );
      }}
    </Formik>
  );
});
