import { Relation } from "./relation";
import { SettingsProjectType } from "./settingsProjectType";

export interface ProjectAndSubject {
  id: number;
  projectNumber: string;
  companyName: string;
  projectName: string;
  subjectDescription: string;
  status: Status;
  displayLine: string;
}

export const getProjectAndSubjectDisplayLine = (
  projectAndSubject: ProjectAndSubject
) => {
  return (
    projectAndSubject.companyName +
    " | " +
    projectAndSubject.projectName +
    (projectAndSubject.subjectDescription
      ? " | " + projectAndSubject.subjectDescription
      : "") +
    " (" +
    projectAndSubject.projectNumber +
    ")"
  );
};

export interface Project {
  id: number;
  projectNumber: string;
  status: string;
  kindTypeProject: string;
  nameTypeProject: string;
  companyName: string;
  projectName: string;
  projectDescription: string;
  subjectDescription: string;
  projectAndSubjectID: number;
}

export const getProjectDisplayLine = (
  project: Project
) => {
  return (
    project.companyName + " | " + project.projectName  + " (" + project.projectNumber + ")"
  );
};

export interface ProjectFormValues {
  id: number;
  status: number | undefined;
  typeProjectID: string | undefined;
  clientID: number | undefined;
  projectName: string;
  projectDescription: string | undefined;
}

export interface ProjectGeneralData {
  settingsProjectTypes: SettingsProjectType[];
  clients: Relation[];
}

export enum Status {
  Aanvraag,
  Offerte_gestuurd,
  Opdracht,
  Opdrachtbevestiging_gestuurd,
  Gereed,
  Factureerbaar,
  Afgerond,
  Vervallen,
  Onbekend,
}
