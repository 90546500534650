import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { Administration } from "../../models/administration";
import { useRootStore } from "../../stores/rootStore";
import ToolStripSpaceBetween from "../../Common/ToolStripSpaceBetween";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

interface Props {
  administration: Administration;
}

export default observer(function AdministrationButton({
  administration,
}: Props) {
  const {
    administrationsStore: { chooseAdministration },
  } = useRootStore();

  const buttonText = administration.name + " (" + administration.userName + ")";

  // const primaryToolStrip = (
  //   <Box display='flex' flexDirection='column'>
  //     <Box>
  //       {currentId && administration.id === currentId ? (
  //         <Typography variant='h6' color='primary' gutterBottom noWrap>
  //           {buttonText}
  //         </Typography>
  //       ) : (
  //         <Typography variant='h6' color='textPrimary' gutterBottom noWrap>
  //           {buttonText}
  //         </Typography>
  //       )}
  //     </Box>
  //   </Box>
  // );

  return (
    <Button
      style={{ height: 75 }}
      key={administration.id}
      variant='outlined'
      color='primary'
      size='large'
      fullWidth
      onClick={() => chooseAdministration(administration.id)}
    >
      <ToolStripSpaceBetween
        primaryToolStrip={<>{buttonText}</>}
        secondaryToolStrip={
          <ArrowForwardIcon style={{ width: 24, height: 24 }} />
        }
      />
    </Button>
  );
});
