import { FormControlLabel, Checkbox } from "@material-ui/core";

interface Props {
  name: string;
  label: string;
  required: boolean;
  size?: "small" | "medium";
  disabled?: boolean | undefined;
  checked: boolean;
  onChange?: ((checked: boolean) => void) | undefined;
}

export default function CCheckbox(props: Props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={props.name}
          required={props.required}
          size={props.size}
          disabled={props.disabled}
          checked={props.checked}
          onChange={(_event, checked) => {
            if (props.onChange !== undefined) props.onChange(checked);
          }}
        />
      }
      label={props.label}
    />
  );
}
