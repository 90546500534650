import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik } from "formik";
import ValidationErrors from "../../Common/form/ValidationErrors";
import { Grid, Link } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { useState } from "react";
import FTextfield from "../../Common/form/FTextField";
import FSubmitButton from "../../Common/form/FSubmitButton";
import Layout from "./Layout";
import { useRootStore } from "../../stores/rootStore";

interface RouteMatchProps {
  id: string;
  token: string;
}

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Wachtwoord is vereist")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Moet 8 karakters bevatten, Één hoofdletter, Één kleine letter, Één nummer en één speciaal karakter"
    ),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Wachtwoorden moeten overeenkomen")
    .required("Wachtwoordbevestiging is vereist"),
});

export default function ResetPassword({
  match,
}: RouteComponentProps<RouteMatchProps>) {
  const {
    appUserStore: { resetPassword },
  } = useRootStore();
  const theme = useTheme();
  const [result, setResult] = useState(false);

  const id = match.params.id;
  const token = match.params.token;

  const loginUrl = window.location.href.split("reset-password")[0] + "/login";

  return (
    <Layout title={"Wachtwoord resetten"}>
      <Formik
        initialValues={{
          id: id,
          token: token,
          password: "",
          passwordConfirmation: "",
          error: null,
        }}
        onSubmit={(values, { setErrors, setSubmitting }) =>
          resetPassword(values)
            .then((result) => result && setResult(result))
            .catch((error) => {setErrors({ error: error }); setSubmitting(false)})
        }
        validationSchema={validationSchema}
      >
        {({ errors }) =>
          !result ? (
            <form noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FTextfield
                    name='password'
                    label='Wachtwoord'
                    required
                    autoFocus
                    type='password'
                    autoComplete='current-password'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='passwordConfirmation'
                    label='Wachtwoord bevestigen'
                    required
                    type='password'
                    autoComplete='current-passwordConfirmation'
                  />
                </Grid>

                {errors.error && (
                  <Grid item xs={12}>
                    <ValidationErrors errors={errors?.error} />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FSubmitButton size={"large"}>Versturen</FSubmitButton>
                </Grid>
              </Grid>
            </form>
          ) : (
            <>
              <Typography>Resetten gelukt!</Typography>
              <div style={{ marginTop: theme.spacing(2) }}>
                <Grid container>
                  <Grid item>
                    <Link href={loginUrl} variant='body2'>
                      Terug naar inloggen
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </>
          )
        }
      </Formik>
    </Layout>
  );
}
