import { UserRightsFinancial } from "./progmaUserRights";
export interface Attachment {
  id: number;
  filePath: string;
  name: string;
  extension: string;
  size: number;
  fileName: string;
  //dbFileID : number;
}

export interface AttachmentFileUpload {
  formFile: File;
  general?: boolean;
  activityID?: number;
  articleID? : number;
  invoiceID? : number;
  projectAndSubjectID? : number;
  relationID?  : number;
  staffMemberID?  : number;
  objectID?  : number;
}

export interface AttachmentFormValues {
  id: number;
  filePath: string;
  name: string;
  nameEnglish: string;
  description: string;
  descriptionEnglish: string;
  onlyAdmin: boolean;
  userRightsFinancial: UserRightsFinancial;
  general: boolean;
  activityID: number | undefined;
  articleID: number | undefined;
  invoiceID: number | undefined;
  projectAndSubjectID: number | undefined;
  relationID: number | undefined;
  staffMemberID: number | undefined;
  objectID: number | undefined;
}

export function getFormData(attachmentFileUpload: AttachmentFileUpload) {
  const formData = new FormData();

  formData.append("formFile", attachmentFileUpload.formFile, attachmentFileUpload.formFile.name);
  if (attachmentFileUpload.activityID !== undefined)
    formData.append("activityID", attachmentFileUpload.activityID.toString());
  if (attachmentFileUpload.articleID !== undefined)
    formData.append("articleID", attachmentFileUpload.articleID.toString());
  if (attachmentFileUpload.invoiceID !== undefined)
    formData.append("invoiceID", attachmentFileUpload.invoiceID.toString());
  if (attachmentFileUpload.projectAndSubjectID !== undefined)
    formData.append("projectAndSubjectID", attachmentFileUpload.projectAndSubjectID.toString());
  if (attachmentFileUpload.relationID !== undefined)
    formData.append("relationID", attachmentFileUpload.relationID.toString());
  if (attachmentFileUpload.staffMemberID !== undefined)
    formData.append("staffMemberID", attachmentFileUpload.staffMemberID.toString());
  if (attachmentFileUpload.objectID !== undefined) formData.append("objectID", attachmentFileUpload.objectID.toString());

  return formData;
}
