import { ProjectArticleFormValues } from "./../models/projectArticles";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { ArticleDTO, ArticleSDTO } from "../models/article";
import { FormManager } from "../../Common/Objects";

const initialValues: {
  isLoading: boolean;
  loadNeeded: boolean;
  articles?: ArticleSDTO[];
  inactiveArticle?: ArticleSDTO;
  projectArticleFormValues: ProjectArticleFormValues;
} = {
  isLoading: false,
  loadNeeded: true,
  articles: undefined,
  inactiveArticle: undefined,
  projectArticleFormValues: {
    id: 0,
    articleID: undefined,
    quantity: 1,
    comments: "",
    projectsAndSubjectsID: 0,
  },
};

export default class ProjectArticleFormStore {
  private formManager: FormManager;
  
  isLoading = initialValues.isLoading;
  loadNeeded = initialValues.loadNeeded;
  articles = initialValues.articles;
  inactiveArticle = initialValues.inactiveArticle;
  projectArticleFormValues = initialValues.projectArticleFormValues;
  projectArticleForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.projectArticleForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.loadNeeded = initialValues.loadNeeded;
    this.articles = initialValues.articles;
    this.inactiveArticle = initialValues.inactiveArticle;
    this.projectArticleFormValues = initialValues.projectArticleFormValues;
    this.projectArticleForm = this.formManager.closeForm();
  };

  resetValues = () => {
    runInAction(() => {
      this.isLoading = initialValues.isLoading;
      this.inactiveArticle = initialValues.inactiveArticle;
      this.projectArticleFormValues = initialValues.projectArticleFormValues;
      this.projectArticleForm = this.formManager.closeForm();
    });
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (id?: number) => {
    try {
      this.setIsLoading(true);
      let myArticles = this.articles;
      let inactiveArticle = initialValues.inactiveArticle;
      if (!this.articles) {
        myArticles = await vAgent.ProjectArticles.loadArticles();
      }

      let formValues: ProjectArticleFormValues | undefined =
        this.projectArticleFormValues;
      if (id) {
        formValues = await vAgent.ProjectArticles.loadFormValues(id);
        if (formValues?.articleID &&
          (!myArticles ||
          myArticles.filter((x) => x.id === formValues?.articleID)
            .length === 0
        )) {
          inactiveArticle = await vAgent.ProjectArticles.loadArticle(formValues?.articleID);
        }
      }

      runInAction(() => {
        this.articles = myArticles ? myArticles : [];
        this.inactiveArticle = inactiveArticle;
        this.projectArticleFormValues = formValues
          ? formValues
          : initialValues.projectArticleFormValues;
        this.loadNeeded = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  create = async (projectArticleFormValues: ProjectArticleFormValues) => {
    try {
        return await vAgent.ProjectArticles.create(projectArticleFormValues);
    } catch (error) {
      console.log(error);
    }
  };

  edit = async (projectArticleFormValues: ProjectArticleFormValues) => {
    try {
        return await vAgent.ProjectArticles.edit(projectArticleFormValues);
    } catch (error) {
      console.log(error);
    }
  };

  openProjectArticlesForm = (projectAndSubjectsId: number, id?: number,) => {
    runInAction(() => {
      this.projectArticleForm = this.formManager.openForm(id);
      this.projectArticleFormValues.projectsAndSubjectsID = projectAndSubjectsId;
    });
  };

  closeProjectArticlesForm = () => {
    runInAction(() => {
      this.projectArticleForm = this.formManager.closeForm();
    });
  };
  
  addArticle = async (article: ArticleDTO) => {
    try {
      if (article) {
        const articles = this.articles ? this.articles : [];
        articles.unshift({id:article.id, articleNumber:article.articleNumber, name: article.name});
        runInAction(() => {
          this.articles = articles;
        });

        return article.id;
      }
    } catch (error) {
      console.log(error);
    }
  };
}
