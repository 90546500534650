import { TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { useField, useFormikContext } from "formik";
import { Texts } from "../Constants";
import { createTextValueFromTime, createTimeFromTextValue } from "../Functions";

interface Props {
  name: string;
  label: string;
  required: boolean;
  format?: string;
  showTodayButton?: boolean;
  size?: "small" | "medium" | undefined;
  open?: boolean;
  onClose?: () => void;
  valueAsString?: boolean;
  onValueChanged?: (value: MaterialUiPickersDate | string | undefined) => void;
}

export default function FTimePicker(props: Props) {
  const [field, mata] = useField(props.name);
  const { setFieldValue } = useFormikContext();
  const { valueAsString, onValueChanged, ...myProps } = props;

  const configTimePicker: any = {
    ...field,
    ...myProps,
  };

  if (mata && mata.touched && mata.error) {
    configTimePicker.error = true;
    configTimePicker.helperText = mata.error;
  }

  const handleChange = (value: MaterialUiPickersDate) => {
    const valueToUse = value
      ? valueAsString
        ? createTextValueFromTime(value)
        : value
      : undefined;
    setFieldValue(props.name, valueToUse);
    if (onValueChanged) onValueChanged(valueToUse);
  };

  const getValue = () => {
    if (field.value && valueAsString)
      return createTimeFromTextValue(field.value);
    return field.value;
  };

  return (
    <TimePicker
      ampm={false}
      clearable={!props.required}
      fullWidth
      inputVariant="outlined"
      size="medium"
      format="HH:mm"
      showTodayButton
      todayLabel={Texts.nowText}
      okLabel={Texts.chooseText}
      cancelLabel={Texts.cancelText}
      clearLabel={Texts.clearText}
      {...configTimePicker}
      value={getValue()}
      onChange={handleChange}
    />
  );
}
