import { Grid, Typography } from "@material-ui/core";
import DetailCard, { DetailCardItem } from "../../../Common/DetailCard";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import SmartGridItem from "../../../Common/SmartGridItem";
import TabComponent from "../../../Common/TabComponent";
import { CellRowPanel } from "../../../Common/TableComponents";
import { observer } from "mobx-react-lite";
import { UserRights } from "../../models/progmaUserRights";
import ToolStrip from "../../../Common/ToolStrip";
import {
  RowDetailButtonDelete,
  RowDetailButton,
} from "../../../Common/RowDetailButtons";
import EditIcon from "@material-ui/icons/Edit";
import FilePreview, {
  PreviewableExtensions,
} from "../../../Common/filepreview/FilePreview";
import { useEffect } from "react";
import LoadingComponent from "../../../Common/LoadingComponent";

export default observer(function AttachmentRowDetail({ row }: any) {
  const {
    attachmentsStore: {
      attachments,
      downloadAttachment,
      getFilePreview,
      filePreview,
      closeFilePreview,
      deleteAttachment,
      isLoadingFilePreview
    },
    attachmentFormStore: { openAttachmentForm },
    progmaUserStore: { progmaUserRights },
  } = useVStore();
  const id: number = row.id;
  const attachment = attachments!.find((x) => x.id === id);

  useEffect(() => {
    //closeFilePreview();
    if (
      id &&
      attachment &&
      (filePreview === undefined || filePreview?.id !== id)
    )
      if (PreviewableExtensions.includes(attachment?.extension.toLowerCase())) {
        getFilePreview(id);
      } else {
        closeFilePreview();
      }
  }, [id, attachment, filePreview, getFilePreview, closeFilePreview]);

  if (!attachment)
    return <Typography color='error'>Kan bijlagen niet vinden</Typography>;

  let detailCardItems: DetailCardItem[] = [
    { title: "Pad", value: attachment.filePath },
    { title: "Naam", value: attachment.name },
    { title: "Extensie", value: attachment.extension },
    { title: "Grootte (kB)", value: attachment.size },
  ];

  const tabContentGeneral = (
    <CellRowPanel>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <SmartGridItem>
          <DetailCard detailCardItems={detailCardItems} />
        </SmartGridItem>

        {progmaUserRights?.attachmentsUserRights === UserRights.Editing && (
          <SmartGridItem>
            <ToolStrip
              flexDirection='column'
              alignItems='start'
              spacing={0.5}
              fullwidth
            >
              <RowDetailButton
                text='Eigenschappen'
                icon={<EditIcon />}
                onClick={() => {openAttachmentForm(id)}}
              />
              <RowDetailButtonDelete
                onDeleteConfirmed={() => deleteAttachment(id)}
              />
            </ToolStrip>
          </SmartGridItem>
        )}

        <Grid item xs={12} >
        {isLoadingFilePreview ? (
            <LoadingComponent useBackdrop={false} />
          ) : (
          <FilePreview
            filename={attachment.fileName}
            extension={attachment.extension}
            onDownloadClick={() => downloadAttachment(id)}
            url={filePreview?.url}
          />
          )}
        </Grid>
      </Grid>
    </CellRowPanel>
  );

  const myTabs: { label: string; tabContent: JSX.Element }[] = [];
  myTabs.push({ label: "Algemeen", tabContent: tabContentGeneral });

  const content =
    myTabs.length > 1 ? <TabComponent tabPanels={myTabs} /> : tabContentGeneral;

  return content;
});
