import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import FCancelButton from "./FCancelButton";
import FSubmitButton from "./FSubmitButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogActions: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

interface Props {
  title: string;
  open: boolean;
  children: any;
  onClose: () => void;
  breakPointFullScreen: number | Breakpoint;
  buttonText?: string;
  useButtons?: boolean;
  IgnoreDirtyForSubmitButton?: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
}

export default function FDialog({
  title,
  open,
  children,
  onClose,
  breakPointFullScreen,
  buttonText,
  useButtons,
  IgnoreDirtyForSubmitButton,
  maxWidth
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(
    theme.breakpoints.down(breakPointFullScreen)
  );

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
      maxWidth={maxWidth}
    >
      <DialogTitle style={{ padding: "16px 16px 8px 24px", margin: "0px" }}>
        <Typography color="primary">{title}</Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {(useButtons === undefined || useButtons) && (
        <DialogActions className={classes.dialogActions}>
          <FCancelButton onClick={onClose} />
          <FSubmitButton IgnoreDirty={IgnoreDirtyForSubmitButton} buttonText={buttonText} />
        </DialogActions>
      )}
    </Dialog>
  );
}
