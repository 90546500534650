import {
  Button,
  ButtonProps,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useState } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExportFormats } from "./Functions";

interface CommonProps {
  onExportClick: (exportFormat: ExportFormats) => void;
}

type Props = CommonProps & ButtonProps;

export default function TableExportButton(props: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { onExportClick, ...buttonProps } = props;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (exportFormat: ExportFormats) => {
    onExportClick(exportFormat);
    handleClose();
  }

  return (
    <>
    {/* <IconButton onClick={handleClick}>
      <SaveIcon />
    </IconButton> */}
      <Button
        variant="outlined"
        size="medium"
        {...buttonProps}
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        Exporteren
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick(ExportFormats.xlsx)}>Excel (xlsx)</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(ExportFormats.csv)}>Csv bestand</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(ExportFormats.pdf)}>PDF bestand</MenuItem>
      </Menu>
    </>
  );
}
