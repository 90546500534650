import { Container, Paper, Typography, useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import LoadingComponent from "../../../Common/LoadingComponent";
import PageAndToolStrip from "../../../Common/PageAndToolStrip";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import ProjectPurchaseForm from "./ProjectPurchaseForm";
import { UserRights } from "../../models/progmaUserRights";
import {
  getProjectAndSubjectDisplayLine,
  Status,
} from "../../models/projectAndSubject";
import ProjectForm from "../Projects/ProjectForm";
import CProjectsAndSubjectsSDTOSelect from "../../../Common/controls/CProjectsAndSubjectsSDTOSelect";
import CCheckbox from "../../../Common/controls/CCheckBox";
import ProjectPurchaseTable from "./ProjectPurchaseTable";

export default observer(function ProjectPurchase() {
  const {
    projectPurchaseStore: {
      loadProjectsAndSubjects,
      projectsAndSubjects,
      isLoading,
      isLoadingProjectPurchase,
      loadProjectsPurchase,
      projectAndSubjectId,
      addProjectPurchase,
      editProjectPurchase,
      addProject,
      setProjectStatus,
      isLoadingProjectStatus,
      allProjectsAndSubjects,
      setAllProjectsAndSubjects,
      isLoadingProjectsAndSubjects
    },
    projectPurchaseFormStore: {
      projectPurchaseForm,
      openProjectPurchaseForm,
      closeProjectPurchaseForm,
    },
    progmaUserStore: { progmaUserRights },
    projectFormStore: { projectForm, openProjectForm, closeProjectForm },
  } = useVStore();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!projectsAndSubjects) loadProjectsAndSubjects();
  }, [projectsAndSubjects, loadProjectsAndSubjects]);

  const mySelectControl = (
    <div style={{ width: !matches ? 448 : 288 }}>
      <CProjectsAndSubjectsSDTOSelect
        name='projectsAndSubjectsID'
        label='Kies project'
        value={projectAndSubjectId}
        required
        useSearchField
        onChange={(id) => typeof id === "number" && loadProjectsPurchase(id)}
        items={projectsAndSubjects?.map((x) => ({
          id: x.id,
          text: getProjectAndSubjectDisplayLine(x),
          sourceObject: x,
        }))}
        allProjectsAndSubjects={allProjectsAndSubjects}
        onAllProjectsAndSubjectsChanged={setAllProjectsAndSubjects}
        isLoadingProjectsAndSubjects={isLoadingProjectsAndSubjects}
        onAddButtonClick={
          progmaUserRights?.projectsUserRights === UserRights.Editing
            ? () => openProjectForm()
            : undefined
        }
      ></CProjectsAndSubjectsSDTOSelect>
    </div>
  );

  const projectAndSubjectChoosed =
    projectAndSubjectId !== undefined
      ? projectsAndSubjects?.filter((x) => x.id === projectAndSubjectId)[0]
      : undefined;

  const pageContent = isLoadingProjectPurchase ? (
    <LoadingComponent useBackdrop={false} />
  ) : (
    projectAndSubjectChoosed !== undefined && (
      <div style={{ marginTop: theme.spacing(1), minWidth: 288 }}>
        <Paper
          style={{ marginBottom: theme.spacing(2), padding: theme.spacing(2) }}
        >
          <Typography>Project status:</Typography>
          {isLoadingProjectStatus ? (
            <LoadingComponent useBackdrop={false} />
          ) : (
            <>
              <CCheckbox
                label='Opdracht'
                name='statusOrder'
                required={false}
                checked={
                  projectAndSubjectChoosed.status === Status.Opdracht ||
                  projectAndSubjectChoosed.status ===
                    Status.Opdrachtbevestiging_gestuurd
                }
                onChange={(checked) =>
                  checked &&
                  setProjectStatus(projectAndSubjectChoosed.id, Status.Opdracht)
                }
              />
              <CCheckbox
                label='Gereed'
                name='statusOrder'
                required={false}
                checked={projectAndSubjectChoosed.status === Status.Gereed}
                onChange={(checked) =>
                  checked &&
                  setProjectStatus(projectAndSubjectChoosed.id, Status.Gereed)
                }
              />
            </>
          )}
        </Paper>

        <Paper>
          <ProjectPurchaseTable/>
        </Paper>
      </div>
    )
  );

  if (isLoading) return <LoadingComponent useBackdrop={false} />;

  return (
    <Container maxWidth={"xl"} style={{ padding: 0 }}>
      <PageAndToolStrip
        onAddButtonClick={progmaUserRights?.projectPurchaseUserRights === UserRights.Editing &&
          projectAndSubjectId
            ? () => openProjectPurchaseForm(projectAndSubjectId)
            : undefined
        }
        secondaryToolStripTools={mySelectControl}
      >
        {pageContent}
      </PageAndToolStrip>

      {projectPurchaseForm.open && projectAndSubjectId && (
        <ProjectPurchaseForm
          open={projectPurchaseForm.open}
          id={projectPurchaseForm.id}
          //projectsAndSubjectsId={projectAndSubjectId}
          onCreated={addProjectPurchase}
          onEdited={editProjectPurchase}
          onClose={closeProjectPurchaseForm}
        />
      )}

      {progmaUserRights?.projectsUserRights === UserRights.Editing &&
        projectForm.open && (
          <ProjectForm
            open={projectForm.open}
            id={projectForm.id}
            onCreated={(project) =>
              addProject(project).then((newId) => {
                if (newId !== undefined) loadProjectsPurchase(newId);
              })
            }
            onEdited={() => {}}
            onClose={closeProjectForm}
          />
        )}
    </Container>
  );
});
