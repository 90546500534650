import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../Common/LoadingComponent";
import { useEffect, useState } from "react";


import { useVStore } from "../../stores/vStore";
import WorkOrderRowDetail from "./WorkOrderRowDetail";
import AppTable, {AppColumn, ColumnType, TableStateValues} from "../../../Common/AppTable";

const columnNameWorkOrderNumber = "workOrderNumber";
const columnNameContactPerson = "contactPerson";
const columnNameSigned = "signed";

const columns: AppColumn[] = [
  { name: columnNameWorkOrderNumber, title: "WB. nummer", visible: "xs" },
  { name: columnNameContactPerson, title: "Contactpersoon", visible: "md" },
  { name: columnNameSigned, title: "Afgetekend", visible: "xs", columnType: ColumnType.boolean },
];

export default observer(function WorkOrdersTable() {
  const {
    workOrdersStore: { workOrders, loadWorkOrders, isLoading },
  } = useVStore();

  useEffect(() => {
    if (!workOrders) loadWorkOrders(false);
  }, [workOrders, loadWorkOrders]);

  const [tableStateValues, setTableStateValues] = useState(() =>
    new TableStateValues(
      columns,
      [{ columnName: columnNameWorkOrderNumber, direction: "desc" }],
      false
    )
  );

  if (isLoading)
    return <LoadingComponent useBackdrop={false} />;

  return (
      <AppTable
        tableName='WorkOrders'
        columns={columns}
        rows={workOrders ? workOrders : []}
        rowDetail={WorkOrderRowDetail}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues} 
      />
  );
});
