import { Grid, Typography } from "@material-ui/core";
import DetailCard, { DetailCardItem } from "../../../Common/DetailCard";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import {
  RowDetailButton,
  RowDetailButtonDelete,
  RowDetailButtonEdit,
} from "../../../Common/RowDetailButtons";
import SmartGridItem from "../../../Common/SmartGridItem";
import { UserRights } from "../../models/progmaUserRights";
import TabComponent from "../../../Common/TabComponent";
import { CellRowPanel } from "../../../Common/TableComponents";
import { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { observer } from "mobx-react-lite";
import Sign from "../../../Common/form/SignForm";
import LoadingComponent from "../../../Common/LoadingComponent";
import FilePreview from "../../../Common/filepreview/FilePreview";
import SyncIcon from '@material-ui/icons/Sync';

export default observer(function WorkOrderRowDetail({ row }: any) {
  const {
    workOrdersStore: {
      workOrders,
      deleteWorkOrder,
      getFilePreview,
      filePreview,
      sign,
      isLoadingFilePreview,
      update
    },
    workOrderFormStore: { openWorkOrderForm },
    progmaUserStore: { progmaUserRights },
  } = useVStore();
  const [signing, setSigning] = useState(false);

  const id: number = row.id;
  const workOrder = workOrders!.find((x) => x.id === id);

  useEffect(() => {
    if (id && (filePreview === undefined || filePreview?.id !== id))
      getFilePreview(id);
  }, [id, filePreview, getFilePreview]);

  if (!workOrder)
    return <Typography color='error'>Kan werkbon niet vinden</Typography>;

  let detailCardItems: DetailCardItem[] = [
    { title: "Werkbonnummer", value: workOrder.workOrderNumber },
    { title: "Klantnaam", value: workOrder.companyName },
    { title: "Projectnaam", value: workOrder.projectName },
    { title: "Contactpersoon", value: workOrder.contactPerson },
    { title: "Afgetekend", value: workOrder.signed, type: "boolean" },
    { title: "Verzonden", value: workOrder.sended, type: "boolean" },
    { title: "Opmerking", value: workOrder.comments },
    { title: "Naam sjabloon", value: workOrder.NameTemplate },
  ];

  const tabContentGeneral = (
    <CellRowPanel>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <SmartGridItem>
          <DetailCard detailCardItems={detailCardItems} />
        </SmartGridItem>

        {progmaUserRights?.workOrdersUserRights === UserRights.Editing && (
          <SmartGridItem>
            <ToolStrip
              flexDirection='column'
              alignItems='start'
              spacing={0.5}
              fullwidth
            >
              <RowDetailButtonEdit
                onClick={() =>
                  workOrder.signed
                    ? window.confirm(
                        "Werkbon is al ondertekend weet u zeker dat u wilt aanpassen"
                      ) && openWorkOrderForm(id)
                    : openWorkOrderForm(id)
                }
              />
                <RowDetailButton
                  text='Updaten'
                  icon={<SyncIcon />}
                  onClick={() =>
                    workOrder.signed
                      ? window.confirm(
                          "Werkbon is al ondertekend weet u zeker dat u wilt updaten"
                        ) && update(id)
                      : update(id)
                  }
                />
              <RowDetailButtonDelete
                onDeleteConfirmed={() => deleteWorkOrder(id)}
              />
              {workOrder.signed === false ? (
                <RowDetailButton
                  text='Ondertekenen'
                  icon={<EditIcon />}
                  onClick={() => setSigning(true)}
                />
              ) : (
                <RowDetailButton
                  text='Opnieuw ondertekenen'
                  icon={<EditIcon />}
                  onClick={() =>
                    window.confirm(
                      "Werkbon is al ondertekend weet u zeker dat u opnieuw wilt ondertekenen?"
                    ) && setSigning(true)
                  }
                />
              )}
              {/* <RowDetailButtonSend
                onClick={() => send(id)}
                isAlreadySended={workOrder.sended}
              /> */}
            </ToolStrip>
          </SmartGridItem>
        )}

        <Grid item xs={12} >
          {isLoadingFilePreview ? (
            <LoadingComponent useBackdrop={false} />
          ) : (
              // <PdfViewer url={filePreview.url} fileName={workOrder.fileName} />
              <FilePreview filename={workOrder.fileName} extension={workOrder.fileExtension} url={filePreview?.url} />
          )}
        </Grid>
      </Grid>
    </CellRowPanel>
  );


  const myTabs: { label: string; tabContent: JSX.Element }[] = [];
  myTabs.push({ label: "Algemeen", tabContent: tabContentGeneral });

  const content =
    myTabs.length > 1 ? <TabComponent tabPanels={myTabs} /> : tabContentGeneral;

  if (signing) {
    return (
      <Sign
        open={true}
        title='Werkbon ondertekenen'
        onSubmit={(values) => sign(id, values)}
        onClose={() => setSigning(false)}
        name={workOrder.contactPerson}
        comments={workOrder.comments}
      />
    );
  }

  // if (filePreviewUrl !== undefined)
  //   return (
  //     <PdfViewer
  //       open={true}
  //       url={filePreviewUrl}
  //       title={workOrder.fileName}
  //       onClose={closeFilePreview}
  //     />
  //   );

  return content;
});
