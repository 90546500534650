import MainLayout from "./Layouts/MainLayout";
import Register from "./features/AppAccount/Register";
import { Redirect, Route, Switch } from "react-router-dom";
import LogIn from "./features/AppAccount/LogIn";
import { useRootStore } from "./stores/rootStore";
import { observer } from "mobx-react-lite";
import TestError from "./features/TestErrors/TestErrors";
import ServerError from "./Pages/ServerError";
import AdministrationsPage from "./features/Administrations/Administrations";
import { useEffect } from "react";
import LoadingComponent from "./Common/LoadingComponent";
import ForgotPassword from "./features/AppAccount/ForgotPassword";
import ResetPassword from "./features/AppAccount/ResetPassword";
import Profile from "./features/Profile/ProfilePage";
import AppV12 from "./V12/AppV12";
import Layout from "./features/AppAccount/Layout";
import TestForm from "./features/TestForm/TestForm";
import AppV13 from "./V13/AppV13";

// import InstallPrompt from './InstallPrompt';
// import { isMobile } from 'react-device-detect';
export default observer(function App() {
  const {
    appUserStore
    //appUserStore: { appUser, getUser, isLoading, isLoggedIn },
  } = useRootStore();
  //const [loading, setLoading] = useState(true);

  // const [showInstallPrompt, setShowInstallPrompt] = useState(true);


  useEffect(() => {
    if (appUserStore.appUser) {
       appUserStore.getUser(); //.finally(() => setLoading(false))
    } 
    // else {
    //   setLoading(false);
    // }
  }, [appUserStore]);


  // const handleCloseInstallPrompt = () => {
  //    setShowInstallPrompt(false);
  // }

  // if (showInstallPrompt && !window.matchMedia('(display-mode: standalone)').matches && isMobile) return <InstallPrompt handleClose={handleCloseInstallPrompt}/>

  //console.log("rendering App.tsx isLoading:" + appUserStore.isLoading)

  if (appUserStore.isLoading)
    return <LoadingComponent content='App laden...' useBackdrop={true} />;

  return (
    <>
      <Switch>
        <Route
          exact
          path='/register'
          render={() => (
            <Layout title={"Registreren"}>
              <Register />
            </Layout>
          )}
        />
        <Route
          exact
          path='/login'
          render={() => (
            <Layout title={"Inloggen"}>
              <LogIn />
            </Layout>
          )}
        />
        <Route
          exact
          path='/forgot-password'
          render={() => (
            <Layout title={"Wachtwoord vergeten"}>
              <ForgotPassword />
            </Layout>
          )}
        />
        <Route path='/reset-password/:id/:token' component={ResetPassword} />
        <Route exact path='/testerrors' component={TestError} />
        <Route exact path='/testform' component={TestForm} />
        <Route exact path='/server-error' component={ServerError} />
        {appUserStore.isLoggedIn ? (
          <Switch>
            <Route
              path='/administrations'
              render={() => {
                //if (appUserStore.appUser?.choosedAdministration) closeAdministration();
                return (
                  <MainLayout useBackButton={false} pageTitle='Administraties'>
                    <AdministrationsPage />
                  </MainLayout>
                );
              }}
            />
            <Route
              path='/profile'
              render={() => (
                <MainLayout useBackButton={true} pageTitle='Profiel'>
                  <Profile />
                </MainLayout>
              )}
            />

            {
              appUserStore.appUser?.choosedAdministration
                ?.administrationVersion === "12" ? (
                <AppV12></AppV12>
              ) : (
                appUserStore.appUser?.choosedAdministration?.administrationVersion === "13"
                ?
                <AppV13/>
                :
                <Redirect to='/administrations' />
              )
              // <Route component={NotFound} />

              // appUserStore.appUser?.choosedAdministration?.administrationVersion === '13'
              // ?
              //   <Switch>
              //       <Route exact path='/' render={() => <MainLayoutV13><Dashboard /></MainLayoutV13>} />
              //       <Route path='/work' render={() => <MainLayoutV13><Work /></MainLayoutV13>} />
              //   </Switch>
              //   :
              //   <Route component={NotFound} />
            }
          </Switch>
        ) : (
          <Redirect to='/login' />
        )}
      </Switch>
    </>
  );
});
