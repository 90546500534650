import { Grid, Typography } from "@material-ui/core";
import {
  RowDetailButtonDelete,
  RowDetailButtonEdit,
} from "../../../Common/RowDetailButtons";
import DetailCard, { DetailCardItem } from "../../../Common/DetailCard";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import SmartGridItem from "../../../Common/SmartGridItem";
import { UserRights } from "../../models/progmaUserRights";
import { CellRowPanel } from "../../../Common/TableComponents";

export default function RelationProjectRowDetail({ row }: any) {
  const {
    relationsStore: { relations, deleteRelation },
    relationFormStore: { openRelationForm },
    progmaUserStore: { progmaUserRights },
  } = useVStore();

  const id: number = row.id;

  const relation = relations!.find((x) => x.id === id);

  if (!relation)
    return <Typography color="error">Kan relatie niet vinden</Typography>;

  let detailCardItems: DetailCardItem[] = [
    { title: "Relatienummer", value: relation.relationNumber },
    { title: "Bedrijfsnaam", value: relation.companyName },
    { title: "Adres (vestiging)", value: relation.adresVisit },
    { title: "Postcode (vestiging)", value: relation.zipCodeVisit },
    { title: "Plaats (vestiging)", value: relation.cityVisit },
  ];

  return (
    <CellRowPanel>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <SmartGridItem>
          <DetailCard detailCardItems={detailCardItems} />
        </SmartGridItem>

        {progmaUserRights?.relationsUserRights === UserRights.Editing && (
          <SmartGridItem>
            <ToolStrip
              flexDirection="column"
              alignItems="start"
              spacing={0.5}
              fullwidth
            >
              <RowDetailButtonEdit onClick={() => openRelationForm(id)} />
              <RowDetailButtonDelete
                onDeleteConfirmed={() => deleteRelation(id)}
              />
            </ToolStrip>
          </SmartGridItem>
        )}
      </Grid>
    </CellRowPanel>
  );
}
