import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { Dashboard } from "../models/dashboard";

const initialValues: { dashboard?: Dashboard; isLoading: boolean } = {
  isLoading: false,
};

export default class DashboardStore {
  dashboard = initialValues.dashboard;
  isLoading = initialValues.isLoading

  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.dashboard = initialValues.dashboard;
    this.isLoading = initialValues.isLoading
  };

  private setIsLoading  = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async () => {
    try {
      this.setIsLoading(true);
      const temp = await vAgent.dashboard.load();
      runInAction(() => (this.dashboard = temp));
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };
}
