// export interface FormObject {
//   open: boolean;
//   id?: number;
// }

export class FormManager {
  //formObject: {open: boolean, id?: number};
  private resetValues: () => void;

  constructor(resetValues: () => void) {
    //this.formObject = {open: false};
    this.resetValues = resetValues;
  }

  openForm = (id?: number): {open: boolean, id?: number} => {
    this.resetValues();
    return { open: true, id: id}
  };

  closeForm = (): {open: boolean, id?: number} => {
    return { open: false, id: undefined}
  };
}
