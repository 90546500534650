import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import * as yup from "yup";
import { Formik } from "formik";
import LoadingComponent from "../../../Common/LoadingComponent";
import FDialog from "../../../Common/form/FDialog";
import { observer } from "mobx-react-lite";
import FTextfield from "../../../Common/form/FTextField";
import {
  Relation,
  RelationFormValues,
  TypeRelation,
} from "../../models/relation";
import ValidationErrors from "../../../Common/form/ValidationErrors";
import { useVStore } from "../../stores/vStore";
import { useEffect } from "react";

interface Props {
  open: boolean;
  id: number | undefined;
  onCreated: (relation: Relation) => void;
  onEdited: (relation: Relation) => void;
  onClose: () => void;
}

const validationSchema = yup.object({
  companyName: yup.string().required("verplicht veld"),
});


export default observer(function RelationForm({
  open,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {
    relationFormStore: { load, isLoading, relationFormValues, create, edit },
  } = useVStore();

  useEffect(() => {
    if (id) load(id);
  }, [id, load]);

  function handleFormSubmit(relationFormValues: RelationFormValues) {
    if (!id) {
      create(relationFormValues)
        .then((relation) => relation && onCreated(relation))
        .finally(() => onClose());
    } else {
      edit(relationFormValues)
        .then((relation) => relation && onEdited(relation))
        .finally(() => onClose());
    }
  }

  if (isLoading)
    return (
      <LoadingComponent
        content='Relatie formulier laden...'
        useBackdrop={true}
      />
    );

  const getValueTypeRelation = (value: string, typeRelation: TypeRelation) => {
    return value.substring(typeRelation, typeRelation + 1) === "1";
  };

  const handleOnChangeTypeRelation = (
    currentValue: string,
    value: boolean,
    typeRelation: TypeRelation,
    setFieldValue: any
  ) => {
    const newChar = value === true ? "1" : "0";
    const pos: number = typeRelation;
    let newValue =
      currentValue.substring(0, pos) +
      newChar +
      currentValue.substring(pos + 1);

    if (typeRelation === TypeRelation.Other && value === true) {
      newValue = "00000" + newChar + "00";
    } else if (typeRelation !== TypeRelation.Other && value === true) {
      newValue =
        newValue.substring(0, TypeRelation.Other) +
        "0" +
        newValue.substring(TypeRelation.Other + 1);
    }

    setFieldValue("typeRelation", newValue);
  };

  const validate = (values: RelationFormValues) => {
    if (values.typeRelation === "00000000")
      return { error: "Kies eerst type relatie" };
    return undefined;
  };

  const initialValues = { error: null, ...relationFormValues };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        const errors = validate(values);
        if (errors) {
          setErrors(errors);
          setSubmitting(false);
        } else handleFormSubmit(values);
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <FDialog
            title={id && id > 0 ? "Relatie aanpassen" : "Nieuwe Relatie"}
            open={open}
            onClose={onClose}
            breakPointFullScreen='md'
          >
            <form noValidate autoComplete='off'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography>Type relatie:</Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='typeRelation_Debtor'
                            size='medium'
                            checked={getValueTypeRelation(
                              values.typeRelation,
                              TypeRelation.Debtor
                            )}
                            onChange={(e) => {
                              handleOnChangeTypeRelation(
                                values.typeRelation,
                                e.target.checked,
                                TypeRelation.Debtor,
                                setFieldValue
                              );
                            }}
                          />
                        }
                        label='Debiteur'
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='typeRelation_Creditor'
                            size='medium'
                            checked={getValueTypeRelation(
                              values.typeRelation,
                              TypeRelation.Creditor
                            )}
                            onChange={(e) => {
                              handleOnChangeTypeRelation(
                                values.typeRelation,
                                e.target.checked,
                                TypeRelation.Creditor,
                                setFieldValue
                              );
                            }}
                          />
                        }
                        label='Crediteur'
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='typeRelation_Supplier'
                            size='medium'
                            checked={getValueTypeRelation(
                              values.typeRelation,
                              TypeRelation.Supplier
                            )}
                            onChange={(e) => {
                              handleOnChangeTypeRelation(
                                values.typeRelation,
                                e.target.checked,
                                TypeRelation.Supplier,
                                setFieldValue
                              );
                            }}
                          />
                        }
                        label='Leverancier'
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='typeRelation_Educator'
                            size='medium'
                            checked={getValueTypeRelation(
                              values.typeRelation,
                              TypeRelation.Educator
                            )}
                            onChange={(e) => {
                              handleOnChangeTypeRelation(
                                values.typeRelation,
                                e.target.checked,
                                TypeRelation.Educator,
                                setFieldValue
                              );
                            }}
                          />
                        }
                        label='Opleider'
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='typeRelation_Insurer'
                            size='medium'
                            checked={getValueTypeRelation(
                              values.typeRelation,
                              TypeRelation.Insurer
                            )}
                            onChange={(e) => {
                              handleOnChangeTypeRelation(
                                values.typeRelation,
                                e.target.checked,
                                TypeRelation.Insurer,
                                setFieldValue
                              );
                            }}
                          />
                        }
                        label='Verzekeraar'
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='typeRelation_Manufacturer'
                            size='medium'
                            checked={getValueTypeRelation(
                              values.typeRelation,
                              TypeRelation.Manufacturer
                            )}
                            onChange={(e) => {
                              handleOnChangeTypeRelation(
                                values.typeRelation,
                                e.target.checked,
                                TypeRelation.Manufacturer,
                                setFieldValue
                              );
                            }}
                          />
                        }
                        label='Fabrikant'
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='typeRelation_Dealer'
                            size='medium'
                            checked={getValueTypeRelation(
                              values.typeRelation,
                              TypeRelation.Dealer
                            )}
                            onChange={(e) => {
                              handleOnChangeTypeRelation(
                                values.typeRelation,
                                e.target.checked,
                                TypeRelation.Dealer,
                                setFieldValue
                              );
                            }}
                          />
                        }
                        label='Dealer'
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='typeRelation_Other'
                            size='medium'
                            checked={getValueTypeRelation(
                              values.typeRelation,
                              TypeRelation.Other
                            )}
                            onChange={(e) => {
                              handleOnChangeTypeRelation(
                                values.typeRelation,
                                e.target.checked,
                                TypeRelation.Other,
                                setFieldValue
                              );
                            }}
                          />
                        }
                        label='Overige'
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {errors.error && (
                  <Grid item xs={12}>
                    <ValidationErrors errors={errors.error} />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FTextfield
                    name='companyName'
                    label='Bedrijfsnaam'
                    required
                    autoComplete='off'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='adresVisit'
                    label='Adres (vestiging)'
                    required={false}
                    autoComplete='off'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='zipCodeVisit'
                    label='Postcode (vestiging)'
                    required={false}
                    autoComplete='off'
                  />
                </Grid>

                <Grid item xs={12}>
                  <FTextfield
                    name='cityVisit'
                    label='Plaats (vestiging)'
                    required={false}
                    autoComplete='off'
                  />
                </Grid>
              </Grid>
            </form>
          </FDialog>
        );
      }}
    </Formik>
  );
});
