import ReactDOM from 'react-dom';
import 'material-react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline';
import '@fontsource/roboto';
import { ThemeProvider } from "@material-ui/styles";
import theme from './themes/theme';
import { Router } from 'react-router-dom';
import { rootStore, RootStoreContext } from './stores/rootStore';
import {createBrowserHistory} from "history";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { nl } from "date-fns/locale";
import { ToastContainer } from 'material-react-toastify';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';


export const history = createBrowserHistory();



ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ToastContainer position='bottom-right' hideProgressBar />
      <ServiceWorkerWrapper/>
    <RootStoreContext.Provider value={rootStore}>
    <Router history={history}>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
      <App />
      </MuiPickersUtilsProvider>
    </Router>
    </RootStoreContext.Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
