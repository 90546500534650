import { Button, Menu, MenuItem } from "@material-ui/core";
import SyncIcon from '@material-ui/icons/Sync';
import { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface Props {
  onReloadActual: () => void;
  onReloadAll: () => void;
}

export default function ReloadActualAndAllButton ({ onReloadActual, onReloadAll }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size='medium'
        variant='outlined'
        // color='secondary'
        onClick={handleClick}
        startIcon={<SyncIcon />}
        endIcon={<ExpandMoreIcon />}
      >
        Herladen
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onReloadActual}>Actuele herladen</MenuItem>
        <MenuItem onClick={onReloadAll}>Alles herladen</MenuItem>
      </Menu>
    </>
  );
}
