import {
  Button,
  ButtonGroup,
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardcon,
} from "@material-ui/icons";

import { DatePicker } from "@material-ui/pickers";

import { isValid,} from "date-fns";
import CSelect from "../../../Common/controls/CSelect";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";
import PageAndToolStrip from "../../../Common/PageAndToolStrip";
import TableExportButton from "../../../Common/TableExportButton";
import { ExportFormats } from "../../../Common/Functions";
import { useVStore } from "../../stores/vStore";
import { observer } from "mobx-react-lite";
import "./../../../Common/Extensions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "265px",
    },
    box: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(2),
    },
    arrowButton: {
      minWidth: "40px",
      maxWidth: "40px",
      // minHeight: "40px",
      // maxHeight: "40px",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
    moreMenu: {
      padding: theme.spacing(2),
    },
  })
);

interface Props {
  onExportClick: (exportFormat: ExportFormats) => void;
  children: any;
}

export default observer(function WorkToolstripAndPage({
  onExportClick,
  children,
}: Props) {
  const classes = useStyles();
  const {
    progmaUserStore: { progmaUserRights },
    workStore: {
      currentDate,
      setCurrentDate,
      weekView,
      setWeekView,
      previousDay,
      nextDay,
      previousWeek,
      nextWeek,
      generalData,
      staffMemberId,
      setStaffMember,
    },
    workFormStore: { openWorkForm },
  } = useVStore();
  const matches = useMediaQuery(theme.breakpoints.up(1280));

  const startDate = currentDate.startOfWeek();
  const endDate = currentDate.endOfWeek();

  const text1 = !weekView
    ? currentDate.formatNL("EEEE d MMMM yyyy")
    : currentDate.formatNL("'week ' w (yyyy)");
  const text2 = !weekView
    ? currentDate.formatNL("('week ' w)")
    : "(" +
      startDate.formatNL("d MMMM") +
      " t/m " +
      endDate.formatNL("d MMMM") +
      ")";

  return (
    <Container maxWidth={"xl"} style={{ padding: 0 }}>
      <PageAndToolStrip
        cssProperties={undefined}
        onAddButtonClick={() => openWorkForm(undefined)}
        primaryToolStripTools={
          matches ? (
            <ToolStrip spacing={0} flexDirection='column' alignItems='start'>
              <Typography>{text1}</Typography>
              <Typography>{text2}</Typography>
            </ToolStrip>
          ) : undefined
        }
        secondaryToolStripTools={[
          <ToolStrip spacing={0}>
            <Button
              variant='outlined'
              size='medium'
              className={classes.arrowButton}
              onClick={!weekView ? previousDay : previousWeek}
            >
              <ArrowBackIcon />
            </Button>

            <DatePicker
              style={{ width: "100px" }}
              value={currentDate}
              size='medium'
              inputVariant='outlined'
              format={weekView ? "'week' w" : "dd-MM-yy"}
              label=''
              showTodayButton
              todayLabel='Vandaag'
              okLabel='Kiezen'
              fullWidth={true}
              cancelLabel='Annuleren'
              onChange={(date) => {
                if (isValid(date)) {
                  setCurrentDate(date!);
                }
              }}
            />

            <Button
              variant='outlined'
              size='medium'
              className={classes.arrowButton}
              onClick={!weekView ? nextDay : nextWeek}
            >
              <ArrowForwardcon />
            </Button>
          </ToolStrip>,
          <ToolStrip limitMoreMenu={674}>
            {progmaUserRights?.workIncludeOthers === true && (
              <div style={{ minWidth: "100px" }}>
                <CSelect
                  name='staffMember'
                  label='Medewerker'
                  required
                  items={
                    generalData &&
                    generalData.staffMembers.map((staffmember) => ({
                      id: staffmember.id,
                      text: staffmember.nameShort,
                    }))
                  }
                  value={staffMemberId}
                  onChange={(value) =>
                    typeof value === "number" && setStaffMember(value)
                  }
                />
              </div>
            )}

            <ButtonGroup disableElevation variant='contained' color='primary'>
              <Button
                size='medium'
                onClick={() => setWeekView(false)}
                disabled={!weekView}
              >
                Dag
              </Button>
              <Button
                size='medium'
                onClick={() => setWeekView(true)}
                disabled={weekView}
              >
                Week
              </Button>
            </ButtonGroup>
            <TableExportButton onExportClick={onExportClick} />
          </ToolStrip>,
        ]}
      >
        {children}
      </PageAndToolStrip>
    </Container>
  );
});
