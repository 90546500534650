import { toast } from 'material-react-toastify';
import { ChangePassword } from "./../models/appUser";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import {
  AppUser,
  AppUserFormValues,
  ForgotPassword,
  ResetPassword,
} from "../models/appUser";
import { history } from "../";
import rootAgent from "../api/rootAgent";
import { RootStore } from "./rootStore";

export default class AppUserStore {
  private rootStore;
  private refreshTokenTimeout: any;

  appUser: AppUser | null = null;
  isLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    reaction(
      () => this.appUser,
      (appUser) => {
        if (appUser?.token) {
          window.localStorage.setItem("progma_token", appUser.token);
        } else {
          window.localStorage.removeItem("progma_token");
        }
      }
    );

    var token: string | null = window.localStorage.getItem("progma_token");
    this.setAppUser(token ? token : undefined);
  }

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  setAppUser = (token: string | undefined) => {
    var appUser: AppUser | null = null;

    if (token) {
      var readedObject = JSON.parse(atob(token.split(".")[1]));

      appUser = {
        token: token,
        username: readedObject.email,
        choosedAdministration: null,
      };

      if (readedObject.AdministrationId) {
        // Has choosedAdministration
        appUser.choosedAdministration = {
          administrationID: +readedObject.AdministrationId,
          administrationName: readedObject.AdministrationName,
          administrationNumber: +readedObject.AdministrationNumber,
          administrationVersion: readedObject.AdministrationVersion,
          administrationUserName: readedObject.AdministrationUsername,
          progmaUserID: +readedObject.ProgmaUserID,
          progmaUserType: +readedObject.ProgmaUserType,
          staffMemberID: readedObject.StaffMemberID ? +readedObject.StaffMemberID : undefined,
          staffMemberNameShort: readedObject.StaffMemberNameShort ? readedObject.StaffMemberNameShort : undefined,
        };

        if (appUser.choosedAdministration.staffMemberID === undefined)
        {
          toast.warning("Uw account is niet gekoppeld aan een medewerker")
        }
      }

      this.startRefreshTokenTimer(token);
    }
    runInAction(() => (this.appUser = appUser));
  };

  register = async (creds: AppUserFormValues) => {
    try {
      const token = await rootAgent.AppAccount.register(creds);
      this.setAppUser(token);
      if (this.isLoggedIn) {
        this.rootStore.resetAllStores();
        history.push("/administrations");
      }
    } catch (error) {
      throw error;
    }
  };

  login = async (creds: AppUserFormValues) => {
    try {
      const token = await rootAgent.AppAccount.login(creds);
      this.setAppUser(token);
      if (this.isLoggedIn) {
        this.rootStore.resetAllStores();
        history.push("/administrations", { autoChoose: true });
      }
    } catch (error) {
      throw error;
    }
  };

  forgotPassword = async (forgotPassword: ForgotPassword) => {
    try {
      const result = await rootAgent.AppAccount.forgotPassword(forgotPassword);
      return result;
    } catch (error) {
      throw error;
    }
  };

  resetPassword = async (resetPassword: ResetPassword) => {
    try {
      const result = await rootAgent.AppAccount.resetPassword(resetPassword);
      return result;
    } catch (error) {
      throw error;
    }
  };

  changePassword = async (changePassword: ChangePassword) => {
    try {
      const result = await rootAgent.AppAccount.changePassword(changePassword);
      return result;
    } catch (error) {
      throw error;
    }
  };

  deleteAccount = async (appUserFormValues: AppUserFormValues) => {
    try {
      const result = await rootAgent.AppAccount.deleteAccount(appUserFormValues);
      if (result) this.logout();
    } catch (error) {
      throw error;
    }
  };

  logout = () => {
    this.rootStore.resetAllStores();
    this.stopRefreshTokenTimer();
    this.appUser = null;
    history.push("/login");
  };

  getUser = async () => {

    try {
      this.setIsLoading(true);
      let newToken: string | undefined;
      if (this.haveActiveAccesToken) {
            newToken = await rootAgent.AppAccount.current();
            this.setAppUser(newToken);
      }
      if (!newToken) {
        await this.refreshToken();
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.setIsLoading(false);
    }
  };

  refreshToken = async () => {
    try {
      this.setIsLoading(true);
      await this.refreshTokenSilent();
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  private refreshTokenSilent = async () => {  
    try {
      this.stopRefreshTokenTimer();
      const token = await rootAgent.AppAccount.refreshToken();
      this.setAppUser(token);
      return token !== undefined;
    } catch (error) {
      console.log(error);
    }
  };

  private startRefreshTokenTimer(token: string) {
    const timeout = this.getTimeout(token);
    this.refreshTokenTimeout = setTimeout(this.refreshTokenSilent, timeout);
  }

  private getTimeout(token: string) {
    const jwtToken = JSON.parse(atob(token.split(".")[1]));
    const expires = new Date(jwtToken.exp * 1000);
    return expires.getTime() - Date.now() - 120 * 1000;
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  get isLoggedIn() {
    return !!this.appUser;
  }

  get haveActiveAccesToken() {
    if (this.appUser?.token && this.getTimeout(this.appUser?.token) > 0) return true;
    return false;
  }
}
