import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../stores/rootStore";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, NavLink } from "react-router-dom";
import { history } from "../";
import { Link as LinkMat } from "@material-ui/core";

import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import theme from "../themes/theme";

interface Props {
  appBarHeight: number;
  useMainMenuIconButton: boolean;
  handleDrawerToggle: () => void;
  useBackButton: boolean;
  pageTitle: string;
}

export default observer(function MainAppBar({
  appBarHeight,
  useMainMenuIconButton,
  handleDrawerToggle,
  useBackButton,
  pageTitle,
}: Props) {
  const [anchorEl_Admin, setAnchorEl_Admin] =
    React.useState<null | HTMLElement>(null);
  const [anchorEl_User, setAnchorEl_User] = React.useState<null | HTMLElement>(
    null
  );
  const open_Admin = Boolean(anchorEl_Admin);
  const open_User = Boolean(anchorEl_User);
  const {
    appUserStore: { isLoggedIn, appUser, logout },
  } = useRootStore();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
      },
      toolbar: {
        minHeight: appBarHeight,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      menuButton: {
        marginLeft: theme.spacing(0),
        [theme.breakpoints.up("xl")]: {
          display: "none",
        },
      },
      //   backbutton: {
      //     minWidth: "32px",
      //     maxWidth: "32px",
      //     minHeight: "32px",
      //     maxHeight: "32px",
      //   },
      pageTitle: {
        marginTop: theme.spacing(0.2),
        marginLeft: theme.spacing(1),
        //marginBottom: theme.spacing(1),
      },
    })
  );

  const classes = useStyles();

  const handleMenu_Admin = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl_Admin(event.currentTarget);
  };

  const handleClose_Admin = () => {
    setAnchorEl_Admin(null);
  };

  const handleMenu_User = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl_User(event.currentTarget);
  };

  const handleClose_User = () => {
    setAnchorEl_User(null);
  };

  const handleLogOut = () => {
    logout();
    handleClose_User();
  };

  const BackButton = useBackButton ? (
    <Box>
      <IconButton
        // className={classes.backbutton}
        color='inherit'
        size='small'
        onClick={() => history.goBack()}
      >
        <ArrowBackIcon />
      </IconButton>
    </Box>
  ) : undefined;

  const PageTitle = (
    <Typography className={classes.pageTitle} noWrap>
      <LinkMat color='inherit' href='' onClick={window.location.reload}>
        {pageTitle}
      </LinkMat>
    </Typography>
  );

  const AdministrationMenu = appUser?.choosedAdministration ? (
    <Box>
      {matches ? (
        <Button
          onClick={handleMenu_Admin}
          color='inherit'
          startIcon={<ViewColumnIcon style={{ width: 28, height: 28 }} />}
        >
          {appUser.choosedAdministration.administrationName} (
          {appUser.choosedAdministration.staffMemberID !== undefined ? appUser.choosedAdministration.staffMemberNameShort : "???"})
        </Button>
      ) : (
        <IconButton
          aria-label='Choosed administration'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu_Admin}
          color='inherit'
          size='small'
        >
          <ViewColumnIcon />
        </IconButton>
      )}
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl_Admin}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open_Admin}
        onClose={handleClose_Admin}
      >
        <List
          subheader={
            <ListSubheader color='primary'>Gekozen administratie</ListSubheader>
          }
        >
          <ListItem>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography>Nummer:</Typography>
                  </td>
                  <td>
                    <Typography>
                      {" "}
                      {appUser.choosedAdministration.administrationNumber}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Naam:</Typography>
                  </td>
                  <td>
                    <Typography>
                      {" "}
                      {appUser.choosedAdministration.administrationName}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Versie:</Typography>
                  </td>
                  <td>
                    <Typography>
                      {" "}
                      {appUser.choosedAdministration.administrationVersion}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography style={{ marginRight: theme.spacing(2) }}>
                      Gebruikersnaam:
                    </Typography>
                  </td>
                  <td>
                    <Typography>
                      {" "}
                      {appUser.choosedAdministration.administrationUserName}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography style={{ marginRight: theme.spacing(2) }}>
                      Medewerker:
                    </Typography>
                  </td>
                  <td>
                    <Typography>
                      {" "}
                      {appUser.choosedAdministration.staffMemberID !== undefined ? appUser.choosedAdministration.staffMemberNameShort : "???"}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            key='administrations'
            component={NavLink}
            to='/administrations'
            exact
            onClick={() => {
              handleClose_Admin();
              //resetStores();
            }}
          >
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary='Administratiekeuze' />
          </ListItem>
        </List>
      </Menu>
    </Box>
  ) : undefined;

  const AppUserMenu = isLoggedIn ? (
    <>
      {matches ? (
        <Button
          onClick={handleMenu_User}
          color='inherit'
          startIcon={<AccountCircle style={{ width: 24, height: 24 }} />}
        >
          {appUser?.username}
        </Button>
      ) : (
        <IconButton
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu_User}
          color='inherit'
          size='small'
        >
          <AccountCircle />
        </IconButton>
      )}
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl_User}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open_User}
        onClose={handleClose_User}
      >
        <MenuItem component={Link} to='/profile' onClick={handleClose_User}>
          Profiel: {appUser?.username}
        </MenuItem>
        <MenuItem onClick={handleLogOut}>Uitloggen</MenuItem>
      </Menu>
    </>
  ) : undefined;

  const MainMenu =
    useMainMenuIconButton && appUser?.choosedAdministration ? (
      <IconButton
        color='inherit'
        aria-label='open drawer'
        edge='start'
        size='small'
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
    ) : undefined;

  return (
    <AppBar position='fixed' className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Box
          display='flex'
          flexDirection='row'
          flexWrap='nowrap'
          alignItems='center'
          justifyContent='space-between'
          style={{ width: "100%" }}
        >
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='nowrap'
            alignItems='center'
          >
            {BackButton && <Box>{BackButton}</Box>}
            <Box>{PageTitle}</Box>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            flexWrap='nowrap'
            alignItems='center'
          >
            {AdministrationMenu && (
              <Box style={{ marginRight: theme.spacing(1) }}>
                {AdministrationMenu}
              </Box>
            )}
            {AppUserMenu && <Box>{AppUserMenu}</Box>}
            {MainMenu && (
              <Box style={{ marginLeft: theme.spacing(1) }}>{MainMenu}</Box>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
});
