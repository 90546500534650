import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { Attachment } from "../models/attachments";
import saveAs from "file-saver";

const initialValues: {
  attachments?: Attachment[];
  isLoading: boolean;
  filePreview?: {id: number, url: string};
  isLoadingFilePreview: boolean;
} = {
  isLoading: false,
  isLoadingFilePreview: false,
};

export default class AttachmentsStore {
  attachments = initialValues.attachments;
  isLoading = initialValues.isLoading;
  filePreview = initialValues.filePreview;
  isLoadingFilePreview = initialValues.isLoadingFilePreview;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.attachments = initialValues.attachments;
    this.isLoading = initialValues.isLoading;
    this.filePreview = initialValues.filePreview;
    this.isLoadingFilePreview = initialValues.isLoadingFilePreview;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  loadAttachmentsByProjectAndSubjectId = async (projectAndSubjectId: number) => {
    try {
      this.setIsLoading(true);
      const attachments = await vAgent.Attachments.loadByProjectAndSubjectId(projectAndSubjectId);
      runInAction(() => {
        this.attachments = attachments;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  downloadAttachment = async(id: number) => {
    try {
      this.setIsLoading(true);
      const attachment = this.attachments?.find(x => x.id === id);
      if (attachment) {
        const blob = await vAgent.Attachments.getBlob(id);
        if (blob) saveAs(blob, attachment.fileName);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  }
  
  getFilePreview = async (id: number) => {
    try {
      runInAction(() => (this.isLoadingFilePreview = true));
      this.closeFilePreview();
      const blob = await vAgent.Attachments.getBlob(id);
      if (blob)
      {
          const fileURL = window.URL.createObjectURL(blob);
          runInAction(() => {
            this.filePreview = {id: id, url: fileURL};
          })
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.isLoadingFilePreview = false));
    }
  }

closeFilePreview =  () => {
  runInAction(() => (this.filePreview = initialValues.filePreview));
}

// addAttachment = async (formValues: AttachmentFormValues) =>
// {
//   try {
//     this.setIsLoading(true);
//     console.log(formValues);
//     const result = await vAgent.Attachments.addAttachment(formValues);
//     if (result) {
//       runInAction(() => {
//         this.attachments = this.attachments ? [result, ...this.attachments] : [result];
//       });
//     }
//   } catch (error) {
//     console.log(error);
//   } finally {
//     this.setIsLoading(false);
//   }
// }

addFile = async(formData: FormData) => {
  try {
    this.setIsLoading(true);
    const result = await vAgent.Attachments.uploadFile(formData);
    if (result) {
      runInAction(() => {
        this.attachments = this.attachments ? [result, ...this.attachments] : [result];
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    this.setIsLoading(false);
  }
}


deleteAttachment = async (id: number) => {
  try {
    this.setIsLoading(true);
    const result = await vAgent.Attachments.delete(id);
    if (result && result > 0) {
      runInAction(() => {
        this.attachments = this.attachments!.filter((x) => x.id !== id);
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    this.setIsLoading(false);
  }
};

editAttachment = (attachment: Attachment) => {
  const temp = this.attachments ? this.attachments.slice() : [];
  temp[temp.findIndex((a) => a.id === attachment.id)] = attachment;
  runInAction(() => (this.attachments = temp));
};

}
