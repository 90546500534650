import { FormManager } from "../../Common/Objects";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { WorkOrderFormValues, WorkOrderGeneralData } from "../models/workOrders";

const initialValues: {
  isLoading: boolean;
  loadNeeded: boolean;
  workOrderGeneralData?: WorkOrderGeneralData;
  workOrderFormValues: WorkOrderFormValues;
} = {
  isLoading: false,
  loadNeeded: true,
  workOrderFormValues: {
    id: 0,
    workOrderNumber: "",
    projectID: undefined,
    templateID: undefined,
    english: false,
    sended: false
  },
};

export default class WorkOrderFormStore {
  private formManager: FormManager;

  isLoading = initialValues.isLoading;
  loadNeeded = initialValues.loadNeeded;
  workOrderGeneralData = initialValues.workOrderGeneralData;
  workOrderFormValues = initialValues.workOrderFormValues;
  workOrderForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.workOrderForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.loadNeeded = initialValues.loadNeeded;
    this.workOrderGeneralData = initialValues.workOrderGeneralData;
    this.workOrderFormValues = initialValues.workOrderFormValues;
    this.workOrderForm = this.formManager.closeForm();
  };

  resetValues = () => {
    this.isLoading = initialValues.isLoading;
    this.workOrderFormValues = initialValues.workOrderFormValues;
    this.workOrderForm = this.formManager.closeForm();
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (id?: number) => {
    try {
      this.setIsLoading(true);
      let workOrderGeneralData = this.workOrderGeneralData;
      let formValues : WorkOrderFormValues | undefined = initialValues.workOrderFormValues;

      if (this.loadNeeded) {
        workOrderGeneralData = await vAgent.WorkOrders.getGeneralData();
        if (workOrderGeneralData !== undefined && workOrderGeneralData.templates.length > 0) formValues.templateID = workOrderGeneralData?.templates[0].id;
      }

      if (id) {
        formValues = await vAgent.WorkOrders.getFormValues(id); //edit
      } else {
        formValues.projectID = this.workOrderFormValues.projectID; //Maintain projectID which is set by openWorkOrderForm (see below)
      }

      runInAction(() => {
        this.workOrderGeneralData = workOrderGeneralData;
        this.workOrderFormValues = formValues
          ? formValues
          : initialValues.workOrderFormValues;
        this.loadNeeded = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  create = async (workOrderFormValues: WorkOrderFormValues) => {
    try {
      return await vAgent.WorkOrders.create(workOrderFormValues);
    } catch (error) {
      console.log(error);
    }
  };

  edit = async (workOrderFormValues: WorkOrderFormValues) => {
    try {
        return await vAgent.WorkOrders.edit(workOrderFormValues);
    } catch (error) {
      console.log(error);
    }
  };

  openWorkOrderForm = (id?: number | undefined, projectId?: number) => {
    var result = this.formManager.openForm(id);
    runInAction(() => {
      this.workOrderFormValues.projectID = projectId;
      this.workOrderForm = result;
    });
  };


  closeWorkOrderForm = () => {
    runInAction(() => {
      this.workOrderForm = this.formManager.closeForm();
    });
  };
}
