import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { Container, Grid, Typography } from "@material-ui/core";
import FTextfield from "../../Common/form/FTextField";
import ValidationErrors from "../../Common/form/ValidationErrors";
import FDatePicker from "../../Common/form/FDatePicker";
import FTimePicker from "../../Common/form/FTimePicker";
import FSelect from "../../Common/form/FSelect";
import FDialog from "../../Common/form/FDialog";

export interface valuesObject {
  id: number;
  name: string;
  description: string;
  date: Date;
  dateString: string;
  time: Date;
  timeString: string;
  myListId: number;
  myListStringId: string;
}

interface listObject {
  id: number;
  name: string;
}

interface listObjectString {
  id: string;
  name: string;
}

export const row: valuesObject = {
  id: 1,
  name: "Nol",
  description: "Mijn omschrijving",
  date: new Date(),
  dateString: "2022-07-14",
  time: new Date(),
  timeString: "14:22",
  myListId: 1,
  myListStringId: "item1",
};

export const listIds: listObject[] = [
  {
    id: 1,
    name: "dit is item1",
  },
  {
    id: 2,
    name: "misschien wel item2",
  },
  {
    id: 3,
    name: "Of anders item3",
  },
];

export const listStringIds: listObjectString[] = [
  {
    id: "item1",
    name: "dit is item1",
  },
  {
    id: "item2",
    name: "misschien wel item2",
  },
  {
    id: "item3",
    name: "Of anders item3",
  },
];

const validationSchema = yup.object({
  name: yup.string().required("verplicht veld"),
  description: yup.string(),
  date: yup.date().required("verplicht veld"),
  dateString: yup.string().nullable(),
  time: yup.date().required("verplicht veld"),
  timeString: yup.string().required("verplicht veld"),
  myListId: yup.number(), //.required("verplicht veld")
  myListStringId: yup.string().required("verplicht veld"),
});

const rowWithError = { error: "", ...row };


export default function TestForm() {
  const [currentValues, setCurrentValues] = useState(rowWithError);
  const [myListIds] = useState(listIds);
  const [myListStringIds] = useState(listStringIds);

  const handleFormSubmit = (values: typeof rowWithError) => {
    setCurrentValues(values);
  };

  return (
    <Container maxWidth='sm'>
      <Grid container spacing={3}>
        <Grid item>
          <Formik
            // enableReinitialize
            initialValues={currentValues}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              try {
                handleFormSubmit(values);
              } catch (error) {
                setErrors({ error: "dit is fout gegaan" });
              } finally {
                setSubmitting(false);
              }
            }}
            validationSchema={validationSchema}
          >
            {({ errors }) => (
              <FDialog
                title='Test formulier'
                open
                onClose={() => {}}
                breakPointFullScreen='xs'
              >
                <form noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FTextfield
                        name='name'
                        label='Naam'
                        required
                        autoFocus
                        autoComplete='off'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FTextfield
                        name='description'
                        label='Omschrijving'
                        multiline
                        minRows={4}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FDatePicker name='date' label='Datum' required />
                    </Grid>

                    <Grid item xs={12}>
                      <FDatePicker
                        name='dateString'
                        label='Datum als string'
                        required={false}
                        valueAsString
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FTimePicker name='time' label='Tijd' required />
                    </Grid>

                    <Grid item xs={12}>
                      <FTimePicker
                        name='timeString'
                        label='TijdString'
                        required
                        valueAsString
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FSelect
                        name='myListId'
                        label='Mijn lijst ID'
                        required
                        items={myListIds.map((x) => ({
                          id: x.id,
                          text: x.name,
                        }))}
                        onAddButtonClick={() => {}}
                        useSearchField
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FSelect
                        name='myListStringId'
                        label='Mijn lijst string ID'
                        required
                        items={myListStringIds.map((x) => ({
                          id: x.id,
                          text: x.name,
                        }))}
                        onAddButtonClick={() => {}}
                        useSearchField
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FSelect
                        name='myListId'
                        label='Simpele lijst ID'
                        required={false}
                        items={myListIds.map((x) => ({
                          id: x.id,
                          text: x.name,
                        }))}
                      />
                    </Grid>

                    {errors.error && (
                      <Grid item xs={12}>
                        <ValidationErrors errors={errors.error} />
                      </Grid>
                    )}

                    {/* <Grid item xs={12}>
                    <FSubmitButton size={"large"} />
                  </Grid> */}
                  </Grid>
                </form>
              </FDialog>
            )}
          </Formik>
        </Grid>
        <Grid item></Grid>
        <Typography>id: {currentValues.id}</Typography>
        <Typography>name: {currentValues.name}</Typography>
        <Typography>date: {currentValues.date.toLocaleDateString()}</Typography>
        <Typography>dateString{currentValues.dateString}</Typography>
        <Typography>time: {currentValues.time.toLocaleTimeString()}</Typography>
        <Typography>timeString {currentValues.timeString}</Typography>
        <Typography>myListId: {currentValues.myListId}</Typography>
        <Typography>myListStringId: {currentValues.myListStringId}</Typography>
      </Grid>
    </Container>
  );
}
