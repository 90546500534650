import {
  ButtonProps,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { Texts } from "../Constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

interface CommonProps {  
  formId?: string;
  children?: any;
  buttonText?: string
  IgnoreDirty?: boolean
}

type Props = CommonProps & ButtonProps

export default function FSubmitButton(props: Props) {
  const classes = useStyles();
  const { submitForm, dirty, isSubmitting } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  return (
    <div className={classes.wrapper}>
      <Button
        form={props.formId}
        type='submit'
        variant='contained'
        color='primary'
        size='medium'
        fullWidth
        disabled={!props.IgnoreDirty ? !dirty || isSubmitting : isSubmitting}
        onClick={handleSubmit}
        {...props}
      >
        {props.children ? props.children : <Typography>{props.buttonText ? props.buttonText : Texts.saveText}</Typography>}
      </Button>
      {isSubmitting && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}
