import { Grid, Typography } from "@material-ui/core";
import DetailCard, { DetailCardItem } from "../../../Common/DetailCard";
import ToolStrip from "../../../Common/ToolStrip";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import {
  RowDetailButtonDelete,
  RowDetailButtonEdit,
} from "../../../Common/RowDetailButtons";
import SmartGridItem from "../../../Common/SmartGridItem";
import { UserRights } from "../../models/progmaUserRights";
import TabComponent, { getTabContent } from "../../../Common/TabComponent";
import ProjectArticlesTable from "../ProjectArticles/ProjectArticlesTable";
import ProjectPurchaseTable from "../ProjectPurchase/ProjectPurchaseTable";
import ProjectFreeTable from "../ProjectFree/ProjectFreeTable";
import { CellRowPanel } from "../../../Common/TableComponents";
import { useEffect } from "react";
import AttachmentsTable from "../Attachments/AttachmentsTable";
import FileUpload from "../../../Common/fileUpload/FileUpload";
import { getFormData } from "../../models/attachments";

export default function ProjectRowDetail({ row }: any) {
  const {
    projectsStore: { projects, deleteProject },
    projectFormStore: { openProjectForm },
    progmaUserStore: { progmaUserRights },
    projectArticlesStore,
    projectArticlesFormStore: { openProjectArticlesForm },
    projectPurchaseStore,
    projectPurchaseFormStore: { openProjectPurchaseForm },
    projectFreeStore,
    projectFreeFormStore: { openProjectFreeForm },
    attachmentsStore,
  } = useVStore();

  const id: number = row.id;

  const project = projects!.find((x) => x.id === id);

    useEffect(() => {
      if (project?.projectAndSubjectID) {
        projectArticlesStore.loadProjectsArticles(project.projectAndSubjectID);
        projectPurchaseStore.loadProjectsPurchase(project.projectAndSubjectID);
        projectFreeStore.loadProjectsFree(project.projectAndSubjectID);
        attachmentsStore.loadAttachmentsByProjectAndSubjectId(
          project.projectAndSubjectID
        );
      } else {
        projectArticlesStore.resetStore();
        projectPurchaseStore.resetStore();
        projectFreeStore.resetStore();
        attachmentsStore.resetStore();
      }
    }, [project?.projectAndSubjectID, projectArticlesStore, projectPurchaseStore, projectFreeStore, attachmentsStore]);


  if (!project)
    return <Typography color="error">Kan project niet vinden</Typography>;

  let detailCardItems: DetailCardItem[] = [
    { title: "Projectnummer", value: project.projectNumber },
    { title: "Klantnaam", value: project.companyName },
    { title: "Projectnaam", value: project.projectName },
    { title: "Status", value: project.status },
    { title: "Naam type", value: project.nameTypeProject },
    { title: "Project omschrijving", value: project.projectDescription },
  ];

  const tabContentGeneral = (
    <CellRowPanel>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <SmartGridItem>
          <DetailCard detailCardItems={detailCardItems} />
        </SmartGridItem>

        {progmaUserRights?.projectsUserRights === UserRights.Editing && (
          <SmartGridItem>
            <ToolStrip
              flexDirection="column"
              alignItems="start"
              spacing={0.5}
              fullwidth
            >
              <RowDetailButtonEdit onClick={() => openProjectForm(id)} />
              <RowDetailButtonDelete
                onDeleteConfirmed={() => deleteProject(id)}
              />
            </ToolStrip>
          </SmartGridItem>
        )}
      </Grid>
    </CellRowPanel>
  );



  const myTabs: { label: string; tabContent: JSX.Element }[] = [];
  myTabs.push({ label: "Algemeen", tabContent: tabContentGeneral });

  if (project.projectAndSubjectID) {
    if (progmaUserRights?.projectArticlesAllow) {
      myTabs.push({
        label: "Artikelen",
        tabContent: getTabContent(() => {
          openProjectArticlesForm(project.projectAndSubjectID);
        }, <ProjectArticlesTable />),
      });
    }

    if (progmaUserRights?.projectPurchaseUserRights !== UserRights.None) {
      myTabs.push({
        label: "Inkoop",
        tabContent: getTabContent(
          progmaUserRights?.projectPurchaseUserRights === UserRights.Editing &&
            project.projectAndSubjectID !== undefined
            ? () => {
                openProjectPurchaseForm(project.projectAndSubjectID!);
              }
            : undefined,
          <ProjectPurchaseTable />
        ),
      });
    }

    if (progmaUserRights?.projectFreeUserRights !== UserRights.None) {
      myTabs.push({
        label: "Vrije invoer",
        tabContent: getTabContent(
          progmaUserRights?.projectFreeUserRights === UserRights.Editing &&
            project.projectAndSubjectID !== undefined
            ? () => {
                openProjectFreeForm(project.projectAndSubjectID!);
              }
            : undefined,
          <ProjectFreeTable />
        ),
      });
    }

    const handleFileUpload = async (file: File) => {
      const formData = getFormData({
        formFile: file,
        projectAndSubjectID: project.projectAndSubjectID,
      });
      attachmentsStore.addFile(formData);
    };

    if (progmaUserRights?.attachmentsUserRights !== UserRights.None) {
      myTabs.push({
        label: "Bijlagen",
        tabContent: getTabContent(
          undefined,
          <>
            {progmaUserRights?.attachmentsUserRights === UserRights.Editing && (
              <div style={{ marginLeft: theme.spacing(2) }}>
                <FileUpload onFileUpload={handleFileUpload} />
              </div>
            )}
            <Grid item xs={12}>
              <AttachmentsTable />
            </Grid>
          </>
        ),
      });
    }
  }

  const content =
    myTabs.length > 1 ? <TabComponent tabPanels={myTabs} /> : tabContentGeneral;

  return content;
}
