import { Column } from "@devexpress/dx-react-grid";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  Switch,
} from "@material-ui/core";
import { useState } from "react";
import theme from "../themes/theme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExportFormats } from "./Functions";

interface Props {
  columns: Column[];
  hiddenColumnNames: string[];
  onHiddenColumnNamesChange: (hiddenColumnNames: string[]) => void;
  useGroupPanel: boolean;
  onUseGroupPanelChanged: (value: boolean) => void;
  useFilterPanel: boolean;
  onUseFilterPanelChanged: (value: boolean) => void;
  onExportClick: (exportFormat: ExportFormats) => void;
}

export default function TableButton({
  columns,
  hiddenColumnNames,
  onHiddenColumnNamesChange,
  useGroupPanel,
  onUseGroupPanelChanged,
  useFilterPanel,
  onUseFilterPanelChanged,
  onExportClick,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //   const handleShowAll = () => {
  //     onHiddenColumnNamesChange(columns.map((x) => x.name));
  //     handleClose();
  //   }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let temp = hiddenColumnNames.slice();
    if (event.target.checked) {
      temp = temp.filter((x) => x !== event.target.name);
    } else {
      temp.push(event.target.name);
    }
    onHiddenColumnNamesChange(temp);
  };

  const handleMenuItemClick = (exportFormat: ExportFormats) => {
    onExportClick(exportFormat);
    handleClose();
  };

  return (
    <>
      {/* <IconButton onClick={handleClick}>
          <VisibilityIcon />
      </IconButton> */}
      <Button
        size="medium"
        variant="outlined"
        // color='secondary'
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
      >
        Tabel
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <List style={{ marginLeft: theme.spacing(1) }}>
            {columns.map((column) => (
              <ListItem key={column.name}>
                <FormControlLabel
                  key={column.name}
                  control={
                    <Checkbox
                      checked={!hiddenColumnNames.includes(column.name)}
                      onChange={handleChange}
                      size="medium"
                      name={column.name}
                      color="secondary"
                    />
                  }
                  label={column.title}
                />
              </ListItem>
            ))}
          </List>

          <Divider />

          <List>
            <ListItem button>
              <ListItemText
                primary="Toon alles"
                onClick={() => onHiddenColumnNamesChange([])}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary="Verberg alles"
                onClick={() =>
                  onHiddenColumnNamesChange(columns.map((x) => x.name))
                }
              />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem>
              <FormControlLabel
                control={
                  <Switch
                    size="medium"
                    checked={useGroupPanel}
                    onChange={(e) => onUseGroupPanelChanged(e.target.checked)}
                  />
                }
                label="Groepeerpaneel"
              />
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={
                  <Switch
                    size="medium"
                    checked={useFilterPanel}
                    onChange={(e) => onUseFilterPanelChanged(e.target.checked)}
                  />
                }
                label="Filterpaneel"
              />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button>
              <ListItemText
                primary="Exporteer naar Excel"
                onClick={() => handleMenuItemClick(ExportFormats.xlsx)}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary="Exporteer naar CSV"
                onClick={() => handleMenuItemClick(ExportFormats.csv)}
              />
            </ListItem>
            <ListItem button>
              <ListItemText
                primary="Exporteer naar PDF"
                onClick={() => handleMenuItemClick(ExportFormats.pdf)}
              />
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
}
