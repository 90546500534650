import { Button, Divider, Menu, MenuItem } from "@material-ui/core";
import { useState } from "react";
import SyncIcon from "@material-ui/icons/Sync";
import { Status } from "../../models/projectAndSubject";
import { observer } from "mobx-react-lite";
import { useVStore } from "../../stores/vStore";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default observer(function ProjectsLoadButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    projectsStore: { loadProjects, loadProjectsByStatus, loadButtonText },
  } = useVStore();

  const handleFilterActual = () => {
    loadProjects(false);
    handleClose();
  };

  const handleFilterAll = () => {
    loadProjects(true);
    handleClose();
  };

  const handleFilterRequests = () => {
    loadProjectsByStatus(
      [Status.Aanvraag, Status.Offerte_gestuurd],
      "Aanvragen"
    );
    handleClose();
  };

  const handleFilterOrder = () => {
    loadProjectsByStatus(
      [Status.Opdracht, Status.Opdrachtbevestiging_gestuurd],
      "In opdracht"
    );
    handleClose();
  };

  const handleFilterReady = () => {
    loadProjectsByStatus([Status.Gereed], "Gereed");
    handleClose();
  };

  const handleFilterInvoiceable = () => {
    loadProjectsByStatus([Status.Factureerbaar], "Factureerbaar");
    handleClose();
  };

  const handleFilterFinished = () => {
    loadProjectsByStatus([Status.Afgerond], "Afgerond");
    handleClose();
  };

  const handleFilterCancelled = () => {
    loadProjectsByStatus([Status.Vervallen], "Vervallen");
    handleClose();
  };

  const handleFilterUnknown = () => {
    loadProjectsByStatus([Status.Onbekend], "Onbekend");
    handleClose();
  };

  //   const handleResetFilters = () => {
  //     onFiltersChange(projects.slice());
  //     handleClose();
  //   };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size='medium'
        variant='outlined'
        // color='secondary'
        onClick={handleClick}
        startIcon={<SyncIcon />}
        endIcon={<ExpandMoreIcon />}
      >
        {loadButtonText}
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleFilterActual}>Actuele projecten</MenuItem>
        <MenuItem onClick={handleFilterAll}>Alle projecten</MenuItem>
        <Divider />
        <MenuItem onClick={handleFilterRequests}>Aanvragen</MenuItem>
        <MenuItem onClick={handleFilterOrder}>In opdracht</MenuItem>
        <MenuItem onClick={handleFilterReady}>Gereed</MenuItem>
        <MenuItem onClick={handleFilterInvoiceable}>Factureerbaar</MenuItem>
        <MenuItem onClick={handleFilterFinished}>Afgerond</MenuItem>
        <MenuItem onClick={handleFilterCancelled}>Vervallen</MenuItem>
        <MenuItem onClick={handleFilterUnknown}>Onbekend</MenuItem>
        {/* <Divider/>
        <MenuItem onClick={handleResetFilters}>Reset filters</MenuItem> */}
      </Menu>
    </>
  );
});
