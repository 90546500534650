import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { ArticleDTO } from "../models/article";

const initialValues: { articles?: ArticleDTO[]; isLoading: boolean } = {
  isLoading: false,
};

export default class ArticlesStore {
  articles = initialValues.articles;
  isLoading = initialValues.isLoading;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.articles = initialValues.articles;
    this.isLoading = initialValues.isLoading;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  loadArticles = async () => {
    try {
      this.setIsLoading(true);
      const articles = await vAgent.Articles.load();
      runInAction(() => {
        this.articles = articles;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  deleteArticle = async (id: number) => {
    try {
      this.setIsLoading(true);
      const result = await vAgent.Articles.delete(id);
      if (result && result > 0) {
        runInAction(() => {
          this.articles = this.articles!.filter((x) => x.id !== id);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  addArticle = (article: ArticleDTO) => {
    const temp = this.articles ? this.articles.slice() : [];
    temp.push(article);
    runInAction(() => (this.articles = temp));
  };

  editArticle = (article: ArticleDTO) => {
    const temp = this.articles ? this.articles.slice() : [];
    temp[temp.findIndex((a) => a.id === article.id)] = article;
    runInAction(() => (this.articles = temp));
  };
}
