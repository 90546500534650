import {
  Column,
  RowDetailState,
  SelectionState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import { useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { TableConstants } from "../../../Common/Constants";
import LoadingComponent from "../../../Common/LoadingComponent";
import {
  CellRowDetail,
  HeaderTitle,
  NumberTypeProvider,
} from "../../../Common/TableComponents";
import theme from "../../../themes/theme";
import { useVStore } from "../../stores/vStore";
import ProjectArticleRowDetail from "./ProjectArticleRowDetail";

export default observer(function ProjectArticlesTable() {
  const {
    projectArticlesStore: {
      isLoading,
      isLoadingProjectArticles,
      projectArticles,
      expandedRowIds,
      setExpandedRowIds,
    },
  } = useVStore();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  let columns: Column[] = [
    { name: "article", title: "Artikel" },
    { name: "quantity", title: "Aantal" },
    { name: "comments", title: "Opmerking" },
  ];

  const numberColumns = ["quantity"];

  if (matches) {
    columns = columns.filter((x) => x.name !== "comments");
  }

  let tableColumnExtensions: any = [
    { columnName: "article", align: "left", wordWrapEnabled: true },
    { columnName: "quantity", align: "right", width: 65 },
    { columnName: "comments", align: "left", wordWrapEnabled: true },
  ];

  const rows = projectArticles.map((x) => ({
    id: x.id,
    article: x.article + " " + x.articleNumber,
    quantity: x.quantity,
    comments: x.comments,
  }));

  const pageContent = isLoadingProjectArticles ? (
    <LoadingComponent useBackdrop={false} />
  ) : (
      <div style={{ marginTop: theme.spacing(1), minWidth: 288 }}>
        {/* <Paper> */}
          <Grid columns={columns} rows={rows} getRowId={(row) => row.id}>
            <RowDetailState
              expandedRowIds={expandedRowIds}
              onExpandedRowIdsChange={setExpandedRowIds}
            />

            <SelectionState
              selection={expandedRowIds}
              onSelectionChange={setExpandedRowIds}
            />

            <NumberTypeProvider for={numberColumns} />

            <Table
              columnExtensions={tableColumnExtensions}
              messages={TableConstants.tableMessages}
            />

            <TableRowDetail
              contentComponent={ProjectArticleRowDetail}
              cellComponent={CellRowDetail}
            />

            <TableSelection
              selectByRowClick
              highlightRow={false}
              showSelectionColumn={false}
            />
            <TableHeaderRow titleComponent={HeaderTitle} />
          </Grid>
      </div>
  );

  if (isLoading) return <LoadingComponent useBackdrop={false} />;

  return (
    <>
      {pageContent}

      {/* {projectArticleForm.open && (
        <ProjectArticleForm
          open={projectArticleForm.open}
          id={projectArticleForm.id}
          // projectsAndSubjectsId={projectAndSubjectId}
          onCreated={addProjectArticle}
          onEdited={editProjectArticle}
          onClose={closeProjectArticlesForm}
        />
      )} */}
    </>
  );
});
