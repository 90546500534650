import { ArticleFormValues } from "../models/article";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { FormManager } from "../../Common/Objects";

const initialValues: {
  isLoading: boolean;
  articleFormValues: ArticleFormValues;
} = {
  isLoading: false,
  articleFormValues: {
    id: 0,
    articleNumber: "",
    type: "",
    name: "",
    inactive: false,
  },
};

export default class ArticleFormStore {
  private formManager: FormManager;
  
  isLoading = initialValues.isLoading;
  articleFormValues = initialValues.articleFormValues;
  articleForm;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.articleForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.articleFormValues = initialValues.articleFormValues;
    this.articleForm = this.formManager.closeForm();
  };

  resetValues = () => {
    this.isLoading = initialValues.isLoading;
    this.articleFormValues = initialValues.articleFormValues;
    this.articleForm = this.formManager.closeForm();
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (id: number) => {
    try {
      this.setIsLoading(true);
      const temp = await vAgent.Articles.loadFormValues(id!);
      if (temp) {
        runInAction(() => {
          this.articleFormValues = temp;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  create = async (article: ArticleFormValues) => {
    try {
      const newItem = await vAgent.Articles.create(article);
      return newItem;
    } catch (error) {
      console.log(error);
    }
  };

  edit = async (article: ArticleFormValues) => {
    try {
      const editedItem = await vAgent.Articles.edit(article);
      return editedItem;
    } catch (error) {
      console.log(error);
    }
  };

  openArticleForm = ( id?: number) => {
    runInAction(() => {
      this.articleForm = this.formManager.openForm(id);
    });
  };

  closeArticleForm = () => {
    runInAction(() => {
      this.articleForm = this.formManager.closeForm();
    });
  };

}
