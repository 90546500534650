import { Box, Button } from "@material-ui/core";
import { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useRootStore } from "../../stores/rootStore";
import { observer } from "mobx-react-lite";

interface Props {
  onFileUpload: (file: File) => void;
}

export default observer(function FileUpload({ onFileUpload }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    commonStore: { isMobile },
  } = useRootStore();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      onFileUpload(file);
    });
  }, [onFileUpload]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop,
  });
  const { ref, ...rootProps } = getRootProps();

  const handleFileUpload = (fileList: FileList) => {
    for (let index = 0; index < fileList.length; index++) {
      onFileUpload(fileList[index]);
    }
  };

  const uploadButton = (
    <Button variant="outlined" color="primary" onClick={isMobile ? () => inputRef.current?.click() : open}>
      Uploaden
    </Button>
  );

  const content = isMobile ? (
    <>
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        onChange={(e) => e.target.files && handleFileUpload(e.target.files)}
        multiple={true}
      />
      {uploadButton}
    </>
  ) : (
    <div ref={ref}>
      <Box
        {...rootProps}
        style={{
          width: 400,
          height: 180,
          padding: 10,
          border: "solid rgba(0, 0, 0, 0.23) 1px",
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CloudUploadIcon color="primary" fontSize="large" />
        <input
          {...getInputProps()}
          onChange={(e) => e.target.files && handleFileUpload(e.target.files)}
        />
        <p>Sleep enkele bestanden hierheen of klik op UPLOADEN</p>
        {uploadButton}
      </Box>
    </div>
  );

  return content;
});
