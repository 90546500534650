import { makeAutoObservable, runInAction } from "mobx";
import rootAgent from "../api/rootAgent";
import { Administration } from "../models/administration";
import { history } from "..";
import { RootStore } from "./rootStore";

const initialValues: {
  administrations?: Administration[];
  isLoading: boolean;
  isLoadingChoose: boolean;
  isLoadingDelete: boolean;
  allowAutoChoose: boolean
} = {
  isLoading: false,
  isLoadingChoose: false,
  isLoadingDelete: false,
  allowAutoChoose: true,
};

export default class AdministrationsStore {
  private rootStore;

  administrations = initialValues.administrations;
  isLoading = initialValues.isLoading;
  isLoadingChoose = initialValues.isLoadingChoose;
  isLoadingDelete = initialValues.isLoadingDelete;
  allowAutoChoose = initialValues.allowAutoChoose;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.administrations = initialValues.administrations;
    this.isLoading = initialValues.isLoading;
    this.isLoadingChoose = initialValues.isLoadingChoose;
    this.isLoadingDelete = initialValues.isLoadingDelete;
    this.allowAutoChoose = initialValues.allowAutoChoose;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  private setIsLoadingChoose = (state: boolean) => {
    runInAction(() => (this.isLoadingChoose = state));
  };

  private setIsLoadingDelete = (state: boolean) => {
    runInAction(() => (this.isLoadingDelete = state));
  };

  loadAdministrations = async () => {
    try {
      this.setIsLoading(true);
      const administrations = await rootAgent.Administrations.list();
      if (administrations) {
        this.setAdministrationsSorted(administrations);
      } else runInAction(() => (this.administrations = []));
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  private setAdministrationsSorted = (administrations: Administration[]) => {
    const administrationsSorted = Array.from(administrations).sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    runInAction(() => {
      this.administrations = administrationsSorted;
    });
  };

  chooseAdministration = async (id: number) => {
    try {
      if (this.allowAutoChoose) runInAction(() => this.allowAutoChoose = false);
      this.setIsLoadingChoose(true);
      const token = await rootAgent.Administrations.choose(id);
      if (token) {
        this.rootStore.resetVStoresOnly();
        this.rootStore.appUserStore.setAppUser(token);
        history.push("/");
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoadingChoose(false);
    }
  };

  closeAdministration = async () => {
    try {
      const token = await rootAgent.Administrations.close();
      if (token) {
        this.rootStore.appUserStore.setAppUser(token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  deleteAdministration = async (id: number) => {
    try {
      this.setIsLoadingDelete(true);
      const result = await rootAgent.Administrations.delete(id);
      if (result && result > 0) {
        runInAction(() => {
          this.administrations = this.administrations?.filter(
            (a) => a.id !== id
          );
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoadingDelete(false);
    }
  };

  addAdministration = (administration: Administration) => {
    const temp = this.administrations ? this.administrations : [];
    temp.push(administration);
    this.setAdministrationsSorted(temp);
  };

  editAdministration = (administration: Administration) => {
    const temp = this.administrations ? this.administrations : [];
    temp[temp.findIndex((a) => a.id === administration.id)] = administration;
    this.setAdministrationsSorted(temp);
  };
}
