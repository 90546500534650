import { Box, useMediaQuery } from "@material-ui/core";
import theme from "../themes/theme";
import AddButton from "./controls/CAddButton";
import MoreMenu from "./MoreMenu";

interface Props {
  onAddButtonClick?: () => void;
  spacing?: number;
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  alignItems?: "start" | "center" | "end" | "flex-start" | "flex-end";
  justifyContent?:
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly";
  fullwidth?: boolean;
  limitMoreMenu?: number;
  children?: any;
}

export default function ToolStrip({
  onAddButtonClick,
  spacing,
  flexDirection,
  alignItems,
  justifyContent,
  fullwidth,
  limitMoreMenu,
  children,
}: Props) {
  const spacingToUse = spacing !== undefined ? spacing : 2;
  const flexDirectionToUse =
    flexDirection !== undefined ? flexDirection : "row";
  const alignItemsToUse = alignItems !== undefined ? alignItems : "center";
  const justifyContentToUse =
    justifyContent !== undefined ? justifyContent : "center";

  const matches = useMediaQuery(`(max-width:${limitMoreMenu}px)`);


  const useMoreMenu = limitMoreMenu && matches;


  const childrenArray = children ? Array.isArray(children) ? children : [children] : [];
  if (onAddButtonClick) childrenArray.unshift(<AddButton onClick={onAddButtonClick} />);

  const childrenToUse = [];
  if (childrenArray.length > 0) {
    for (let i = 0; i < childrenArray.length; i++) {
      if (typeof childrenArray[i] === "object") {
        if (!useMoreMenu){
        switch (flexDirectionToUse) {
          case "row": {
            childrenToUse.push(
              <Box key={i} style={{ marginLeft: i === 0 ? undefined : theme.spacing(spacingToUse), width: fullwidth ? "100%" : undefined }}>
                {childrenArray[i]}
              </Box>
            );
            break;
          }
          case "row-reverse": {
            childrenToUse.push(
              <Box key={i} style={{ marginRight: i === 0 ? undefined : theme.spacing(spacingToUse), width: fullwidth ? "100%" : undefined }}>
                {childrenArray[i]}
              </Box>
            );
            break;
          }
          case "column": {
            childrenToUse.push(
              <Box key={i} style={{ marginTop: i === 0 ? undefined : theme.spacing(spacingToUse), width: fullwidth ? "100%" : undefined }}>
                {childrenArray[i]}
              </Box>
            );
            break;
          }
          case "column-reverse": {
            childrenToUse.push(
              <Box key={i} style={{ marginBottom: i === 0 ? undefined : theme.spacing(spacingToUse), width: fullwidth ? "100%" : undefined }}>
                {childrenArray[i]}
              </Box>
            );
            break;
          }
        }
        } else {
          childrenToUse.push(childrenArray[i]);
        }
      }
    }
  }

  if (!useMoreMenu) {
    return (
      <Box
        display='flex'
        flexDirection={flexDirectionToUse}
        flexWrap='wrap'
        alignItems={alignItemsToUse}
        justifyContent={justifyContentToUse}
        style={{ width: fullwidth ? "100%" : undefined }}
      >
        {childrenToUse}
      </Box>
    );
  } else {
    return <MoreMenu>{childrenToUse}</MoreMenu>;
  }
}
