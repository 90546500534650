import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import LoadingComponent from "../../../Common/LoadingComponent";
import FDialog from "../../../Common/form/FDialog";
import { getProjectDisplayLine } from "../../models/projectAndSubject";
import { observer } from "mobx-react-lite";
import { useVStore } from "../../stores/vStore";
import { WorkOrder, WorkOrderFormValues } from "../../models/workOrders";
import FProjectsAndSubjectsSDTOSelect from "../../../Common/form/FProjectsAndSubjectsSDTOSelect";
import FSelect from "../../../Common/form/FSelect";
import FCheckbox from "../../../Common/form/FCheckbox";

interface Props {
  open: boolean;
  id: number | undefined;
  onCreated: (workOrder: WorkOrder) => void;
  onEdited: (workOrder: WorkOrder) => void;
  onClose: () => void;
}

const validationSchema = yup.object({
  projectID: yup.number().required("verplicht veld"),
  templateID: yup.number().required("verplicht veld"),
  english: yup.boolean().required("verplicht veld"),
});

export default observer(function WorkOrderForm({
  open,
  id,
  onCreated,
  onEdited,
  onClose,
}: Props) {
  const {
    workOrderFormStore: {
      isLoading,
      loadNeeded,
      load,
      workOrderGeneralData,
      workOrderFormValues,
      create,
      edit,
    },
  } = useVStore();

  useEffect(() => {
    if (loadNeeded || id) load(id);
  }, [loadNeeded, load, id]);

  function handleFormSubmit(workOrderFormValues: WorkOrderFormValues) {
    if (!id) {
      create(workOrderFormValues)
        .then((workOrder) => workOrder && onCreated(workOrder))
        .finally(() => onClose());
    } else {
      edit(workOrderFormValues)
        .then((workOrder) => workOrder && onEdited(workOrder))
        .finally(() => onClose());
    }
  }

  if (isLoading)
    return (
      <LoadingComponent
        content='Werkbon formulier laden...'
        useBackdrop={true}
      />
    );

  return (
    <Formik
      enableReinitialize
      initialValues={workOrderFormValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {({ setFieldValue }) => {
        return (
          <FDialog
            title={id && id > 0 ? "Werkbon aanpassen" : "Nieuwe Werkbon"}
            open={open}
            onClose={onClose}
            breakPointFullScreen='md'
            IgnoreDirtyForSubmitButton={!(id && id > 0)}
          >
            <form noValidate autoComplete='off'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FProjectsAndSubjectsSDTOSelect
                    name='projectID'
                    label='Project'
                    required={true}
                    items={
                      workOrderGeneralData &&
                      workOrderGeneralData.projects.map((project) => ({
                        id: project.id,
                        text: getProjectDisplayLine(project),
                        sourceObject: project,
                      }))
                    }
                    allProjectsAndSubjects={false}
                    onAllProjectsAndSubjectsChanged={undefined}
                    isLoadingProjectsAndSubjects={false}
                    useSearchField
                    autoFocus={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FSelect
                    name='templateID'
                    label='Sjabloon'
                    required={true}
                    items={
                      workOrderGeneralData &&
                      workOrderGeneralData.templates.map((x) => ({
                        id: x.id,
                        text: x.name,
                      }))
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FCheckbox name='english' label='Engels' required />
                </Grid>

                {id && id > 0 && (
                  <Grid item xs={12}>
                    <FCheckbox name='sended' label='Verzonden' required />
                  </Grid>
                )}
              </Grid>
            </form>
          </FDialog>
        );
      }}
    </Formik>
  );
});
