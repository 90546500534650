import { ProjectPurchaseFormValues } from "../models/projectPurchase";
import { makeAutoObservable, runInAction } from "mobx";
import vAgent from "../api/vAgent";
import { FormManager } from "../../Common/Objects";
import { Ledger } from "../models/ledger";

const initialValues: {
  isLoading: boolean;
  loadNeeded: boolean;
  projectPurchaseFormValues: ProjectPurchaseFormValues;
  ledgers: Ledger[] | undefined;
} = {
  isLoading: false,
  loadNeeded: true,
  projectPurchaseFormValues: {
    id: 0,
    ledgerID: undefined,
    quantity: 1,
    description: "",
    costPrice: undefined,
    margin: undefined,
    profit: undefined,
    sellingPrice: undefined,
    costPriceTotal: undefined,
    profitTotal: undefined,
    sellingPriceTotal: undefined,
    projectsAndSubjectsID: 0,
  },
  ledgers: undefined
};


export default class ProjectPurchaseFormStore {
  private formManager: FormManager;
  
  isLoading = initialValues.isLoading;
  loadNeeded = initialValues.loadNeeded;
  projectPurchaseFormValues = initialValues.projectPurchaseFormValues;
  projectPurchaseForm;
  ledgers = initialValues.ledgers;

  constructor() {
    this.formManager = new FormManager(this.resetValues);
    this.projectPurchaseForm = this.formManager.closeForm();
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.isLoading = initialValues.isLoading;
    this.loadNeeded = initialValues.loadNeeded;
    this.projectPurchaseFormValues = initialValues.projectPurchaseFormValues;
    this.projectPurchaseForm = this.formManager.closeForm();
    this.ledgers = initialValues.ledgers;
  };

  resetValues = () => {
    runInAction(() => {
      this.isLoading = initialValues.isLoading;
      this.projectPurchaseFormValues = initialValues.projectPurchaseFormValues;
      this.projectPurchaseForm = this.formManager.closeForm();
    });
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  load = async (id?: number) => {
    try {
      this.setIsLoading(true);

      let myLedgers = this.ledgers;
      if (this.loadNeeded) {
        myLedgers = await vAgent.ProjectPurchase.loadLedgers();
      }

      let formValues: ProjectPurchaseFormValues | undefined =
        this.projectPurchaseFormValues;
      if (id) {
        formValues = await vAgent.ProjectPurchase.loadFormValues(id);
      }

      runInAction(() => {
        this.ledgers = myLedgers !== undefined ? myLedgers : [];
        this.projectPurchaseFormValues = formValues
          ? formValues
          : initialValues.projectPurchaseFormValues;
        this.loadNeeded = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };

  create = async (projectPurchaseFormValues: ProjectPurchaseFormValues) => {
    try {
        return await vAgent.ProjectPurchase.create(projectPurchaseFormValues);
    } catch (error) {
      console.log(error);
    }
  };

  edit = async (projectPurchaseFormValues: ProjectPurchaseFormValues) => {
    try {
        return await vAgent.ProjectPurchase.edit(projectPurchaseFormValues);
    } catch (error) {
      console.log(error);
    }
  };

  openProjectPurchaseForm = (projectAndSubjectsId: number, id?: number) => {
    runInAction(() => {
      this.projectPurchaseForm = this.formManager.openForm(id);
      this.projectPurchaseFormValues.projectsAndSubjectsID = projectAndSubjectsId;
    });
  };

  closeProjectPurchaseForm = () => {
    runInAction(() => {
      this.projectPurchaseForm = this.formManager.closeForm();
    });
  };
}
