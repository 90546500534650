import { Button, Container, Grid, Typography } from "@material-ui/core";
import { history } from "..";

export default function AccountNotLinked() {
  return (
    <Container maxWidth='md'>
      <Grid container direction='column' alignItems='center'>
        <Grid item>
          <Typography variant='h6' color='error' style={{ marginTop: "24px" }}>
            Uw account is niet gekoppeld aan een medewerker in de gekozen administratie.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            style={{ marginTop: "16px" }}
            onClick={() => history.goBack()}
          >
            Ga terug...
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            style={{ marginTop: "16px" }}
            onClick={() => history.push("/")}
          >
            Dashboard
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            style={{ marginTop: "16px" }}
            onClick={() => history.push("/administrations")}
          >
            Administraties
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
