import { VStore } from './vStore';
import vAgent from "../api/vAgent";
import { ProgmaUserRights } from "../models/progmaUserRights";
import { makeAutoObservable, runInAction } from "mobx";

const initialValues: { progmaUserRights?: ProgmaUserRights; isLoading: boolean } = {
  isLoading: false,
};

export default class ProgmaUserStore {
  private vStore;

  progmaUserRights = initialValues.progmaUserRights;
  isLoading = initialValues.isLoading;

  constructor(vStore: VStore) {
    this.vStore = vStore;
    makeAutoObservable(this);
  }

  resetStore = () => {
    this.progmaUserRights = initialValues.progmaUserRights;
    this.isLoading = initialValues.isLoading;
  };

  private setIsLoading = (state: boolean) => {
    runInAction(() => (this.isLoading = state));
  };

  loadProgmaUserRights = async () => {
    try {
      //console.log("loadProgmaUserRights");
      this.setIsLoading(true);
      const temp = this.vStore.rootStore.appUserStore.appUser?.choosedAdministration
        ?.progmaUserID
        ? await vAgent.ProgmaUser.loadProgmaUserRights(
          this.vStore.rootStore.appUserStore.appUser?.choosedAdministration?.progmaUserID
          )
        : undefined;
      runInAction(() => {
        this.progmaUserRights = temp;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  };
}
