import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../Common/LoadingComponent";
import { useState } from "react";
import { useVStore } from "../../stores/vStore";
import AppTable, {AppColumn, TableStateValues} from "../../../Common/AppTable";
import AttachmentRowDetail from "./AttachmentRowDetail";

const columnNameFilePath = "filePath";
const columnNameName = "name";
const columnNameExtension = "extension";
const columnNameSize= "size";

const columns: AppColumn[] = [
  { name: columnNameName, title: "Naam", visible: "xs" },
  { name: columnNameExtension, title: "Extensie", visible: "xs" },
  { name: columnNameFilePath, title: "Pad", visible: "md" },
  { name: columnNameSize, title: "Grootte (kB)", visible: "lg", align: "right" },
];

export default observer(function AttachmentsTable() {
  const {
    attachmentsStore: { attachments, isLoading },
  } = useVStore();

  const [tableStateValues, setTableStateValues] = useState(() =>
    new TableStateValues(
      columns,
      [], //{ columnName: columnNameName, direction: "asc" }
      false
    )
  );

  if (isLoading)
    return <LoadingComponent useBackdrop={false} />;

  return (
      <AppTable
        tableName='Bijlagen'
        columns={columns}
        rows={attachments?? []}
        rowDetail={AttachmentRowDetail}
        tableStateValues={tableStateValues}
        setTableStateValues={setTableStateValues} 
      />
  );
});
