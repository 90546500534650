import { rootStore } from "../stores/rootStore";
import {
  Administration,
  AdministrationFormValues,
} from "../models/administration";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { history } from "..";
import {
  AppUserFormValues,
  ChangePassword,
  ForgotPassword,
  ResetPassword,
} from "../models/appUser";
import { toast } from "material-react-toastify";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const isDevelopment = process.env.NODE_ENV === "development";

axios.interceptors.request.use((config) => {
  const token = rootStore.appUserStore.appUser?.token;
  if (token !== undefined) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (isDevelopment) await sleep(500);
    return response;
  },
  (async(error: AxiosError) => {
    if (isDevelopment) console.log(error.response!);
    const { data, status, headers } = error.response!;

    let dataToUse: any = data;
    if (data instanceof Blob)
    {
      dataToUse = await data.text()
      //dataToUse = JSON.parse(await data.text());
      //console.log(dataToUse)
    }

    switch (status) {
      case 400:
        if (typeof dataToUse === "string") {
          toast.error(dataToUse);
        } else if (typeof dataToUse === "object") {
          if (dataToUse.hasOwnProperty("title")) {
            toast.error(dataToUse.title);
          } else if (dataToUse.hasOwnProperty("errors")) {
            const modalStateErrors = [];
            for (const key in dataToUse.errors) {
              if (dataToUse.errors[key]) {
                modalStateErrors.push(dataToUse.errors[key]);
              }
            }
            throw modalStateErrors.flat();
          } else {
            toast.error("Bad request (400)");
          }
        }
        // if (config.method === "get" && dataToUse.errors?.hasOwnProperty("id")) {
        //   history.push("/not-found");
        // }
        if (dataToUse.errors) {
          const modalStateErrors = [];
          for (const key in dataToUse.errors) {
            if (dataToUse.errors[key]) {
              modalStateErrors.push(dataToUse.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        }
        break;
      case 401:
        if (
          headers["www-authenticate"]?.startsWith(
            'Bearer error="invalid_token"'
          )
        ) {
          rootStore.appUserStore.logout();
          toast.error("Sessie is verlopen - graag opnieuw inloggen");
        } else {
          //history.goBack();
          rootStore.appUserStore.logout();
          toast.error("U bent niet bevoegd");
        }
        break;
      case 403:
        toast.error("Fout (403)");
        break;
      case 404:
        if (dataToUse && typeof dataToUse === "string") {
          toast.error(dataToUse);
        } else {
          toast.error("Niet gevonden op de server");
        }
        //history.push("/not-found");
        break;
      case 500:
        if (isDevelopment) {
          rootStore.commonStore.setServerError(dataToUse);
          history.push("/server-error");
        } else {
          toast.error("Er is een server fout opgetreden");
        }
        break;
    }
  }
));

const responseBody = <T>(response: AxiosResponse<T>): T | undefined => {
  if (isDevelopment) {
    console.log(response);
  }
  return response?.data;
};

const requests = {
  get: async <T>(url: string, axiosRequestConfig?: AxiosRequestConfig ) => {
    if (isDevelopment) console.log(url);
    await ensureAccesTokenIfPossible(url);
   const response = await axios.get<T>(url, axiosRequestConfig);
    return responseBody(response);
  },
  post: async <T>(url: string, body: {} | null, axiosRequestConfig?: AxiosRequestConfig) => {
    if (isDevelopment) {
      console.log(url);
      console.log(body);
    }
    await ensureAccesTokenIfPossible(url);
    const response = await axios.post<T>(url, body, { ...axiosRequestConfig, withCredentials: true });
    return responseBody(response);
  },
  put: async <T>(url: string, body: {}, axiosRequestConfig?: AxiosRequestConfig) => {
    if (isDevelopment) {
      console.log(url);
      console.log(body);
    }
    await ensureAccesTokenIfPossible(url);
    const response = await axios.put<T>(url, body, axiosRequestConfig);
    return responseBody(response);
  },
  del: async <T>(url: string) => {
    if (isDevelopment) console.log(url);
    await ensureAccesTokenIfPossible(url);
    const response = await axios.delete<T>(url);
    return responseBody(response);
  },
};

const ensureAccesTokenIfPossible = async (url: string) => {
  if (url !== "/AppAccount/register" && url !== "/AppAccount/login" && url !== "/AppAccount/refreshToken" && !rootStore.appUserStore.haveActiveAccesToken) {
    await rootStore.appUserStore.refreshToken();
  }
}

const AppAccount = {
  current: () => requests.get<string>("/AppAccount"),
  login: (user: AppUserFormValues) =>
    requests.post<string>("/AppAccount/login", user),
  register: (user: AppUserFormValues) =>
    requests.post<string>("/AppAccount/register", user),
  forgotPassword: (forgotPassword: ForgotPassword) =>
    requests.post<boolean>("/AppAccount/forgot-password", forgotPassword),
  resetPassword: (resetPassword: ResetPassword) =>
    requests.post<boolean>("/AppAccount/reset-password", resetPassword),
  changePassword: (changePassword: ChangePassword) =>
    requests.post<boolean>("/AppAccount/change-password", changePassword),
  deleteAccount: (account: AppUserFormValues) =>
    requests.post<boolean>(`/AppAccount/delete`, account),
  refreshToken: () => requests.post<string>("/AppAccount/refreshToken", {}),
};

const Administrations = {
  list: () => requests.get<Administration[]>("/administrations"),
  getFormValues: (id: number) =>
    requests.get<AdministrationFormValues>(`/administrations/${id}`),
  create: (administrationFormValues: AdministrationFormValues) =>
    requests.post<Administration>(
      "/administrations/create",
      administrationFormValues
    ),
  edit: (AdministrationFormValues: AdministrationFormValues) =>
    requests.put<Administration>(
      `/administrations/${AdministrationFormValues.id}`,
      AdministrationFormValues
    ),
  delete: (id: number) => requests.del<number>(`/administrations/${id}`),
  choose: (id: number) =>
    requests.post<string>(`/administrations/choose-administration/${id}`, null),
  close: () =>
    requests.post<string>("/administrations/close-administration/", null),
};

const TestErrors = {
  NotFound: () => requests.get("/buggy/not-found"),
  NotFoundMessage: () => requests.get("/buggy/not-found-message"),
  BadRequest: () => requests.get("/buggy/bad-request"),
  ServerError: () => requests.get("/buggy/server-error"),
  Unauthorised: () => requests.get("/buggy/unauthorised"),
  BadGuid: (id: string) => requests.get(`/buggy/notaguid/${id}`),
  ValidationErrors: () => requests.post("/buggy/validationerrors", {}),
  Crash: () => requests.get("/buggy/crash"),
};

const rootAgent = {
  AppAccount,
  Administrations,
  TestErrors,
  requests,
};

export default rootAgent;
