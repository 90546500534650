export const FileExtensions ={
    pdf: ".pdf",
    jpg: ".jpg",
    jpeg: ".jpeg",
    png: ".png",
    bmp: ".bmp",
    gif: ".gif",
    ico: ".ico",
    svg: ".svg",
}


export const ImgExtensions = [
    FileExtensions.jpg,
     FileExtensions.jpeg,
     FileExtensions.png,
     FileExtensions.bmp,
     FileExtensions.gif,
     FileExtensions.ico,
     FileExtensions.svg
    ]

export const Texts = {
    noDataText: "Geen gegevens",
    newText: "Nieuw",
    saveText: "Opslaan",
    editText: "Aanpassen",
    deleteText: "Verwijderen",
    cancelText: "Annuleren",
    chooseText: "Kiezen",
    clearText: "Wissen",
    nowText: "Nu",
    todayText: "Vandaag",
    sendText: "Verzenden",
}

export const TableConstants = {
    tableMessages: {
        noData: Texts.noDataText
    },
    editColumnMessages: {
        addCommand: Texts.newText,
        editCommand: Texts.editText,
        deleteCommand: Texts.deleteText,
        commitCommand: Texts.saveText,
        cancelCommand: Texts.cancelText,
    },
    groupingPanelMessages: {
        groupByColumn: 'Sleep een kolom hierheen om erop te groeperen'
    },
    filterRowMessages: {
        filterPlaceholder: 'Filter...'
    },
    pagingPanelMessages: {
        showAll: 'Alle',
        rowsPerPage: 'Rijen per pagina',
        info: (parameters: { from: number; to: number; count: number; }) => `${parameters.from} - ${parameters.to} (${parameters.count})`,
    }
}

export const Messages = {
    deleteConfirmationMessage: "Weet u zeker dat u dit item wilt verwijderen?"
}