import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import rootAgent from '../../api/rootAgent';
import ValidationErrors from '../../Common/form/ValidationErrors';

export default function TestErrors() {
    const [validationErrors, setvalidationErrors] = useState(null);

    function handleNotFound() {
        rootAgent.TestErrors.NotFound();
    }

    function handleNotFoundMessage() {
        rootAgent.TestErrors.NotFoundMessage();
    }

    function handleBadRequest() {
        rootAgent.TestErrors.BadRequest();
    }

    function handleServerError() {
        rootAgent.TestErrors.ServerError();
    }

    function handleUnauthorised() {
        rootAgent.TestErrors.Unauthorised();
    }

    function handleBadGuid() {
        rootAgent.TestErrors.BadGuid("");
    }

    function handleValidationError() {
        rootAgent.TestErrors.ValidationErrors().catch((error) => setvalidationErrors(error)
        );
    }

    function handleCrash() {
        rootAgent.TestErrors.Crash();
    }

    return (
        <>
            <h1>Fout afhandeling testen</h1>
            <Grid container spacing={3}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleNotFound}>Not found</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleNotFoundMessage}>Not found message</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleBadRequest}>Bad Request</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleValidationError}>Validation Error</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleServerError}>Server Error</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleUnauthorised}>Unauthorised</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleBadGuid}>Bad Guid</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleCrash}>Crash</Button>
                </Grid>
            </Grid>
            {validationErrors && <ValidationErrors errors={validationErrors} />}
        </>
    )
}

